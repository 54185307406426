import React, { useContext } from 'react';
import GlobalContext from '../../../../context/GlobalContext';
import NotificationItem from '../notificationItem/NotificationItem';
import { stringToDate } from '../../../../CONSTANTS';

const Notifications = ({
  onNotificationOpen,
  openNotificationId,
  onNotificationRegistered,
  openDeclineNotificationId,
  openDeclineNotificationIdChange,
  declineSelectorRef
}) => {
  const { notifications } = useContext(GlobalContext);

  return (
    <>
      {notifications
        .sort(
          (a, b) =>
            stringToDate(a.courseDetails.courseStart) -
            stringToDate(b.courseDetails.courseStart)
        )
        .map((notification) => (
          <NotificationItem
            notification={notification}
            key={`notification_item_${notification.id}`}
            onOpen={onNotificationOpen}
            onRegistered={onNotificationRegistered}
            selected={notification.id === openNotificationId}
            declineOpen={notification.id === openDeclineNotificationId}
            declineOpenChange={(value) => {
              openDeclineNotificationIdChange(value ? notification.id : -1);
            }}
            declineSelectorRef={declineSelectorRef}
          />
        ))}
    </>
  );
};

Notifications.defaultProps = {
  onNotificationOpen: () => {},
  onNotificationRegistered: () => {}
};

export default Notifications;

import { checkInnovatorStatus } from '../../CONSTANTS';

export function getNewBadgesList(tutorialStatus, currentBadges) {
  let newBadgeList = [];

  if (currentBadges) {
    // Check user's all badges and tutorial status of them.
    currentBadges.forEach((badge) => {
      if (
        !checkInnovatorStatus(
          tutorialStatus,
          badge.achievement.slug
        ) /* && !seenOldTutorialStatus*/
      ) {
        newBadgeList.push(badge);
      }
    });
  }

  return newBadgeList;
}

// Check if the user has the specific badge
export function hasBadge(badges, badgeSlug) {
  if (badges) {
    for (let index = 0; index < badges.length; index++) {
      const element = badges[index].achievement.slug;
      if (element === badgeSlug) return true;
    }
  }
  return false;
}

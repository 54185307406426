import React, { useState } from 'react';
import AccessibleButton from '../accessibleButton/AccessibleButton';
import style from './DigilandFilter.module.css';

// options: list of possible values
// optionchange: callback for changes
function DigilandFilter(props) {
  const [activeFilter, setActiveFilter] = useState(props.options[0]);

  return (
    <div className={style.filters}>
      {props.options.map((option, i) => (
        <AccessibleButton
          key={i}
          className={activeFilter === option ? style.filterActive : null}
          onClick={() => {
            setActiveFilter(option);
            props.optionchange(option);
          }}
        >
          {option}
        </AccessibleButton>
      ))}
    </div>
  );
}

export default DigilandFilter;

import React, { Component } from 'react';


// Calendar imports
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import AccessibleButton from '../../../components/accessibleButton/AccessibleButton';

import style from './SessionCalendar.module.css';


export default class SessionCalendar extends Component {
  //used to disable previous months in calendar
  currentMonth;

  constructor(props) {
    super(props);

    this.calendarRef = React.createRef();
    this.state = {
      calendarMonth: '',
      calendarYear: '',
      onCurrentMonth: true,
      isPastSession: null
    };

    this.eventRender = this.eventRender.bind(this);
  }

  componentDidMount() {
    this.updateCalendarState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPastSession !== prevProps.isPastSession) {
      this.updateCalendarState();
    }

    if (
      prevProps.changeDate !== this.props.changeDate &&
      prevProps.changeDate !== null
    ) {
      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.gotoDate(prevProps.changeDate);
      this.updateCalendarState();
    }
  }

  //formats current date to disable previous calendar months
  getCurrentMonth() {
    let dateObj = new Date();
    let month = '';
    month = new Date().getUTCMonth() + 1;
    //let date = new Date().getUTCDate();

    if (month < 10) {
      month = '0' + String(month);
    }
    let year = dateObj.getUTCFullYear();

    // Set end date to first day of next month, for past sessions
    if (this.props.isPastSession) {
      month += 1;
      // Is the month past December? If so, set to January of the next year.
      if (month > 12) {
        year += 1;
        month = '01';
      }
    }
    this.currentMonth = `${year}-${month}-01`;
  }

  changeCalendar(newPosition) {
    let calendarApi = this.calendarRef.current.getApi();
    if (
      newPosition === 1 &&
      (!this.state.isPastSession || !this.state.onCurrentMonth)
    ) {
      calendarApi.next();
    } else if (newPosition === -1) {
      calendarApi.prev();
    } else if (newPosition === 0) {
      calendarApi.today();
    }
    this.updateCalendarState();
  }

  updateCalendarState() {
    this.getCurrentMonth();
    this.setState({ isPastSession: this.props.isPastSession });
    let calendarApi = this.calendarRef.current.getApi();

    const calendarDate = new Date(calendarApi.getDate());

    const currentDate = new Date();
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    this.setState({
      calendarMonth: monthNames[calendarDate.getMonth()],
      calendarYear: calendarDate.getFullYear(),
      onCurrentMonth:
        calendarDate.getMonth() === currentDate.getMonth() &&
        calendarDate.getYear() === currentDate.getYear()
    });
  }

  eventRender(info) {
    let startDate = new Date(info.event.start);
    let endDate = new Date(info.event.end);
    let start =
      startDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      }) +
      ' - ' +
      (startDate.getHours() % 12 === 0 ? 12 : startDate.getHours() % 12) +
      ':' +
      startDate
        .getMinutes()
        .toString()
        .padStart(2, '0') +
      (startDate.getHours() / 12.0 >= 1 ? 'PM' : 'AM') +
      ' - ' +
      (endDate.getHours() % 12 === 0 ? 12 : endDate.getHours() % 12) +
      ':' +
      endDate
        .getMinutes()
        .toString()
        .padStart(2, '0') +
      (endDate.getHours() / 12.0 >= 1.0 ? 'PM' : 'AM');
    info.el.title = `${info.event.title}\n\r${start}`;
  }

  render() {
    return (
      <div className={['flex', style.calendarHolder].join(' ')}>
        <div className={style.calendarTitle}>
          <div className={style.left}>
            <AccessibleButton
              onClick={() => this.changeCalendar(-1)}
              className={[
                this.state.onCurrentMonth && !this.props.isPastSession
                  ? style.disabled
                  : ''
              ].join(' ')}
            >
              &lt;
            </AccessibleButton>
            <span className={style.month}>
              &nbsp;{this.state.calendarMonth}&nbsp;
            </span>
            <AccessibleButton
              onClick={() => this.changeCalendar(1)}
              className={[
                this.state.onCurrentMonth && this.props.isPastSession
                  ? style.disabled
                  : ''
              ].join(' ')}
            >
              &gt;
            </AccessibleButton>
          </div>
          <div className={style.center}>
            {this.state.calendarMonth} {this.state.calendarYear}
          </div>
          <div className={style.right}>
            <AccessibleButton
              onClick={() => this.changeCalendar(0)}
              className={[this.state.onCurrentMonth ? style.disabled : ''].join(' ')}
            >
              Today
            </AccessibleButton>
          </div>
        </div>
        <div className={style.spacer}></div>
            <div className={style.fullCalendarHolder}>
          <FullCalendar
            defaultView="dayGridMonth"
            header={false}
            displayEventTime={false}
            eventClick={(e) =>
              this.props.parentCallback(e.event.extendedProps.courseDetailsId)
            }
            eventStartEditable={false}
            ref={this.calendarRef}
            progressiveEventRendering={false}
            plugins={[dayGridPlugin, interactionPlugin]}
            events={[...this.props.sessions, ...this.props.holidays]}
            aspectRatio={1.7}
            height={'parent'}
            fixedWeekCount={false}
            validRange={{
              start: !this.props.isPastSession ? this.currentMonth : null,
              end: this.props.isPastSession ? this.currentMonth : null
            }}
            eventRender={this.eventRender}
                    />
        </div>
      </div>
    );
  }
}

import React from 'react';
import ReactGA from 'react-ga';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { validateEmail, validateNoReply  } from '../../../../CONSTANTS';
import { toast } from 'react-toastify';
import styles from './PresenterAddModify.module.css';

export default class PresenterAddModify extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.onModify = this.onModify.bind(this);
    this.parseForm = this.parseForm.bind(this);

    this.state = {
      submitting: false,
      name: '',
      email: '',
      bio: '',
      characterLength: 625
    };
    this.originalState = {
      submitting: false,
      name: '',
      email: '',
      bio: ''
    };
  }

  componentDidMount() {
    if (this.props.payload != null) this.parsePayload();
  }

    parseForm(presenterId = null) {
        //Validate EMAIL
        if (this.state.email !== '') {
            if (!validateEmail(this.state.email) && !validateNoReply(this.state.email)) {
                toast('Please enter a valid Email address.', {
                    type: toast.TYPE.ERROR,
                    autoClose: true
                });
                return null;
            }
        }

        let data = {
            name: this.state.name,
            email: this.state.email,
            bio: this.state.bio
        };

        if (presenterId !== null) data.presenterId = presenterId;
        return data;
    }

  add() {
    if (this.state.submitting) return null;

    const data = this.parseForm();
    if (data === null) return null;

    toastRequest.notify('Creating Presenter');
    this.setState({ submitting: true });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Presenters', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: this.abortController.signal
    })
      .then((response) => {
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'Presenter added successfully!'
        );
        if (myJson.success === true) {
          this.setState(this.originalState);
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  update() {
    const data = this.parseForm(this.props.payload.presenterId);
    if (data === null) return null;
    this.props.closeModal();

    toastRequest.notify('Updating Presenter');
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Presenters', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
      // signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'Presenter edited successfully!'
        );
        if (myJson.success === true) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  onModify(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  parsePayload() {
    for (const [key, value] of Object.entries(this.props.payload)) {
      if (value != null) {
        this.setState({ [key]: value });
      }
    }
  }

  render() {
    return (
      <div>
        <Form onSubmit={(event) => event.preventDefault()}>
          {/*TITLE*/}
          <FormGroup>
            <Label for="presenterName">Presenter Name</Label>
            <Input
              type="text"
              value={this.state.name}
              name="name"
              id="presenterName"
              onChange={this.onModify}
            />
          </FormGroup>
          {/*EMAIL*/}
          <FormGroup>
            <Label for={`presenterEmail${!this.props.payload ? 'New' : ''}`}>
              email
            </Label>
            <Input
              type="text"
              value={this.state.email}
              name="email"
              id={`presenterEmail${!this.props.payload ? 'New' : ''}`}
              onChange={this.onModify}
              disabled={this.props.payload != null}
            />
          </FormGroup>
          {/*BIO*/}
          <FormGroup>
            <Label for="presenterBio">Bio</Label>
            <Input
              type="textarea"
              maxLength={this.state.characterLength}
              rows={5}
              value={this.state.bio}
              name="bio"
              id="presenterBio"
              onChange={this.onModify}
            />
            <div
              className={[
                styles.characterCount,
                this.state.characterLength - this.state.bio.length <= 0 &&
                  styles.characterCountZero
              ].join(' ')}
            >
              Characters remaining:{' '}
              {this.state.characterLength - this.state.bio.length}
            </div>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

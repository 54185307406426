import React, { useContext, useRef } from 'react';
import styles from './NotificationItem.module.css';
import { getFormattedDate, getFormattedRange } from '../../../../utils/date';
import { stringToDate } from '../../../../CONSTANTS';
import GlobalContext from '../../../../context/GlobalContext';
import { NotificationStatus } from '../../../../utils/notifications';
import DigiButton from '../../../../components/digiButton/DigiButton';
import RegisterButton from './../../../../components/registerButton/RegisterButton';
import scrollIntoView from 'scroll-into-view-if-needed';
import { isPastSession, getCourseLink } from '../../../../utils/courses';
import DeclineContainer from './components/declineContainer/DeclineContainer';
import { adalPost } from './../../../../adalConfig';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

const NotificationItem = ({
  notification,
  onOpen,
  selected,
  onRegistered,
  declineOpen,
  declineOpenChange,
  declineSelectorRef
}) => {
  const {
    notificationAnimations,
    setNotificationAnimation,
    reloadNotifications
  } = useContext(GlobalContext);

  const itemRef = useRef(null);

  const scrollToTop = () => {
    if (itemRef && itemRef.current) {
      const target = itemRef.current;
      scrollIntoView(target, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const onClose = async () => {
    await adalPost(`/api/Notifications/close/${notification.id}`);
    reloadNotifications();
  };

  const render = () => {
    const courseStart = stringToDate(notification.courseDetails.courseStart);
    const duration = notification.courseDetails.courseDuration;
    return (
      <div className={styles.paddingDiv} ref={itemRef}>
        <div
          className={[
            styles.borderDiv,
            notificationAnimations[notification.id] ||
            notification.status === NotificationStatus.SENT
              ? styles.borderAnimation
              : ''
          ].join(' ')}
          onAnimationStart={() => {
            if (notification.status === NotificationStatus.SENT) {
              setNotificationAnimation(notification.id, true);
            }
          }}
          onAnimationEnd={() => {
            if (notificationAnimations[notification.id]) {
              setNotificationAnimation(notification.id, false);
            }
          }}
        >
          <div
            className={[
              styles.notificationContainer,
              selected && styles.selected
            ].join(' ')}
          >
            {isPastSession(notification.courseDetails) ? (
              <div className={styles.registrationClosedContainer}>
                Registration has closed.
              </div>
            ) : null}
            <div className={styles.paddingContainer}>
              <div>
                Your manager recommended you to register to{' '}
                {isPastSession(notification.courseDetails) ? (
                  <Link
                    className={styles.emphasizedText}
                    onClick={() => {
                      window.open(getCourseLink(notification.courseDetails));
                    }}
                  >
                    {notification.courseDetails.courseName}
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      onOpen(notification.id);
                    }}
                    className={styles.emphasizedText}
                  >
                    {notification.courseDetails.courseName}
                  </Link>
                )}{' '}
                Session.
              </div>
              <div className={styles.emphasizedText}>
                <div>
                  Date: {getFormattedDate(notification.courseDetails.courseStart)}
                </div>
                <div>Time: {getFormattedRange(courseStart, duration)}</div>
              </div>
              <>
                {isPastSession(notification.courseDetails) ? (
                  <div className={styles.buttonHolder}>
                    <DigiButton
                      onClick={() => {
                        window.open(getCourseLink(notification.courseDetails));
                      }}
                    >
                      Materials
                    </DigiButton>
                    <DigiButton
                      className={styles.disabledNotificationButton}
                      disabled
                    >
                      Decline
                    </DigiButton>
                    <DigiButton
                      onClick={() => {
                        onClose(notification.id);
                      }}
                    >
                      Close
                    </DigiButton>
                  </div>
                ) : (
                  <div className={styles.buttonHolder}>
                    <DigiButton
                      onClick={() => {
                        onOpen(notification.id);
                      }}
                      selected={selected}
                    >
                      More
                    </DigiButton>
                    <DigiButton
                      onClick={() => {
                        declineOpenChange(true);
                        declineSelectorRef &&
                          declineSelectorRef.current &&
                          declineSelectorRef.current.select &&
                          declineSelectorRef.current.select.focus();
                      }}
                      selected={declineOpen}
                    >
                      Decline
                    </DigiButton>
                    <RegisterButton
                      area="notification-item"
                      courseDetails={notification.courseDetails}
                      registered={notification.courseDetails.registered}
                      registrationId={notification.courseDetails.registrationId}
                      onRegistered={() => {
                        onRegistered(notification.courseDetailsId);
                      }}
                    ></RegisterButton>
                  </div>
                )}
              </>
            </div>
            <CSSTransition
              in={declineOpen}
              classNames={{
                enter: styles.declineExpand,
                enterActive: styles.declineExpandActive,
                exitActive: styles.declineCollapseActive
              }}
              timeout={300}
              unmountOnExit
            >
              <DeclineContainer
                declineOpenChange={declineOpenChange}
                notification={notification}
                scrollToTop={scrollToTop}
                selectorRef={declineSelectorRef}
              />
            </CSSTransition>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

NotificationItem.defaultProps = {
  onOpen: () => {}
};

export default NotificationItem;

import React from 'react';
import ReactGA from 'react-ga';
import Select from 'react-select';
import {
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  Container,
  Card,
  CardHeader,
  CardBody,
  Table
} from 'reactstrap';
import classnames from 'classnames';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import { deepcopy, stringDateToUtcString } from '../../../../CONSTANTS';
import style from './JobShadowing.module.css';
import CardAdmin from './CardAdmin';

export default class JobShadowingAddModify extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();
    this.toggle = this.toggle.bind(this);
    this.onModify = this.onModify.bind(this);
    this.parseForm = this.parseForm.bind(this);
    this.update = this.update.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.findUser = this.findUser.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.parsePayload = this.parsePayload.bind(this);

    this.defaultFormValues = {
      activeTab: 0,
      notes: '',
      hostId: '',
      hostBranch: '',
      hostCurrentRole: '',
      hostUser: null,
      hostManager: null,
      hostManagerId: '',
      shadowId: '',
      shadowBranch: '',
      shadowCurrentRole: '',
      shadowManagerId: '',
      shadowUserDetails: null,
      shadowReportDetails: null,
      shareITRole: '',
      timelineId: '',
      startDate: '',
      endDate: ''
    };

    this.state = {
      confirmModal: false,
      submitting: false,
      allTimelineOptions: [],
      allUsers: [],
      managerSelectList: [],
      userSelectLists: [],
      branchList: [],
      shareItHost: [],
      index: 0,
      hostListEditIndex: -1,
      isModal: this.props.modModal === false ? 'update' : 'add',
      ...this.defaultFormValues
    };
  }

  //toggles which tab to show 0,1,2....
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  //parses payload from the props
  componentDidMount() {
    this.loadOptions();
    if (this.props.payload != null) this.parsePayload();
  }
  //compwillmount
  componentWillUnmount() {
    this.abortController.abort();
  }

  //includes all the get methods
  loadOptions() {
    //Loads all the users from the users table and used in Select options and finding information
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/dropdown',
      { signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when loading User list', {
            type: toast.TYPE.ERROR,
            autoClose: false
          });
        } else {
          //all users
          const userList = [];
          for (let i = 0; i < myJson.data.length; i++) {
            userList.push({
              email: myJson.data[i].email,
              userId: myJson.data[i].userId,
              name: myJson.data[i].name,
              jobTitle: myJson.data[i].JobTitle
            });
          }
          //users list for SELECT
          const userSelectList = [];
          userList.map((myJson) =>
            userSelectList.push({
              value: myJson.userId,
              label: myJson.email
            })
          );
          this.setState({ userSelectLists: userSelectList });
          //sorted all users
          userList.sort((a, b) => a.email.localeCompare(b.email));
          this.setState({ allUsers: userList });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toast(`Error in loading Users List.`, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      });

    //Loads the actual branches that we have in db from the managers in branchesList
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Group/ShareITOptions',
      { signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when loading branch list', {
            type: toast.TYPE.ERROR,
            autoClose: false
          });
        } else {
          //creates all the branches available in db
          const branchesList = [];
          myJson.data.map((myJson) =>
            branchesList.push({
              value: myJson.groupName,
              label: myJson.groupName
            })
          );
          //including extra branches that are named as office @see static.props
          // this.props.extraBranches.map((branch) =>
          //   branchesList.push({
          //     value: branch,
          //     label: branch
          //   })
          // );
          this.setState({ branchList: branchesList });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toast(`Error in loading Branches List.`, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      });

    //loads all the customized manager list that we have in the db in managerList
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Managers', {
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when attempting to load the list of Managers', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          //makes a SELECT list for managers
          const managerSelectList = [];
          myJson.data.map((myJson) =>
            managerSelectList.push({
              value: myJson.user.userId,
              label: myJson.user.email
            })
          );
          this.setState({ managerSelectList });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toast(`Error in loading Managers.`, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      });

    //loads all the timelineoptions available in the db for ShareIT
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/ShareIttimelineOption',
      {
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast(
            'An error occurred when attempting to load the list of Timeline Options',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          this.setState({ allTimelineOptions: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toast(`Error in loading Timeline options.`, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      });
  }

  //parse the input form,
  parseForm(ShadowId = null) {
    //validate the input such as date and same shadow and host user
    if (!this.checkFields()) {
      return null;
    }

    //here is the data for db
    const data = {
      shadowId: ShadowId,
      shadowUserId: parseInt(this.state.shadowId),
      shadowManagerId: parseInt(this.state.shadowManagerId),
      shadowBranch: this.state.shadowBranch,
      shareItRole: this.state.shareITRole,
      shadowCurrentRole: this.state.shadowCurrentRole,
      createdOn: this.props.payload?.createdOn,
      createdBy: parseInt(this.props.payload?.createdByNavigation?.userId),
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      timelineId: parseInt(this.state.timelineId),
      notes: this.state.notes,
      shareITHost:
        this.state.shareItHost.length < 1
          ? {
              hostId: parseInt(this.props.payload?.shareItHost[0]?.hostId),
              hostUserId: parseInt(this.state.userId),
              hostManagerId: parseInt(this.state.hostManagerId),
              hostBranch: this.state.hostBranch,
              hostCurrentRole: this.state.hostCurrentRole,
              hostManager: this.state.hostManager
            }
          : this.state.shareItHost
    };
    return data;
  }

  clearFields = () => {
    this.setState(() => ({
      hostBranch: '',
      hostId: '',
      hostCurrentRole: '',
      hostUser: null,
      hostManagerId: '',
      hostManager: null
    }));
  };
  /**
   *
   * @param {this is key you are using} key
   * @param {this is the list} listManager
   */
  notIn = (manager, listOfSelectedManagers) => {
    for (let i = 0; i < listOfSelectedManagers.length; i++) {
      //can't be a for each loop as we need to break in the middle.
      if (listOfSelectedManagers[i].hostUserId === manager.value) return false;
    }
    return true;
  };
  /**
   * This is an helper function to remove already added data to the userHostList.
   * This uses
   * @param {The list you want to remove items from } A @see this.state.userSelectList //structure is value : key and label : email address
   * @param {The list you are going to remove items from A} B @see this.state.shareItHost // strucutre is hostUserId and value are same
   * @returns {The list that A that doesn't contain B}
   */
  removeListBFromListA = (A, B) => {
    const values = [];
    A.forEach((manager) => {
      if (this.notIn(manager, B) && manager.value !== this.state.shadowId) {
        // makes sure values array doesn't contain anything from B or the Shadow manager.
        values.push(manager);
      }
    });
    return values;
  };

  /**
   * this is a function given an hostUser Id, it will load it up in the tab for editing hosts.
   * then it will remove it from the host list.
   * @param {the host user Id that was selected} hostUserId
   */
  selectHost = (hostUserId) => {
    for (let i = 0; i < this.state.shareItHost.length; i++) {
      const host = this.state.shareItHost[i];
      if (host.hostUserId === hostUserId) {
        this.setState({
          hostId: host.hostUser.userId,
          hostBranch: host.hostBranch,
          hostCurrentRole: host.hostCurrentRole,
          hostManagerId: host.hostManagerId,
          hostUser: host.hostUser,
          hostManager: host.hostManager,
          hostListEditIndex: i
        });
      }
    }
  };

  addMoreHosts = () => {
    let isNotInHostList = true;
    const listOfHosts = [];
    for (let i = 0; i < this.state.shareItHost.length; i++) {
      if (this.state.hostListEditIndex === i) {
        //if we are editing the state hostListEditIndex is > -1
        //if it already exist in the list, just update it.
        const details = {}; //this is a temperory variable to change one value in the state
        details.hostId = parseInt(this.props.payload?.shareItHost[0]?.hostId);
        details.hostUser = this.state.hostUser;
        details.hostUserId = parseInt(this.state.hostUser.userId);
        details.hostManagerId = parseInt(this.state.hostManagerId);
        details.hostBranch = this.state.hostBranch;
        details.hostCurrentRole = this.state.hostCurrentRole;
        details.hostManager = this.state.hostManager;
        isNotInHostList = false;
        listOfHosts.push(details);
      } else listOfHosts.push(this.state.shareItHost[i]);
    } //end of for loop
    if (isNotInHostList) {
      // if we are adding a new host to the host list
      this.state.shareItHost.push({
        hostId: parseInt(this.props.payload?.shareItHost[0]?.hostId),
        hostUser: this.state.hostUser,
        hostUserId: parseInt(this.state.hostUser.userId),
        hostManagerId: parseInt(this.state.hostManagerId),
        hostBranch: this.state.hostBranch,
        hostCurrentRole: this.state.hostCurrentRole,
        hostManager: this.state.hostManager
      });
    } else this.setState({ shareItHost: listOfHosts });
  };

  checkFields() {
    //check that host and shadow have diferent emails
    if (this.state.hostId === this.state.shadowId) {
      toast(`Host and Shadow can not be the same person.`, {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return false;
    }

    //check that the start date is earlier than the end date
    if (this.state.startDate >= this.state.endDate) {
      toast(
        `End Date value is OLDER than Start Date value. Please update the 'Date' values. Share IT record not added.`,
        {
          type: toast.TYPE.ERROR,
          autoClose: true
        }
      );
      return false;
    }
    return true;
  }

  //create a new Share IT record
  add() {
    if (this.state.submitting) return null;
    //checks if the data from the form are correct/available
    const data = this.parseForm();
    //when data null do nothing
    if (data === null) return;
    toastRequest.notify('Creating Share It Record.');
    this.setState({ submitting: true });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/ShareIT', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: this.abortController.signal
    })
      .then((response) => {
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'Share IT record has been added successfully.'
        );
        if (myJson.success) {
          // this.clearFields();
          this.setState({ shareItHost: [] }); //clears the host list before exiting
          this.props.manualReload();
          this.setState(this.defaultFormValues);
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
        toast(`Error in Adding Share IT record.`, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      });
  }

  //update an existing Share IT record
  //it first deletes the record and updates it with the new details, the reason being
  // the put function, just appends the data to the already existing one, so it is easier to delete and then add the data back
  update() {
    //edits the shadowID present in each components
    const data = this.parseForm(this.props.payload.shadowId);
    //dont edit if the data not valid from parseform
    if (data === null) return;
    //closes the edit modal
    this.props.closeModal();
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        '/api/ShareIT/' +
        this.props.payload.shadowId,
      {
        method: 'DELETE'
      }
    ).then(() => {
      adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/ShareIT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          toast(`Share IT record has been saved successfully.`, {
            type: toast.TYPE.SUCCESS,
            autoClose: true
          });
          if (myJson.success) {
            this.props.manualReload();
          }
        })
        .catch((e) => {
          toast(`Error in saving Share IT record.`, {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
          ReactGA.exception({
            description: e.toString()
          });
        });
    });
  }

  //functions helps to find all the details for users and update in the state
  findUser = (hostId, shadowId, hostManagerId, shadowManagerId) => {
    //find users details of host using hostid
    var hostUser = this.state.allUsers?.find(
      (element) => element.userId === parseInt(hostId)
    );
    this.setState({ hostUser });

    //find shadow user details using shadowid
    var shadowUserDetails = this.state.allUsers?.find(
      (element) => element.userId === parseInt(shadowId)
    );
    this.setState({ shadowUserDetails });

    //finds the host report user details using the hostmanagerid
    var hostManager = this.state.allUsers?.find(
      (element) => element.userId === parseInt(hostManagerId)
    );
    this.setState({ hostManager });

    //finds the shadow repot user details using the shadowManagerId
    var shadowReportDetails = this.state.allUsers?.find(
      (element) => element.userId === parseInt(shadowManagerId)
    );
    this.setState({ shadowReportDetails });
  };

  //updates each and every state if there is any changes in the form
  onModify(e, action) {
    if (!action) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [action.name]: e.value }, () => {
        //also update each user details if theres any changes everytime
        this.findUser(
          this.state.hostId,
          this.state.shadowId,
          this.state.hostManagerId,
          this.state.shadowManagerId
        );
      });
    }
  }
  //parses the payload from props for each and every data coming from db
  parsePayload() {
    for (const [key, value] of Object.entries(this.props.payload)) {
      //setting the state for each using the values from db generically
      if (value !== null) {
        this.setState({ [key]: value });
      }

      //startdate and enddate states
      if (key === 'startDate' || key === 'endDate') {
        const date = stringDateToUtcString(value).split('T')[0];
        if (key === 'startDate') this.setState({ startDate: date });
        if (key === 'endDate') this.setState({ endDate: date });
      }
      //setting the shadow user details state
      if (key === 'shadowUser') {
        this.setState({
          shadowId: value.userId,
          shadowUserDetails: value
        });
      }
      //setting the shadow manager user details state
      if (key === 'shadowManager') {
        this.setState({ shadowReportDetails: value });
      }

      //if we are in edit mode and have previously added hosts.
      if (key === 'shareItHost') {
        const listOfHosts = [];
        value.forEach((host) => {
          // if(host !== value[0]){// when in the edit tab, the 0th values are loaded in, however, we want to be able to ensure we dont' add that again
          const hostDetails = {};
          hostDetails.hostBranch = host.hostBranch;
          hostDetails.hostCurrentRole = host.hostCurrentRole;
          hostDetails.hostId = host.hostId;
          hostDetails.hostManagerId = host.hostManagerId;
          hostDetails.hostManager = {
            email: host.hostManager.email,
            userId: host.hostManager.userId,
            name: host.hostManager.name,
            jobTitle: host.hostManager.jobTitle
          };
          hostDetails.hostUser = {
            email: host.hostUser.email,
            userId: host.hostUser.userId,
            name: host.hostUser.name,
            jobTitle: host.hostUser.jobTitle
          };
          hostDetails.hostUserId = host.hostUserId;
          listOfHosts.push(hostDetails);
          // }
        });
        this.setState({ shareItHost: listOfHosts });
      }
    }
  }

  //confirmation modal for delete/save edited inputs
  toggleConfirmModal() {
    this.setState((prevState) => ({
      confirmModal: !prevState.confirmModal
    }));
  }

  /** isEmpty() returns true if any of the fields are empty. *Returns FALSE if whole form is filled*  */
  isEmpty() {
    if (
      this.state.hostId === '' ||
      this.state.hostBranch === '' ||
      this.state.hostCurrentRole === '' ||
      this.state.hostManagerId === ''
    )
      return true;
  }

  /**
   *
   * @returns true if the form in stage two is filled out.
   */
  isFilled() {
    if (
      this.state.hostId !== '' &&
      this.state.hostBranch !== '' &&
      this.state.hostCurrentRole !== '' &&
      this.state.hostManagerId !== ''
    )
      return true;
  }
  /** isPartial() returns true if form any of the fields are filled.
   *
   * use it with '&& !isEmpty()' to check if form is partially filled but allow to move ahead if it is fully empty for when there's something in the shareItHost */
  isPartial() {
    if (
      this.state.hostId !== '' ||
      this.state.hostBranch !== '' ||
      this.state.hostCurrentRole !== '' ||
      this.state.hostManagerId !== ''
    )
      return true;
  }

  removeHost = (hostUserId) => {
    let list = deepcopy(this.state.shareItHost);
    list = list.filter((host) => host.hostUserId !== hostUserId);
    this.setState({
      shareItHost: list
    });
    if (list === []) this.clearFields();
  };
  /**
   * this function given an index, compares the hostList and returns true if all of the data matches with the ones in the fields.
   * else false
   * @param {the index of the host we are comparing} index
   */
  isAlreadyInHostList(index) {
    return (
      this.state?.shareItHost[index]?.hostUser?.email ===
        this.state?.hostUser?.email &&
      this.state?.shareItHost[index]?.hostBranch === this.state?.hostBranch &&
      this.state?.shareItHost[index]?.hostCurrentRole ===
        this.state?.hostCurrentRole &&
      this.state?.shareItHost[index]?.hostManager.email ===
        this.state?.hostManager.email
    );
  }

  render() {
    return (
      <div>
        <Form onSubmit={(event) => event.preventDefault()}>
          <Container className="shadowHost">
            <Row>
              <Col style={{ paddingLeft: '0px' }}>
                {/*nav bar for tabs*/}
                <Nav tabs className={`${style.tabMargin} ${style.hostshadowTab}`}>
                  <NavItem>
                    <NavLink
                      disabled
                      className={`${style.hostshadowNavlink} ${
                        this.state.activeTab === 0 ? style.hostshadowCurrentTab : ''
                      } ${classnames({
                        active: this.state.activeTab === 0
                      })} `}
                      id="shadowTab"
                      onClick={() => {
                        this.toggle(0);
                      }}
                    >
                      Step 1 - Shadow
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      disabled
                      className={`${style.hostshadowNavlink} ${classnames({
                        active: this.state.activeTab === 1
                      })} ${
                        this.state.activeTab === 1 ? style.hostshadowCurrentTab : ''
                      } `}
                      id="hostTab"
                      onClick={() => {
                        this.toggle(1);
                      }}
                    >
                      Step 2 - Host
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      disabled
                      className={`${style.hostshadowNavlink} ${classnames({
                        active: this.state.activeTab === 2
                      })} ${
                        this.state.activeTab === 2 ? style.hostshadowCurrentTab : ''
                      } `}
                      id="durationTab"
                      onClick={() => {
                        this.toggle(2);
                      }}
                    >
                      Step 3 - Duration
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId={0}>
                    <FormGroup>
                      {/*shadowEmail*/}
                      <Label for={`${this.state.isModal}+shadowId`}>
                        Email Address
                      </Label>
                      <Select
                        components={{ IndicatorSeparator: () => null }}
                        value={this.state.shadowId}
                        placeholder={
                          <div className={style.selectPlaceholder}>
                            {this.state.shadowUserDetails
                              ? this.state.shadowUserDetails.email
                              : ''}
                          </div>
                        }
                        inputId={`${this.state.isModal}+shadowId`}
                        name="shadowId"
                        options={this.state.userSelectLists}
                        onChange={this.onModify}
                      />
                    </FormGroup>
                    {/*shadowBranch*/}
                    <FormGroup>
                      <Label for={`${this.state.isModal}+shadowBranch`}>
                        Branch
                      </Label>
                      <Select
                        components={{ IndicatorSeparator: () => null }}
                        placeholder={
                          <div className={style.selectPlaceholder}>
                            {this.state.shadowBranch}
                          </div>
                        }
                        value={this.state.shadowBranch}
                        inputId={`${this.state.isModal}+shadowBranch`}
                        name="shadowBranch"
                        options={this.state.branchList}
                        onChange={this.onModify}
                      />
                      {/*shadowCurrentRole*/}
                      <FormGroup style={{ marginTop: '16px' }}>
                        <Label for={`${this.state.isModal}+shadowCurrentRole`}>
                          Current role/ position
                        </Label>
                        <Input
                          type="text"
                          value={this.state.shadowCurrentRole}
                          name="shadowCurrentRole"
                          id={`${this.state.isModal}+shadowCurrentRole`}
                          onChange={this.onModify}
                          maxLength="100"
                        ></Input>
                      </FormGroup>
                      {/*shadowManagerId*/}
                      <FormGroup>
                        <Label for={`${this.state.isModal}+shadowManagerId`}>
                          Report to (email address)
                        </Label>
                        <Select
                          components={{ IndicatorSeparator: () => null }}
                          value={this.state.shadowManagerId}
                          placeholder={
                            <div className={style.selectPlaceholder}>
                              {this.state.shadowReportDetails
                                ? this.state.shadowReportDetails.email
                                : ''}
                            </div>
                          }
                          inputId={`${this.state.isModal}+shadowManagerId`}
                          name="shadowManagerId"
                          options={this.state.managerSelectList}
                          onChange={this.onModify}
                          maxLength="100"
                        />
                      </FormGroup>
                      {/*next button*/}
                      <Button
                        disabled={
                          this.state.shadowId === '' ||
                          this.state.shadowBranch === '' ||
                          this.state.shadowCurrentRole === '' ||
                          this.state.shadowManagerId === ''
                        }
                        color="primary"
                        size="md"
                        className={`${style.hostshadowButton} ${style.submitBtn +
                          classnames({
                            active: this.state.activeTab === 1
                          })}`}
                        id="nextTab"
                        onClick={() => {
                          this.state.shadowId !== this.state.shadowManagerId //if the host and their manager is different
                            ? this.toggle(1)
                            : toast(
                                'You cannot have the same person as Shadow and Manager',
                                {
                                  type: toast.TYPE.ERROR,
                                  autoClose: true
                                }
                              ); //throw error because of the above reason
                        }}
                      >
                        Next
                      </Button>
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId={1}>
                    <FormGroup>
                      {/*Host email and id*/}
                      <Label for={`${this.state.isModal}+hostId`}>
                        Email Address
                      </Label>
                      <Select
                        components={{ IndicatorSeparator: () => null }}
                        value={this.state.hostId}
                        placeholder={
                          <div className={style.selectPlaceholder}>
                            {this.state.hostUser ? this.state.hostUser.email : ''}
                          </div>
                        }
                        inputId={`${this.state.isModal}+hostId`}
                        name="hostId"
                        options={this.removeListBFromListA(
                          this.state.userSelectLists,
                          this.state.shareItHost
                        )}
                        onChange={this.onModify}
                      />
                    </FormGroup>
                    {/*hostBranch*/}
                    <FormGroup>
                      <Label for={`${this.state.isModal}+hostBranch`}>Branch</Label>
                      <Select
                        components={{ IndicatorSeparator: () => null }}
                        value={this.state.hostBranch}
                        placeholder={
                          <div className={style.selectPlaceholder}>
                            {this.state.hostBranch}
                          </div>
                        }
                        inputId={`${this.state.isModal}+hostBranch`}
                        name="hostBranch"
                        options={this.state.branchList}
                        onChange={this.onModify}
                      />
                    </FormGroup>
                    {/*hostCurrentRole*/}
                    <FormGroup>
                      <Label for={`${this.state.isModal}+hostCurrentRole`}>
                        Current role/ position
                      </Label>
                      <Input
                        type="text"
                        name="hostCurrentRole"
                        id={`${this.state.isModal}+hostCurrentRole`}
                        value={this.state.hostCurrentRole}
                        onChange={this.onModify}
                        maxLength="100"
                      ></Input>
                    </FormGroup>
                    {/*hostManagerId*/}
                    <FormGroup>
                      <Label for={`${this.state.isModal}+hostManagerId`}>
                        Report to (email address)
                      </Label>
                      <Select
                        components={{ IndicatorSeparator: () => null }}
                        value={this.state.hostManagerId}
                        placeholder={
                          <div className={style.selectPlaceholder}>
                            {this.state.hostManager
                              ? this.state.hostManager.email
                              : ''}
                          </div>
                        }
                        inputId={`${this.state.isModal}+hostManagerId`}
                        name="hostManagerId"
                        options={this.state.managerSelectList}
                        onChange={this.onModify}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Row
                        className="flex"
                        style={{ marginRight: '2px', marginLeft: '2px' }}
                      >
                        {/*Add More button*/}
                        <Button
                          style={{ marginRight: '15px' }}
                          disabled={
                            this.isEmpty() ||
                            (this.state.hostListEditIndex > -1 &&
                              this.isAlreadyInHostList(this.state.hostListEditIndex))
                          }
                          color="primary"
                          size="md"
                          className={`${
                            style.hostshadowButton
                          } flex-fixed ${style.submitBtn +
                            classnames({
                              active: this.state.activeTab === 0
                            })}`}
                          id="moreHostsTab"
                          onClick={() => {
                            if (this.state.hostId !== this.state.hostManagerId) {
                              this.addMoreHosts();
                              this.clearFields();
                              this.setState({ hostListEditIndex: -1 });
                            }
                            //throw error if host and shadow are same
                            else
                              toast(
                                'You cannot have the same person as host and Manager',
                                {
                                  type: toast.TYPE.ERROR,
                                  autoClose: true
                                }
                              ); //throw error because of the above reason
                          }}
                        >
                          {this.state.hostListEditIndex > -1
                            ? 'Update Host'
                            : 'Add Host'}
                          {/* the above code checks if the user already exit in the shareItHost, if so then we are updating a host.*/}
                        </Button>

                        {/*Cancel button*/}
                        <Button
                          disabled={
                            this.state.hostId === '' &&
                            this.state.hostBranch === '' &&
                            this.state.hostCurrentRole === '' &&
                            this.state.hostManagerId === ''
                          }
                          color="primary"
                          size="md"
                          className={`${
                            style.hostshadowButton
                          } flex-fixed ${style.submitBtn +
                            classnames({
                              active: this.state.activeTab === 0
                            })}`}
                          id="cancelTab"
                          onClick={() => {
                            this.clearFields();
                            this.setState({ hostListEditIndex: -1 });
                          }}
                        >
                          Cancel
                        </Button>

                        <div className="flex-auto"></div>

                        {/*previous button*/}
                        <Button
                          style={{ marginRight: '15px' }}
                          color="primary"
                          size="md"
                          className={`${
                            style.hostshadowButton
                          } flex-fixed ${style.submitBtn +
                            classnames({
                              active: this.state.activeTab === 0
                            })}`}
                          id="prevTab"
                          onClick={() => {
                            this.toggle(0);
                          }}
                        >
                          Previous
                        </Button>

                        {/*next button*/}
                        <Button
                          disabled={
                            /**The following condition makes sure that NEXT is disabled when:
                             * 1.  when shareItHost is empty and any of the fields are missing ----> ( this.state.shareItHost.length === 0 && this.isEmpty() )
                             * 2.  shareItHost is not empty and details are partially filled [we don't want to add partial record when calling addMoreHosts(), right?] ----> ( this.isPartial() && this.isEmpty() ) */

                            (this.state.shareItHost.length === 0 ||
                              this.isPartial()) &&
                            (this.isEmpty() || this.isFilled())
                          }
                          color="primary"
                          size="md"
                          className={`${
                            style.hostshadowButton
                          } flex-fixed ${style.submitBtn +
                            classnames({
                              active: this.state.activeTab === 2
                            })}`}
                          id="addTab"
                          onClick={() => {
                            this.toggle(2);
                            this.isPartial() && this.addMoreHosts();
                            this.state.shareItHost.length >= 1 && this.clearFields();
                            this.setState({ hostListEditIndex: -1 });
                          }}
                        >
                          Next
                        </Button>
                      </Row>
                    </FormGroup>
                    {this.state.shareItHost.length > 0 && (
                      <Table size="sm" striped>
                        <thead>
                          <tr>
                            <th>Host Email &nbsp;</th>
                            <th>Branch &nbsp;</th>
                            <th>Current Role</th>
                            <th>Report To</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.shareItHost?.map((host) => {
                            return (
                              <tr key={host.hostUserId}>
                                <td>{host.hostUser.email}</td>
                                <td>{host.hostBranch}</td>
                                <td className={style.overflowWrap}>
                                  {host.hostCurrentRole}
                                </td>
                                <td>{host.hostManager?.email}</td>
                                <td>
                                  <button
                                    className={style.removeButton}
                                    onClick={() => {
                                      this.selectHost(host.hostUserId);
                                    }}
                                  >
                                    Select
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className={style.removeButton}
                                    onClick={() => {
                                      this.removeHost(host.hostUserId);
                                      this.setState({ hostListEditIndex: -1 });
                                    }}
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </TabPane>
                  <TabPane tabId={2}>
                    {/*shareITRole*/}
                    <FormGroup>
                      <Label for={`${this.state.isModal}+shareITRole`}>
                        Share IT role/position
                      </Label>
                      <Input
                        type="text"
                        value={this.state.shareITRole}
                        name="shareITRole"
                        id={`${this.state.isModal}+shareITRole`}
                        onChange={this.onModify}
                        maxLength="100"
                      ></Input>
                    </FormGroup>
                    <Row>
                      <Col>
                        {/*startDate*/}
                        <FormGroup>
                          <Label for={`${this.state.isModal}+startDate`}>
                            Start Date
                          </Label>
                          <Input
                            type="date"
                            value={this.state.startDate}
                            name="startDate"
                            id={`${this.state.isModal}+startDate`}
                            onChange={this.onModify}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        {/*endDate*/}
                        <FormGroup>
                          <Label for={`${this.state.isModal}+endDate`}>
                            End Date
                          </Label>
                          <Input
                            type="date"
                            value={this.state.endDate}
                            name="endDate"
                            id={`${this.state.isModal}+endDate`}
                            onChange={this.onModify}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      {/*timeline*/}
                      <Label for={`${this.state.isModal}+timelineId`}>
                        Timeline
                      </Label>
                      <Input
                        type="select"
                        value={this.state.timelineId}
                        name="timelineId"
                        id={`${this.state.isModal}+timelineId`}
                        onChange={this.onModify}
                      >
                        <option disabled value={''} key={'default-key'}></option>
                        {this.state.allTimelineOptions.map((option) => (
                          <option
                            value={option.timelineId}
                            id={'timelineId-' + option.timelineId}
                            key={'timelineId-' + option.timelineId}
                          >
                            {option.timelineDesc}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <Row>
                      {/*SHADOW/HOST DETAILS CARD*/}
                      <Col className={style.collapseShadowHost_column}>
                        <Card className={style.collapseShadowHost_card}>
                          <CardHeader
                            className={`${style.collapseShadowHost_cardHeader} ${style.collapseShadowHost_cardBold}`}
                          >
                            Shadow
                          </CardHeader>
                          <CardBody>
                            <ul className={style.collapseShadowHost_infoList}>
                              <li className={style.collapseShadowHost_cardBold}>
                                {this.state.shadowUserDetails?.name}
                              </li>
                              <li className={style.overflowWrap}>
                                {this.state.shadowCurrentRole}
                              </li>
                              <li>{this.state.shadowUserDetails?.email}</li>
                              <li>
                                <i>{this.state.shadowBranch}</i>
                              </li>
                              <br />
                              <li className={style.collapseShadowHost_cardBold}>
                                Report to:
                              </li>
                              <li>{this.state.shadowReportDetails?.name}</li>
                              <li>{this.state.shadowReportDetails?.email}</li>
                              <li>Manager</li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col className={style.collapseShadowHost_column}>
                        {/* {this.state.shareItHost.length > 1
                          ? this.multipleHosts()
                          : this.singleHost()} */}
                        {/* {CardAdmin(this.state)} */}
                        <CardAdmin host={this.state} />
                      </Col>
                    </Row>

                    {/*Notes*/}
                    <FormGroup style={{ marginTop: '2px' }}>
                      <Label for={`${this.state.isModal}+notes`}>Note</Label>
                      <Input
                        value={this.state.notes}
                        type="textarea"
                        name="notes"
                        id={`${this.state.isModal}+notes`}
                        onChange={this.onModify}
                        maxLength="4000"
                      ></Input>
                      <Row className="float-right" style={{ marginRight: '4px' }}>
                        {/*Previous button*/}
                        <Button
                          color="primary"
                          size="md"
                          className={
                            style.submitBtn +
                            classnames({
                              active: this.state.activeTab === 1
                            })
                          }
                          id="prevTab"
                          onClick={() => {
                            this.toggle(1);
                            this.setState({ hostListEditIndex: -1 });
                          }}
                        >
                          Previous
                        </Button>
                        {/*Checks if modifying modal is closed and show the ADD button to create new SHARE IT record or else update*/}
                        {
                          <Button
                            disabled={
                              this.state.shareITRole === '' ||
                              this.state.startDate === '' ||
                              this.state.endDate === '' ||
                              this.state.timelineId === ''
                            }
                            style={{ marginLeft: '15px' }}
                            color="primary"
                            size="md"
                            className={`${style.hostshadowButton} ${style.submitBtn}`}
                            onClick={() =>
                              this.props.editModal ? this.update() : this.add()
                            }
                          >
                            {this.props.editModal ? 'Save' : 'Submit'}
                          </Button>
                        }
                      </Row>
                    </FormGroup>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    );
  }
}

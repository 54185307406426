import React from 'react';
import style from './../navbar/Header.module.css';
import { Link } from 'react-router-dom';
import { officeFilterStateStore } from '../../pages/manager/components/OfficeFilter.jsx';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

const managerRouteRoot = '/manager/';
const upcomingRoute = `${managerRouteRoot}upcoming`;
const attendedRoute = `${managerRouteRoot}attended`;
const recommendedRoute = `${managerRouteRoot}recommended`;
const mandatoryRoute = `${managerRouteRoot}mandatory`;

function ManagerDashboardHeader() {
  const [officeFilterSelection, setOfficeFilterSelection] = useState([]);
  useEffect(() => {
    officeFilterStateStore.listenToChanges(setOfficeFilterSelection);
    return () => {
      officeFilterStateStore.stopListening(setOfficeFilterSelection);
    };
  }, []);

  // My Branch -> Team -> Details
  const upcoming = useRouteMatch(upcomingRoute);
  const attended = useRouteMatch(attendedRoute);
  const recommended = useRouteMatch(recommendedRoute);
  const mandatory = useRouteMatch(mandatoryRoute);
  const anyDetails = upcoming || attended || recommended || mandatory;

  return (
    <>
      <span className={style.managerDashboardHeaderTitle}>LEADERSHIP DASHBOARD</span>
      {officeFilterStateStore.title && (
        <span className={style['leadership-title__dash']}>—</span>
      )}
      <span className={style['leadership-title__name']}>
        {officeFilterStateStore.title}
      </span>
      <br />
      <div className={style['leadership-breadcrumb-holder']}>
        {officeFilterSelection.length <= 1 && !anyDetails ? (
          <HeaderButton
            noDivider
            active
            route={managerRouteRoot}
            name={officeFilterStateStore.groupTitle}
          />
        ) : (
          <HeaderButton
            noDivider
            onClick={() => officeFilterStateStore.setSelection([])}
            route={managerRouteRoot}
            name={officeFilterStateStore.groupTitle}
          />
        )}
        {officeFilterSelection.slice(1).map((teamLevel, i) => {
          return i + 1 === officeFilterSelection.length - 1 && !anyDetails ? (
            <HeaderButton active name={teamLevel.groupName} />
          ) : (
            <HeaderButton
              onClick={() =>
                officeFilterStateStore.setSelection(
                  officeFilterSelection.slice(0, i + 2)
                )
              }
              route={managerRouteRoot}
              name={teamLevel.groupName}
            />
          );
        })}

        {upcoming ? (
          <HeaderButton
            route={upcoming}
            name="Upcoming Sessions (Registered) Details"
            active
          />
        ) : null}
        {attended ? (
          <HeaderButton route={attended} name="Attended Sessions Details" active />
        ) : null}
        {recommended ? (
          <HeaderButton
            route={recommended}
            name="Recommended Sessions Details"
            active
          />
        ) : null}
        {mandatory ? (
          <HeaderButton
            route={mandatory}
            name="Mandatory Attendance Details"
            active
          />
        ) : null}
      </div>
    </>
  );
}

// TODO: Accessible!!

const HeaderButton = (props) => (
  <div className={style['leadership-breadcrumb-bit']}>
    {props.noDivider ? null : <span className={style.breadcrumbDivider}>/</span>}
    <Link onClick={props.onClick} to={props.route}>
      <span
        className={[
          style.breadcrumb,
          props.active ? style.headerLinkActive : ''
        ].join(' ')}
      >
        {props.name}
      </span>
    </Link>
  </div>
);

export default ManagerDashboardHeader;

import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { Row, Col, CustomInput, Card, CardHeader, CardBody } from 'reactstrap';
import style from './Dashboard.module.css';
import UpcomingSessions from './components/User_sessions/UpcomingSessions.jsx';
import LearningHistory from './components/user_history/LearningHistory.jsx';
import UserBadges from './components/userbadges/UserBadges.jsx';
import { adalApiFetch, adalPost } from '../../adalConfig';
import DashboardItem from './components/dashboardItem/DashboardItem';
import { stringToDate } from './../../CONSTANTS';
import { ON_DEMAND_SLUG } from '../sessions/components/SessionUtils';
import { sortArrayByDateKey } from '../../utils/courses';
import NotificationIcon from '../../components/notificationIcon/NotificationIcon';
import Notifications from './components/notifications/Notifications';
import GlobalContext from '../../context/GlobalContext';
import { NotificationStatus } from '../../utils/notifications';
import DashboardSessionDetails from './components/dashboardSessionDetails/DashboardSessionDetails';
import TimeFrame from '../../components/timeFrame/TimeFrame';
import { joinClasses } from '../../utils/styles';

const initialState = {
  registeredSessions: [],
  upcomingSessions: [],
  pastSessions: [],
  onDemandAttended: [],
  onDemandAttending: [],
  loading: true,
  notifications: false,
  upcomingSessionsClicked: false,
  learningHistoryClicked: false,
  badgesClicked: false,
  openNotificationId: -1,
  openDeclineNotificationId: -1,
  upcomingSessionsActiveTab: '1',
  shareIT: [],
  compliance: []
};

export default class Dashboard extends Component {
  static contextType = GlobalContext;
  abortController = new AbortController();
  notifDetailsRef = createRef();
    learningHistoryRef = createRef();

  /**
   * Get the default start date (3 months ago) & end date (1 month ahead) for time frame
   * @param {*} name  "start" -> start date & "end" -> end date
   */
  defaultDateDisplay = (name) => {
    const defaultDate = new Date();

    if (name === 'startDate') {
      defaultDate.setFullYear(this.getCurrentFiscalYear(), 3, 1);
    } else if (name === 'endDate') {
      defaultDate.setMonth(defaultDate.getMonth());
    }
    return defaultDate.toISOString().substr(0, 10);
  };

  getCurrentFiscalYear = () => {
    var fiscalyear = '';
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear = today.getFullYear() - 1;
    } else {
      fiscalyear = today.getFullYear();
    }
    return fiscalyear;
  };

  state = {
    ...initialState,
    animateUpcomingDict: {},
    componentLoaded: false, // Disable animations until first paint
    startDate: this.defaultDateDisplay('startDate'),
    endDate: this.defaultDateDisplay('endDate'),
    ifFilter: false, // If this is true, then the filter was changed at least once.
    loading: false,
    refreshed: false,
    seeAll: false
  };

  declineSelectorRef = createRef();

    componentDidMount() {
    setTimeout(() => {
      this.setState({ componentLoaded: true });
    }, 200); // Unfortunately there's no way to wait for the first paint, componentDidMount can trigger before paint. 200ms seemed like a good number.
    this.fetchRegistered(this.state.startDate, this.state.endDate);
    this.fetchShareIT(this.state.startDate, this.state.endDate);
   // this.fetchCompliance();
  }

  // Default active tab for My Upcoming Sessions will be 'MY UPCOMING'
  fetchRegistered = (
    startDate,
    endDate,
    ifFilter = null,
    upcomingSessionsActiveTab = '1'
  ) => {
    this.setState(
      {
        ...initialState,
        startDate,
        endDate,
        ...(ifFilter && { ifFilter })
      },
      () => {
        adalApiFetch(
          fetch,
          process.env.REACT_APP_API_BASE_DOMAIN +
            `/api/Register/userregistrations?startDate=${
              this.state.seeAll ? '' : startDate || ''
            }&endDate=${this.state.seeAll ? '' : endDate || ''}`,
          { method: 'GET', signal: this.abortController.signal }
        )
          .then((response) => response.json())
            .then((data) => {
            this.fetchCompliance();
            this.fetchShareIT(this.state.startDate, this.state.endDate);
            // Create a unique entry for every single child of a parent session.
            let allChildrenSessions = [];
            for (let i = 0; i < data.data.length; i++) {
              let childSessionWithParentDetails = {
                ...data.data[i],
                ...data.data[i].courseDetails
              }; // Include parent and this child's information all in one object
              allChildrenSessions.push(childSessionWithParentDetails);
            }
            data.data = this.sortArrayByDate(allChildrenSessions, true);

            let upcoming = [];
            let past = [];
            let onDemandAttending = [];
            let onDemandAttended = [];

            for (let session of data.data) {
              const onDemand =
                session &&
                session.courseDetails &&
                session.courseDetails &&
                session.courseDetails.format.slug === ON_DEMAND_SLUG;
              if (onDemand) {
                if (session.attended) {
                  onDemandAttended.push(session);
                } else {
                  onDemandAttending.push(session);
                }
              } else if (
                stringToDate(session.courseStart).getTime() > new Date().getTime()
              ) {
                upcoming.push(session);
              } else if (session.registered) {
                past.push(session);
              }
            }

            this.setState({
              registeredSessions: data.data,
              onDemandAttended: onDemandAttended,
              onDemandAttending: sortArrayByDateKey(
                onDemandAttending,
                (obj) => obj.courseStart,
                false
              ),
              upcomingSessions: this.sortArrayByDate(upcoming, false),
              pastSessions: past,
              loading: false,
              upcomingSessionsActiveTab: upcomingSessionsActiveTab
            });
          })
          .catch((e) => {
            ReactGA.exception({
              description: e.toString()
            });
          });
      }
    );
  };
  fetchShareIT = (startDate, endDate) => {
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/ShareIT/usershareit?startDate=${
          this.state.seeAll ? '' : startDate || ''
        }&endDate=${this.state.seeAll ? '' : endDate || ''}`,
      { method: 'GET', signal: this.abortController.signal }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          shareIT: data.data
        });
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

    fetchCompliance = () => {
        adalApiFetch(
            fetch,
            process.env.REACT_APP_API_BASE_DOMAIN +
            `/api/Register/usercompliance`,
            { method: 'GET', signal: this.abortController.signal }
        )
            .then((response) => response.json())
            .then((data) => {
                //console.log("data----", data);
                this.setState({
                    compliance: data.data
                    
                }); //debugger;
            })
            .catch((e) => {
                ReactGA.exception({
                    description: e.toString()
                });
            });
    };

  sortArrayByDate(array, desc = false) {
    return sortArrayByDateKey(array, (obj) => obj.courseStart, desc);
  }

  sortArrayByAttendedOn(array) {
    return sortArrayByDateKey(array, (obj) => obj.attendedOn, true);
  }

  markNotificationsRead = async () => {
    const { notifications } = this.context;
    if (notifications) {
      const unread = notifications.filter(
        (n) => n.status === NotificationStatus.SENT
      );
      const body = unread.map((n) => n.id);
      if (unread.length > 0) {
        const { success } = await adalPost(
          `/api/Notifications/updatestatus/${NotificationStatus.READ}`,
          body,
          this.abortController
        );
        if (success) {
          this.context.reloadNotifications();
        }
      }
    }
  };

  toggleNotifications = () => {
    this.setState(({ notificationsToggled }) => {
      if (!notificationsToggled) {
        this.markNotificationsRead();
      }
      return {
        notificationsToggled: !notificationsToggled
      };
    });
  };

  onNotificationRegistered = async (courseDetailsId) => {
    this.setState(({ animateUpcomingDict }) => ({
      animateUpcomingDict: {
        ...animateUpcomingDict,
        [courseDetailsId]: true
      }
    }));

    this.context.reloadNotifications();

    this.fetchRegistered(this.state.startDate, this.state.endDate);
  };

  showNotificationPanel = (notifications) => {
    return (
      this.state.notificationsToggled && notifications && notifications.length > 0
    );
  };

  openDeclineNotificationIdChange = (id) => {
    this.setState({ openDeclineNotificationId: id });
  };

  toggleSeeAll = () => {
    this.setState(
      { seeAll: !this.state.seeAll }, // setting state of seeAll to the opposite current state (true <-> false) as we click it
      () => {
        this.fetchRegistered(
          this.state.startDate,
          this.state.endDate,
          this.state.ifFilter
        ); // calling fetchRegistered with the current state
      }
    );
  };

  myNotifications = (notifications) => {
    const title = (
      <>
        <h2>NOTIFICATIONS</h2>
        <div className={style.notificationIconContainer}>
          <NotificationIcon yellow />
        </div>
      </>
    );
    return (
      notifications &&
      notifications.length > 0 && (
        <DashboardItem
          animateExpand={this.state.componentLoaded}
          focusOnExpand={this.state.componentLoaded}
          title={title}
          key="dashboard_notifications"
          collapsed={!this.showNotificationPanel(notifications)}
          collapsible
          onToggleCollapse={this.toggleNotifications}
        >
          <Notifications
            openNotificationId={this.state.openNotificationId}
            onNotificationOpen={(id) => {
              this.notifDetailsRef &&
                this.notifDetailsRef.current &&
                this.notifDetailsRef.current.focus();
              this.setState({ openNotificationId: id });
            }}
            onNotificationRegistered={this.onNotificationRegistered}
            openDeclineNotificationId={this.state.openDeclineNotificationId}
            openDeclineNotificationIdChange={this.openDeclineNotificationIdChange}
            declineSelectorRef={this.declineSelectorRef}
          />
        </DashboardItem>
      )
    );
  };

  myUpcomingSessions = (notifications) => (
    <DashboardItem
      title={<h2>UPCOMING SESSIONS</h2>}
      loading={this.state.upcomingSessions.length === 0 && this.state.loading}
      key="dashboard_badges" // Needed for CSSTransition group to figure out that animation needs to be triggered when myUpcomingSessions and myNotifications are switched, even though it's largely the same component so react wouldn't want to re-render.
      collapsed={this.showNotificationPanel(notifications)}
      collapsible={notifications && notifications.length > 0}
      onToggleCollapse={this.toggleNotifications}
      highlighted={this.state.refreshed && !this.props.loading && !this.state.seeAll}
    >
      <UpcomingSessions
        animateDict={this.state.animateUpcomingDict}
        stopAnimation={(courseDetailsId) => {
          this.setState(({ animateUpcomingDict }) => {
            delete animateUpcomingDict[courseDetailsId];
            return animateUpcomingDict;
          });
        }}
        upcoming={this.state.upcomingSessions}
        onDemand={this.state.onDemandAttending}
        loading={this.state.loading}
        reloadSessions={this.fetchRegistered}
        addBadgeToList={this.props.addBadgeToList}
        upcomingSessionsActiveTab={this.state.upcomingSessionsActiveTab}
      />
    </DashboardItem>
  );

    compliancePanel = () => (
        <>
        <div className={style.titleHolder2}>
            <div className={style.innerTitleHolder2}><h2>Mandatory Training</h2></div>
            
        </div>
            <div className={this.state.compliance[0] == 1 ? style.compliant : style.nonCompliant}>
                {this.state.compliance[0] == 1 ? "Compliant" : "Non-Compliant"} <br/>
               {this.state.compliance[2]} / {this.state.compliance[1]} completed
            </div>
        </>
    );

  defaultPanel = () => (
    <>
      <Col>
        <DashboardItem
          highlighted={
            this.state.refreshed && !this.props.loading && !this.state.seeAll
          }
          title={
            <>
              <h2>LEARNING HISTORY</h2>

              <button
                className={style.exportHistoryCsvButton}
                id={`download-csv-LearningHistory`}
                onClick={() => {
                  this.learningHistoryRef.current &&
                    this.learningHistoryRef.current.downloadSessionsCsv();
                }}
              >
                &nbsp;
                <img
                  src={require('../../assets/export_Sign.png')}
                  alt="Export"
                  width="25"
                  height="22"
                />
                &nbsp; Export &nbsp;
                {/* <FontAwesomeIcon icon={faDownload} /> */}
              </button>
            </>
          }
          loading={this.state.pastSessions.length === 0 && this.state.loading}
        >
          <LearningHistory
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            ifFilter={this.state.ifFilter}
            seeAll={this.state.seeAll}
            ref={this.learningHistoryRef}
            sessions={[
                ...this.sortArrayByAttendedOn(this.state.onDemandAttended),
                ...this.state.onDemandAttending,
              ...this.state.pastSessions
            ]}
            loading={this.state.loading}
            addBadgeToList={this.props.addBadgeToList}
            shareIT={this.state.shareIT}
          />
        </DashboardItem>
          </Col>

          <Col>
                  <DashboardItem
                  highlighted={
                      this.state.refreshed && !this.props.loading && !this.state.seeAll
                  }
                  animateExpand={this.state.componentLoaded}
                  focusOnExpand={this.state.componentLoaded}
                  title={<h2>BADGES</h2>}
                  child2={ this.compliancePanel()}
                      >
          <UserBadges
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            ifFilter={this.state.ifFilter} // if timeframe is updated
            seeAll={this.state.seeAll} // if seeAll toggle is on
          />
        </DashboardItem>
      </Col>
             </>
  );

  notificationDetailsPanel = (notifications) => {
    const notification = notifications.find(
      (n) => n.id === this.state.openNotificationId
    );
    if (!notification) {
      this.setState({ openNotificationId: -1 });
      return <></>;
    }
    return (
      <>
        <Col xs={8}>
          <DashboardSessionDetails
            ref={this.notifDetailsRef}
            notification={notification}
            onRegistered={this.onNotificationRegistered}
            onCloseClick={() => {
              this.setState({ openNotificationId: -1 });
            }}
            openDeclineNotificationIdChange={this.openDeclineNotificationIdChange}
          ></DashboardSessionDetails>
        </Col>
      </>
    );
  };

  render() {
    return (
      <GlobalContext.Consumer>
        {({ notifications }) => (
          <div className={style.wrapper} id="dashboardWrapper">
            <div className={style.homeArea}>
              <Row className={style.contentRow}>
                <Col md="4" sm="12">
                  <div className={[style.titleHolder, 'flex'].join(' ')}>
                    <h1>MY DASHBOARD</h1>
                  </div>
                  {// this code  shows the notifications panel. It switches with the "My Upcoming Sessions"
                  this.showNotificationPanel(notifications)
                    ? this.myUpcomingSessions(notifications)
                    : this.myNotifications(notifications)}
                </Col>
                <Col
                  className={joinClasses(
                    style.flex,
                    !this.state.seeAll && style.faded
                  )}
                >
                  <div className="flex-resize"></div>
                  <div className={style.texty}>
                    <span>SEE ALL</span>
                  </div>

                  <div className={style.radio}>
                    <CustomInput
                      type="switch"
                      id="see-all"
                      value={this.state.seeAll}
                      label={
                        <span className={style.dis} aria-label="SEE ALL">
                          {' '}
                          SEE ALL
                        </span>
                      }
                      checked={!this.state.seeAll}
                      onChange={this.toggleSeeAll}
                    />
                  </div>
                  {
                    <TimeFrame
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      updateTimeframe={(startDate, endDate) =>
                        this.fetchRegistered(startDate, endDate, true)
                      } //
                      loading={this.state.loading}
                      theme={'dashboard'}
                      faded={this.state.seeAll ? 'faded' : ''}
                      seeAll={this.state.seeAll}
                      //refreshed() gets called inside TimeFrame.jsx each time after TimeFrame's 'enableRefresh' property changes
                      // Simply put: when either startDate or endDate gets changed
                      refreshed={(enableRefresh) => {
                        this.setState({ refreshed: enableRefresh });
                      }}
                    />
                  }
                </Col>
              </Row>

              <Row className={style.contentRow}>
                {/* followng column is switched with My Upcoming Sessions with the Notifications */}
                <Col>
                  {this.showNotificationPanel(notifications)
                    ? this.myNotifications(notifications)
                    : this.myUpcomingSessions(notifications)}
                </Col>
                {this.state.openNotificationId === -1
                  ? this.defaultPanel(notifications)
                  : this.notificationDetailsPanel(notifications)}
              </Row>
            </div>
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}

import React from 'react';
import ReactGA from 'react-ga';
import style from './PresenterGallery.module.css';
import { adalApiFetch } from '../../adalConfig';
import { Row, Col } from 'reactstrap';
import PresenterCard from './PresenterCard';

import Carousel from '../../components/carousel/Carousel';
import uuidv4 from 'uuid';
import { config } from 'react-spring';

import BackButton from '../../components/backButton/BackButton';
import { Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faSearch,
  faAngleRight
} from '@fortawesome/free-solid-svg-icons';
import AccessibleButton from '../../components/accessibleButton/AccessibleButton';

export default class PresenterGallery extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();
    this.carouselHolder = React.createRef();

    this.state = {
      presenters: [],
      sortedPresenters: [],
      slides: [],
      searchedText: '',
      goToSlide: 0,
      defaultSlide: 0,
      showNavigation: false,
      offsetRadius: 2,
      config: config.default,
      loading: true,
      pathFromUrl: false,
      movedSlide: 0
    };
    this.presenterCardDivRef = React.createRef();

    // Create an observer for detect changing animation of 3d-carousel component
    this.observer = new MutationObserver(() => {
      this.focusOnCurrentPresenter();
    });
  }

  componentDidMount() {
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Presenters', {
      method: 'get',
      signla: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        this.setState({ presenters: myJson.data });
        let slideId = 0;
        let sortedList = this.sortByName();
        this.setState({ presenters: sortedList });
        let slidesArray = [];
        let sortedPresentersArray = [];
        this.state.presenters.forEach((presenter) => {
          if (
            presenter.name.toLowerCase() !== 'tbd' &&
            presenter.name.toLowerCase() !== 'empty'
          ) {
            slidesArray.push({
              key: uuidv4(),
              content: (
                <div key={slideId} ref={this.presenterCardDivRef}>
                  <PresenterCard
                    payload={presenter}
                    key={`presenter_${presenter.presenterId}`}
                  />
                </div>
              )
            });
            sortedPresentersArray.push({
              slideId: slideId,
              id: presenter.presenterId,
              name: presenter.name.toLowerCase()
            });
            slideId++;
          }
        });
        this.setState(
          {
            slides: slidesArray,
            sortedPresenters: sortedPresentersArray,
            loading: false
          },
          () => {
            this.showDefaultPresenter();

            const config = { attributes: false, childList: true, subtree: true };
            this.observer.observe(this.carouselHolder.current, config);
          }
        );
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  /**
   * Add aria-hidden tag to presenter cards in the background, and focus on the current presenter.
   * The third-party library does not support this be default. Therefore, selectors are used to find and update the elements.
   */
  focusOnCurrentPresenter() {
    if (!this.carouselHolder || !this.carouselHolder.current) {
      return;
    }
    this.carouselHolder.current
      .querySelectorAll('#carousel-holder > div > div')
      .forEach(function(presenterItem) {
        presenterItem.setAttribute('aria-hidden', 'true');
        presenterItem.removeAttribute('tabindex');
      });
    setTimeout(() => {
      if (this.state.movedSlide !== 0 && this.carouselHolder.current !== null) {
        let currentPresenterHolder = this.carouselHolder.current.querySelector(
          '#carousel-holder > div > div:nth-child(3)'
        );
        currentPresenterHolder.setAttribute('aria-hidden', 'false');
        currentPresenterHolder.setAttribute('tabindex', '-1');
        currentPresenterHolder.focus();
      }
    }, 250 * this.state.movedSlide); //one slide take 250ms to move next
  }

  componentWillUnmount() {
    this.abortController.abort();
    this.observer.disconnect();
  }

  sortByName() {
    let newList = this.state.presenters;
    newList.sort(function(a, b) {
      var firstNameA = a.name.toLowerCase(),
        firstNameB = b.name.toLowerCase();
      var lastNameA = a.name
          .toLowerCase()
          .split(' ')
          .slice(-1)
          .join(' '),
        lastNameB = b.name
          .toLowerCase()
          .split(' ')
          .slice(-1)
          .join(' ');
      if (lastNameA < lastNameB)
        //sort string ascending
        return -1;
      if (lastNameA > lastNameB) return 1;
      if (lastNameA === lastNameB) {
        if (firstNameA < firstNameB) return -1;
        if (firstNameA > firstNameB) return 1;
      }
      return 0; //default return value (no sorting)
    });
    return newList;
  }

  showDefaultPresenter() {
    this.state.sortedPresenters.forEach((presenter) => {
      if (presenter.id === parseInt(this.props.match.params.presenterId, 10)) {
        this.setState({ defaultSlide: presenter.slideId });
        this.setState({ pathFromUrl: true });
        this.goToSlideChangeHandler(presenter.slideId);
      }
    });
  }

  goToSlideChangeHandler(value) {
    let changedValue = this.state.goToSlide - value;
    if (changedValue < 0) changedValue = changedValue * -1;
    this.setState({ goToSlide: value });
    this.setState({ movedSlide: changedValue });
  }

  //filter function
  changeHandler = (e) => {
    this.setState(
      {
        searchedText: e.target.value
      },
      () => {}
    );
  };

  clickHandler = () => {
    this.state.sortedPresenters.forEach((presenter, index) => {
      let searchedText = this.state.searchedText;
      if (searchedText === '') {
        this.goToSlideChangeHandler(this.state.defaultSlide);
      } else if (presenter.name.toLowerCase().includes(searchedText.toLowerCase())) {
        this.goToSlideChangeHandler(index);
      }
    });
  };

  onEnter = (e) => {
    if (e.key === 'Enter') {
      this.state.sortedPresenters.forEach((presenter, index) => {
        let searchedText = this.state.searchedText;
        if (searchedText === '') {
          this.goToSlideChangeHandler(this.state.searchedText);
        } else if (
          presenter.name.toLowerCase().includes(searchedText.toLowerCase())
        ) {
          this.goToSlideChangeHandler(index);
        }
      });
    }
  };

  // Carousel next and previous arrow handler
  changePage(pageTo) {
    let nextSlide = null;
    if (pageTo === 'prev') {
      nextSlide = (this.state.goToSlide - 1) % this.state.sortedPresenters.length;
    } else if (pageTo === 'next') {
      nextSlide = (this.state.goToSlide + 1) % this.state.sortedPresenters.length;
    }
    this.goToSlideChangeHandler(nextSlide);
    /* Change presenter ID in URI whenever the presenter change => the back function will not work */
    // this.props.history.push('/presenters/' + this.getNextPresenterId(nextSlide))
  }

  getNextPresenterId(nextSlide) {
    for (let presenter of this.state.sortedPresenters) {
      if (presenter.slideId === nextSlide) {
        return presenter.id;
      }
    }
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <div
            style={{
              margin: '0 auto',
              textAlign: 'center',
              height: '500px',
              width: '1250px'
            }}
          >
            <Spinner
              style={{ marginTop: '250px', padding: '20px' }}
              color="primary"
            />
          </div>
        ) : (
          <div
            style={{ margin: '0 auto', backgroundColor: '#DDDFE0', width: '1250px' }}
          >
            <Row>
              <Col>
                <h1 className={style.title}>OUR PRESENTERS</h1>
              </Col>
              <Col>
                <div className={style.searchBar} role="search">
                  <label>
                    <input
                      name="goToSlide"
                      type="search"
                      placeholder=" Search..."
                      className={style.inputField}
                      onChange={this.changeHandler}
                      onKeyPress={this.onEnter}
                    />
                    <span className="show-for-sr">Search keywords</span>
                  </label>
                  <AccessibleButton className={style.searchButton}>
                    {' '}
                    <FontAwesomeIcon
                      icon={faSearch}
                      className={style.searchIcon}
                      onClick={this.clickHandler}
                    />
                    <span className="show-for-sr">Search</span>
                  </AccessibleButton>
                </div>
              </Col>
            </Row>

            <div
              className={style.carousel}
              ref={this.carouselHolder}
              id="carousel-holder"
            >
              <Carousel
                slides={this.state.slides}
                goToSlide={this.state.goToSlide}
                offsetRadius={this.state.offsetRadius}
                showNavigation={this.state.showNavigation}
                animationConfig={this.state.config}
                aria-hidden="true"
                goToInterval={0}
              />
            </div>

            <Row>
              <Col sm="6">
                <AccessibleButton
                  onClick={() => this.changePage('prev')}
                  className={style.angleLeft}
                >
                  <span className="show-for-sr">move to previous presenter</span>
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className={style.presenterMoveBtn}
                  />
                </AccessibleButton>
              </Col>
              <Col sm="4">
                <AccessibleButton
                  onClick={() => this.changePage('next')}
                  className={style.angleRight}
                >
                  <span className="show-for-sr">move to next presenter</span>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={style.presenterMoveBtn}
                  />
                </AccessibleButton>
              </Col>
              <Col sm="2">
                <div className={style.backButtonContainer}>
                  <BackButton />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

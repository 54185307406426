import React from 'react';
import styles from './BlueSquaredScroll.module.css';

const BlueSquaredScroll = ({ className, scrollRef, ...props }) => (
  <div
    className={[styles.scrollContainer, className].join(' ')}
    ref={scrollRef}
    {...props}
  ></div>
);

export default BlueSquaredScroll;

import React from 'react';
import ReactGA from 'react-ga';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardText,
  CardBody
} from 'reactstrap';
import delIcon from '../../images/garbage.svg';
import editIcon from '../../images/pencil.svg';
import { adalApiFetch } from '../../../../adalConfig';
import * as toastRequest from '../../utils/toastRequests';
import RepAddModify from './RepAddModify';

export default class Rep extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleModifyModal = this.toggleModifyModal.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.deleteSelf = this.deleteSelf.bind(this);
    this.formRef = React.createRef();
    this.state = { modalOpen: false, modModal: false, collapse: false };
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  toggleDeleteModal() {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen
    }));
  }

  toggleModifyModal() {
    this.setState((prevState) => ({
      modModal: !prevState.modModal
    }));
  }

  toggleCollapse() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  updateSelf() {
    this.formRef.current.update();
    // this.toggleModifyModal();
  }

  deleteSelf() {
    toastRequest.notify('Deleting representative');
    this.toggleDeleteModal();

    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + `/api/Rep/${this.props.payload.repId}`,
      { method: 'delete', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `L+D Rep "${this.props.payload.name}" deleted successfully!`
        );
        if (myJson.success === true) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <h5 className={'card-title'} style={{ float: 'left' }}>
            {this.props.payload.name}{' '}
            <Badge color="secondary">id:{this.props.payload.repId}</Badge>
          </h5>
          <Button
            size="sm"
            outline
            color="danger"
            style={{ float: 'right' }}
            onClick={this.toggleDeleteModal}
          >
            <img style={{ height: '24px' }} src={delIcon} alt={'delete button'} />
          </Button>
          <Button
            size="sm"
            outline
            color="white"
            style={{ float: 'right' }}
            onClick={this.toggleModifyModal}
          >
            <img style={{ height: '24px' }} src={editIcon} alt={'delete button'} />
          </Button>
        </CardHeader>
        <CardBody>
          <CardText>{this.props.payload.email}</CardText>
        </CardBody>

        {/*DELETE MODAL*/}
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleDeleteModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>Confirm Deletion</ModalHeader>
          <ModalBody>
            Are you sure you want to delete L+D Rep "{this.props.payload.name}"? This
            action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.deleteSelf}>
              DELETE
            </Button>{' '}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/*MODIFY MODAL*/}
        <Modal
          isOpen={this.state.modModal}
          toggle={this.toggleModifyModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModifyModal}>
            Update this Representative
          </ModalHeader>
          <ModalBody>
            <RepAddModify
              payload={this.props.payload}
              ref={this.formRef}
              manualReload={this.props.manualReload}
              closeModal={this.toggleModifyModal}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.updateSelf}>
              Modify
            </Button>
            <Button color="secondary" onClick={this.toggleModifyModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    );
  }
}

import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import style from '../AdminAllTabs.module.css';
import delIcon from '../../images/garbage.svg';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Card,
  CardHeader
} from 'reactstrap';

const DaysOff = (props) => {
  const startDate = props.result.startDate.split('T')[0];
  const endDate = props.result.endDate.split('T')[0];
  const abortController = new AbortController();
  const [modalOpen, setModalOpen] = useState(false);
  // This function deletes an event when user clicks the delete button on ViewTab
  const deleteEvent = async () => {
    let toastId = toastRequest.notify(`Deleting ${props.result.eventDescription}`);
    try {
      // If the results are fetched then what to do
      const response = await adalApiFetch(
        fetch,
        process.env.REACT_APP_API_BASE_DOMAIN +
          `/api/DaysOff?eventId=${props.result.eventId}`,
        {
          method: 'delete',
          signal: abortController.signal
        }
      );

      const myJson = await response.json();

      if (!myJson.success && myJson.error) {
        toast.update(toastId, {
          render: `${myJson.error}`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      } else {
        toast.update(toastId, {
          render: `${props.result.eventDescription} deleted successfully!`,
          type: toast.TYPE.SUCCESS,
          autoClose: 5000
        });
        props.reloadEvents();
      }
    } catch (e) {
      toast.update(toastId, {
        render: `${e.toString()} `,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
      ReactGA.exception({
        description: e.toString()
      });
    }
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    return () => {
      abortController.abort();
    };
    // The following line of code disables the missing dependencies error as this useEffect is ran only once when the user clicks
    // on View Tab

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader>
        <h5 className={style.cardTitle} style={{ float: 'left' }}>
          {props.result.eventDescription}{' '}
        </h5>
        <Button
          size="sm"
          outline
          color="danger"
          style={{ float: 'right' }}
          id={`delete-event-${props.result.eventId}`}
          onClick={() => setModalOpen(!modalOpen)}
        >
          <img style={{ height: '24px' }} src={delIcon} alt={'delete button'} />
        </Button>
        <br />
        {startDate === endDate ? `${startDate}` : `${startDate}  to  ${endDate}`}
      </CardHeader>
      {/*DELETE BUTTON*/}

      {/*DELETE MODAL*/}
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Confirm Deletion
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete event {props.result.eventDescription}? This
          action cannot be undone."
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            id="delete-modal-confirm-delete"
            onClick={deleteEvent}
          >
            DELETE
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalOpen(!modalOpen)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default DaysOff;

import React, { useState } from 'react';
import AccessibleButton from '../../../../components/accessibleButton/AccessibleButton';
import { Card, CardHeader, CardBody } from 'reactstrap';
import style from './JobShadowing.module.css';
import leftIcon from '../../../../assets/carousel/ontario-icon-chevron-left.svg';
import rightIcon from '../../../../assets/carousel/ontario-icon-chevron-right.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
const colors = {
  colorGrey: '#000000',
  colorWhite: '#FFFFFF'
};
/** createButton(num) creates 'num' number of carousel dots */
const createButton = (num, index, setIndex) => {
  const numbers = [...Array(num).keys()]; // holds the number from [0,1,.....,num] this is the ensure that we are able to map to dots
  return numbers.map((i) => {
    return (
      <FontAwesomeIcon
        // ref={this.buttonIcon}
        icon={faCircle}
        color={i === index ? colors.colorGrey : colors.colorWhite}
        className={style.dots}
        onClick={() => setIndex(i)}
        key={i}
      />
    );
  });
};
/**
 *
 * @param {this is a flag, where true is left and false is right for the button} flag
 * @param {this is the index of the data} index
 * @param {this is the setter for index, i.e. setState} indexSetter
 * @param {this is the host list of the data} hostList
 */
const clickArrow = (flag, index, setIndex, hostList) => {
  if (flag && index > 0)
    // moving left
    setIndex(index - 1);
  else if (!flag && index < hostList.length - 1)
    // moving right
    setIndex(index + 1);
  else if (flag && index === 0)
    // if we have reached the left most
    setIndex(hostList.length - 1);
  else if (!flag && index === hostList.length - 1)
    //reached the right most end
    setIndex(0);
};

/** hostView() returns card for when there are multiple hosts or single host, as it understand length of users, using the data.shareItHost */
/**
 *
 * @param {the state in which the tab is present} host
 * @returns
 */
const hostView = (data, index, setIndex) => {
  // const [index, setIndex] = useState(data.index);
  const host = data;
  const descButton = createButton(host?.shareItHost.length, index, setIndex);
  if (index !== 0 && index >= host?.shareItHost.length)
    //this is so that, when we delete, if the index was left on one which was deleted, we reset.
    setIndex(0);
  return (
    <Card className={style.collapseShadowHost_card}>
      <div>
        {host?.shareItHost.length > 1 ? (
          <CardHeader
            className={`${style.collapseShadowHost_cardHeader} ${style.collapseShadowHost_cardBold} ${style.multipleHostsCardHeader}  flex width=100%`}
          >
            <>
              <div className="flex-fixed"> Host </div>
              <div className="flex-auto"> </div>
              <div className="flex-fixed"> {descButton} </div>
            </>
          </CardHeader>
        ) : (
          <CardHeader
            className={`${style.collapseShadowHost_cardHeader} ${style.collapseShadowHost_cardBold}`}
          >
            Host
          </CardHeader>
        )}

        <CardBody
          className="flex"
          style={{
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {host?.shareItHost.length > 1 ? (
            <AccessibleButton
              onClick={() => clickArrow(true, index, setIndex, data.shareItHost)}
            >
              <img
                id="chev-left"
                src={leftIcon}
                alt="left-arrow"
                height="50"
                width="50"
              />
            </AccessibleButton>
          ) : (
            <></>
          )}

          <div className="flex-auto">
            <ul className={style.collapseShadowHost_infoList}>
              <li className={style.collapseShadowHost_cardBold}>
                {host.shareItHost[index]?.hostUser?.name}
              </li>
              <li>{host.shareItHost[index]?.hostCurrentRole}</li>
              <li>{host.shareItHost[index]?.hostUser?.email}</li>
              <li>
                <i>{host.shareItHost[index]?.hostBranch}</i>
              </li>
              <br />
              <li className={style.collapseShadowHost_cardBold}>Report to:</li>
              <li>{host.shareItHost[index]?.hostManager?.name}</li>
              <li>{host.shareItHost[index]?.hostManager?.email}</li>
              <li>Manager</li>
            </ul>
          </div>
          {host?.shareItHost.length > 1 ? (
            <AccessibleButton
              onClick={() => clickArrow(false, index, setIndex, data.shareItHost)}
            >
              <img
                id="chev-right"
                src={rightIcon}
                alt="right-arrow"
                height="50"
                width="50"
              />
            </AccessibleButton>
          ) : (
            <></>
          )}
        </CardBody>
      </div>
    </Card>
  );
};
/**
 *
 * @param {the only props it uses is host} props
 * props.host contains
 *  hostList
 * and the hostlist contains
 * .hostUser.name
 * .hostCurrentRole
 * .hostBranch
 * .hostUser.email
 * .hostManager.name
 * .hostManager.email
 * @returns the host view for given data
 */
const CardAdmin = (props) => {
  const [index, setIndex] = useState(0);
  return hostView(props.host, index, setIndex); // if the host list is less than 2, then we do the single host one, else the multiple hosts
  // return host.hostList.length <=1 ? singleHost(host) : multipleHosts(host, index, setIndex);// if the host list is less than 2, then we do the single host one, else the multiple hosts
};
export default CardAdmin;

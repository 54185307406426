import React, { Component } from 'react';
import style from './Footer.module.css';
import { Link } from 'react-router-dom';
import DigitalWorldHelp from '../../assets/DigitalWorldHelp.pdf';
import GlobalContext from '../../context/GlobalContext';

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      hide: false
    };
  }

  componentDidMount() {
    if (
      window.location.href.includes('pastsessions') &&
      window.location.href.includes('feedback')
    ) {
      this.setState({ hide: true });
    }
  }

  render() {
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <footer className={style.footer}>
            <div className="app-container">
              <div className={style.footerContent}>
                <div className={style.footerLeft}>
                  <ul className={style.footerHighlight}>
                    <li>
                      <Link
                        className={
                          this.props.path.length === 1 &&
                          this.props.path.substring(0, 1) === '/'
                            ? style.activeLink
                            : ''
                        }
                        to="/"
                      >
                        Digital World
                      </Link>
                    </li>
                    {!this.state.hide && (
                      <>
                        <li>
                          <Link
                            className={
                              this.props.path.substring(0, 9) === '/sessions'
                                ? style.activeLink
                                : ''
                            }
                            to="/sessions"
                          >
                            Upcoming Sessions
                          </Link>
                         </li>
                        <li>
                          <Link
                            className={
                              this.props.path.substring(0, 9) === '/ondemand'
                                 ? style.activeLink
                                 : ''
                            }
                            to="/ondemand"
                          >
                            On Demand Sessions
                          </Link>
                        </li>
                        <li>
                          <a
                            href={DigitalWorldHelp}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Help
                          </a>
                        </li>
                        <li>
                          <Link
                            className={
                              this.props.path.substring(0, 10) === '/dashboard'
                                ? style.activeLink
                                : ''
                            }
                            to="/dashboard"
                          >
                            My Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.props.path.substring(0, 13) === '/pastsessions'
                                ? style.activeLink
                                : ''
                            }
                            to="/pastsessions"
                          >
                            Past Sessions
                          </Link>
                        </li>
                        <li>
                          <a
                            className={style.contactLink}
                                 href="mailto: LTC.PCL@ontario.ca?"
                          >
                            Contact
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <div className={style.footerRight}>
                  {!this.state.hide && context.user !== null && (
                    <ul>
                      {context.user.isAdmin && (
                        <li>
                          <Link
                            className={
                              this.props.path.substring(0, 6) === '/admin'
                                ? style.activeLink
                                : ''
                            }
                            to="/admin"
                          >
                            Admin
                          </Link>
                        </li>
                      )}
                      {context.user.isManager && (
                        <li>
                          <Link
                            className={
                              this.props.path.substring(0, 8) === '/manager'
                                ? style.activeLink
                                : ''
                            }
                            to="/manager/"
                          >
                            Leadership
                          </Link>
                        </li>
                      )}
                    </ul>
                  )}
                  <img
                    id={style.ltcLogo}
                    src={require('../../assets/LTClogo.png')}
                    alt="LTC Logo"
                  />
                  <ul>
                    <li>
                      <a
                        href="https://www.ontario.ca/page/privacy-statement"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://intra.sdc.gov.on.ca/sites/ltc/Pages/Terms-of-Use.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://intra.sdc.gov.on.ca/sites/ltc/Pages/Accessibility.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Accessibility
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.ontario.ca/page/copyright-information"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        &copy; King's Printer for Ontario,{' '}
                        {process.env.REACT_APP_BUILD_VERSION &&
                          process.env.REACT_APP_BUILD_VERSION.substring(0, 4)}
                      </a>
                    </li>
                  </ul>
                  <p className={style.buildInfo}>
                    UI: {process.env.REACT_APP_BUILD_VERSION}. API:{' '}
                    {this.props.buildVersion && this.props.buildVersion}
                  </p>
                </div>
              </div>
            </div>
          </footer>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default Footer;

import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './DigiMenu.module.css';
import { Tooltip } from 'reactstrap';
import AccessibleButton from '../accessibleButton/AccessibleButton';

const DigiMenu = ({ buttonClassName, children, design, id }) => {
  //in this case, the design is as an array of svg from free-solid-svg-icons where [iconWhenClosed,iconWhenOpened]
  const [menuOpen, menuOpenChange] = useState(false);
  const menuToggleRef = useRef();
  const toggleMenu = () => {
    menuOpenChange(!menuOpen);
    menuToggleRef.current.nextSibling
      .getElementsByClassName('tooltip-inner')[0]
      .focus(); //this is to focus on the menuOptions div tag, and focus the first button on it, so we have to give it Accessibility button.
  };
  return (
    <>
      <AccessibleButton
        className={buttonClassName}
        buttonRef={menuToggleRef}
        id={id}
      >
        <span className="show-for-sr">More options</span>
        <FontAwesomeIcon icon={menuOpen ? design[1] : design[0]} size="2x" />
        {/*if the menu is open, design[1] else design[0] */}
      </AccessibleButton>
      <div id="menuOptions"></div>
      {menuToggleRef.current && (
        <Tooltip
          isOpen={menuOpen}
          container={document.getElementById('menuOptions')}
          target={menuToggleRef.current}
          onClick={() => {
            menuOpenChange(!menuOpen);
          }}
          placement="bottom"
          toggle={toggleMenu}
          trigger="legacy"
          popperClassName={styles.tooltipPopper}
          arrowClassName={styles.tooltipArrow}
          innerClassName={styles.tooltipBody}
        >
          {children}
        </Tooltip>
      )}
    </>
  );
};

export default DigiMenu;

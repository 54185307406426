import React from 'react';
import style from './nonLtcIcon.module.css';
import NonLtcImg from '../../assets/NonLtcSession.png';

export function NonLtcIcon(props) {
  return (
    <img
      className={props.detailsPage ? style.nonLtcDetails : style.nonLtc}
      src={NonLtcImg}
      alt="Non Ltc Session"
    />
  );
}

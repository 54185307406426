import React from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import { Table } from 'react-bootstrap';
import styles from './LastLogin.module.css';
import { stringToDate } from '../../../../CONSTANTS';
import { AdminTabs } from '../../../../enums/admintabs.enum';
import AdminHomeTabs from '../AdminHomeTabs';
import sharedStyle from '../AdminAllTabs.module.css';
export default class AdminLastLoginTab extends React.Component {
  constructor() {
    super();
    this.abortController = new AbortController();

    this.state = {
      users: [],
      sort: 'email-descending'
    };
    this.loadContent = this.loadContent.bind(this);
    this.sortLogins = this.sortLogins.bind(this);
    this.sortUsers = this.sortUsers.bind(this);

    // this.loadContent(true);
  }

  componentDidMount() {
    this.loadContent(true);
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async loadContent() {
    const toastId = toast(
      <div>
        <Spinner className="toast-spinner" /> Loading Users...
      </div>,
      {
        type: toast.TYPE.INFO,
        autoClose: false
      }
    );
    try {
      const response = await adalApiFetch(
        fetch,
        process.env.REACT_APP_API_BASE_DOMAIN + '/api/User',
        { method: 'get', signal: this.abortController.signal }
      );
      let myJson = await response.json();
      //Sort by email alphabetically
      myJson.data.sort((a, b) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      });
      // Convert to date objects
      for (let i = 0; i < myJson.data.length; i++) {
        myJson.data[i].lastLogin = stringToDate(myJson.data[i].lastLogin);
      }
      this.setState({ users: myJson.data });
      toast.update(toastId, {
        render: 'Users loaded',
        type: toast.TYPE.SUCCESS,
        autoClose: true
      });
    } catch (e) {
      toast.dismiss(toastId);
    }
  }

  sortUsers() {
    let users = this.state.users;
    if (this.state.sort === 'email-descending') {
      users.sort((a, b) => {
        if (a.email > b.email) {
          return -1;
        }
        if (a.email < b.email) {
          return 1;
        }
        return 0;
      });
      this.setState({ sort: 'email-ascending', users: users });
    } else {
      users.sort((a, b) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      });
      this.setState({ sort: 'email-descending', users: users });
    }
  }

  sortLogins() {
    let users = this.state.users;
    if (this.state.sort === 'login-descending') {
      users.sort((a, b) => {
        if (a.lastLogin < b.lastLogin) {
          return -1;
        }
        if (a.lastLogin > b.lastLogin) {
          return 1;
        }
        return 0;
      });
      this.setState({ sort: 'login-ascending', users: users });
    } else {
      users.sort((a, b) => {
        if (a.lastLogin > b.lastLogin) {
          return -1;
        }
        if (a.lastLogin < b.lastLogin) {
          return 1;
        }
        return 0;
      });
      this.setState({ sort: 'login-descending', users: users });
    }
  }

  render() {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    };
    return (
      <div className={sharedStyle.adminRoot}>
        <AdminHomeTabs
          activetab={AdminTabs.Users}
          switchtab={this.props.history.push}
        />
        <Container>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th onClick={this.sortUsers} className={styles.tableHeader}>
                      Email Address &nbsp;
                      {this.state.sort.substring(0, 5) === 'email' && (
                        <i
                          className={[
                            styles.arrow,
                            this.state.sort === 'email-descending'
                              ? styles.down
                              : styles.up
                          ].join(' ')}
                        />
                      )}
                    </th>
                    <th onClick={this.sortLogins} className={styles.tableHeader}>
                      Last Login &nbsp;
                      {this.state.sort.substring(0, 5) === 'login' && (
                        <i
                          className={[
                            styles.arrow,
                            this.state.sort === 'login-descending'
                              ? styles.down
                              : styles.up
                          ].join(' ')}
                        />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map((user) => {
                    return (
                      <tr key={`row-${user.email}`}>
                        <td>{user.email}</td>
                        <td>
                          {user.lastLogin.toLocaleDateString('en-US', options)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

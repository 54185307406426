import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import style from './UserBadges.module.css';
import { GetAllActiveAchievements } from '../../../../components/badges/BadgesAPI.jsx';
import GlobalContext from '../../../../context/GlobalContext';
import DetailedBadge from '../../../../components/detailedBadge/DetailedBadge';
import { stringToDate } from '../../../../CONSTANTS';
import BlueSquaredScroll from '../../../../components/blueSquaredScroll/BlueSquaredScroll';
import { toast } from 'react-toastify';
export default class UserBadges extends Component {
  state = {
    badges: [],
    loading: true,
    allActiveBadges: []
  };

  abortController = new AbortController();

  componentDidMount() {
    let allActiveBadgesFromAPI = [];
    GetAllActiveAchievements(this.abortController)
      .then((data) => {
        for (let achievement of data.data.reverse()) {
          achievement.achievementType.achievementTypeId === 1 &&
            allActiveBadgesFromAPI.push(achievement);
        }
        this.setState(
          {
            allActiveBadges: allActiveBadgesFromAPI
          },
          () => {
            this.setState(
              {
                badges: []
              },
              () => {
                this.getBadges();
              }
            );
          }
        );
      })
      .catch((err) => {
        if (err.name === 'AbortError') return;
        toast.error('Error loading badges');
      });
  }

  componentDidUpdate(prevProps) {
    // We will check if a filter is applied i.e. either startDate or endDate has changed OR if 'SEE ALL' toggle is ON (current seeAll != previous seeAll)
    // and then, we will call getBadges()
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate ||
      this.props.seeAll !== prevProps.seeAll
    ) {
      this.getBadges();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  getBadges() {
    const badgesList = []; // list of all badges (used when we first load the page AND when user hits 'SEE ALL' toggle)
    const filteredBadges = []; // // list of filtered badges
    const startDate = new Date(this.props.startDate + 'T00:00:00'); // creates the date in the midnight in your current timezone
    const endDate = new Date(this.props.endDate + 'T23:59:59'); // creates the date in the midnight in your current timezone

    for (let achievement of this.state.allActiveBadges) {
      let earnDate = null;
      let earnTime = null;
      for (let earnedBadge of this.context.badges) {
        if (earnedBadge.achievement.name === achievement.name) {
          earnDate = earnedBadge.dateAwarded.split('T')[0];
          earnTime = earnedBadge.dateAwarded;
        }
      }

      if (!this.props.ifFilter || this.props.seeAll) {
        // When page gets loaded for the first time
        if (earnDate !== null) {
          badgesList.push({
            name: achievement.name.toUpperCase().replace(' BADGE', ''),
            desc: achievement.description,
            image: achievement.imageLink,
            earnDate: earnDate,
            earnTime: earnTime
          });
        } else {
          badgesList.push({
            name: achievement.name.toUpperCase().replace(' BADGE', ''),
            desc: achievement.description,
            image: achievement.imageLink,
            earnDate: null,
            earnTime: null
          });
        }
        this.setState({
          badges: badgesList
        });
      } else {
        // On changing dates
        if (
          earnDate !== null &&
          stringToDate(earnTime) >= startDate &&
          stringToDate(earnTime) <= endDate
        ) {
          filteredBadges.push({
            name: achievement.name.toUpperCase().replace(' BADGE', ''),
            desc: achievement.description,
            image: achievement.imageLink,
            earnDate: earnDate,
            earnTime: earnTime
          });
        }
      }
    }

    // The block of code below sets the state of badges[] to filtered badge iff there's a filter OR 'SEE ALL' toggle is off
    if (this.props.ifFilter && !this.props.seeAll) {
      this.setState({
        badges: filteredBadges
      });
    }

    this.setState({ loading: false });
  }

  sortByEarnDate(array) {
    array.sort(function(a, b) {
      if (b.earnTime && !a.earnTime) {
        return 1;
      } else if (a.earnTime && !b.earnTime) {
        return -1;
      } else if (!a.earnTime && !b.earnTime) {
        return 0;
      }
      return stringToDate(b.earnTime) - stringToDate(a.earnTime);
    });
  }

  render() {
    this.sortByEarnDate(this.state.badges);
    return (
      <div className={style.badgeContainer}>
        {this.state.loading ? (
          <div className={style.spinnercontainer}>
            <Spinner color="primary" className="dashboard-session-spinner" />
          </div>
        ) : (
          <>
            <div className={style.description}>
              <p>You can earn badges by completing challenges in the game.</p>
            </div>

            <BlueSquaredScroll>
              <div className={style.badgesGrid}>
                {this.state.badges.map((badge) => {
                  return (
                    <DetailedBadge
                      key={`detailed_badge_${badge.name}`}
                      badge={badge}
                    ></DetailedBadge>
                  );
                })}
              </div>
            </BlueSquaredScroll>
          </>
        )}
      </div>
    );
  }
}
UserBadges.contextType = GlobalContext;

import React from 'react';
import ReactGA from 'react-ga';
import { stringDateToUtcString, stringToDate } from '../../../../CONSTANTS';
import {
  Button,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
  ModalHeader,
  Col,
  CardBody,
  Row
} from 'reactstrap';
import delIcon from '../../images/garbage.svg';
import editIcon from '../../images/pencil.svg';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import JobShadowingAddModify from './JobShadowingAddModify';
import style from './JobShadowing.module.css';
import styles from '../courses/Course.module.css';
import CardAdmin from './CardAdmin';

export default class JobShadowing extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleModifyModal = this.toggleModifyModal.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.deleteSelf = this.deleteSelf.bind(this);
    this.convertDateDisplay = this.convertDateDisplay.bind(this);
    this.formRef = React.createRef();
    this.state = {
      deleteModal: false,
      editModal: false,
      collapse: false
    };
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  //stop propagation helps to stop using parent onclick function to expand/contract the card
  toggleDeleteModal(e) {
    if (e != null) e.stopPropagation();
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal
    }));
  }

  toggleModifyModal(e) {
    if (e != null) e.stopPropagation();
    this.setState((prevState) => ({
      editModal: !prevState.editModal
    }));
  }

  //collapse each and every card for share it records
  toggleCollapse() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  //Delete share it function
  deleteSelf() {
    //toggles confirmation
    this.toggleDeleteModal();
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/ShareIT/${this.props.payload.shadowId}`,
      { method: 'delete', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toast(
          `Shadow record "${this.props.payload.shadowUser.email}" has been deleted.`,
          {
            type: toast.TYPE.SUCCESS,
            autoClose: true
          }
        );
        if (myJson.success) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toast(`Error in Deleting Share IT record.`, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      });
  }
  //converts date from utc to est
  //takes utc as argument if no utc its converted to utc in the function
  convertDateDisplay = (date, dateOnly = false) => {
    if (date == null) {
      return;
    }
    var dateObj = stringToDate(
      dateOnly === true ? stringDateToUtcString(date) : date
    );
    const newDate = String(
      dateObj.toLocaleDateString('en-US', {
        month: 'long',
        weekday: 'long',
        day: 'numeric',
        year: 'numeric'
      })
    );
    return newDate;
  };

  render() {
    return (
      <Card>
        <CardHeader style={{ paddingBottom: '0px' }} onClick={this.toggleCollapse}>
          <h5
            className={`${style.cardTitle} ${style.collapseShadowHost_cardBold}`}
            style={{ float: 'left' }}
          >
            {this.props.payload.shadowUser?.name}
          </h5>
          {/*DISPLAY DETAILS OF THE Share IT RECORD*/}
          <Button
            size="sm"
            outline
            color="danger"
            style={{ float: 'right' }}
            onClick={this.toggleDeleteModal}
          >
            {/*DELETE*/}
            <img style={{ height: '24px' }} src={delIcon} alt={'delete button'} />
          </Button>
          <Button
            size="sm"
            outline
            color="white"
            style={{ float: 'right' }}
            onClick={this.toggleModifyModal}
          >
            {/*EDIT*/}
            <img style={{ height: '24px' }} src={editIcon} alt={'edit button'} />
          </Button>
          <br />
          <div
            className={style.showHideButton}
            id={'show-hide-button-id-' + this.props.payload.ShadowUserId}
            color="secondary"
            size="sm"
          >
            {
              <i
                className={[
                  styles.arrow,
                  this.state.collapse ? styles.up : styles.down
                ].join(' ')}
              />
            }
          </div>
        </CardHeader>
        {/*dont show if not collapsed*/}
        {this.state.collapse && (
          <div>
            <div className={style.collapseShadowHost}>
              <Row style={{ margin: 'none' }}>
                <Col className={style.collapseShadowHost_column}>
                  <Card className={style.collapseShadowHost_card}>
                    <CardHeader
                      className={`${style.collapseShadowHost_cardHeader} ${style.collapseShadowHost_cardBold}`}
                    >
                      Shadow
                    </CardHeader>
                    <CardBody>
                      <ul className={style.collapseShadowHost_infoList}>
                        <li className={style.collapseShadowHost_cardBold}>
                          {this.props.payload.shadowUser.name}
                        </li>
                        <li>{this.props.payload.shadowCurrentRole}</li>
                        <li>{this.props.payload.shadowUser.email}</li>
                        <li>
                          <i>{this.props.payload.shadowBranch}</i>
                        </li>
                        <br />
                        <li className={style.collapseShadowHost_cardBold}>
                          Report to:
                        </li>
                        <li>{this.props.payload.shadowManager.name}</li>
                        <li>{this.props.payload.shadowManager.email}</li>
                        <li>Manager</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
                <Col className={style.collapseShadowHost_column}>
                  <CardAdmin host={this.props.payload} />
                </Col>
              </Row>
            </div>
            <div className={style.collapseShadowHost_infoTable}>
              <Table size="sm" striped style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th style={{ width: '30%' }}>Parameter</th>
                    <th style={{ width: '70%' }}>Value</th>
                  </tr>
                </thead>
                <tbody style={{ width: '100%' }}>
                  <tr>
                    <td>Share IT role/postion</td>
                    <td>{this.props.payload.shareITRole}</td>
                  </tr>
                  <tr>
                    <td>Start Date</td>
                    <td>
                      {this.convertDateDisplay(this.props.payload.startDate, true)}
                    </td>
                  </tr>
                  <tr>
                    <td>End Date</td>
                    <td>
                      {this.convertDateDisplay(this.props.payload.endDate, true)}
                    </td>
                  </tr>
                  <tr>
                    <td>Timeline</td>
                    <td>{this.props.payload.timeline?.timelineDesc}</td>
                  </tr>
                  <tr>
                    <td>Note</td>
                    <td>{this.props.payload.notes}</td>
                  </tr>
                  <tr>
                    <td>Created</td>
                    <td>
                      {this.convertDateDisplay(this.props.payload.createdOn)} by{' '}
                      {this.props.payload.createdByNavigation?.name}
                    </td>
                  </tr>
                  <tr>
                    <td>Last Updated</td>
                    <td>
                      {this.convertDateDisplay(this.props.payload.updatedOn)} by{' '}
                      {this.props.payload.updatedByNavigation?.name}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}
        {/*DELETE MODAL*/}
        <Modal
          style={{ maxWidth: '700px' }}
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeleteModal}
          className={this.props.className}
        >
          <ModalHeader style={{ border: 'none' }} toggle={this.toggleDeleteModal}>
            Confirm Deletion
          </ModalHeader>
          <ModalBody className={style.shadowHostDeleteModal}>
            The selected "Share IT Shadow and Host" will be deleted. Are you sure?
          </ModalBody>
          <ModalFooter style={{ border: 'none' }}>
            <Button color="danger" onClick={this.deleteSelf}>
              DELETE
            </Button>{' '}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/*MODIFY MODAL*/}
        <Modal
          style={{ maxWidth: '900px' }}
          isOpen={this.state.editModal}
          toggle={this.toggleModifyModal}
          className={this.props.className}
        >
          <ModalHeader style={{ border: 'none' }} toggle={this.toggleModifyModal}>
            Update Share IT Host and Shadow
          </ModalHeader>
          <ModalBody>
            <JobShadowingAddModify
              payload={this.props.payload}
              editModal={true}
              ref={this.formRef}
              manualReload={this.props.manualReload}
              closeModal={this.toggleModifyModal}
            />
          </ModalBody>
        </Modal>
      </Card>
    );
  }
}

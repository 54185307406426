import React from 'react';
import { register, unregister } from '../../CONSTANTS';
import { toast } from 'react-toastify';
import { hasBadge } from '../badges/BadgesFunctions.jsx';
import { GetRegistrationBadge } from '../badges/BadgesAPI.jsx';
import { Badges } from '../../enums/badges.enum';
import PropTypes from 'prop-types';

import GlobalContext from '../../context/GlobalContext';
import DigiButton from '../digiButton/DigiButton';
import { ON_DEMAND_SLUG } from '../../pages/sessions/components/SessionUtils';
import ReactGA from 'react-ga';

export default class RegisterButton extends React.Component {
  static defaultProps = {
    addBadgeToList: () => {}
  };

  static propTypes = {
    addBadgeToList: PropTypes.func,
    onRegistered: PropTypes.func,
    onUnregistered: PropTypes.func,
    courseDetails: PropTypes.object,
    registrationRecord: PropTypes.object,
    registrationId: PropTypes.number,
    registered: PropTypes.bool,
    area: PropTypes.string
  };

  state = {
    loading: false
  };

  callOnRegistered = () => {
    if (this.props.onRegistered) {
      try {
        this.props.onRegistered();
      } catch (e) {
        ReactGA.exception({
          description: e.toString()
        });
      }
    }
  };

  isOnDemand = () =>
    this.props.courseDetails &&
    this.props.courseDetails.format &&
    this.props.courseDetails.format.slug === ON_DEMAND_SLUG;

  registerForCourse = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true });
    register(
      this.context.user,
      this.props.courseDetails.courseId,
      this.props.courseDetails.courseDetailsId
      // ,
      // this.props.courseDetails.maximumCapacity,
    ).then(
      (result) => {
        if (result.success) {
          if (!hasBadge(this.context.badges, Badges.Registration)) {
            GetRegistrationBadge().then((myJson) => {
              if (myJson.success) {
                this.props.addBadgeToList(myJson.data);
              }
            });
          }
          toast(
            `Session ${this.props.courseDetails.courseName} ${
              this.isOnDemand()
                ? 'is available to attend'
                : 'registered successfully'
            }`,
            {
              type: toast.TYPE.SUCCESS,
              autoClose: true
            }
          );
          //set value in to context
          let updatedParticipantsArray = [];
          updatedParticipantsArray.push(result.data);
          this.context.setParticipantsListForCourse(
            this.props.courseDetails.courseDetailsId,
            updatedParticipantsArray
          );
        } else {
          toast(result.error, {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        }
        this.callOnRegistered();
        this.setState({ loading: false });
      },
      (err) => {
        // Toast message on error
        toast(err, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        this.callOnRegistered();
        this.setState({
          loading: false
        });
      }
    );
  };

  unregisterForCourse = () => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });
    const registerId = this.props.registrationId;
    unregister(registerId).then(
      (result) => {
        if (result.success) {
          this.setState({ loading: false });
          try {
            if (this.props.onUnregistered) this.props.onUnregistered();
          } catch (e) {
            ReactGA.exception({
              description: e.toString()
            });
          }
          toast(
            'Session "' +
              this.props.courseDetails.courseName +
              (this.isOnDemand() ? '" withdrawn' : '" unregistered') +
              ' successfully',
            {
              type: toast.TYPE.SUCCESS,
              autoClose: true
            }
          );
          //clear value in the context
          const newParticipantsList = [];
          this.context.setParticipantsListForCourse(
            this.props.courseDetails.courseDetailsId,
            newParticipantsList
          );
        } else {
          toast(result.error, {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        }
        this.setState({ loading: false });
      },
      (err) => {
        toast(err, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });

        this.setState({
          loading: false
        });
      }
    );
  };

  render() {
    const course = this.props.courseDetails;
    const registered = this.props.registered;
    const registrationRecord =
      this.props.courseDetails && this.props.courseDetails.participants
        ? this.props.courseDetails.participants.find(
            (participant) => participant.userId === this.context.user.userId
          )
        : null;
    const onDemandAttended = registrationRecord
      ? registrationRecord.attended
      : false;
    return (
      <>
        {course && (
          <DigiButton
            id={`register-button-${this.props.area || 'default'}-${
              course.courseDetailsId
            }`}
            selected={registered}
            onClick={!registered ? this.registerForCourse : this.unregisterForCourse}
            disabled={this.isOnDemand() && registered && onDemandAttended}
            loading={this.state.loading}
            colorTheme="lightBlue"
          >
            {registered
              ? this.isOnDemand()
                ? onDemandAttended
                  ? 'Completed'
                  : 'Withdraw'
                : 'Unregister'
              : this.isOnDemand()
              ? 'Enroll'
              : 'Register'}
          </DigiButton>
        )}
      </>
    );
  }
}
RegisterButton.contextType = GlobalContext;

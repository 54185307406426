const tableCustomStyles = {
    headCells: {
        style:
        {
            textAlign: 'left', border: '1px solid #ccc',
            /*padding: 0.6em;*/
            //   borderBottom: '2px solid black',
            backgroundColor: '#5873c3', color: 'white', fontWeight: '500', fontSize: 'inherit',
            //   borderRadius: '0 1em 1em 0'
            paddingLeft: '10px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
    rows: {
        style: {
            '&:nth-child(even)': {
                backgroundColor: '#eaebf5',
            },
            '&:nth-child(odd)': {
                backgroundColor: '#d1d5ea',
            },
            '&:th last-child': {
                //   borderRadius: '0 1em 1em 0'
            }
        },
    },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    

}

export { tableCustomStyles };
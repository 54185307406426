import React from 'react';


function EmptyDataTableBase(props) {
    const thStyle = {
        textAlign: 'left',
        border: '1px solid #ccc',
        backgroundColor: '#5873c3',
        color: 'white',
        fontWeight: '500',
        fontSize: '12px',
        padding: '0.5em',
    };
    const trStyle = {
        backgroundColor: '#EEEEEE',
        fontSize: '12px',
        textAlign: 'center',

    }
    return (
        <table>
            <thead  >
                <tr>
                    {props.map((column, index) => (
                        <th style={thStyle} key={index} >
                            {column.name}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody ><tr style={trStyle}><td colSpan='100'>There are no records to display.</td></tr></tbody>
        </table>
    );
}


export default EmptyDataTableBase;
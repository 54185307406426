import React from 'react';
import styles from './CollapseIcon.module.css';

const CollapseIcon = ({ collapse }) => {
  return (
    <>
      <div
        className={[
          styles.collapseButton,
          collapse ? styles.collapse : styles.expand
        ].join(' ')}
      >
        <div className={styles.innerButton}></div>
      </div>
    </>
  );
};

export default CollapseIcon;

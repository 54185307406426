import { adalApiFetch } from './adalConfig';
import { Badges } from './enums/badges.enum';
import { PageName } from './enums/pagePaths.enum';
import { ON_DEMAND_SLUG } from './pages/sessions/components/SessionUtils';

// Max capacity switch
export const MAX_CAPACITY = 295;

// FEEDBACK QUESTIONS
export const QUESTION1 = 'Was this learning session valuable to you?';
export const QUESTION2 = 'Did this session meet your learning expectations?';
export const QUESTION3 =
  'If no, what were you expecting to learn that wasn’t covered in the session?';
export const QUESTION4 =
  'What did you learn that you will be able to use immediately?';
export const QUESTION5 = 'Was the presenter knowledgeable and well prepared?';
export const QUESTION6 = 'Was the content well organized and easy to follow?';
export const QUESTION7 = 'Was enough time allotted for the session?';
export const QUESTION8 =
  'What additional sessions would you like to have in the future?';

export const NEW_BADGE_BROADCAST_CHANNEL = 'received_new_badge';
export const DAYS_FOR_SURVEY = 10; // How many days can you take the survey?

export const TECHNICAL_ERROR_MESSAGE =
  'A technical error has occurred. Please try again later.';

// These page identifier strings are to mark page and badge innovator messages as complete or not.
// Order is important here! Only add new pages to end of array or everything will break.
// Numbers next to the names of pages/badges indicates the binary representation in tutorial status
export const INNOVATOR_TUTORIAL_BADGE_STATUS = [
  PageName.Homepage, //1
  PageName.Dashboard, //2
  PageName.UpcomingSessions, //4
  PageName.PastSessions, //8  
  Badges.Welcome, //16
  Badges.Buddy, //32
  Badges.Registration, //64
  Badges.Presenter, //128
  Badges.Survey, //256
  Badges.Eureka, //512
  Badges.LtcToolkit, //1024
  Badges.LtcTechnology, //2048
  Badges.EmergingTechnology, //4096
  Badges.NonTechnical, //8192
  Badges.BusinessAcumen, //16384
  Badges.NonLtc,
  Badges.Shadow,
  Badges.Host,
  PageName.OnDemandSessions
  // Add any new items here
];

// FUNCTIONS
export function validateUrl(url) {
  const pattern = new RegExp(
    'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)'
  ); // fragment locator
  return pattern.test(url);
}

export function validateEmail(email) {
  const pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return pattern.test(email);
}

export function validateNoReply(email) {
    const pattern = new RegExp(
        /^\w+@_noreply\.ca$/

    );
    return pattern.test(email);
}

// Return whether the user can take the survey based on his attendance and how long ago the session was conducted
export function canTakeSurvey(session, userId, forceAttended = false) {
  // Have you attended?
  const userParticipantRecord = session.participants.find(
    (participant) => participant === userId
  );

  if (
    !forceAttended &&
    (!userParticipantRecord || !userParticipantRecord.attended)
  ) {
    return false;
  }

  let courseStart = null;
  let tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - DAYS_FOR_SURVEY);

  let onDemand = false;

  if (session.courseDetails) {
    const courseDetails = session.courseDetails;
    if (Array.isArray(session.courseDetails)) {
      onDemand =
        courseDetails.length > 0 && courseDetails[0].format.slug === ON_DEMAND_SLUG;
    } else {
      onDemand = courseDetails && courseDetails.format.slug === ON_DEMAND_SLUG;
    }
  }

  if (onDemand) {
    if (userParticipantRecord) {
      if (userParticipantRecord.attended && userParticipantRecord.attendedOn) {
        courseStart = stringToDate(userParticipantRecord.attendedOn);
      } else {
        return false;
      }
    } else {
      if (session.attended && session.attendedOn) {
        courseStart = stringToDate(session.attendedOn);
      } else {
        return false;
      }
    }
  } else {
    courseStart = stringToDate(session.courseStart);
  }

  return courseStart > tenDaysAgo;
}

/**
 *  Convert an ISO string (in UTC) to a date object
 */
export function stringToDate(dateString) {
  var b = dateString.split(/\D/);
  return new Date(Date.UTC(b[0], b[1] - 1, b[2], b[3], b[4], b[5]));
}

/**
 * Takes a string such as 2020-04-04 (if there is a time value as well i.e. 2020-04-04T00:00:00, it assumes that the time is Eastern Time Zone)
 * and converts it to an ISO string in UTC time (i.e. 4AM or 5AM). This value can then be sent to the API when it expects a UTC value, for example.
 * @param {*} date
 */
export function stringDateToUtcString(date) {
  if (!date) {
    return '';
  }

  const dateCurrentTimezone = new Date(date);
  return new Date(
    dateCurrentTimezone.getTime() +
      dateCurrentTimezone.getTimezoneOffset() * 60 * 1000
  ).toISOString();
}

/**
 * Checks to see if the given page's tutorial has been completed
 * @param status the current user's tutorial status
 * @param pageName the page identifier
 * @returns {null|boolean} true if the tutorial has been competed, false if it has not been completed, null if the 'pageName' is invalid.
 */
export function checkInnovatorStatus(status, pageName) {
  let bin = (status >>> 0).toString(2);
  bin = bin
    .split('')
    .reverse()
    .join('');
  const index = INNOVATOR_TUTORIAL_BADGE_STATUS.indexOf(pageName);
  if (index === -1) return null;
  return bin.charAt(index) === '1';
}
/**
 * Get the innovator page/badge index
 * @param pageName the page name/ badge slug
 * @returns {null|int} the innovator page/badge index
 */
export function getInnovatorPageIndex(pageName) {
  const index = INNOVATOR_TUTORIAL_BADGE_STATUS.indexOf(pageName);
  if (index === -1) return null;
  return index;
}

/**
 * Return the new tutorial status number
 * @param {*} currentStatus  the current status
 * @param {*} newItemName    name of the item to add to the tutorial status
 */
export function getNewInnovatorStatus(currentStatus, newItemName) {
  let index = INNOVATOR_TUTORIAL_BADGE_STATUS.indexOf(newItemName);
  return currentStatus + Math.pow(2, index);
}

/**
 * Marks a page's tutorial as complete
 * @param currentStatus the user's current tutorial status
 * @param pageName the page identifier
 * @returns {Promise<Object>} a promise that makes the request to the server and returns the response
 */
export function updateInnovatorStatus(currentStatus, pageName = null) {
  return new Promise((resolve, reject) => {
    let newStatus = currentStatus;
    if (pageName !== null) {
      const check = checkInnovatorStatus(currentStatus, pageName);
      if (check === true) {
        resolve({
          success: true,
          msg: 'tutorial has already been completed for this page'
        });
        return;
      }
      if (check === null) {
        reject('invalid page name');
        return;
      }
      newStatus = getNewInnovatorStatus(currentStatus, pageName);
    }

    let data = {
      tutorialStatus: newStatus
    };
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/tutorialStatus',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
      .then(
        (response) => {
          forceCache();

          return response.json();
        },
        () => {
          reject('An error occurred');
        }
      )
      .then(
        (myJson) => {
          if (myJson.success) {
            resolve(newStatus);
          } else {
            reject('An error occurred');
          }
        },
        () => {
          reject('An error occurred');
        }
      );
  });
}

/**
 * This function consolidates all the code that is needed to REGISTER a user in a specific course
 */
export function register(user, courseId, courseDetailsId) {
  return new Promise((resolve, reject) => {
    // REGISTER
    const data = {
      courseId: courseId,
      courseDetailsId: courseDetailsId
    };

    // REGISTER
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Register', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(
        (response) => {
          return response.json();
        },
        () => {
          reject('An error occurred');
        }
      )
      .then(
        (myJson) => {
          resolve(myJson);
        },
        () => {
          reject('An error occurred');
        }
      );
  });
}

/**
 * This function consolidates all the code that is needed to UNREGISTER a user in a specific course
 */
export function unregister(registrationId) {
  return new Promise((resolve, reject) => {
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Register/' + registrationId,
      {
        method: 'delete'
      }
    )
      .then(
        (response) => {
          return response.json();
        },
        () => {
          reject('An error occurred');
        }
      )
      .then(
        (myJson) => {
          resolve(myJson);
        },
        () => {
          reject('An error occurred');
        }
      );
  });
}

/**
 * Forces a cache expiry on the database
 */
export function forceCache() {
  return adalApiFetch(
    fetch,
    process.env.REACT_APP_API_BASE_DOMAIN + '/api/Cache/clearall',
    {
      method: 'get'
    }
  );
}
/**
 * Returns a deep copy of an item
 * @param {*} item
 * @returns {*} deepcopy
 */
export function deepcopy(item) {
  return JSON.parse(JSON.stringify(item));
}

/**
 * Return the value of a cookie, or null if it doesn't exist
 * @param {*} name  name of cookie
 */
export function cookieValue(name) {
  const theCookie = document.cookie.split('; ').find((row) => row.startsWith(name));

  if (!theCookie) {
    return null;
  }

  return theCookie.split('=')[1];
}

export function downloadCsv(filename, csv) {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

import React, { useEffect, useState } from 'react';
import DigiHeadingBoxCustomTitleBg from './../../../components/DigiHeadingBox.jsx/DigiHeadingBoxCustomTitleBg';
import CustomScroll from './../../../components/customScroll/CustomScroll';
import { stringToDate } from '../../../CONSTANTS';
import styles from './EmailContent.module.css';
import DigiButton from './../../../components/digiButton/DigiButton';
import { urlToBase64 } from '../../../utils/images';
import dwLogo from '../../../assets/logo-email.png';
import { getCourseLink } from '../../../utils/courses';
import RecommendButton from '../../../components/recommendButton/RecommendButton';

const EmailContent = (props) => {
  const [dwUri, dwUriChange] = useState(null);
  const [subject, changeSubject] = useState([]);
  const [bodyFirstLine, changeBodyFirstLine] = useState('');

  const fetchImages = async () => {
    dwUriChange(await urlToBase64(dwLogo));
  };

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    const getEmailFields = () => {
      let subject = [];
      if (props.isRecommend) {
        return;
      } else if (props.actionBtnText === 'Remind') {
        // Remind a mandatory session
        if (props.courseDetails.course.mandatory) {
          subject.push('YOUR MANAGER');
          subject.push('YOU TO ATTEND');
          subject.push('A MANDATORY SESSION!');
          changeSubject(subject);
          changeBodyFirstLine(
            'Your manager reminds you to attend the following upcoming session:'
          );
        }
        // Remind a regular session
        else {
          subject.push('YOUR MANAGER');
          subject.push('YOU TO FOLLOW UP');
          subject.push('A SESSION!');
          changeSubject(subject);
          changeBodyFirstLine(
            'Your manager reminds you to follow up the following upcoming recommended session:'
          );
        }
      }
      // SHARE
      else {
        // Share a mandatory session
        if (props.courseDetails.course.mandatory) {
          subject.push('YOUR MANAGER');
          subject.push('WITH YOU THE MATERIALS');
          subject.push('OF A PAST MANDATORY SESSION!');
          changeSubject(subject);
          changeBodyFirstLine(
            'Your manager shares with you the materials for the following session:'
          );
        }
        // Share a recommended session
        else if (props.pageId === 'recommendedSessions-details') {
          let subject = [];
          subject.push('YOUR MANAGER');
          subject.push('WITH YOU THE MATERIALS');
          subject.push('OF A RECOMMENDED SESSION!');
          changeSubject(subject);
          changeBodyFirstLine(
            'Your manager shares with you the materials for the following session:'
          );
        }
        // Share a regular (not recommended) session
        else {
          subject.push('YOUR MANAGER');
          subject.push('WITH YOU THE MATERIALS');
          subject.push('OF A PAST SESSION!');
          changeSubject(subject);
          changeBodyFirstLine(
            'Your manager shares with you the materials for the following session:'
          );
        }
      }
    };
    getEmailFields();
  }, [
    props.isRecommend,
    props.actionBtnText,
    props.courseDetails.course.mandatory,
    props.pageId
  ]);

  const recommendEmailContentHtmlString = (firstName = '(Staff Name)') => {
    const courseStartObj = stringToDate(props.courseDetails.courseStart);
    const courseEndObj = stringToDate(props.courseDetails.courseEnd);

    const date = String(
      courseStartObj.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
    );

    const startTime = String(
      courseStartObj.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    );

    const endTime = String(
      courseEndObj.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    );

    const link = `${process.env.REACT_APP_UI_BASE_DOMAIN}/${getCourseLink(
      props.courseDetails
    )}`;

    const courseName = props.courseDetails.course.mandatory
      ? `${props.courseDetails.courseName} (Mandatory)`
      : `${props.courseDetails.courseName}`;

    const fixedWidth = (content) => `
    <table border="0" cellspacing="0" width="100%">
      <tr>
        <td></td>
        <td width="460">
        ${content}  
        </td>
        <td></td>
      </tr>
    </table> 
      `;

    return `
    <div>
    ${fixedWidth(`
      <table border="0" cellspacing="0" width="100%">
        <tr>
          <td width="100"></td>
          <td width="260"></td>
          <td width="100" style="text-align: center;"><img src="${dwUri}" alt="Digital World Logo"></td>
        </tr>
      </table>
      `)}
      ${fixedWidth(`
        <div style="text-align: center; font-weight: bold; font-size: 1.1em; line-height: 1.2;">
          <br>
          YOUR MANAGER<br>
          <i>RECOMMENDS YOU</i><br>
          TO ATTEND A SESSION!<br>
        </div>  
      `)}
      ${fixedWidth(`
            <br>
            Dear ${firstName},
            <br><br>
            Your manager recommends that you attend the following session:
            <br><br>
            <p>
            <strong>Session Name: </strong>${courseName}<br>
            <strong>Date: </strong>${date}<br>
            <strong>Time: </strong>${startTime} - ${endTime}<br>
            <a href="${link}">${link}</a>
            <br><br>
              Should you have any questions, please arrange to speak with your manager.
            <br><br>
            Your L+D Team
        `)}
    </div>`;
  };

  /** Email Content for SHARING a session
   */
  const shareEmailContentHtmlString = () => {
    const courseStartObj = stringToDate(props.courseDetails.courseStart);

    const date = String(
      courseStartObj.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
    );

    const link = `${process.env.REACT_APP_UI_BASE_DOMAIN}/${getCourseLink(
      props.courseDetails
    )}`;

    const courseName = props.courseDetails.course.mandatory
      ? `${props.courseDetails.courseName} (Mandatory)`
      : `${props.courseDetails.courseName}`;

    const fixedWidth = (content) => `
    <table border="0" cellspacing="0" width="100%">
      <tr>
        <td width="100%">
        ${content}  
        </td>
      </tr>
    </table> 
      `;

    return `
    <div>
    
      ${fixedWidth(`
        <table border="0" cellspacing="0" width="100%">
          <tr>
            <td width="90%">
              <div style="text-align: center; font-weight: bold; font-size: 1.5rem; line-height: 1.2;">
                <span style="color: #005EB8">${subject[0]}</span><br />
                <span style="color: #00205B"><i>SHARES</i> ${subject[1]}</span><br />
                <span style="color: #005EB8">${subject[2]}</span><br />
              </div>
            </td>
            <td width="10%" style="text-align: center;"><img src="${dwUri}" alt="Digital World Logo"></td>
          </tr>
        </table>
      `)}
      ${fixedWidth(`
            <div style="font-size: 18px; text-align: left; color: #00205B">
              <br>
              ${bodyFirstLine}
              <br>
              <p>
              <span style="font-weight: bold"; color: "#00205B">Session Name:</span> ${courseName}
              <br>
              <span style="font-weight: bold"; color: "#00205B">Pillar:</span> ${props.courseDetails.course.domain.name}
              <br>
              <span style="font-weight: bold"; color: "#00205B">Date:</span> ${date}
              <br><br>
              <a href="${link}">${link}</a>
              <br><br>
              Should you have any questions, please arrange to speak with your manager.
              <br>
              To see the latest L+D sessions, please check out the full calendar in Digital World.
              <br><br>
              Happy learning!
              <br>
              Your L+D Team
            </div>
        `)}
    </div>`;
  };

  /** Email Content for REMINDING a session
   */
  const remindEmailContentHtmlString = () => {
    const courseStartObj = stringToDate(props.courseDetails.courseStart);
    const courseEndObj = stringToDate(props.courseDetails.courseEnd);

    const date = String(
      courseStartObj.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
    );

    const startTime = String(
      courseStartObj.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    );

    const endTime = String(
      courseEndObj.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    );

    const link = `${process.env.REACT_APP_UI_BASE_DOMAIN}/${getCourseLink(
      props.courseDetails
    )}`;

    const courseName = props.courseDetails.course.mandatory
      ? `${props.courseDetails.courseName} (Mandatory)`
      : `${props.courseDetails.courseName}`;

    const fixedWidth = (content) => `
    <table border="0" cellspacing="0" width="100%">
      <tr>
        <td width="460">
        ${content}  
        </td>
      </tr>
    </table> 
      `;

    return `
    <div>
    
      ${fixedWidth(`
        <table border="0" cellspacing="0" width="100%">
          <tr>
            <td width="360">
              <div style="text-align: center; font-weight: bold; font-size: 1.5rem; line-height: 1.2;">
                <span style="color: #005EB8">${subject[0]}</span><br />
                <span style="color: #00205B"><i>REMINDS</i> ${subject[1]}</span><br />
                <span style="color: #005EB8">${subject[2]}</span><br />
              </div>
            </td>
            <td width="100" style="text-align: center;"><img src="${dwUri}" alt="Digital World Logo"></td>
          </tr>
        </table>
      `)}
      ${fixedWidth(`
            <div style="font-size: 18px; text-align: left; color: #00205B">
              <br>
              ${bodyFirstLine}
              <br>
              <p>
              <span style="font-weight: bold"; color: "#00205B">Session Name:</span> ${courseName}
              <br>
              <span style="font-weight: bold"; color: "#00205B">Pillar:</span> ${props.courseDetails.course.domain.name}
              <br>
              <span style="font-weight: bold"; color: "#00205B">Date:</span> ${date}
              <br>
              <span style="font-weight: bold"; color: "#00205B">Time:</span> ${startTime} - ${endTime}
              <br><br>
              <a href="${link}">${link}</a>
              <br><br>
              Should you have any questions, please arrange to speak with your manager.
              <br>
              To see the latest L+D sessions, please check out the full calendar in Digital World.
              <br><br>
              Happy learning!
              <br>
              Your L+D Team
            </div>
        `)}
    </div>`;
  };

  /**
   * Gets email content depending on if session is being shared or reminded of
   ** Note: Outlook image sizes cannot be trusted...
   * If you want two images the same size, they should be the same size in
   * pixels in the raw image. If you wanna set the size of the image, also
   * gotta do that in pixels on the actual image asset.
   */
  const getEmailContent = () => {
    if (props.isRecommend) return recommendEmailContentHtmlString();
    if (props.actionBtnText === 'Remind') {
      return remindEmailContentHtmlString();
    } else {
      return shareEmailContentHtmlString();
    }
  };

  const render = () => {
    return (
      <div>
        <DigiHeadingBoxCustomTitleBg
          title={`The following message will be sent to ${props.staffCount} of your staff.`}
          customTitleBg={styles.yellowTitleBg}
          wrapperClassName={styles.step2Container}
          contentHolderClassName={styles.step2Content}
          onCloseClick={props.onCloseClick}
          closeable
          moveInAnimation
        >
          <CustomScroll className={styles.emailScroll}>
            <div dangerouslySetInnerHTML={{ __html: getEmailContent() }}></div>
          </CustomScroll>
          <div className={styles.actionButtonContainer}>
            {props.isRecommend ? (
              <RecommendButton
                courseId={props.courseDetails.courseDetailsId}
                onClick={() => {
                  props.sendEmails();
                }}
              />
            ) : (
              <DigiButton
                id={`confirm-action-${props.courseDetails.courseDetailsId}`}
                className={styles.actionBtn}
                onClick={() => {
                  props.sendEmails(subject, bodyFirstLine);
                }}
              >
                {props.actionBtnText}
              </DigiButton>
            )}
          </div>
        </DigiHeadingBoxCustomTitleBg>
      </div>
    );
  };

  return render();
};

export default EmailContent;

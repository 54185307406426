import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Spinner } from 'reactstrap';
import { adalApiFetch } from '../../../adalConfig';

import style from './SessionFilter.module.css';
import AccessibleButton from '../../../components/accessibleButton/AccessibleButton';

export default class SessionFilter extends Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.state = {
      fetchedDomain: [],
      fetchedCategory: [],
      domain: [],
      category: [],
      mandatory: false,
      loadingD: true,
      loadingC: true
    };

    this.filterChange = this.filterChange.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedDomain !== -1) {
      this.filterChange('domain', this.props.selectedDomain);
    }

    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Domains', {
      method: 'GET',
      signal: this.abortController.signal
    })
      .then((response) => response.json())
      .then((data) => {
        let domains = data.data;
        domains.sort((a, b) => (a.order > b.order ? 1 : -1));
        this.setState({ fetchedDomain: domains, loadingD: false });
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });

    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/SessionTypes',
      { method: 'GET', signal: this.abortController.signal }
    )
      .then((response) => response.json())
      .then((data) => {
        let categories = data.data;
        categories.sort((a, b) => (a.order > b.order ? 1 : -1));
        this.setState({ fetchedCategory: categories, loadingC: false });
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  filterChange(target, value) {
    let promise = new Promise((resolve) => {
      if (target === 'domain') {
        let domainFilter = this.state.domain;
        let index = domainFilter.indexOf(value);

        if (index > -1) {
          domainFilter.splice(index, 1);
          this.setState({ domain: domainFilter });
        } else {
          domainFilter.push(value);
          this.setState({ domain: domainFilter });
        }
      } else if (target === 'category') {
        let categoryFilter = this.state.category;
        let index = categoryFilter.indexOf(value);

        if (index > -1) {
          categoryFilter.splice(index, 1);
          this.setState({ category: categoryFilter });
        } else {
          categoryFilter.push(value);
          this.setState({ category: categoryFilter });
        }
      } else if (target === 'mandatory') {
        this.setState({ mandatory: value });
      }

      resolve();
    });

    promise.then(() => {
      this.props.parentFilter(
        this.state.domain,
        this.state.category,
        this.state.mandatory
      );
    });
  }

  render() {
    let selected = style.upcomingSessionFilterSelected;
    let notSelected = style.upcomingSessionFilter;

    return (
      <div className={style.filterContainer}>
        {(this.state.loadingD || this.state.loadingC) && <Spinner color="primary" />}

        {!(this.state.loadingD || this.state.loadingC) && (
          <div className={style.filterBlock}>
            <p className={style.filterTitle}>Pillar</p>
            {this.state.fetchedDomain.map((domain) => {
              return (
                <AccessibleButton
                  key={domain.domainId}
                  onClick={() => this.filterChange('domain', domain.domainId)}
                  className={
                    this.state.domain.indexOf(domain.domainId) !== -1
                      ? selected
                      : notSelected
                  }
                >
                  {domain.name}
                </AccessibleButton>
              );
            })}
          </div>
        )}

        {!(this.state.loadingD || this.state.loadingC) && (
          <div className={style.filterBlock}>
            <p className={style.filterTitle}>Category</p>
            {this.state.fetchedCategory.map((category) => {
              return (
                <AccessibleButton
                  key={category.sessionTypeId}
                  onClick={() =>
                    this.filterChange('category', category.sessionTypeId)
                  }
                  className={
                    this.state.category.indexOf(category.sessionTypeId) !== -1
                      ? selected
                      : notSelected
                  }
                >
                  {category.name}
                </AccessibleButton>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

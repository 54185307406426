import React from 'react';
import styles from './Innovator.module.css';
import { updateInnovatorStatus, getNewInnovatorStatus } from './../../CONSTANTS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Badges } from '../../enums/badges.enum';
import { Pillars } from '../../enums/pillars.enum';
import { GetWelcomeBadge } from '../badges/BadgesAPI.jsx';
import { hasBadge } from '../badges/BadgesFunctions.jsx';
import {
  displayFireWorks,
  displayConfetti
} from './animations/InnovatorAnimationTags.jsx';
import './animations/FireworksAnimation.css';
import './animations/ConfettiAnimation.css';
import GlobalContext from '../../context/GlobalContext';

export default class Innovator extends React.Component {
  constructor(props) {
    super(props);

    this.innovator = React.createRef();
    this.innovatorArrow = React.createRef();
    this.innovatorImg = React.createRef();
    this.innovatorDialogBox = React.createRef();
    this.innovatorDialogConnector = React.createRef();

    this.fireworksTimeout = null;
    this.confettiTimeout = null;

    this.state = {
      allTutorialMessages: [],
      index: -1,
      showFireworks: false,
      showConfetti: false,
      forceClose: false,
      isPageTutorial: true, // Set to false if the innovator's purpose is to show a badge
      newBadge: null,
      registrationBadge: null
    };
  }

  componentDidMount() {
    this.props.openInnovator(this.openInnovator);
    this.props.closeInnovator(this.closeInnovator);
    this.props.setAllTutorialMessages(this.setAllTutorialMessages);
    //handle click outside innovator
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;

    if (this.fireworksTimeout) {
      clearTimeout(this.fireworksTimeout);
      this.fireworksTimeout = null;
    }
    if (this.confettiTimeout) {
      clearTimeout(this.confettiTimeout);
      this.confettiTimeout = null;
    }
    this.props.setNewBadge(null);
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const node = this.innovator.current;
    if (!this.state.isPageTutorial && node && !node.contains(e.target)) {
      this.closeInnovator();
    }
  };

  /**
   * STEP 1: Set messages that the tutorial should show.
   * @param {} messages List of messages for the innovator
   * @param {*} isPageTutorial Is this a page tutorial (true) or badge tutorial (false)? This determines whether you can click away to close the innovator.
   */
  setAllTutorialMessages = (messages, isPageTutorial) => {
    return new Promise((resolve) => {
      //if (this.state.index === null || this.state.index === -1) {
      this.setState(
        {
          allTutorialMessages: messages,
          isPageTutorial: isPageTutorial
        },
        () => {
          resolve();
        }
      );
      // } else {
      //   reject();
      // }
    });
  };

  /**
   * STEP 2: Open the innovator.
   */
  openInnovator = () => {
    if (this.interval || this.disappearTimer) {
      this.resetInnovatorState().then(() => {
        this.showNextTutorialMessage();
      });
    } else {
      this.showNextTutorialMessage();
    }
  };

  /**
   * Close the innovator with animation and check for new badges afterwards.
   * @param e event
   */
  closeInnovator = (e = null, immediateClose = false) => {
    return new Promise((resolve) => {
      if (e) {
        e.preventDefault();
      }

      if (this.props.changeHasPillarNameHighlight) {
        this.props.changeHasPillarNameHighlight(null);
      }

      if (this.innovator.current) {
        this.innovatorDialogBox.current.style.visibility = 'hidden';
        this.innovatorDialogConnector.current.style.visibility = 'hidden';
        if (this.innovatorArrow.current) {
          this.innovatorArrow.current.style.visibility = 'hidden';
        }

        this.props.setNewBadge(null);

        // Fade out the innovator
        let imgOpacity = 1.0;

        if (immediateClose) {
          this.resetInnovatorState().then(() => {
            resolve();
          });
        } else {
          this.disappearTimer = setInterval(() => {
            if (this.innovatorImg.current) {
              this.innovatorImg.current.style.opacity = imgOpacity;
              imgOpacity = imgOpacity - 0.02;
              if (imgOpacity <= 0) {
                this.resetInnovatorState().then(() => {
                  this.props.showAnyNewBadges(); // Once innovator is completed, show any new badges you have received
                  resolve();
                });
              }
            }
          }, 25);
        }
      } else {
        resolve();
      }
    });
  };

  /**
   * Helper function to reset the innovator's UI state, and set the tutorial index to -1 (start from beginning).
   */
  resetInnovatorState() {
    return new Promise((resolve) => {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      if (this.disappearTimer) {
        clearInterval(this.disappearTimer);
        this.disappearTimer = null;
      }
      this.setState(
        {
          index: -1,
          showFireworks: false,
          showConfetti: false
        },
        () => {
          resolve();
        }
      );
    });
  }

  /**
   * Stop the fireworks animation
   */
  hideFireworks() {
    if (this.fireworksTimeout) {
      clearTimeout(this.fireworksTimeout);
      this.fireworksTimeout = null;
    }
    this.setState({ showFireworks: false });
  }

  /**
   * Stop the confetti animation
   */
  hideConfetti() {
    if (this.confettiTimeout) {
      clearTimeout(this.confettiTimeout);
      this.confettiTimeout = null;
    }
    this.setState({ showFireworks: false });
  }

  /**
   * This function is used to progress the innovator to the next message.
   */
  showNextTutorialMessage = () => {
    if (this.state.allTutorialMessages.length > 0) {
      let addOnCurrentIndex = 1;

      // Is this message for when a badge is received?

      let nextDialogPurpose = this.state.allTutorialMessages[
        this.state.index + addOnCurrentIndex
      ].tutorialPurposeForBadge;

      this.props.setNewBadge(nextDialogPurpose || null);

      if (
        nextDialogPurpose !== undefined &&
        this.shouldSkipBadgeMessage(nextDialogPurpose)
      ) {
        addOnCurrentIndex++;
      }

      // Should we highlight a pillar name? Otherwise, remove highlight.
      nextDialogPurpose = this.state.allTutorialMessages[
        this.state.index + addOnCurrentIndex
      ].purpose;
      this.changePillarHighlight(nextDialogPurpose);

      if (this.state.index < this.state.allTutorialMessages.length) {
        this.setState({ index: this.state.index + addOnCurrentIndex }, () => {
          if (!this.interval) {
            this.interval = setInterval(() => {
              if (this.state.allTutorialMessages.length > 0) {
                this.positionInnovator(
                  this.state.allTutorialMessages[this.state.index]
                );
              }
            }, 500);
          }
        });
      }
    }
  };

  //control animations, tutorial status for badges here
  shouldSkipBadgeMessage(nextDialogPurpose) {
    switch (nextDialogPurpose) {
      case Badges.Welcome:
        if (!hasBadge(this.context.badges, Badges.Welcome)) {
          // Ajax call to receive the welcome badge
          GetWelcomeBadge().then((myJson) => {
            if (myJson.success) {
              this.setState({ newBadge: myJson.data, showFireworks: true }, () => {
                this.setTutorialCompletedStatus(nextDialogPurpose);
                this.props.addBadgeToList(this.state.newBadge); // Update user object with the new badge
                this.fireworksTimeout = setTimeout(() => {
                  this.hideFireworks();
                }, 10000);
              });
            }
          });
          return false;
        }
        break;
      case Badges.Registration:
      case Badges.Buddy:
      case Badges.Survey:
      case Badges.Presenter:
      case Badges.Eureka:
      case Badges.LtcToolkit:
      case Badges.LtcTechnology:
      case Badges.EmergingTechnology:
      case Badges.NonTechnical:
      case Badges.BusinessAcumen:
      case Badges.NonLtc:
      case Badges.Shadow:
      case Badges.Host:
        // Another component already made Ajax call to receive the badge itself.
        this.setState({ showConfetti: true }, () => {
          this.setTutorialCompletedStatus(nextDialogPurpose);
        });
        return false;
      default:
        this.props.setNewBadge(null);
        break;
    }
    return true;
  }

  /**
   * Update status of tutorials completed. No changes to UI.
   * @param {*} completedTutorial
   */
  setTutorialCompletedStatus(completedTutorial) {
    const newStatus = getNewInnovatorStatus(
      this.context.user.tutorialStatus,
      completedTutorial
    );
    this.props.updateUserTutorialStatus(newStatus); // Update user object immediately in case of slow network. Preferably, wait for the fetch request to complete. However, this sometimes resulted in the innovator appearing far sooner than the badge shows up in the list
    updateInnovatorStatus(newStatus);
  }

  /**
   * Called regularly to keep the innovator in the proper position on the screen.
   * @param {*} elementToPositionNextToInfo object containing information about the element the innovator should go next to
   */
  positionInnovator = (elementToPositionNextToInfo) => {
    if (!document.getElementById(elementToPositionNextToInfo.ref)) {
      return;
    }

    const innovatorHolder = this.innovator.current;
    innovatorHolder.style.display = 'block';
    const elementBoundingRect = document
      .getElementById(elementToPositionNextToInfo.ref)
      .getBoundingClientRect();
    const elementBottom = elementBoundingRect.bottom + window.scrollY;
    const elementLeft = elementBoundingRect.left + window.scrollX;
    const innovatorImg = this.innovatorImg.current;
    let innovatorArrow = 0;
    if (this.innovatorArrow.current) {
      innovatorArrow = this.innovatorArrow.current;
    }

    const innovatorBoundingRect = innovatorHolder.getBoundingClientRect();
    const innovatorHolderWidth = innovatorBoundingRect.width;

    // The goal is to position innovatorHolder to the right reference
    let innovatorHolderX = elementLeft;
    let innovatorHolderY = elementBottom;
    if (elementToPositionNextToInfo.displayLayout.arrow) {
      if (elementToPositionNextToInfo.displayLayout.arrow === 'up') {
        if (elementToPositionNextToInfo.displayLayout.innovator === 'right')
          innovatorHolderX -= innovatorHolderWidth - innovatorImg.width * 0.61;
        else if (elementToPositionNextToInfo.displayLayout.innovator === 'left')
          innovatorHolderX -= innovatorImg.width * 0.48;
        innovatorHolderY += innovatorArrow.height;
      } else if (elementToPositionNextToInfo.displayLayout.arrow === 'down') {
        if (elementToPositionNextToInfo.displayLayout.innovator === 'right')
          innovatorHolderX -= innovatorHolderWidth - innovatorImg.width * 0.51;
        else if (elementToPositionNextToInfo.displayLayout.innovator === 'left')
          innovatorHolderX -= innovatorImg.width * 0.46;
        innovatorHolderY -= innovatorImg.height + innovatorArrow.height * 1.1;
      }
    } else {
      if (elementToPositionNextToInfo.displayLayout.innovator === 'right') {
        // don't need it now
      } else if (elementToPositionNextToInfo.displayLayout.innovator === 'left') {
        innovatorHolderX -= innovatorImg.width * 0.36;
      }
    }
    //Does it spill over the left edge of the page?
    if (innovatorHolderX < 0) {
      innovatorHolderX = 0;
    }

    innovatorHolder.style.top = innovatorHolderY + 'px';
    innovatorHolder.style.left = innovatorHolderX + 'px';
  };

  changePillarHighlight(nextDialogPurpose) {
    nextDialogPurpose === Pillars.LtcToolkit &&
      this.props.changeHasPillarNameHighlight(0);
    nextDialogPurpose === Pillars.LtcTechnology &&
      this.props.changeHasPillarNameHighlight(1);
    nextDialogPurpose === Pillars.EmergingTechnology &&
      this.props.changeHasPillarNameHighlight(2);
    nextDialogPurpose === Pillars.BusinessAcumen &&
      this.props.changeHasPillarNameHighlight(3);
    nextDialogPurpose === Pillars.NonTechnical &&
      this.props.changeHasPillarNameHighlight(4);
    nextDialogPurpose === Pillars.BusinessAcumen &&
      this.props.changeHasPillarNameHighlight(5);
    !nextDialogPurpose && this.props.changeHasPillarNameHighlight(null);
  }

  moveToLastDialog = () => {
    if (this.state.allTutorialMessages.length) {
      this.setState({ index: this.state.allTutorialMessages.length - 1 }, () => {
        if (this.props.changeHasPillarNameHighlight) {
          this.props.changeHasPillarNameHighlight(null);
        }
        this.positionInnovator(this.state.allTutorialMessages[this.state.index]);
        if (!this.interval) {
          this.interval = setInterval(() => {
            if (this.state.allTutorialMessages.length > 0) {
              this.positionInnovator(
                this.state.allTutorialMessages[this.state.index]
              );
            }
          }, 500);
        }
      });
    }
  };

  displayNextButtons() {
    let buttonsTag = [];
    if (
      this.state.allTutorialMessages.length > 0 &&
      this.state.index >= 0 &&
      this.state.allTutorialMessages[this.state.index].buttonName
    ) {
      for (
        let i = 0;
        i < this.state.allTutorialMessages[this.state.index].buttonName.length;
        i++
      ) {
        const element = this.state.allTutorialMessages[this.state.index].buttonName[
          i
        ];
        buttonsTag.push(
          <div
            key={i}
            onClick={
              element === 'NOT NOW'
                ? this.moveToLastDialog
                : this.showNextTutorialMessage
            }
            className={styles.nextDialogBtn}
            id="innovatorOkayButton"
          >
            {element}
          </div>
        );
      }
    }
    return buttonsTag;
  }

  getInnovatorHolderLayout(requestFor) {
    let cssClassNames = [];
    let arrowPoint = this.state.allTutorialMessages[this.state.index].displayLayout
      .arrow;
    let innovatorPosition = this.state.allTutorialMessages[this.state.index]
      .displayLayout.innovator;
    let textLength = this.state.allTutorialMessages[this.state.index].text.length;
    if (innovatorPosition === 'right') {
      cssClassNames.push(
        styles.innovatorFramedImgRight,
        styles.innovatorDialogConnectorLeft,
        styles.dialogEdgeLeft
      );
      arrowPoint === 'up' && cssClassNames.push(styles.arrowUpOnRightInnovator);
      arrowPoint === 'down' && cssClassNames.push(styles.arrowDownOnRightInnovator);
    } else if (innovatorPosition === 'left') {
      cssClassNames.push(
        styles.innovatorFramedImgLeft,
        styles.innovatorDialogConnectorRight,
        styles.dialogEdgeRight
      );
      arrowPoint === 'up' && cssClassNames.push(styles.arrowUpOnLeftInnovator);
      if (arrowPoint === 'down') {
        textLength <= 300 && cssClassNames.push(styles.arrowDownOnLeftInnovator);
        textLength > 300 && cssClassNames.push(styles.arrowDownOnLeftInnovator2);
      }
    }
    switch (requestFor) {
      case 'connector':
        return cssClassNames[1];
      case 'dialog':
        return cssClassNames[2];
      case 'arrow':
        return cssClassNames[3];
      case 'innovator':
      default:
        return cssClassNames[0];
    }
  }

  render() {
    return this.state.allTutorialMessages.length > 0 &&
      this.state.index !== null &&
      this.state.index > -1 ? (
      <div className={styles.innovatorHolder} id="innovator" ref={this.innovator}>
        {this.state.showFireworks && displayFireWorks()}
        {this.state.showConfetti && displayConfetti()}
        {this.state.allTutorialMessages[this.state.index].displayLayout.arrow ? (
          <img
            src={require('./../../assets/innovatorArrow.png')}
            alt="innovator Arrow"
            className={[
              styles.innovatorArrowImg,
              this.getInnovatorHolderLayout('arrow')
            ].join(' ')}
            ref={this.innovatorArrow}
          />
        ) : (
          <></>
        )}
        {this.state.allTutorialMessages[this.state.index].displayLayout.innovator ? (
          <>
            <img
              src={require('./../../assets/InnovatorFramed.png')}
              alt="innovator"
              className={[
                styles.innovatorFramedImg,
                this.getInnovatorHolderLayout('innovator')
              ].join(' ')}
              ref={this.innovatorImg}
            />
            <img
              src={require('./../../assets/innovatorDialogConnector.png')}
              alt="innovator Dialog Connector"
              className={[
                styles.innovatorDialogConnector,
                this.getInnovatorHolderLayout('connector')
              ].join(' ')}
              ref={this.innovatorDialogConnector}
            />
            <div
              className={[
                styles.dialogEdge,
                this.getInnovatorHolderLayout('dialog')
              ].join(' ')}
              ref={this.innovatorDialogBox}
            >
              <div className={styles.dialogContent}>
                {this.state.allTutorialMessages.length > 0 &&
                this.state.index >= 0 ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.allTutorialMessages[this.state.index].text
                    }}
                  />
                ) : (
                  ''
                )}
                {this.state.allTutorialMessages.length > 0 &&
                this.state.index >= 0 &&
                this.state.allTutorialMessages[this.state.index]
                  .isCloseButtonShow ? (
                  <FontAwesomeIcon
                    icon={faTimes}
                    size={'2x'}
                    id={styles.closeDialogXBtn}
                    name={'InnovatorCloseButton'}
                    onClick={
                      this.state.index === this.state.allTutorialMessages.length - 1
                        ? this.closeInnovator
                        : this.moveToLastDialog
                    }
                    role="button"
                  />
                ) : (
                  ''
                )}
              </div>
              <div className={styles.buttonAreaInDialog}>
                {this.displayNextButtons()}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    ) : null;
  }
}
Innovator.contextType = GlobalContext;

import React, { useState, useEffect, useRef } from 'react';
import style from './DashboardItem.module.css';
import { Spinner } from 'reactstrap';
import CollapseIcon from './collapseIcon/CollapseIcon';
import BlueSquaredScroll from '../../../../components/blueSquaredScroll/BlueSquaredScroll';
import { joinClasses } from '../../../../utils/styles';
import { CSSTransition } from 'react-transition-group';
import AccessibleButton from '../../../../components/accessibleButton/AccessibleButton';

const DashboardItem = ({
  loading,
  title,
  children,
  collapsible,
  collapsed,
  onToggleCollapse,
  animateExpand,
  focusOnExpand,
  highlighted,
  child2,
}) => {
  const contentRef = useRef(null);
  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    const focusOnContent = () => {
      if (!collapsed && contentRef && contentRef.current) {
        contentRef.current.focus();
      }
    };
    if (!collapsed && focusOnExpand) focusOnContent();
    setAnimateIn(true);
  }, [collapsed, focusOnExpand]); // Can't watch for prop it seems, CSSTransition needs to look for a change.
  // Why not just simple classes? Can't watch animateExpand properly for first render with that.

  const content = () => (
    <>
      {!collapsed && (
        <div
          className={joinClasses(
            style.dashboardItem,
            highlighted && style.highlighted,
            collapsible && style.collapsible
          )}
          tabIndex="-1"
          ref={contentRef}
        >
          <div className={style.container}>
            {collapsible ? (
              <AccessibleButton
                className={style.expandCollapseButton}
                onClick={() => {
                  if (collapsible) onToggleCollapse(true);
                }}
              >
                <div className={style.titleHolder}>
                  <div className={style.innerTitleHolder}>{title}</div>
                  <div aria-hidden="true" className={style.collapseButtonHolder}>
                    {collapsible && (
                      <CollapseIcon
                        onClick={() => {
                          onToggleCollapse(true);
                        }}
                        collapse
                      ></CollapseIcon>
                    )}
                  </div>
                </div>
              </AccessibleButton>
                      ) : (<>
                              {child2 }
              <div className={style.titleHolder}>
                <div className={style.innerTitleHolder}>{title}</div>
              </div>
              </>
            )}
            {loading ? (
              <div className={style.spinnerContainer}>
                <Spinner color="primary" className="dashboard-session-spinner" />
              </div>
            ) : (
              <BlueSquaredScroll className={style.children}>
                {children}
              </BlueSquaredScroll>
            )}
          </div>
        </div>
      )}
      {collapsed && (
        <>
          {collapsible ? (
            <div
              className={[
                style.dashboardItemCollapsed,
                collapsible ? style.collapsible : ''
              ].join(' ')}
            >
              <AccessibleButton
                className={style.expandCollapseButton}
                onClick={() => {
                  if (collapsible) onToggleCollapse(false);
                }}
              >
                <div className={style.headingItemsContainer}>
                  <div className={style.innerTitleHolder}>{title}</div>
                  <div aria-hidden="true" className={style.collapseButtonHolder}>
                    {collapsible && <CollapseIcon></CollapseIcon>}
                  </div>
                </div>
              </AccessibleButton>
            </div>
          ) : (
            <div className={style.headingItemsContainer}>
              <div className={style.innerTitleHolder}>{title}</div>
            </div>
          )}
        </>
      )}
    </>
  );

  return animateExpand ? (
    <CSSTransition
      in={animateIn}
      classNames={{
        enter: collapsed ? style.collapse : style.expand,
        enterActive: collapsed ? style.collapseActive : style.expandActive
      }}
      timeout={500}
    >
      {content()}
    </CSSTransition>
  ) : (
    <>{content()}</>
  );
};

DashboardItem.defaultProps = {
  title: <h2>Untitled</h2>,
  loading: false,
  collapsed: false,
  onToggleCollapse: () => {},
  animateExpand: false,
    focusOnExpand: false,
  child2:''
};

export default DashboardItem;

import { stringToDate } from '../CONSTANTS';

export const getFormattedDate = (date) => {
  const dateObj = typeof date === 'string' ? stringToDate(date) : date;
  return dateObj.toLocaleString([], {
    day: 'numeric',
    year: 'numeric',
    month: 'long'
  });
};

export const getFormattedTime = (date) => {
  const dateObj = typeof date === 'string' ? stringToDate(date) : date;
  return dateObj.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit'
  });
};

export const getFormattedRange = (startDate, duration) => {
  const courseStart =
    typeof startDate === 'string' ? stringToDate(startDate) : startDate;
  const courseEnd = new Date(courseStart.getTime() + duration * 60000);
  return `${getFormattedTime(startDate)} - ${getFormattedTime(courseEnd)}`;
};

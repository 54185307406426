import React, { useState, useEffect } from 'react';
import {
  Input,
  Label,
  Form,
  FormGroup,
  CustomInput,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button
} from 'reactstrap';
import { adalApiFetch } from '../../../../adalConfig';
import ReactGA from 'react-ga';
import * as toastRequest from '../../utils/toastRequests';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { stringToDate } from '../../../../CONSTANTS';

const EditRecurringSession = ({ parentCourse, manualReload, open, openChange }) => {
  const [mandatory, mandatoryChange] = useState(false);
  const [managerOnly, managerOnlyChange] = useState(false);
  const [domain, domainChange] = useState(null);
  const [sessionType, sessionTypeChange] = useState(null);
  const [submitting, submittingChange] = useState(null);
  const [domains, domainsChange] = useState([]);
  const [sessionTypes, sessionTypesChange] = useState([]);
  const [isPast, isPastChange] = useState(null);

  const openToggle = () => {
    openChange(!open);
  };
  useEffect(() => {
    const abortController = new AbortController();
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Domains', {
      method: 'get',
      signal: abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when attempting to load the list of Domains', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          domainsChange(myJson.data);
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });

    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/SessionTypes',
      { method: 'get', signal: abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast(
            'An error occurred when attempting to load the list of session types',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          sessionTypesChange(myJson.data);
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
    return () => {
      abortController.abort();
    };
  }, []);
  useEffect(() => {
    managerOnlyChange(parentCourse && parentCourse.managerOnly);
    mandatoryChange(parentCourse && parentCourse.mandatory);
    domainChange(parentCourse && parentCourse.domain);
    sessionTypeChange(parentCourse && parentCourse.sessionType);
    // Checks if there are any recurring sessions that are in the past. If so, it returns true and makes fields disabled
    isPastChange(
      parentCourse &&
        !!parentCourse.courseDetails.find(
          (cd) => stringToDate(cd.courseStart).getTime() < new Date().getTime()
        )
    );
  }, [parentCourse]);

  const abortController = new AbortController();
  const update = () => {
    if (submitting) return;

    const dataCourse = {
      ...parentCourse,
      mandatory: mandatory,
      managerOnly: managerOnly,
      domain: domain,
      domainId: domain.domainId,
      sessionType: sessionType,
      sessionTypeId: sessionType.sessionTypeId
    };

    toastRequest.notify(`Updating session`);
    submittingChange(true);
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      signal: abortController.signal,
      body: JSON.stringify(dataCourse)
    })
      .then((response) => {
        submittingChange(false);
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success) {
          toastRequest.updateToast(
            myJson.success,
            myJson.error,
            `Session Updated successfully!`
          );
          manualReload();
          openChange(false);
        } else {
          toastRequest.updateToast(false, 'An error occurred.');
        }
      })
      .catch((e) => {
        submittingChange(false);
        toastRequest.updateToast(false, 'An error occurred.');
        ReactGA.exception({
          description: e.toString()
        });
      });

    return () => {
      abortController.abort();
    };
  };

  const SessionOnModify = (e) => {
    sessionTypeChange(
      sessionTypes.find(
        (sessionType) => sessionType.sessionTypeId === parseInt(e.target.value)
      )
    );
  };
  const DomainOnModify = (e) => {
    domainChange(
      domains.find((domain) => domain.domainId === parseInt(e.target.value))
    );
  };

  return (
    <Modal size={'lg'} isOpen={open} toggle={openToggle}>
      {open && parentCourse && (
        <>
          <ModalHeader toggle={openToggle}>
            Update Recurring Session {parentCourse.courseId}
          </ModalHeader>
          <ModalBody>
            <Form>
              {/*DOMAIN*/}
              <FormGroup>
                <Label for="domainSelect">Pillar</Label>
                <Input
                  value={domain ? domain.domainId : domains[0].domainId}
                  type="select"
                  name="recurringDomainId"
                  id="domainSelect"
                  placeholder={'select domain'}
                  onChange={DomainOnModify}
                  disabled={!!isPast}
                >
                  <option disabled value={'default'} key={'default-key'}>
                    select a domain
                  </option>
                  {domains.map((domain) => (
                    <option
                      value={domain.domainId}
                      id={'domain-' + domain.domainId}
                      key={'domain-' + domain.domainId}
                    >
                      {domain.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              {/*SESSION CATEGORY*/}
              <FormGroup>
                <Label for="sessionTypeId">Category</Label>
                <Input
                  value={
                    sessionType
                      ? sessionType.sessionTypeId
                      : sessionTypes[0].sessionTypeId
                  }
                  type="select"
                  name="recurringSessionTypeId"
                  id="sessionTypeId"
                  onChange={SessionOnModify}
                  disabled={!!isPast}
                >
                  <option disabled value={'default'} key={'default-key'}>
                    select a category
                  </option>
                  {sessionTypes.map((session) => (
                    <option
                      value={session.sessionTypeId}
                      id={'session-' + session.sessionTypeId}
                      key={'session-' + session.sessionTypeId}
                    >
                      {session.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              {/*MANDATORY*/}
              <FormGroup check>
                <CustomInput
                  type="switch"
                  id={'recurringMandatory' + parentCourse.courseId}
                  value={mandatory}
                  checked={mandatory}
                  label="Mandatory"
                  onChange={() => {
                    mandatoryChange(!mandatory);
                  }}
                />
              </FormGroup>
              {/*MANAGER ONLY*/}
              <FormGroup check>
                <CustomInput
                  type="switch"
                  id={'recurringManagerOnly' + parentCourse.courseId}
                  value={managerOnly}
                  checked={managerOnly}
                  label="Manager Only"
                  onChange={() => {
                    managerOnlyChange(!managerOnly);
                  }}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={update}>
              Modify
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                openChange(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

EditRecurringSession.propTypes = {
  parentCourse: PropTypes.object,
  manualReload: PropTypes.func,
  open: PropTypes.bool,
  toggleOpe: PropTypes.func
};

export default EditRecurringSession;

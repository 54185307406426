import React from 'react';
import ReactGA from 'react-ga';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import styles from './Points.module.css';
import Select from 'react-select';
import {
  forceCache,
  stringToDate,
  TECHNICAL_ERROR_MESSAGE
} from '../../../../CONSTANTS';
import { Badges } from '../../../../enums/badges.enum';
import PointsTable from './PointsTable';
import Report from '../reports/Report';
import { validate, makeFilterType } from '../../utils/dateFilter';
import AdminHomeTabs from '../AdminHomeTabs';
import { AdminTabs } from '../../../../enums/admintabs.enum';
import sharedStyle from '../AdminAllTabs.module.css';

export default class PointsAddModify extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTab: '0',
      allUsers: [],
      allAchievements: [],
      domains: [],
      domainId: 'default',
      showDefault: true, // Report type
      fromDate: this.defaultDateDisplayStart(),
      toDate: this.defaultDateDisplay(),
      filters: [
        {
          label: 'Fiscal',
          value: 'Fiscal Year and Quarter'
        },
        {
          label: 'Date',
          value: 'Date Range'
        }
      ],
      years: [],
      quarters: [
        {
          value: 'Q1',
          label: 'Q1'
        },
        {
          value: 'Q2',
          label: 'Q2'
        },
        {
          value: 'Q3',
          label: 'Q3'
        },
        {
          value: 'Q4',
          label: 'Q4'
        }
      ],
      selectedFilter: '',
      selectedYear: '',
      fromQuarter: 'Q1',
      toQuarter: 'Q4',
      description: '',
      selectedUsers: {},
      selectedUsersHistory: {},
      achievementId: 'default',
      submitting: false,
      username: '',
      provider: '',
      courseName: '',
      users: [],
      sort: 'awarded-ascending',
      userDetails: null
    };
    this.loadContent = this.loadContent.bind(this);
    this.sortTable = this.sortTable.bind(this);
    this.getContext = this.getContext.bind(this);
  }

  abortController = new AbortController();
  componentDidMount() {
    this.loadOptions();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  loadOptions = () => {
    // Load active achievement options
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Achievements/visible',
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when loading achievements', {
            type: toast.TYPE.ERROR,
            autoClose: false
          });
        } else {
          this.setState({ allAchievements: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
    // Load domains
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Domains', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when attempting to load the list of Domains', {
            type: toast.TYPE.ERROR,
            autoClose: false
          });
        } else {
          this.setState({ domains: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
    //  Load users
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/dropdown',
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when loading User list', {
            type: toast.TYPE.ERROR,
            autoClose: false
          });
        } else {
          let usersList = [];
          for (let i = 0; i < myJson.data.length; i++) {
            usersList.push({
              value: myJson.data[i].userId,
              label: myJson.data[i].email
            });
          }
          this.setState({ allUsers: usersList });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
    // Load fiscal years
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + `/api/Report/fiscalyears`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        },
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `Fiscal years loaded successfully!`
        );

        let years = [];
        for (let i = 0; i < myJson.data.length; i++) {
          years.push({
            value: myJson.data[i].fiscalYear,
            label: myJson.data[i].fiscalYear
          });
        }
        this.setState({ years: years, selectedYear: years[0].value });
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toastRequest.updateToast(
          false,
          `Could not load fiscal years. \n Error: ${e}`
        );
        toastRequest.dismissToast();
      });
    // Load fiscal years
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + `/api/Report/fiscalyears`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        },
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `Fiscal years loaded successfully!`
        );

        let years = [];
        for (let i = 0; i < myJson.data.length; i++) {
          years.push({
            value: myJson.data[i].fiscalYear,
            label: myJson.data[i].fiscalYear
          });
        }
        this.setState({ years: years, selectedYear: years[0].value });
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toastRequest.updateToast(
          false,
          `Could not load fiscal years. \n Error: ${e}`
        );
        toastRequest.dismissToast();
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  parseForm = () => {
    if (!this.checkFields()) {
      return null;
    }

    let data = {};
    if (this.state.achievementId === 'default' || !this.state.selectedUsers.value) {
      return null;
    } else {
      data = {
        achievementId: this.state.allAchievements[this.state.achievementId]
          .achievementId,
        userId: this.state.selectedUsers.value,
        description: this.state.description,
        provider: this.state.provider,
        courseName: this.state.courseName
      };
      if (this.state.domainId !== 'default') {
        data.domainId = this.state.domainId;
      }
    }
    return data;
  };

  checkFields = (disp = true) => {
    let missing = '';

    if (Object.entries(this.state.selectedUsers).length === 0)
      missing += ', Recipient Email';

    if (!this.checkStateDisabled()) {
      if (this.state.achievementId === 'default') missing += ', Achievement';

      if (this.state.domainId === 'default') missing += ', Pillar';

      if (this.state.provider === '') missing += ', Provider';

      if (this.state.courseName === '') missing += ', Course Name';
    }

    if (missing !== '') {
      if (disp) {
        toast(`Please provide "${missing.substring(2)}".`, {
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      }
      return false;
    }
    return true;
  };

  add = () => {
    if (this.state.submitting) return null;

    const data = this.parseForm();
    if (data === null) return null;

    let badger = false;
    // Check if it is a badge
    if (
      this.state.allAchievements[this.state.achievementId].achievementType.name ===
      'Badge'
    )
      badger = true;

    let toastId = toastRequest.notify(`Adding ${badger ? 'Badge' : 'Points'}`);
    this.setState({ submitting: true });
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/UserAchievements',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (response.ok) forceCache();
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success && myJson.error) {
          toast.update(toastId, {
            render: `${myJson.error} `,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        } else if (!badger) {
          toast.update(toastId, {
            render: `${
              this.state.allAchievements[this.state.achievementId].pointValue
            } L+D Session Points on
                ${
                  this.state.domains.find(
                    (obj) => parseInt(obj.domainId) === parseInt(this.state.domainId)
                  ).name
                }
                has been granted to
                ${this.state.selectedUsers.value}
                successfully.`,
            type: toast.TYPE.SUCCESS,
            autoClose: 5000
          });
        } else {
          toast.update(toastId, {
            render: `${
              this.state.allAchievements[this.state.achievementId].name
            } granted successfully to ${this.state.selectedUsers.value}`,
            type: toast.TYPE.SUCCESS,
            autoClose: 5000
          });
        }
        if (myJson.success === true) {
          this.setState(this.state);
        }
      })
      .catch((e) => {
        toastRequest.updateToast(toastId, TECHNICAL_ERROR_MESSAGE);
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  async getUserData(userId) {
    try {
      let response = await adalApiFetch(
        fetch,
        process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/getuser?userId=' + userId,
        { method: 'get', signal: this.abortController.signal }
      );
      let myJson = await response.json();
      if (!myJson.success) {
        toast('An error occurred when loading User data', {
          type: toast.TYPE.ERROR,
          autoClose: false
        });
      } else {
        return myJson.data;
      }
    } catch (e) {
      ReactGA.exception({
        description: e.toString()
      });
    }
  }

  onModify = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (selectedUsers) => {
    this.setState({ selectedUsers });
    this.getUserData(selectedUsers.value).then((data) => {
      this.setState({ userDetails: data });
    });
  };

  handleHistoryChange = (selectedUsersHistory) => {
    if (selectedUsersHistory == null) {
      this.setState({ selectedUsersHistory: {} });
    } else {
      this.setState({ selectedUsersHistory });
    }
  };

  getContext(a, b, context) {
    let object1 = a;
    let object2 = b;
    switch (context) {
      case 'email':
        return {
          firstObject: object1.user.email.toLowerCase(),
          secondObject: object2.user.email.toLowerCase()
        };
      case 'awarded':
        return {
          firstObject: object1.dateAwarded,
          secondObject: object2.dateAwarded
        };
      case 'type':
        return {
          firstObject: a.achievement.achievementType.name.toLowerCase(),
          secondObject: b.achievement.achievementType.name.toLowerCase()
        };
      case 'pillar':
        if (a.achievement.achievementType.name !== 'Brick') {
          object1.achievement.name = 'none';
        }
        if (b.achievement.achievementType.name !== 'Brick') {
          object2.achievement.name = 'none';
        }
        return {
          firstObject: object1.achievement.name.toLowerCase(),
          secondObject: object2.achievement.name.toLowerCase()
        };
      case 'provider':
        return {
          firstObject: a.addedBy.toLowerCase(),
          secondObject: b.addedBy.toLowerCase()
        };
      case 'session':
        return {
          firstObject: a.courseName.toLowerCase(),
          secondObject: b.courseName.toLowerCase()
        };
      case 'points':
        return {
          firstObject: a.achievement.pointValue,
          secondObject: b.achievement.pointValue
        };
      case 'badges':
        if (a.achievement.achievementType.name !== 'Badge') {
          object1.achievement.name = 'none';
        }
        if (b.achievement.achievementType.name !== 'Badge') {
          object2.achievement.name = 'none';
        }
        return {
          firstObject: object1.achievement.name.toLowerCase(),
          secondObject: object2.achievement.name.toLowerCase()
        };
      default:
        return { firstObject: a.dateAwarded, secondObject: b.dateAwarded };
    }
  }

  sortTable(context) {
    let users = this.state.users;
    let sortType = this.state.sort;
    if (sortType.includes('descending')) {
      users.sort((a, b) => {
        var sortContext = this.getContext(a, b, context);
        let object1 = sortContext.firstObject;
        let object2 = sortContext.secondObject;
        if (object1 > object2) {
          return -1;
        } else if (object1 < object2) {
          return 1;
        } else return 0;
      });
      this.setState({
        sort: context + '-ascending',
        users: users
      });
    } else {
      users.sort((a, b) => {
        var sortContext = this.getContext(a, b, context);
        let object1 = sortContext.firstObject;
        let object2 = sortContext.secondObject;
        if (object1 < object2) {
          return -1;
        } else if (object1 > object2) {
          return 1;
        } else return 0;
      });
      this.setState({
        sort: context + '-descending',
        users: users
      });
    }
  }

  defaultDateDisplay = () => {
    var newDate = new Date().toISOString().substr(0, 10);
    return newDate;
  };

  defaultDateDisplayStart = () => {
    var startDate = new Date();
    var date = new Date().setDate(startDate.getDate() - 30);
    var date1 = new Date(date).toISOString().substr(0, 10);
    return date1;
  };

  handleFilterChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value === 'Fiscal Year and Quarter') {
      this.setState({ showDefault: true });
    } else if (e.target.value === 'Date Range') {
      this.setState({ showDefault: false });
    }
  };
  //For the achivements that does not requier Pillar, Provider and CourseName
  checkStateDisabled = () => {
    if (this.state.allAchievements[this.state.achievementId]) {
      return (
        this.state.allAchievements[this.state.achievementId].slug ===
          Badges.Eureka ||
        this.state.allAchievements[this.state.achievementId].slug ===
          Badges.Shadow ||
        this.state.allAchievements[this.state.achievementId].slug === Badges.Host
      );
    } else {
      return false;
    }
  };
  async loadContent() {
    if (!validate(this.state)) return null;
    const toastId = toast(
      <div>
        <Spinner className="toast-spinner" /> Loading History...
      </div>,
      {
        type: toast.TYPE.INFO,
        autoClose: false
      }
    );
    try {
      var response;
      switch (this.state.showDefault) {
        case true:
          response = await adalApiFetch(
            fetch,
            process.env.REACT_APP_API_BASE_DOMAIN +
              `/api/UserAchievements/fiscalyear/getachievementshistory?` +
              (this.state.selectedUsersHistory.value
                ? `userId=${this.state.selectedUsersHistory.value}&`
                : '') +
              `year=${this.state.selectedYear}&` +
              `qFrom=${this.state.fromQuarter}&` +
              `qTo=${this.state.toQuarter}`,
            { method: 'get', headers: { 'Content-Type': 'applcation/json' } }
          );
          break;
        case false:
          response = await adalApiFetch(
            fetch,
            process.env.REACT_APP_API_BASE_DOMAIN +
              `/api/UserAchievements/daterange/getachievementshistory?` +
              (this.state.selectedUsersHistory.value
                ? `userId=${this.state.selectedUsersHistory.value}&`
                : '') +
              `fromDate=${this.state.fromDate}&` +
              `toDate=${this.state.toDate}`,
            { method: 'get', headers: { 'Content-Type': 'applcation/json' } }
          );
          break;
        default:
          response = await adalApiFetch(
            fetch,
            process.env.REACT_APP_API_BASE_DOMAIN +
              `/api/UserAchievements/fiscalyear/getachievementshistory?` +
              (this.state.selectedUsersHistory.value
                ? `userId=${this.state.selectedUsersHistory.value}&`
                : '') +
              `year=${this.state.selectedYear}&` +
              `qFrom=${this.state.fromQuarter}&` +
              `qTo=${this.state.toQuarter}`,
            { method: 'get', headers: { 'Content-Type': 'applcation/json' } }
          );
      }
      let myJson = await response.json();

      // Convert to date objects
      for (let i = 0; i < myJson.data.length; i++) {
        myJson.data[i].dateAwarded = stringToDate(myJson.data[i].dateAwarded);
      }

      myJson.data.sort((a, b) => {
        if (a.dateAwarded > b.dateAwarded) {
          return -1;
        }
        if (a.dateAwarded < b.dateAwarded) {
          return 1;
        }
        return 0;
      });

      this.setState({ users: myJson.data });
      toast.update(toastId, {
        render: 'History loaded',
        type: toast.TYPE.SUCCESS,
        autoClose: true
      });
    } catch (e) {
      toast.dismiss(toastId);
    }
  }

  render() {
    const achievement = this.state.allAchievements[this.state.achievementId];
    return (
      <div className={sharedStyle.adminRoot}>
        <AdminHomeTabs
          activetab={AdminTabs.Achievements}
          switchtab={this.props.history.push}
        />
        <Container>
          <Row>
            <Col>
              <Nav tabs className={sharedStyle.tabMargin}>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '0' })}
                    id="addTab"
                    onClick={() => {
                      this.toggle('0');
                    }}
                  >
                    ADD
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                    id="historyTab"
                  >
                    HISTORY
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                {/*MAIN COURSE TAB*/}
                <TabPane tabId="0">
                  {this.state.activeTab === '0' && (
                    <Row>
                      <Col sm="12">
                        <div>
                          <Form onSubmit={(event) => event.preventDefault()}>
                            {/*USERNAME*/}
                            <FormGroup>
                              <Label for="recipientEmail">Recipient Email</Label>
                              <Select
                                value={this.state.selectedUsers}
                                onChange={this.handleChange}
                                id="recipientEmail"
                                options={this.state.allUsers}
                                isLoading={this.state.allUsers.length === 0}
                              />
                            </FormGroup>
                            {/*ACHIEVEMENT TYPE*/}
                            <FormGroup>
                              <Label for="achievementSelect">Achievement Type</Label>
                              <Input
                                value={this.state.achievementId}
                                type="select"
                                name="achievementId"
                                id="achievementSelect"
                                placeholder={'Select a type'}
                                onChange={this.onModify}
                              >
                                <option
                                  disabled
                                  value={'default'}
                                  key={'default-key'}
                                >
                                  select type
                                </option>
                                {this.state.allAchievements.map(
                                  (achievement, index) => (
                                    <option
                                      value={index}
                                      key={'option-' + achievement.achievementId}
                                    >
                                      {achievement.name}
                                    </option>
                                  )
                                )}
                              </Input>
                              <p className={sharedStyle.subText}>
                                {this.state.allAchievements.length > 0 &&
                                  this.state.achievementId !== 'default' &&
                                  this.state.allAchievements[
                                    this.state.achievementId
                                  ].description}
                              </p>
                            </FormGroup>
                            {/*DOMAIN*/}
                            <FormGroup>
                              <Label for="domainSelect">Pillar</Label>
                              <Input
                                value={this.state.domainId}
                                type="select"
                                name="domainId"
                                id="domainSelect"
                                placeholder={'Select a pillar'}
                                onChange={this.onModify}
                                disabled={this.checkStateDisabled()}
                              >
                                <option
                                  disabled
                                  value={'default'}
                                  key={'default-key'}
                                >
                                  select a domain
                                </option>
                                {this.state.domains.map((domain) => (
                                  <option
                                    value={domain.domainId}
                                    key={'option-' + domain.domainId}
                                  >
                                    {domain.name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                            {/*PROVIDER*/}
                            <FormGroup>
                              <Label for="provider">Provider</Label>
                              <Input
                                disabled={this.checkStateDisabled()}
                                type="text"
                                value={this.state.provider}
                                name="provider"
                                id="provider"
                                onChange={this.onModify}
                              />
                            </FormGroup>
                            {/*COURSE-NAME*/}
                            <FormGroup>
                              <Label for="courseName">Course Name</Label>
                              <Input
                                type="text"
                                value={this.state.courseName}
                                name="courseName"
                                id="courseName"
                                onChange={this.onModify}
                                disabled={this.checkStateDisabled()}
                              />
                            </FormGroup>
                            {/*DISPLAY POINTS*/}
                            <p className={styles.point}>
                              {achievement &&
                              achievement.achievementType.name !== 'Badge'
                                ? `Points: ${achievement.pointValue} pts`
                                : 'Point: N/A pts'}
                            </p>
                            {/*DISPLAY BADGES*/}
                            <p className={styles.point}>
                              {achievement &&
                              achievement.achievementType.name === 'Badge'
                                ? `Badge: ${achievement.name}`
                                : //TODO display image
                                  'Badge: N/A'}
                            </p>
                            {/*DESCRIPTION*/}
                            <FormGroup>
                              <Label for="description">
                                Details/Comments (OPTIONAL)
                              </Label>
                              <Input
                                type="text"
                                value={this.state.description}
                                name="description"
                                id="description"
                                onChange={this.onModify}
                              />
                            </FormGroup>
                          </Form>

                          <Button
                            color="primary"
                            size="lg"
                            className={sharedStyle.submitBtn}
                            onClick={this.add}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
                </TabPane>
                {/*HISTORY TAB*/}
                <TabPane tabId="1">
                  {this.state.activeTab === '1' && (
                    <Row>
                      <Col sm="12">
                        <div>
                          <Form>
                            <FormGroup>
                              <Row>
                                <Col>
                                  <Label for="filterBy">Filtered By</Label>
                                  <Input
                                    type="select"
                                    name="selectedFilter"
                                    value={this.state.selectedFilter}
                                    id="filterBy"
                                    options={this.state.filters}
                                    onChange={this.handleFilterChange}
                                  >
                                    <option
                                      disabled
                                      value={'default'}
                                      key={'default-key'}
                                    >
                                      Select a filter
                                    </option>
                                    {this.state.filters.map((filter) => (
                                      <option
                                        value={filter.value}
                                        key={'option-' + filter.label}
                                      >
                                        {filter.value}
                                      </option>
                                    ))}
                                  </Input>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row
                                style={{
                                  display: this.state.showDefault ? 'flex' : 'none'
                                }}
                              >
                                <Col>
                                  <Label for="fiscalYear">Fiscal Year</Label>
                                  <Input
                                    type="select"
                                    name="selectedYear"
                                    value={this.state.selectedYear}
                                    id="fiscalYear"
                                    options={this.state.years}
                                    onChange={this.handleFilterChange}
                                  >
                                    <option
                                      disabled
                                      value={'default'}
                                      key={'default-key'}
                                    >
                                      Select a year
                                    </option>
                                    {this.state.years.map((year) => (
                                      <option
                                        value={year.value}
                                        key={'option-' + year.label}
                                      >
                                        {year.value}
                                      </option>
                                    ))}
                                  </Input>
                                </Col>
                                <Col>
                                  <Label for="fiscalFrom">From Quarter</Label>
                                  <Input
                                    type="select"
                                    name="fromQuarter"
                                    value={this.state.fromQuarter}
                                    id="fiscalFrom"
                                    options={this.state.quarters}
                                    onChange={this.handleFilterChange}
                                  >
                                    <option
                                      disabled
                                      value={'default'}
                                      key={'default-key'}
                                    >
                                      Select a quarter
                                    </option>
                                    {this.state.quarters.map((q) => (
                                      <option
                                        value={q.value}
                                        key={'option-' + q.label}
                                      >
                                        {q.value}
                                      </option>
                                    ))}
                                  </Input>
                                </Col>
                                <Col>
                                  <Label for="fiscalTo">To Quarter</Label>
                                  <Input
                                    type="select"
                                    name="toQuarter"
                                    value={this.state.toQuarter}
                                    id="fiscalTo"
                                    options={this.state.quarters}
                                    onChange={this.handleFilterChange}
                                  >
                                    <option
                                      disabled
                                      value={'default'}
                                      key={'default-key'}
                                    >
                                      Select a quarter
                                    </option>
                                    {this.state.quarters.map((q) => (
                                      <option
                                        value={q.value}
                                        key={'option-' + q.label}
                                      >
                                        {q.value}
                                      </option>
                                    ))}
                                  </Input>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  display: this.state.showDefault ? 'none' : 'flex'
                                }}
                              >
                                <Col>
                                  <Label for="fromDate">From</Label>
                                  <Input
                                    type="date"
                                    name="fromDate"
                                    value={this.state.fromDate}
                                    id="fromDate"
                                    onChange={this.handleFilterChange}
                                  ></Input>
                                </Col>
                                <Col>
                                  <Label for="toDate">To</Label>
                                  <Input
                                    type="date"
                                    name="toDate"
                                    value={this.state.toDate}
                                    id="toDate"
                                    onChange={this.handleFilterChange}
                                  ></Input>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row
                                style={{
                                  display: 'flex'
                                }}
                              >
                                <Col sm="10">
                                  <Label for="staffEmail">Staff email address</Label>
                                  <Select
                                    isClearable={true}
                                    value={this.state.selectedUsersHistory}
                                    onChange={this.handleHistoryChange}
                                    id="staffEmail"
                                    options={this.state.allUsers}
                                    isLoading={this.state.allUsers.length === 0}
                                  />
                                </Col>
                                <Col sm="1">
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className={styles.courseInlineButton}
                                    onClick={this.loadContent}
                                  >
                                    Submit
                                  </Button>
                                </Col>
                                <Col sm="1">
                                  <Report
                                    name={'Achievement History'}
                                    outsideUse={true}
                                    endpoint={'achievements'}
                                    csvTitle={'AchievementHistory'}
                                    validate={() => validate(this.state)}
                                    filter={makeFilterType({
                                      ...this.state,
                                      userId: this.state.selectedUsersHistory.value
                                    })}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Form>
                          <div className={styles.tableOverflow}>
                            <PointsTable
                              sortTable={this.sortTable}
                              sort={this.state.sort}
                              users={this.state.users}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styles from './DashboardDetails.module.css';
import { Col, Row } from 'reactstrap';
import SessionsList from './components/SessionsList';
import StatDetails from './components/StatDetails';
import RecommendedSessionsDetails from './components/RecommendedSessionsDetails';
import UpcomingRegisteredSessionsDetails from './components/UpcomingRegisteredSessionsDetails';
import { stringToDate } from '../../CONSTANTS';
import RecommendContainer from '../sessions/components/recommendContainer/RecommendContainer';
import GlobalContext from '../../context/GlobalContext';
import ManagerSessionsList from './components/ManagerSessionsList';

export default class DashboardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSession: null,
      recommendSession: null,
      scrollToSession: false
    };

    this.statDetailsRef = React.createRef();
  }
    handleDateFilterChange = (val) => {
        this.props.hideDateFilter(val);
    };
  componentDidMount() {
    // select first session in the list by default
    if (this.props.sessions.length > 0)
          this.setState({ selectedSession: this.props.sessions[0] });

      this.handleDateFilterChange(this.props.pageId === 'mandatoryAttendance-details' ? true : false);
    }
  //reset flag to show data filter
    componentWillUnmount() {
        this.handleDateFilterChange(false);
    }
  componentDidUpdate(prevProps, prevState) {
    // if data has been refreshed, reset the selected session
    if (prevProps.loading && !this.props.loading) {
      if (this.props.sessions.length === 0) this.setState({ selectedSession: null });
      else
        this.setState({
          selectedSession: this.props.sessions[this.props.defaultSelectedIndex],
          scrollToSession: this.props.defaultSelectedIndex !== 0
        });
    }

    // content is about to be reloaded: make sure to close the recommend container
    else if (!prevProps.loading && this.props.loading)
      this.setState({ recommendSession: null });

    // on clicking recommend buton: get staff who registered for the recommended session
    if (!prevState.recommendSession && this.state.recommendSession) {
      let recommendSession = this.state.recommendSession;
      recommendSession.courseDetails.participants = recommendSession.staffRegistered;
      this.setState({ recommendSession: recommendSession }, () => {
        this.statDetailsRef.current && this.statDetailsRef.current.focus();
      });
    }
  }

  // scroll to selected session (if not in view)
  setScroll = (value) => {
    this.setState({ scrollToSession: value });
  };

  isUpcoming = () => {
    if (this.state.selectedSession)
      return (
        stringToDate(this.state.selectedSession.courseDetails.courseStart) >
        Date.now()
      );
  };

  // used to return staff in the second column of details panel
  getNotRegOrAttendedStaffList = () => {
    if (!this.state.selectedSession) return [];

    return this.props.getNotRegisteredOrAttendedStaffList(
      this.state.selectedSession,
      this.isUpcoming() ? 'staffRegistered' : 'staffAttended'
    );
  };

  // show the Recommend Container when Recommend button is pressed
  openRecommendStaffList = (courseDetailsId) => {
    this.props.sessions.forEach((session) => {
      if (session.courseDetails.courseDetailsId === courseDetailsId) {
        this.setState({
          selectedSession: session,
          recommendSession: session
        });
      }
    });
    if (!this.context.employees) this.context.reloadEmployees();
  };

  // close Recommended Container after pressing X
  closeRecommendSession = () => {
    this.setState({ recommendSession: null });
  };

  // close Recommend Container after sending emails
  closeAndReloadAfterRecommendation = () => {
    this.props.loadContent(this.state.selectedSession.courseDetails.courseDetailsId);
    this.setState({ selectedSession: null, recommendSession: null });
  };

  /**
   * Gets staffUserId's response to a recommended session
   */
  getNotRegisteredStatus = (staffUserId, recResponses) => {
    for (let i = 0; i < recResponses.length; i++) {
      const response = recResponses[i];
      if (response.userId === staffUserId) return response.status;
    }
    return '';
  };

  /**
   * Returns true if staffUserId is present in staffList
   */
  staffIsPresent = (staffUserId, staffList) => {
    for (let i = 0; i < staffList.length; i++) {
      if (staffList[i].userId === staffUserId) return true;
    }
    return false;
  };

  /**
   * Gets the list of staff who have been recommended but have not registered for the selected session
   * * notRegStaffList: list of staff who have not registered for the selected session
   */
  getRecButNotRegStaffList = (notRegStaffList) => {
    const session = this.state.selectedSession;

    if (!session) return [];

    const recButNotReg = [];

    // get Recommendation data for the selected session
    if (
      this.props.recommendedSessions &&
      session.staffRecommendedCount !== null &&
      session.staffRecommendedCount > 0
    ) {
      for (let i = 0; i < this.props.recommendedSessions.length; i++) {
        const recSession = this.props.recommendedSessions[i];
        if (
          recSession.courseDetails.courseDetailsId ===
          session.courseDetails.courseDetailsId
        ) {
          recSession.staffRecommended.forEach((staff) => {
            // Check if 'staff' is in 'NOT REGISTERED' column
            if (this.staffIsPresent(staff.userId, notRegStaffList)) {
              // Get status of staff's response to recommendation
              const status = this.getNotRegisteredStatus(
                staff.userId,
                recSession.recommendationResponses
              );

              if (status === 'declined') staff.status = 'Declined';
              else staff.status = 'Outstanding';

              recButNotReg.push(staff);
            }
          });
          break;
        }
      }
    }

    return recButNotReg;
  };

  /**
   * Gets the list of staff who have not been recommended and have not registered for the selected session
   * * notRegStaffList: list of staff who have not registered for the selected session
   * * recButNotRegStaffList: list of staff who have been recommended but have not registered for the selected session
   */
  getNotRecAndNotRegStaffList = (notRegStaffList, recButNotRegStaffList) => {
    let notRecAndNotReg = [];
    notRegStaffList.forEach((staff) => {
      if (!this.staffIsPresent(staff.userId, recButNotRegStaffList))
        notRecAndNotReg.push(staff);
    });

    return notRecAndNotReg;
  };

  /**
   * Returns true if 'Upcoming Sessions (Registered)' page should display 3 columns of staff list
   */
  renderThreeColumnUpcomingReg = () => {
    if (
      this.props.pageId === 'registeredUpcoming-details' &&
      this.state.selectedSession &&
      this.state.selectedSession.staffRecommendedCount > 0
    )
      return true;
    return false;
  };

  /**
   * On selecting a different session, ensure that the Recommend Container is hidden
   */
  selectSession = (session) => {
    this.setState(
      {
        recommendSession: null,
        selectedSession: session
      },
      () => {
        this.statDetailsRef.current && this.statDetailsRef.current.focus();
      }
    );
  };

  render() {
    const pageId = this.props.pageId;
    let notRegOrAttendedStaffList = [];
    let recButNotRegStaffList = [];
    let notRecAndNotRegStaffList = [];

    if (pageId !== 'recommendedSessions-details')
      notRegOrAttendedStaffList = this.getNotRegOrAttendedStaffList();

    if (pageId === 'registeredUpcoming-details') {
      recButNotRegStaffList = this.getRecButNotRegStaffList(
        notRegOrAttendedStaffList
      );
      notRecAndNotRegStaffList = this.getNotRecAndNotRegStaffList(
        notRegOrAttendedStaffList,
        recButNotRegStaffList
      );
    }
    return (
      <div className={styles.detailsContainer}>
        <Row>
          <Col xs="4" className={styles.sessionsList}>
            <Switch>
              <Route path="/manager/mandatory">
                <ManagerSessionsList
                  sessions={this.props.sessions}
                  selectSession={this.selectSession}
                  selectedSession={this.state.selectedSession}
                  noData={this.props.noData}
                  loading={this.props.loading}
                />
              </Route>
              <Route path="/manager/">
                <SessionsList
                  pageId={pageId}
                  sessions={this.props.sessions}
                  selectSession={this.selectSession}
                  selectedSession={this.state.selectedSession}
                  noData={this.props.noData}
                  showRecommendedCount={pageId !== 'recommendedSessions-details'}
                  loading={this.props.loading}
                  openRecommendStaffList={this.openRecommendStaffList}
                  recommendSession={this.state.recommendSession}
                  scrollToSession={this.state.scrollToSession}
                  setScroll={this.setScroll}
                />
              </Route>
            </Switch>
          </Col>
          {this.state.recommendSession ? (
            // SHOW RECOMMEND STEP 1 + STEP 2
            <Col className={styles.recommendDiv}>
              <div ref={this.statDetailsRef} tabIndex="-1">
                <RecommendContainer
                  onCloseClick={this.closeRecommendSession}
                  onCloseAndReload={this.closeAndReloadAfterRecommendation}
                  session={this.state.recommendSession.courseDetails}
                />
              </div>
            </Col>
          ) : (
            // SHOW DETAILS PANEL
            <Col>
              <div ref={this.statDetailsRef} tabIndex="-1">
                {/* Recommended Sessions Details */}
                {pageId === 'recommendedSessions-details' && (
                  <RecommendedSessionsDetails
                    session={this.state.selectedSession}
                    staff={this.props.staff}
                    loading={this.props.loading}
                  />
                )}

                {/* Upcoming Sessions (Registered) Details if selected session was recommended */}
                {this.renderThreeColumnUpcomingReg() && (
                  <UpcomingRegisteredSessionsDetails
                    pageId={pageId}
                    property={this.props.property}
                    session={this.state.selectedSession}
                    notRegisteredStaffList={notRecAndNotRegStaffList} // 1st column
                    recommendedButNotRegisteredStaffList={recButNotRegStaffList} // 2nd column
                    loading={this.props.loading}
                    loadContent={this.props.loadContent}
                  />
                )}

                {/* Attended Sessions Details OR Mandatory Attendance Details OR Upcoming Sessions (Registered) Details if selected session was never recommended */}
                {pageId !== 'recommendedSessions-details' &&
                  (pageId === 'sessionAttendance-details' ||
                    pageId === 'mandatoryAttendance-details' ||
                    !this.renderThreeColumnUpcomingReg()) && (
                    <StatDetails
                      pageId={pageId}
                      property={this.props.property}
                      session={this.state.selectedSession}
                      notRegisteredOrAttendedStaffList={notRegOrAttendedStaffList}
                      loading={this.props.loading}
                      loadContent={this.props.loadContent}
                    />
                  )}
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
DashboardDetails.contextType = GlobalContext;

import React from 'react';
import style from './CustomLoader.module.css';

const CustomLoader = () => (
  <div className={style.customLoader}>
    <img
      src={require('../../assets/loading.gif')}
      width="100"
      height="100"
      alt="Loading"
    />
  </div>
);

export default CustomLoader;

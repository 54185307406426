export const PagePaths = {
  Homepage: '/',
  Dashboard: '/dashboard/',
  UpcomingSession: '/sessions/:courseId?',
  PastSession: '/pastsessions/:courseId?'
};

export const PageName = {
  Homepage: 'HOMEPAGE',
  Dashboard: 'DASHBOARD',
  UpcomingSessions: 'UPCOMING_SESSIONS',
  PastSessions: 'PAST_SESSIONS',
  Presenters: 'PRESENTERS',
  OnDemandSessions: 'ON_DEMAND_SESSIONS'
};

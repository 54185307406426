import React, { forwardRef } from 'react';
import styles from './DashboardSessionDetails.module.css';
import { getFormattedDate, getFormattedRange } from '../../../../utils/date';
import BlueSquaredScroll from '../../../../components/blueSquaredScroll/BlueSquaredScroll';
import SquareCloseButton from '../squareCloseButton/SquareCloseButton';
import RegisterButton from './../../../../components/registerButton/RegisterButton';
import DigiButton from '../../../../components/digiButton/DigiButton';
import { useLayoutEffect } from 'react';

const DashboardSessionDetails = forwardRef(
  (
    {
      notification: { id: notificationId, courseDetails },
      onCloseClick,
      onRegistered,
      openDeclineNotificationIdChange,
      declineSelectorRef
    },
    ref
  ) => {
    useLayoutEffect(() => {
      ref && ref.current && ref.current.focus();
    }, [ref]);

    const render = () => {
      const {
        courseName,
        courseStart,
        courseDuration,
        presenters,
        locations
      } = courseDetails;

      const {
        domain: { name: domainName },
        sessionType
      } = courseDetails.course;

      const tbd =
        !presenters ||
        presenters.length === 0 ||
        presenters[0].name.toUpperCase() === 'TBD';

      return (
        <div className={styles.heightContainer} ref={ref} tabIndex="-1">
          <div className={styles.borderContainer}>
            <div className={styles.contentHolder}>
              <div className={styles.titleHolder}>
                <h2>{courseName}</h2>
                <div className={styles.closeButtonContainer}>
                  <SquareCloseButton onClick={onCloseClick} />
                </div>
              </div>
              <div className={styles.pillarHolder}>
                <h3>Pillar: {domainName}</h3>
              </div>
              <div className={styles.rowWithImage}>
                <img
                  className={styles.logoHolder}
                  src={sessionType.imageLink}
                  alt={sessionType.name + ' banner'}
                />
                <div className={styles.imageRightContainer}>
                  <div>
                    <div>
                      <strong>{sessionType.name}</strong>
                    </div>
                    <div>
                      <strong>Date: </strong>
                      {getFormattedDate(courseStart)}
                    </div>
                    <div>
                      <strong>Time: </strong>
                      {getFormattedRange(courseStart, courseDuration)}
                    </div>
                    <div>
                      <strong>Location: </strong>
                      {locations.length > 0 ? locations[0].name : '(No location)'}
                    </div>
                    <div>
                      <strong>
                        {presenters.length > 1 ? 'Presenters: ' : 'Presenter: '}
                      </strong>
                      {tbd && 'No one'}
                      {!tbd &&
                        presenters &&
                        presenters.length > 0 &&
                        presenters.map((presenter, index) => (
                          <span key={`presenter_${presenter.id}`}>
                            {presenter.name} {index < presenters.length - 1}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.sessionDescText}>
                <strong>Session Description:</strong>
              </div>
              <BlueSquaredScroll className={styles.descScrollContainer}>
                <div
                  className={styles.descContent}
                  dangerouslySetInnerHTML={{
                    __html: courseDetails.details
                  }}
                />
              </BlueSquaredScroll>
              <div className={styles.buttonsHolder}>
                <DigiButton
                  onClick={() => {
                    openDeclineNotificationIdChange(notificationId);
                    declineSelectorRef &&
                      declineSelectorRef.current &&
                      declineSelectorRef.current.select &&
                      declineSelectorRef.current.select.focus();
                    onCloseClick();
                  }}
                >
                  Decline
                </DigiButton>
                <RegisterButton
                  area="dashboard-session-details"
                  courseDetails={courseDetails}
                  registered={courseDetails.registered}
                  registrationId={courseDetails.registrationId}
                  onRegistered={() => {
                    onRegistered(courseDetails.courseDetailsId);
                    onCloseClick();
                  }}
                ></RegisterButton>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return render();
  }
);

DashboardSessionDetails.displayName = 'DashboardSessionDetails';

export default DashboardSessionDetails;

import React, { Component } from 'react';
import style from './Header.module.css';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import Contact from '../contactLink/contactLink';
import Badges from '../badges/Badges';
import Points from '../points/points';
import DigitalWorldHelp from '../../assets/DigitalWorldHelp.pdf';
import { checkInnovatorStatus } from '../../CONSTANTS';
import GlobalContext from '../../context/GlobalContext';
import AccessibleButton from '../accessibleButton/AccessibleButton';
import DigiTooltip from '../digiTooltip/DigiTooltip';
import NotificationIcon from '../notificationIcon/NotificationIcon';
import ManagerDashboardHeader from '../ManagerDashboardHeader/ManagerDashboardHeader';

const defaultProfileSvg =
  '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa fa-user fa-w-14 fa-2x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" color="white" alt="Default User Icon"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      numBadges: 0,
      hide: false,
      helpTooltipOpen: false
    };
    this.setTotalNumBadge = this.setTotalNumBadge.bind(this);
  }

  toggleHelpTooltip = () => {
    this.setState({ helpTooltipOpen: !this.state.helpTooltipOpen });
  };

  componentDidMount() {
    this.setTotalNumBadge();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.didJustReceiveNewBadge !== this.props.didJustReceiveNewBadge)
      this.setTotalNumBadge();
  }

  countReceivedBadges() {
    if (this.context.badges) {
      let badgeCount = 0;
      this.context.badges.forEach((currentBadge) => {
        if (
          checkInnovatorStatus(
            this.context.user.tutorialStatus,
            currentBadge.achievement.slug
          )
        ) {
          badgeCount++;
        }
      });
      return badgeCount;
    }

    return 0;
  }

  setTotalNumBadge() {
    if (this.context.badges !== undefined) {
      this.setState({
        numBadges: this.countReceivedBadges()
      });
    }
    if (
      window.location.href.includes('pastsessions') &&
      window.location.href.includes('feedback')
    ) {
      this.setState({ hide: true });
    }
  }

  isDashboard = () => this.props.path.substring(0, 10) === '/dashboard';
  render() {
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <>
            <div className={style.skipMain}>
              <a href="#pageContent">Skip to main content</a>
            </div>
            {this.state.hide ? (
              <div>
                <Navbar className={style.firstNav}>
                  <a href="/">
                    <img
                      className={[style.navIconLink, style.logo].join(' ')}
                      src={require('../../assets/logo.png')}
                      alt="Digital World Logo"
                    />
                  </a>
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className={style.collapseToggle}
                  />
                  <Navbar.Collapse className={style.navHighlight}>
                    <div className={style.hiddenContact}>
                      <Contact id={style.contactLink} />
                    </div>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            ) : (
              <div>
                <Navbar className={style.firstNav}>
                  <a href="/">
                    <img
                      className={[style.navIconLink, style.logo].join(' ')}
                      src={require('../../assets/logo.png')}
                      alt="Digital World Logo"
                    />
                  </a>
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className={style.collapseToggle}
                  />
                  <Navbar.Collapse className={style.navHighlight}>
                    <Nav className="mr-auto">
                      <Nav.Link
                        className={[
                          style.headerLink,
                          this.isDashboard() ? style.headerLinkActive : ''
                        ].join(' ')}
                        as={Link}
                        to="/dashboard"
                      >
                        <div className={style.headerLinkContent}>
                          <span className={style.headerLinkText}>MY DASHBOARD</span>
                          <NotificationIcon
                            enableHoverStyle={!this.isDashboard()}
                            yellow={!this.isDashboard()}
                            className={style.notificationIcon}
                          />
                        </div>
                      </Nav.Link>
                      <span></span>
                      <Nav.Link
                        className={[
                          style.headerLink,
                          this.props.path.substring(0, 9) === '/sessions'
                            ? style.headerLinkActive
                            : ''
                        ].join(' ')}
                        as={Link}
                        to="/sessions/"
                      >
                        <span id="innovatorUpcomingSessionRef">
                          UPCOMING SESSIONS
                        </span>
                      </Nav.Link>
                      <Nav.Link
                        className={[
                          style.headerLink,
                          this.props.path.substring(0, 13) === '/pastsessions'
                            ? style.headerLinkActive
                            : ''
                        ].join(' ')}
                        as={Link}
                        to="/pastsessions"
                      >
                        PAST SESSIONS
                      </Nav.Link>
                      <Nav.Link
                        className={[
                          style.headerLink,
                          this.props.path.substring(0, 9) === '/ondemand'
                            ? style.headerLinkActive
                            : ''
                        ].join(' ')}
                        as={Link}
                        to="/ondemand"
                      >
                      <span id="innovatorOnDemandSessionRef">
                        ON DEMAND SESSIONS
                      </span>
                      </Nav.Link>
                    </Nav>
                    {this.props.path === '/' && (
                      <AccessibleButton
                        className={style.innovatorIcon}
                        onClick={this.props.openInnovatorForPageTutorials}
                      >
                        <img
                          id="innovatorIconRef"
                          className={style.innovatorIconImg}
                          src={require('../../assets/innovatorNoFramed.png')}
                          alt="Innovator Icon for Guiding Website"
                          width="45"
                          height="50"
                        />
                      </AccessibleButton>
                    )}
                    <Nav>
                      {context.user.isManager && (
                        <Nav.Link
                          className={[
                            style.headerLink,
                            this.props.path.substring(0, 8) === '/manager'
                              ? style.headerLinkActive
                              : ''
                          ].join(' ')}
                          as={Link}
                          to="/manager/"
                        >
                          LEADERSHIP
                        </Nav.Link>
                      )}
                      {context.user.isAdmin && (
                        <Nav.Link
                          className={[
                            style.headerLink,
                            this.props.path.substring(0, 6) === '/admin'
                              ? style.headerLinkActive
                              : ''
                          ].join(' ')}
                          as={Link}
                          to="/admin/"
                        >
                          ADMIN
                        </Nav.Link>
                      )}
                      <Contact id={style.contactLink} />
                      <AccessibleButton
                        id="helpButton"
                        onClick={this.toggleHelpTooltip}
                      >
                        <div
                          className={[
                            style.headerLink,
                            style.helpBtn,
                            this.state.helpTooltipOpen ? style.headerLinkActive : ''
                          ].join(' ')}
                        >
                          HELP
                        </div>
                      </AccessibleButton>
                      <DigiTooltip
                        target="helpButton"
                        isOpen={this.state.helpTooltipOpen}
                        onClick={this.toggleHelpTooltip}
                      >
                        <Link className={style.digiTooltipButton} to="/help/tour/">
                          Digital World Tour Video
                        </Link>
                        <a
                          className={style.digiTooltipButton}
                          href={DigitalWorldHelp}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Digital World Guide (PDF)
                        </a>
                      </DigiTooltip>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <div className={style.secondaryNavWrapper}>
                  <div className={style.secondaryNav}>
                    <div>
                      {this.props.path.split('/')[1] !== 'admin' &&
                        this.props.path.split('/')[1] !== 'manager' && (
                          <Badges
                            didJustReceiveNewBadge={
                              this.props.didJustReceiveNewBadge
                            }
                          />
                        )}
                      {this.props.path.split('/')[1] === 'manager' && (
                        <ManagerDashboardHeader />
                      )}
                    </div>
                    {this.props.path.split('/')[1] !== 'manager' && (
                      <div>
                        <span className={style.center} />
                        {this.props.path.split('/')[1] !== 'admin' &&
                          this.props.path.split('/')[1] !== 'manager' && (
                            <>
                              <Points />
                              <span className={style.secondHeaderLink} to="/">
                                {this.state.numBadges === 1 ? (
                                  <span>BADGE</span>
                                ) : (
                                  <span>BADGES</span>
                                )}
                                <br />
                                {this.state.numBadges}
                              </span>
                            </>
                          )}
                        <span className={style.center} />
                      </div>
                    )}
                    <div>
                      <Link
                        className={`${style.secondHeaderLink} ${style.profileLink}`}
                        id="profileLink"
                        to="/dashboard"
                      >
                        {context.user.name}
                        <img
                          className={style.headerProfilePicture}
                          src={
                            typeof context.user.photo === 'undefined' ||
                            context.user.photo === ''
                              ? `data:image/svg+xml;utf8,${defaultProfileSvg}`
                              : `data:image/jpeg;base64,${context.user.photo}`
                          }
                          alt={context.user.name}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </GlobalContext.Consumer>
    );
  }
}
Header.contextType = GlobalContext;

export default Header;

import React, { useState, useRef } from 'react';
import { Form, FormGroup, Label, Button } from 'reactstrap';
import Select from 'react-select';
import CourseAddModify from './CourseAddModify';
import PropTypes from 'prop-types';
import { ON_DEMAND_SLUG } from '../../../sessions/components/SessionUtils';

export const AddRepeatingSession = ({ allCourses, manualReload̥ }) => {
  const formRef = useRef(null);

  const generateCourseSelectOptions = () => {
    let options = [];
    allCourses.forEach((parentCourse) => {
      if (!parentCourse.externalUrl) {
        // Exclude nonltc courses
        parentCourse.courseDetails.forEach((childCourse) => {
          if (childCourse.format.slug !== ON_DEMAND_SLUG) {
            options.push({
              label: childCourse.courseName,
              value: {
                ...childCourse,
                domainId: parentCourse.domain.domainId,
                sessionTypeId: parentCourse.sessionType.sessionTypeId,
                mandatory: parentCourse.mandatory
              }
            });
          }
        });
      }
    });
    return options;
  };

  const [selectedSession, selectedSessionChange] = useState(null);

  return (
    <>
      <Form onSubmit={(event) => event.preventDefault()}>
        <FormGroup>
          <Label for="recurringSessionSelector">Session to Repeat</Label>
          <Select
            isSearchable
            value={selectedSession}
            onChange={(newSelectedSession) => {
              selectedSessionChange(newSelectedSession);
              formRef.current.reload();
            }}
            id="recurringSessionSelector"
            options={generateCourseSelectOptions()}
            className={'repeatingSessionSelect'}
            isClearable
          />
        </FormGroup>
      </Form>
      <CourseAddModify
        disableEdit={!selectedSession}
        manualReload={manualReload̥}
        courseDetails={selectedSession ? selectedSession.value : null}
        repeating={true}
        ref={formRef}
      ></CourseAddModify>
      <Button
        color={'primary'}
        style={{ float: 'right' }}
        id={'submit-button'}
        onClick={() => formRef.current.addRepeating()}
      >
        Submit
      </Button>
    </>
  );
};

AddRepeatingSession.propTypes = {
  allCourses: PropTypes.array,
  manualReload: PropTypes.func
};

export default AddRepeatingSession;

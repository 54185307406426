import ReactGA from 'react-ga';
import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import { adalApiFetch } from './adalConfig';

import './App.css';

//Page imports
import Home from './pages/home/Home.jsx';
//import Help from './pages/help/Help.jsx';
import Feedback from './pages/feedback/Feedback.jsx';
import Dashboard from './pages/dashboard/Dashboard.jsx';
import Sessions from './pages/sessions/Sessions';
import ManagerDashboard from './pages/manager/ManagerDashboard';

//Component Imports
import Header from './components/navbar/Header.jsx';
import Footer from './components/footer/Footer.jsx';
//import Badges from './components/badges/Badges.jsx'
import { toast, ToastContainer } from 'react-toastify';
// import Course from "./pages/course/Course";

//Javascript imports
import nprogress from 'nprogress';
import PresenterGallery from './pages/presenterGallery/PresenterGallery';

import { getNewBadgesList } from './components/badges/BadgesFunctions.jsx';
import { innovatorTutorialMessagesForBadges } from './components/innovator/tutorials/BadgesTutorial';
import { innovatorTutorialMessagesForPages } from './components/innovator/tutorials/PagesTutorial';
import Innovator from './components/innovator/Innovator';
import {
  checkInnovatorStatus,
  getNewInnovatorStatus,
  updateInnovatorStatus,
  NEW_BADGE_BROADCAST_CHANNEL
} from './CONSTANTS';
import { Badges } from './enums/badges.enum';
import GlobalContext from './context/GlobalContext';
import TourVideo from './pages/tourVideo/TourVideo';
import { SLType } from './pages/sessions/components/SessionUtils';
import SuperAdmin from './pages/admin/components/SuperAdmin';
import AdminCourseTabs from './pages/admin/components/courses/AdminCourseTabs';
import AdminAdminTab from './pages/admin/components/admins/AdminAdminTab';
import AdminManagerTab from './pages/admin/components/managers/AdminManagerTab';
import PointsAddModify from './pages/admin/components/points/PointsAddModify';
import AdminReportsTab from './pages/admin/components/reports/AdminReportsTab';
import AdminLastLoginTab from './pages/admin/components/last_login/AdminLastLoginTab';
import AdminLocationsTab from './pages/admin/components/locations/AdminLocationsTab';
import AdminPresenterTab from './pages/admin/components/presenters/AdminPresenterTab';
import AdminProvidersTab from './pages/admin/components/providers/AdminProvidersTab';
import AdminRepTab from './pages/admin/components/reps/AdminRepTab';
import AdminAttributesTab from './pages/admin/components/attributes/AdminAttributesTab';
import AdminJobShadowingTab from './pages/admin/components/JobShadowing/AdminJobShadowingTab';
import AdminLDCalendar from './pages/admin/components/ld_calendar/AdminLDCalendar';

class App extends Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();
    this.state = {
      versionNumber: null,
      didJustReceiveNewBadge: null,
      newBadgesTutorials: [],
      pageTutorials: [],
      hasPillarNameHighlight: [false, false, false, false, false],
      receiveBadgesOnPageVisible: []
    };
    this.innovator = React.createRef();
    this.boxRef = React.createRef();
    this.updateUserTutorialStatus = this.updateUserTutorialStatus.bind(this);
    this.setNewBadge = this.setNewBadge.bind(this);
    this.changeHasPillarNameHighlight = this.changeHasPillarNameHighlight.bind(this);
    // Listen for survey badge grant
    const channel = new BroadcastChannel(NEW_BADGE_BROADCAST_CHANNEL);
    channel.onmessage = (messageEvent) => {
      if (!document.hidden) {
        // If the page is visible when received, then this is the survey page itself.
        return;
      }

      if (messageEvent.data.badgeInfo) {
        let newReceiveBadgesList = this.state.receiveBadgesOnPageVisible;
        newReceiveBadgesList.push(messageEvent.data.badgeInfo);
        this.setState(
          {
            receiveBadgesOnPageVisible: newReceiveBadgesList
          },
          () => {
            this.showBadgeIfPageVisible();
          }
        );
      }
    };
  }

  showBadgeIfPageVisible() {
    if (!document.hidden) {
      // Currently, this forEach loop will not function properly if there is more than one badge
      // Consider how to handle the edge case where two badges are received at the same time in this scenario
      this.state.receiveBadgesOnPageVisible.forEach((currentBadge) => {
        this.addBadgeToList(currentBadge);
      });
      this.setState({
        receiveBadgesOnPageVisible: []
      });
    }
  }

  componentWillUnmount() {
    this.unlisten();
    document.removeEventListener('visibilitychange', this.visibilityChange);
    this.abortController.abort();
  }

  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
      gaOptions: {
        siteSpeedSampleRate: 100 // Send 100% of page timings
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.addEventListener('visibilitychange', () => {
      this.showBadgeIfPageVisible();
    });
    this.unlisten = this.props.history.listen((location) => {
      ReactGA.pageview(location.pathname + location.search);
      this.boxRef.current.focus();
      this.boxRef.current.blur();

      this.closeInnovator().then(() => {
        this.getLatestBadgesAndPoints();
      });
    });
    // Monitor how long the page took to load
    const pageLoadTime = Math.round(window.performance.now());
    ReactGA.timing({
      category: 'Load Times',
      variable: window.location.pathname + window.location.search,
      value: pageLoadTime, // in milliseconds
      label: 'Page Load'
    });

    nprogress.configure({
      showSpinner: false,
      trickleSpeed: 100
    });
    nprogress.start();
    // Load user data to pass to children
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/me', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          this.handleError();
        }
      })
      .then((myJson) => {
        if (typeof myJson === 'undefined' || myJson.success === false) {
          this.handleError();
        } else {
          this.context.setUser(myJson.data);
          this.setState({ versionNumber: myJson.versionNumber });

          this.migrateOldBadgesIfComplete()
            .then(() => {
              this.showTutorialIfComplete();
            })
            .catch((e) => {
              ReactGA.exception({
                description: e.toString()
              });
            });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        this.handleError();
      });

    this.getLatestBadgesAndPoints();
    this.context.reloadNotifications();
    this.context.reloadNotificationStatusReasons();
  }

  getLatestBadgesAndPoints() {
    // Check for new badges every page change
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/badgesandpoints',
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          this.handleError(true);
          return;
        }
      })
      .then((myJson) => {
        if (!myJson || !myJson.success) {
          this.handleError(true);
          return;
        }

        // Update the global context with the new badges and points. Then, show the tutorial if available and the page is ready
        this.context.setBadges(myJson.data.badges, () => {
          this.context.setPoints(myJson.data.points, () => {
            this.forceUpdate();
            this.migrateOldBadgesIfComplete()
              .then(() => {
                this.showTutorialIfComplete();
              })
              .catch(() => {});
          });
        });
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        this.handleError(true);
      });
  }

  /**
   * Once all of the initial data has been loaded, hide the loader and show the tutorial if available
   */
  showTutorialIfComplete() {
    if (this.context.user && this.context.badges && this.context.points) {
      // Have all the Ajax calls completed?
      nprogress.done();
      this.setCurrentPageTutorialAndOpen(); // Should a tutorial load for THIS particular page?
    }
  }

  /**
   *  Migrate users' tutorial status if needed (pilot users don't have the original three badge statuses stored in the new binary format).
   *  If the user has one of the three badges listed, but the tutorial status is not updated, then update the status.
   *  The tutorial status should have immediately updated after each of these badges are received. The reason it didn't is because a data migration is required.
   */
  migrateOldBadgesIfComplete() {
    return new Promise((resolve, reject) => {
      if (!this.context.user || !this.context.badges) {
        reject('Could not find user or badge list for badge migration');
      }

      let userObject = this.context.user;

      let migratedTutorialStatus = userObject.tutorialStatus;
      for (let i = 0; i < this.context.badges.length; i++) {
        if (
          this.context.badges[i].achievement.slug === Badges.Welcome &&
          !checkInnovatorStatus(migratedTutorialStatus, Badges.Welcome)
        ) {
          // Welcome badge
          migratedTutorialStatus = getNewInnovatorStatus(
            migratedTutorialStatus,
            Badges.Welcome
          );
        } else if (
          this.context.badges[i].achievement.slug === Badges.Registration &&
          !checkInnovatorStatus(migratedTutorialStatus, Badges.Registration)
        ) {
          // Registration badge
          migratedTutorialStatus = getNewInnovatorStatus(
            migratedTutorialStatus,
            Badges.Registration
          );
        } else if (
          this.context.badges[i].achievement.slug === Badges.Buddy &&
          !checkInnovatorStatus(migratedTutorialStatus, Badges.Buddy)
        ) {
          // Buddy badge
          migratedTutorialStatus = getNewInnovatorStatus(
            migratedTutorialStatus,
            Badges.Buddy
          );
        }
      }
      if (userObject.tutorialStatus !== migratedTutorialStatus) {
        updateInnovatorStatus(migratedTutorialStatus, null)
          .then((newStatus) => {
            userObject.tutorialStatus = newStatus;
            this.context.setUser(userObject, () => {
              resolve();
            });
          })
          .catch((e) => {
            ReactGA.exception({
              description: e.toString()
            });
            toast('An error occurred while acquiring badges.', {
              type: toast.TYPE.ERROR,
              autoClose: true
            });
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  handleError(genericMessage = false) {
    nprogress.done();
    toast(
      genericMessage
        ? 'A technical error has occurred. Please refresh the page.'
        : 'An error occurred when attempting to verify user login.',
      {
        type: toast.TYPE.ERROR,
        autoClose: true
      }
    );
  }

  /**
   * This function is used to load a tutorial for this particular page (if you have not already gone through it)
   */
  setCurrentPageTutorialAndOpen() {
    let hasInnovator = false;

    innovatorTutorialMessagesForPages.forEach((currentMessage) => {
      if (currentMessage.displayOnPages === this.props.history.location.pathname) {
        // Does this page have a tutorial?
        hasInnovator = true;
        this.setState(
          {
            pageTutorials: currentMessage.content
          },
          () => {
            if (
              !checkInnovatorStatus(this.context.user.tutorialStatus, Badges.Welcome)
            ) {
              // Have I seen this tutorial already?
              // No, I have not.
              this.openInnovatorForPageTutorials();
            } else {
              this.showAnyNewBadges(); // Don't wait to show new badges since this page's tutorial is already seen
            }
          }
        );
      }
    });

    if (!hasInnovator) {
      this.setState({
        pageTutorials: []
      });
      this.showAnyNewBadges(); // Don't wait to show new badges since this page does not have a tutorial
    }
  }

  /**
   * This function will check if the user has received any new badges since last time
   */
  showAnyNewBadges = () => {
    if (!this.onPageWithBadges()) {
      // Don't show badge on admin or manager pages
      return;
    }

    let newBadges = getNewBadgesList(
      this.context.user.tutorialStatus,
      this.context.badges
    );

    if (newBadges.length > 0) {
      let tutorialList = [];

      const currentBadgeToShow = newBadges[0]; // This function is called again after processing each badge. Focus only on the first badge this iteration.

      //newBadges.forEach((newBadge) => {
      // Update tutorial status for each new badge
      innovatorTutorialMessagesForBadges.forEach((tutorial) => {
        if (
          currentBadgeToShow.achievement.slug === tutorial.tutorialPurposeForBadge
        ) {
          tutorialList.push(tutorial);
        }
      });
      //});

      this.setState({ newBadgesTutorials: tutorialList }, () => {
        this.openInnovatorForBadge();
      });
    }
  };

  /**
   * Check if the current page shows the badges on the top of the screen or not
   */
  onPageWithBadges = () => {
    return (
      this.props.history.location.pathname.split('/')[1] !== 'admin' &&
      this.props.history.location.pathname.split('/')[1] !== 'manager' &&
      (this.props.history.location.pathname.split('/').length < 4 ||
        this.props.history.location.pathname.split('/')[3] !== 'feedback')
    );
  };

  /**
   * This method is used to show the PAGE innovator
   */
  openInnovatorForPageTutorials = () => {
    if (this.state.pageTutorials) {
      this.setAllTutorialMessages(this.state.pageTutorials, true).then(() => {
        this.openInnovator();
      });
    }
  };

  /**
   * This method is used to show the BADGE innovator
   */
  openInnovatorForBadge = () => {
    if (this.state.newBadgesTutorials) {
      this.closeInnovator(null, true).then(() => {
        this.setAllTutorialMessages(this.state.newBadgesTutorials, false).then(
          () => {
            this.openInnovator();
          }
        );
      });
    }
  };

  setNewBadge(newStatus) {
    this.setState({ didJustReceiveNewBadge: newStatus });
  }

  //update tutorial status of user temporary without refreshing the app
  updateUserTutorialStatus(newStatus, callback = null) {
    let newUser = this.context.user;
    newUser.tutorialStatus = newStatus;
    this.context.setUser(newUser, () => {
      callback && callback();
    });
  }

  /* Update the list of badges for the user */
  addBadgeToList = (newBadges) => {
    if (!(newBadges instanceof Array)) newBadges = [newBadges];
    const newBadgesList = this.context.badges.concat(newBadges);
    this.context.setBadges(newBadgesList, () => {
      this.forceUpdate(); // Required for the badge to appear properly (bug with context rendering?)
      let containsWelcomeBadge = false;
      newBadges.forEach((badge) => {
        if (badge.achievement.slug === Badges.Welcome) {
          containsWelcomeBadge = true;
        }
      });
      if (!containsWelcomeBadge) {
        this.showAnyNewBadges();
      }
    });
  };

  changeHasPillarNameHighlight(index) {
    this.setState({
      hasPillarNameHighlight: [
        index === 0,
        index === 1,
        index === 2,
        index === 3,
        index === 4
      ]
    });
    if (index === null) {
      this.setState({
        hasPillarNameHighlight: [false, false, false, false, false]
      });
    }
  }

  // TODO: filter box state passing for header

  // toggleManagerDashboardPage = (pageName) => {
  //   this.setState({ selectedManagerDashboardPage: pageName });
  //   if (pageName === 'Team Stats')
  //     this.setState({ managerDashboardRoute: [pageName] });
  //   else if (
  //     pageName !==
  //     this.state.managerDashboardRoute[this.state.managerDashboardRoute.length - 1]
  //   )
  //     this.setState((prevState) => ({
  //       managerDashboardRoute: [...prevState.managerDashboardRoute, pageName]
  //     }));
  // };

  render() {
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className="box" ref={this.boxRef} tabIndex="-1">
            <ToastContainer />
            {context.user !== null &&
              context.badges !== null &&
              context.points !== null && (
                <Header
                  path={this.props.history.location.pathname}
                  className="row header"
                  openInnovatorForPageTutorials={this.openInnovatorForPageTutorials}
                  didJustReceiveNewBadge={this.state.didJustReceiveNewBadge}
                  pageTutorials={this.state.pageTutorials}
                />
              )}
            {context.user !== null &&
              context.badges !== null &&
              (context.points !== null) !== null && (
                <Innovator
                  setAllTutorialMessages={(allTutorialMessages) =>
                    (this.setAllTutorialMessages = allTutorialMessages)
                  }
                  openInnovator={(innovator) => (this.openInnovator = innovator)}
                  closeInnovator={(innovator) => (this.closeInnovator = innovator)}
                  addBadgeToList={this.addBadgeToList}
                  setNewBadge={this.setNewBadge}
                  changeHasPillarNameHighlight={this.changeHasPillarNameHighlight}
                  updateUserTutorialStatus={this.updateUserTutorialStatus}
                  showAnyNewBadges={this.showAnyNewBadges}
                  // forceClose={true}
                />
              )}

            {context.user !== null &&
              context.badges !== null &&
              context.points !== null && (
                <div id="pageContent">
                  <Switch className="row content">
                    <Route
                      path="/"
                      exact
                      render={(props) => (
                        <Home
                          {...props}
                          onInnovatorOpen={(innovator) =>
                            (this.onInnovatorOpen = innovator)
                          }
                          addBadgeToList={this.addBadgeToList}
                          hasPillarNameHighlight={this.state.hasPillarNameHighlight}
                          changeHasPillarNameHighlight={
                            this.changeHasPillarNameHighlight
                          }
                        />
                      )}
                    />
                    <Route
                      path="/dashboard/"
                      render={() => (
                        <Dashboard
                          onInnovatorOpen={(innovator) =>
                            (this.onInnovatorOpen = innovator)
                          }
                          addBadgeToList={this.addBadgeToList}
                        />
                      )}
                    />
                    <Route
                      path="/admin/"
                      exact
                      render={
                        //by default if admin opens /admin => sessions tab is open
                        () => <Redirect to="/admin/sessions" />
                      }
                    />
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/super"
                        exact
                        render={() => (
                          <SuperAdmin addBadgeToList={this.addBadgeToList} />
                        )}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/sessions"
                        exact
                        render={(props) => <AdminCourseTabs {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/shareit"
                        exact
                        render={(props) => <AdminJobShadowingTab {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      //by default if admin opens SessionConfigurations => locations tab is open
                      <Route
                        path="/admin/sessionconfigurations"
                        exact
                        render={() => (
                          <Redirect to="/admin/sessionconfigurations/locations" />
                        )}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/sessionconfigurations/locations"
                        exact
                        render={(props) => <AdminLocationsTab {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/sessionconfigurations/attributes"
                        exact
                        render={(props) => <AdminAttributesTab {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/sessionconfigurations/presenters"
                        exact
                        render={(props) => <AdminPresenterTab {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/sessionconfigurations/providers"
                        exact
                        render={(props) => <AdminProvidersTab {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/sessionconfigurations/l+dreps"
                        exact
                        render={(props) => <AdminRepTab {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/ldcalendar"
                        exact
                        render={(props) => <AdminLDCalendar {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/admins"
                        exact
                        render={(props) => <AdminAdminTab {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/managerstaff"
                        exact
                        render={(props) => <AdminManagerTab {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/achievements"
                        exact
                        render={(props) => <PointsAddModify {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/users"
                        exact
                        render={(props) => <AdminLastLoginTab {...props} />}
                      />
                    )}
                    {context.user.isAdmin && (
                      <Route
                        path="/admin/reports"
                        exact
                        render={(props) => <AdminReportsTab {...props} />}
                      />
                    )}
                    <Route path="/manager/" component={ManagerDashboard} />
                    <Route path="/help/tour/" exact render={() => <TourVideo />} />
                    <Route
                      exact
                      path="/presenters/:presenterId"
                      render={(props) => <PresenterGallery {...props} />}
                    />
                    <Route
                      exact
                      path="/pastsessions/:courseDetailsId?"
                      render={(props) => (
                        <Sessions
                          key="1"
                          {...props}
                          type={SLType.PAST}
                          onInnovatorOpen={(innovator) =>
                            (this.onInnovatorOpen = innovator)
                          }
                          addBadgeToList={this.addBadgeToList}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/sessions/:courseDetailsId?"
                      render={(props) => (
                        <Sessions
                          key="2"
                          {...props}
                          type={SLType.UPCOMING}
                          onInnovatorOpen={(innovator) =>
                            (this.onInnovatorOpen = innovator)
                          }
                          addBadgeToList={this.addBadgeToList}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/ondemand/:courseDetailsId?"
                      render={(props) => (
                        <Sessions
                          key="3"
                          {...props}
                          type={SLType.ON_DEMAND}
                          onInnovatorOpen={(innovator) =>
                            (this.onInnovatorOpen = innovator)
                          }
                          addBadgeToList={this.addBadgeToList}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/pastsessions/:courseDetailsId/:courseId/feedback"
                      render={(props) => <Feedback {...props} />}
                    />
                    <Route
                      path="*"
                      exact={true}
                      component={() => <h1>404 page not found</h1>}
                    />
                  </Switch>
                </div>
              )}
            <Footer
              path={this.props.history.location.pathname}
              className="row footer"
              buildVersion={this.state.versionNumber}
            />
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}
App.contextType = GlobalContext;

export default withRouter(App);

import React from 'react';
import ReactGA from 'react-ga';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';

export default class LocationAddModify extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.onModify = this.onModify.bind(this);
    this.parseForm = this.parseForm.bind(this);
    this.update = this.update.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);

    this.state = {
      confirmModal: false,
      submitting: false,
      name: ''
    };
    this.originalState = {
      confirmModal: false,
      submitting: false,
      name: ''
    };
  }

  componentDidMount() {
    if (this.props.payload != null) this.parsePayload();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  parseForm(locationId = null) {
    let data = {
      name: this.state.name
    };

    if (locationId !== null) data.locationId = locationId;
    return data;
  }

  add() {
    if (this.state.submitting) return null;

    toastRequest.notify('Creating Location');
    const data = this.parseForm();

    this.setState({ submitting: true });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Locations', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: this.abortController.signal
    })
      .then((response) => {
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'Location added successfully!'
        );
        if (myJson.success === true) {
          this.setState(this.originalState);
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  update() {
    if (this.state.confirmModal) {
      this.toggleConfirmModal();
      this.props.closeModal();
      toastRequest.notify('Updating Location');
      const data = this.parseForm(this.props.payload.locationId);
      adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Locations', {
        method: 'put',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        // signal: this.abortController.signal
      })
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          toastRequest.updateToast(
            myJson.success,
            myJson.error,
            'Location edited successfully!'
          );
          if (myJson.success === true) {
            this.props.manualReload();
          }
        })
        .catch((e) => {
          toastRequest.dismissToast();
          ReactGA.exception({
            description: e.toString()
          });
        });
    } else {
      this.toggleConfirmModal();
    }
  }

  onModify(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  parsePayload() {
    for (const [key, value] of Object.entries(this.props.payload)) {
      if (value != null) {
        this.setState({ [key]: value });
      }
    }
  }

  toggleConfirmModal() {
    this.setState((prevState) => ({
      confirmModal: !prevState.confirmModal
    }));
  }

  render() {
    return (
      <div>
        <Form onSubmit={(event) => event.preventDefault()}>
          {/*NAME*/}
          <FormGroup>
            <Label for="locationName">Location</Label>
            <Input
              type="text"
              value={this.state.name}
              name="name"
              id="locationName"
              onChange={this.onModify}
            />
          </FormGroup>
        </Form>
        {typeof this.props.payload !== 'undefined' && (
          <Modal
            isOpen={this.state.confirmModal}
            toggle={this.toggleConfirmModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleConfirmModal}>
              Confirm Location Update
            </ModalHeader>
            <ModalBody>
              {`Location will be updated from "${this.props.payload.name}" to "${this.state.name}". Please confirm`}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.update}>
                Confirm
              </Button>
              <Button color="secondary" onClick={this.toggleConfirmModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}

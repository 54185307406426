import React from 'react';
import PropTypes from 'prop-types';

import GlobalContext from '../../context/GlobalContext';
import DigiButton from '../digiButton/DigiButton';

export default class WithdrawButton extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();
  }

  static propTypes = {
    courseDetails: PropTypes.object
  };

  openLink = () => {
    if (this.props.courseDetails.externalUrl !== 'noExternalUrl') {
      const httpLeadRegex = /^http:\/\/|https:\/\//;
      if (!this.props.courseDetails.externalUrl.match(httpLeadRegex)) {
        this.props.courseDetails.externalUrl =
          'https://' + this.props.courseDetails.externalUrl;
      }
      window.open(this.props.courseDetails.externalUrl, '_blank').focus();
    }
  };

  render() {
    const course = this.props.courseDetails;
    const registrationRecord =
      this.props.courseDetails && this.props.courseDetails.participants
        ? this.props.courseDetails.participants.find(
            (participant) => participant.userId === this.context.user.userId
          )
        : null;
    const onDemandAttended = registrationRecord
      ? registrationRecord.attended
      : false;
    return (
      <>
        {course && course.mandatory && (
          <DigiButton
            colorTheme={onDemandAttended ? 'lightBlue' : 'red'}
            selected={true}
            disabled={onDemandAttended}
            onClick={this.openLink}
          >
            {onDemandAttended ? 'Completed' : 'Outstanding'}
          </DigiButton>
            )}
            {course && !course.mandatory && onDemandAttended && (
                <DigiButton
                    colorTheme={'lightBlue'}
                    selected={true}
                    disabled={onDemandAttended}
                    onClick={this.openLink}
                >
                    {'Completed'}
                </DigiButton>
            )}
      </>
    );
  }
}

WithdrawButton.contextType = GlobalContext;

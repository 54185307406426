import React from 'react';
import ReactGA from 'react-ga';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import delIcon from '../../images/garbage.svg';
import { adalApiFetch } from '../../../../adalConfig';
import * as toastRequest from '../../utils/toastRequests';
import AdminAddModify from './AdminAddModify';
import GlobalContext from '../../../../context/GlobalContext';
import style from '../AdminAllTabs.module.css';

export default class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleModifyModal = this.toggleModifyModal.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.deleteSelf = this.deleteSelf.bind(this);
    this.formRef = React.createRef();
    this.state = { modalOpen: false, modModal: false, collapse: false };
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  toggleDeleteModal() {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen
    }));
  }

  toggleModifyModal() {
    this.setState((prevState) => ({
      modModal: !prevState.modModal
    }));
  }

  toggleCollapse() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  updateSelf() {
    this.formRef.current.update();
    this.toggleModifyModal();
  }

  deleteSelf() {
    toastRequest.notify('Deleting admin');
    this.toggleDeleteModal();
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/Admins/${this.props.payload.adminId}`,
      { method: 'delete', signal: this.abortController.signal }
    )
      .then((response) => {
        if (!response.ok) {
          toastRequest.updateToast(
            false,
            'An error occurred when attempting to delete this Admin'
          );
        } else {
          return response.json();
        }
      })
      // .catch(toastRequest.updateToast(false, "An error occurred when attempting to delete this Admin"))
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `Admin "${this.props.payload.user.email}" deleted successfully!`
        );
        if (myJson.success === true) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  render() {
    return (
      <GlobalContext.Consumer>
        {(consumer) => (
          <Card>
            <CardHeader>
              <h5 className={style.cardTitle} style={{ float: 'left' }}>
                {this.props.payload.user.email}{' '}
                <Badge color="secondary">id:{this.props.payload.adminId}</Badge>
              </h5>
              <Button
                size="sm"
                outline
                color={
                  consumer.user.email === this.props.payload.user.email
                    ? 'secondary'
                    : 'danger'
                }
                style={{ float: 'right' }}
                onClick={this.toggleDeleteModal}
                disabled={consumer.user.email === this.props.payload.user.email}
              >
                <img
                  style={{ height: '24px' }}
                  src={delIcon}
                  alt={'delete button'}
                />
              </Button>
            </CardHeader>

            {/*DELETE MODAL*/}
            <Modal
              isOpen={this.state.modalOpen}
              toggle={this.toggleDeleteModal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                Confirm Deletion
              </ModalHeader>
              <ModalBody>
                Are you sure you want to delete Admin "
                {this.props.payload.user.email}
                "? This action cannot be undone.
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={this.deleteSelf}>
                  DELETE
                </Button>{' '}
                <Button color="secondary" onClick={this.toggleDeleteModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            {/*MODIFY MODAL*/}
            <Modal
              isOpen={this.state.modModal}
              toggle={this.toggleModifyModal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleModifyModal}>
                Update this Admin
              </ModalHeader>
              <ModalBody>
                <AdminAddModify
                  payload={this.props.payload}
                  ref={this.formRef}
                  manualReload={this.props.manualReload}
                  closeModal={this.toggleModifyModal}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.updateSelf}>
                  Modify
                </Button>
                <Button color="secondary" onClick={this.toggleModifyModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Card>
        )}
      </GlobalContext.Consumer>
    );
  }
}

import React, { useContext, useState } from 'react';
import DigiButton from '../digiButton/DigiButton';
import { toast } from 'react-toastify';
import { register, unregister } from '../../CONSTANTS';
import { hasBadge } from '../badges/BadgesFunctions.jsx';
import { Badges } from '../../enums/badges.enum.js';
import { GetNotLtcBadge, GetRegistrationBadge } from '../badges/BadgesAPI.jsx';
import GlobalContext from '../../context/GlobalContext';

export function NonLtcRegisterButton(props) {
  const context = useContext(GlobalContext);
  const courseDetails = props.courseDetails;
  const registrationId = props.registrationId;
  const registered = !!registrationId;

  const [loading, setLoading] = useState(false);
  // const [registered, setRegistered] = useState(false)

  const onRegister = () => {
    // Open Non-LTC registration
    if (courseDetails.externalUrl !== 'noExternalUrl') {
      const httpLeadRegex = /^http:\/\/|https:\/\//;
      if (!courseDetails.externalUrl.match(httpLeadRegex)) {
        courseDetails.externalUrl = 'https://' + courseDetails.externalUrl;
      }
      window.open(courseDetails.externalUrl, '_blank').focus();
    }
    // Ensure we don't spam the API
    if (loading) return;
    setLoading(true);
    register(
      context.user,
      courseDetails.courseId,
      courseDetails.courseDetailsId
    ).then(async (result) => {
      // not loading anymore
      setLoading(false);
      if (result.success) {
        // set value in to context (update UI)
        const updatedParticipantsArray = [];
        updatedParticipantsArray.push(result.data);
        context.setParticipantsListForCourse(
          courseDetails.courseDetailsId,
          updatedParticipantsArray
        );
        // display confirmation
        toast(`Session ${courseDetails.courseName} registered successfully`, {
          type: toast.TYPE.SUCCESS,
          autoClose: true
        });
        // award nonltc badge
        let nonLtcBadgePromise, registerBadgePromise;
        if (!hasBadge(context.badges, Badges.NonLtc))
          nonLtcBadgePromise = GetNotLtcBadge();
        if (!hasBadge(context.badges, Badges.Registration))
          registerBadgePromise = GetRegistrationBadge();

        let badgesToAdd = [];
        if (nonLtcBadgePromise) {
          const nonLtcBadgeJson = await nonLtcBadgePromise;
          if (nonLtcBadgeJson.success) {
            badgesToAdd.push(nonLtcBadgeJson.data);
          } else {
            toast(nonLtcBadgeJson.error, {
              type: toast.TYPE.ERROR,
              autoClose: true
            });
          }
        }
        if (registerBadgePromise) {
          const registerBadgeJson = await registerBadgePromise;
          if (registerBadgeJson.success) {
            badgesToAdd.push(registerBadgeJson.data);
          } else {
            toast(registerBadgeJson.error, {
              type: toast.TYPE.ERROR,
              autoClose: true
            });
          }
        }
        if (badgesToAdd.length > 0) {
          props.addBadgeToList(badgesToAdd);
        }
      } else {
        toast(result.error, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      }
    }); // TODO: api timeout errors
  };

  const onUnregister = () => {
    if (loading || !registered) return;
    setLoading(true);
    unregister(registrationId).then((result) => {
      // not loading anymore
      setLoading(false);
      if (result.success) {
        //clear value in the context
        const newParticipantsList = [];
        context.setParticipantsListForCourse(
          courseDetails.courseDetailsId,
          newParticipantsList
        );
        // display confirmation
        toast(`Session ${courseDetails.courseName} unregistered successfully`, {
          type: toast.TYPE.SUCCESS,
          autoClose: true
        });
      } else {
        toast(result.error, {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      }
    });
  };

  return (
    <DigiButton
      id={`register-button-${props.area || 'default'}-${
        courseDetails.courseDetailsId
      }`}
      selected={registered}
      onClick={registered ? onUnregister : onRegister}
      disabled={false}
      loading={false}
      colorTheme="darkBlue"
    >
      {registered ? 'Unregister' : 'Register'}
    </DigiButton>
  );
}

import { toast } from 'react-toastify';
import { FilterType } from '../components/reports/FilterType';

export const makeFilterType = (state) => {
  // Bandaid function. Wanted to keep the filtering more generic in case we need to add new filter types
  // Showdefault true means fiscal quarter
  // false means date range
  return state.showDefault
    ? {
        type: FilterType.FiscalYear,
        data: {
          ...(state.userId && { userId: state.userId }),
          selectedYear: state.selectedYear,
          fromQuarter: state.fromQuarter,
          toQuarter: state.toQuarter
        }
      }
    : {
        type: FilterType.DateRange,
        data: {
          ...(state.userId && { userId: state.userId }),
          fromDate: state.fromDate,
          toDate: state.toDate
        }
      };
};

const validateQuarters = (state) => {
  if (state.fromQuarter === null || state.fromQuarter === '') return false;
  if (state.toQuarter === null || state.toQuarter === '') return false;

  let lastQ1 = parseInt(state.fromQuarter.substring(1));
  let lastQ4 = parseInt(state.toQuarter.substring(1));

  return lastQ4 >= lastQ1;
};

const validateDates = (state) => {
  if (state.fromDate === null || state.fromDate === '') return false;
  if (state.toDate === null || state.toDate === '') return false;

  return state.toDate >= state.fromDate;
};

export const validate = (state) => {
  if (!validateQuarters(state) && state.showDefault) {
    toast(
      'Invalid Quarter selection. "From Quarter" cannot be after "To Quarter".',
      {
        type: toast.TYPE.ERROR,
        autoClose: true
      }
    );
    return null;
  }

  if (!validateDates(state) && !state.showDefault) {
    toast('Invalid Date selection. "From Date" cannot be after "To Date".', {
      type: toast.TYPE.ERROR,
      autoClose: true
    });
    return null;
  }

  return true;
};

import React, { useContext } from 'react';
import styles from './NotificationIcon.module.css';
import GlobalContext from '../../context/GlobalContext';
import { joinClasses } from '../../utils/styles';

const NotificationIcon = ({ className, enableHoverStyle, yellow }) => {
  const { notifications } = useContext(GlobalContext);
  return (
    notifications &&
    notifications.length > 0 && (
      <div
        className={joinClasses(
          styles.bellContainer,
          enableHoverStyle && styles.enableHoverStyle,
          yellow && styles.yellow,
          className
        )}
      >
        <span className={styles.numberHolder}>{notifications.length}</span>
      </div>
    )
  );
};

NotificationIcon.defaultProps = {
  className: ''
};

export default NotificationIcon;

import React, { Component } from 'react';
import styles from './PageButton.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import AccessibleButton from '../accessibleButton/AccessibleButton';

export default class PageButton extends Component {
  static defaultProps = {
    onClick: () => {}
  };

  render() {
    return (
      <AccessibleButton
        className={[styles.pageIcon, this.props.className].join(' ')}
        onClick={() => this.props.onClick()}
        disabled={this.props.disabled}
        aria-label={this.props.label}
      >
        <FontAwesomeIcon
          icon={this.props.className === 'previous' ? faCaretLeft : faCaretRight}
          role="button"
        />
      </AccessibleButton>
    );
  }
}

import React from 'react';
import ReactGA from 'react-ga';
import styles from './UpcomingRegisteredSessionsDetails.module.css';
import { Row, Col, Spinner } from 'reactstrap';
import DigiCheckbox from '../../../components/digiCheckbox/DigiCheckbox';
import DigiButton from '../../../components/digiButton/DigiButton';
import EmailContent from './EmailContent';
import { toast } from 'react-toastify';
import * as toastRequest from '../../admin/utils/toastRequests';
import { adalApiFetch } from '../../../adalConfig';
import MandatoryCircle from '../../../components/mandatoryCircle/mandatoryCircle';
import RecommendButton from '../../../components/recommendButton/RecommendButton';

export default class UpcomingRegisteredSessionsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.state = {
      selectedRecommendEmployees: {}, // Represents staff that manager selected to send recommendation to
      selectedEmployees: {}, // Represents staff that manager selected to send reminder to
      recommendSelectAll: false, // Select All button for staff under 'NOT REGISTERED' column
      selectAll: false, // Select All button for staff under 'NOT REGISTERED (RECOMMENDED)' column
      enableRemindBtn: false,
      enableRecommendBtn: false,
      showEmailContent: false,
      sent: {}, // Staff of manager who has ALREADY been reminded of a session
      loadingSent: false
    };
  }

  componentDidMount() {
    if (this.isSessionNull()) {
      return;
    }
    this.fetchCheckedStaff();
  }

  componentDidUpdate(prevProps) {
    // If no session in Sessions list, simply return & do not update any state
    if (this.isSessionNull()) {
      return;
    }

    // Update state if another session selected
    if (
      this.isSessionNull(prevProps) ||
      prevProps.session.courseDetails.courseDetailsId !==
        this.props.session.courseDetails.courseDetailsId
    ) {
      this.fetchCheckedStaff();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  /**
   * Gets all session-share or session-reminder notifications sent by manager to their staff
   */
  fetchCheckedStaff = () => {
    let endpoint = `/api/Notifications/remind?courseDetailsId=${this.props.session.courseDetails.courseDetailsId}`;

    this.setState({ loadingSent: true });

    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + endpoint, {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          this.setState({ loadingSent: false });
          toast(
            'Something went wrong while fetching sessions that were already shared!',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          let sent = {};
          myJson.data.forEach((sessionShare) => {
            this.props.recommendedButNotRegisteredStaffList.forEach((staff) => {
              if (staff.userId === sessionShare.userId) {
                sent[staff.userId] = true;
              }
            });
          });

          /* If a different session was selected from the Sessions list 
          or 'Remind' button was hit, we unselect all checkboxes next to staff name */
          const reminderEmployees = this.setReminderCheckboxForAllStaff(false, sent);
          const recommendEmployees = this.setRecommendCheckboxForAllStaff(false);

          // Set state
          this.setState({
            selectedEmployees: reminderEmployees,
            selectedRecommendEmployees: recommendEmployees,
            showEmailContent: false,
            enableRemindBtn: false,
            enableRecommendBtn: false,
            sent: sent,
            selectAll: this.allStaffReminded(sent),
            recommendSelectAll: false,
            loadingSent: false
          });
        }
      })
      .catch((e) => {
        this.setState({ loadingSent: false });
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  /**
   * Sorts staff list by their name
   * @param staff: list of staff to be sorted by names
   */
  sortStaff = (staff) => {
    return staff.sort((a, b) => a.name.localeCompare(b.name));
  };

  /**
   * Selects or un-selects checkboxes for all staff under 1st Column i.e. 'NOT REGISTERED'
   * @param value: checks all boxes if true, un-checks all boxes if false
   */
  setRecommendCheckboxForAllStaff = (value) => {
    let employees = {};
    this.props.notRegisteredStaffList.forEach((staff) => {
      employees[staff.userId] = value;
    });
    return employees;
  };

  /**
   * Selects or un-selects checkboxes for all staff under 2nd Column i.e. 'NOT REGISTERED (RECOMMENDED)'
   * @param value: checks all boxes if true, un-checks all boxes if false
   */
  setReminderCheckboxForAllStaff = (value, sent) => {
    let employees = {};
    this.props.recommendedButNotRegisteredStaffList.forEach((staff) => {
      if (!(staff.userId in sent) && staff.status !== 'Declined') {
        employees[staff.userId] = value;
      } else {
        // if a staff has already been reminded or has declined, they cannot be manually selected by manager. Hence false.
        employees[staff.userId] = false;
      }
    });
    return employees;
  };

  /**
   * Checks if checkboxes for all staff under 2nd column i.e. 'NOT REGISTERED (RECOMMENDED)' are not selected
   */
  noReminderStaffSelected = () => {
    for (const staffUserId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(staffUserId)) {
        if (this.state.selectedEmployees[staffUserId]) {
          return false;
        }
      }
    }
    return true;
  };

  /**
   * Checks if checkboxes for all staff under 1st column i.e. 'NOT REGISTERED' are not selected
   */
  noRecommendedStaffSelected = () => {
    for (const staffUserId in this.state.selectedRecommendEmployees) {
      if (this.state.selectedRecommendEmployees.hasOwnProperty(staffUserId)) {
        if (this.state.selectedRecommendEmployees[staffUserId]) {
          return false;
        }
      }
    }
    return true;
  };

  /**
   * Returns true if check box of at least one staff under 2nd column i.e. 'NOT REGISTERED (RECOMMENDED)' is selected.
   * False if no check box selected.
   */
  shouldEnableRemindBtn = () => {
    return !this.noReminderStaffSelected();
  };

  /**
   * Returns true if check box of at least one staff under 1st column i.e. 'NOT REGISTERED' is selected.
   * False if no check box selected.
   */
  shouldEnableRecommendBtn = () => {
    return !this.noRecommendedStaffSelected();
  };

  /**
   * Returns true if all of the staff under 2nd column i.e. 'NOT REGISTERED (RECOMMENDED)'
   * with 'outstanding' status have been reminded of a session
   */
  allStaffReminded = (noActionStaff) => {
    let notDeclinedStaffCount = 0;
    this.props.recommendedButNotRegisteredStaffList.forEach((staff) => {
      if (staff.status !== 'Declined') notDeclinedStaffCount += 1;
    });
    return notDeclinedStaffCount === Object.keys(noActionStaff).length;
  };

  /**
   * Closes the email container.
   */
  onCloseEmailContainer = () => {
    this.setState({
      showEmailContent: false
    });
  };

  /**
   * Sends out an email to the selected staff recommending them a session
   */
  sendRecommendEmails = () => {
    const requestBody = [];
    for (const userId in this.state.selectedRecommendEmployees) {
      if (this.state.selectedRecommendEmployees.hasOwnProperty(userId)) {
        if (this.state.selectedRecommendEmployees[userId]) {
          requestBody.push({
            userId: userId,
            courseDetailsId: this.props.session.courseDetails.courseDetailsId,
            envBaseUrl: process.env.REACT_APP_UI_BASE_DOMAIN
          });
        }
      }
    }

    // API call to send emails
    toastRequest.notify('Sending recommendations.');
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Notifications/recommendation/',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success === true) {
          toastRequest.updateToast(
            true,
            null,
            `Session ${this.props.session.courseDetails.courseName} has been recommended to your staff.`
          );

          /*
          Re-load manager dashboard data after recommending a session to maintain consistency between all details pages
          Also, staff who were recommended, now move from the 1st column () to the 
          2nd column i.e. from 'NOT REGISTERED' to 'NOT REGISTERED (RECOMMENDED)'
          */
          const recommendEmployees = this.setRecommendCheckboxForAllStaff(false);
          this.setState(
            {
              selectedRecommendEmployees: recommendEmployees,
              enableRecommendBtn: false,
              selectAll: false,
              recommendSelectAll: false
            },
            () => {
              this.props.loadContent(
                this.props.session.courseDetails.courseDetailsId
              );
            }
          );
        } else {
          toastRequest.updateToast(false, 'Something went wrong!');
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });

    // Close the email container
    this.onCloseEmailContainer();
  };

  /**
   * Sends out an email to the selected staff reminding them about a session
   */
  sendReminderEmails = (subject, bodyFirstLine) => {
    const requestBody = [];
    for (const userId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(userId)) {
        if (this.state.selectedEmployees[userId]) {
          requestBody.push({
            userId: userId,
            courseDetailsId: this.props.session.courseDetails.courseDetailsId,
            domain: this.props.session.courseDetails.course.domain.name,
            subject: subject,
            firstLine: bodyFirstLine,
            isMandatory: this.props.session.courseDetails.course.mandatory,
            envBaseUrl: process.env.REACT_APP_UI_BASE_DOMAIN
          });
        }
      }
    }

    // API call to send emails
    toastRequest.notify('Sending notifications.');
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Notifications/remind',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success === true) {
          // Update staff list state after selected staff have been reminded
          this.fetchCheckedStaff();
          toastRequest.updateToast(
            true,
            null,
            `Reminder of Session ${this.props.session.courseDetails.courseName} has been sent to your staff`
          );
        } else {
          toastRequest.updateToast(false, 'Something went wrong!');
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });

    // Close the email container
    this.onCloseEmailContainer();
  };

  /**
   * Gets the number of staff manager has selected (i.e. enabled checkbox for) to send a reminder to
   */
  selectedReminderStaffCount = () => {
    let count = 0;
    for (const userId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(userId)) {
        if (this.state.selectedEmployees[userId]) count += 1;
      }
    }
    return count;
  };

  /**
   * Gets the number of staff manager has selected (i.e. enabled checkbox for) to send a recommendation to
   */
  selectedRecommendationStaffCount = () => {
    let count = 0;
    for (const userId in this.state.selectedRecommendEmployees) {
      if (this.state.selectedRecommendEmployees.hasOwnProperty(userId)) {
        if (this.state.selectedRecommendEmployees[userId]) count += 1;
      }
    }
    return count;
  };

  /**
   * Checks if prop's session is null
   */
  isSessionNull = (prop = this.props) => {
    return prop.session === null;
  };

  /**
   * Checks if selected session has been reminded of to userId
   */
  notificationSent = (userId) => {
    return userId in this.state.sent;
  };

  /**
   * 1st Column - Display the list of staff who were not recommended and did not register for the session
   */
  getNotRegistered = () => {
    // Show spinner if data is loading
    if (this.props.loading) {
      return (
        <div className={styles.loadingSpinner}>
          <Spinner color="primary" />
        </div>
      );
    }
    // Show staff list if data is loaded
    return (
      <>
        {this.props.notRegisteredStaffList.length > 0 && (
          <>
            {/* SELECT ALL button to Recommend */}
            <div className={`${styles.staffRow} ${styles.selectAll}`}>
              <DigiCheckbox
                checked={!!this.state.recommendSelectAll}
                disabled={this.state.loadingSent}
                toggle={() => {
                  const employees = this.setRecommendCheckboxForAllStaff(
                    !this.state.recommendSelectAll
                  );
                  this.setState((prevState) => ({
                    selectedRecommendEmployees: employees,
                    recommendSelectAll: !prevState.recommendSelectAll,
                    enableRecommendBtn: !prevState.recommendSelectAll
                  }));
                }}
                label="Select all"
              ></DigiCheckbox>
            </div>

            {/* Staff list */}
            {this.sortStaff(this.props.notRegisteredStaffList).map(
              (staff, index) => {
                return (
                  <div id={staff.userId} key={index} className={styles.staffRow}>
                    <DigiCheckbox
                      userId={staff.userId}
                      checked={this.state.selectedRecommendEmployees[staff.userId]}
                      disabled={this.state.loadingSent}
                      toggle={() => {
                        this.setState(
                          (prevState) => ({
                            selectedRecommendEmployees: {
                              ...prevState.selectedRecommendEmployees,
                              [staff.userId]: !prevState.selectedRecommendEmployees[
                                staff.userId
                              ]
                            }
                          }),
                          () => {
                            this.setState({
                              enableRecommendBtn: this.shouldEnableRecommendBtn()
                            });
                          }
                        );
                      }}
                      label={`${staff.name}`}
                    ></DigiCheckbox>
                  </div>
                );
              }
            )}
          </>
        )}
      </>
    );
  };

  /**
   * 2nd Column - Display list of staff who were recommended but did not register for the session
   */
  getRecommendedButNotRegistered = () => {
    // Show spinner if data is loading
    if (this.props.loading) {
      return (
        <div className={styles.loadingSpinner}>
          <Spinner color="primary" />
        </div>
      );
    }
    // Show staff list if data is loaded
    return (
      <>
        {this.props.recommendedButNotRegisteredStaffList.length > 0 && (
          <>
            {/* SELECT ALL button to Remind */}
            <div className={`${styles.staffRow} ${styles.selectAll}`}>
              <DigiCheckbox
                checked={!!this.state.selectAll}
                disabled={
                  this.state.loadingSent || this.allStaffReminded(this.state.sent)
                }
                toggle={() => {
                  const employees = this.setReminderCheckboxForAllStaff(
                    !this.state.selectAll,
                    this.state.sent
                  );
                  this.setState((prevState) => ({
                    selectedEmployees: employees,
                    selectAll: !prevState.selectAll,
                    enableRemindBtn: !prevState.selectAll
                  }));
                }}
                label="Select all"
              ></DigiCheckbox>
            </div>

            {/* Staff list */}
            {this.sortStaff(this.props.recommendedButNotRegisteredStaffList).map(
              (staff, index) => {
                return (
                  <div id={staff.userId} key={index} className={styles.staffRow}>
                    <DigiCheckbox
                      userId={staff.userId}
                      checked={
                        this.notificationSent(staff.userId) ||
                        this.state.selectedEmployees[staff.userId]
                      }
                      disabled={
                        this.state.loadingSent ||
                        this.notificationSent(staff.userId) ||
                        staff.status === 'Declined'
                      }
                      toggle={() => {
                        this.setState(
                          (prevState) => ({
                            selectedEmployees: {
                              ...prevState.selectedEmployees,
                              [staff.userId]: !prevState.selectedEmployees[
                                staff.userId
                              ]
                            }
                          }),
                          () => {
                            this.setState({
                              enableRemindBtn: this.shouldEnableRemindBtn()
                            });
                          }
                        );
                      }}
                      label={`${staff.name}`}
                    ></DigiCheckbox>
                    <div className={styles.disabledMessage}>{`${staff.status}`}</div>
                  </div>
                );
              }
            )}
          </>
        )}
      </>
    );
  };

  /**
   * 3rd Column - Display the list of staff that registered for the session
   */
  getRegistered = () => {
    // Show spinner if data is loading
    if (this.props.loading) {
      return (
        <div className={styles.loadingSpinner}>
          <Spinner color="primary" />
        </div>
      );
    }
    // Show staff list if data is loaded
    return (
      <>
        {this.sortStaff(this.props.session.staffRegistered).map((staff, index) => {
          return (
            <div id={staff.userId} key={index} className={styles.staffRow}>
              {staff.name}
            </div>
          );
        })}
      </>
    );
  };

  /**
   * Renders details area when there is no session in Sessions list
   */
  renderEmptyDetailsPage = () => {
    return (
      <div className={styles.courseContainer}>
        <div className={styles.coursePageBanner}>
          <div className={styles.headerContainer}>
            <div sm={9} className={styles.sessionTitle}>
              &nbsp;
            </div>
          </div>

          {/* Column Headers */}
          <Row className={styles.colHeaderRow}>
            {/* NOT REGISTERED HEADER */}
            <Col className={`${styles.colBlockHeaderTitle}`}>
              <img
                className={styles.icon}
                src={require('../../../assets/NotRegistered.png')}
                alt="Staff who did not register for the session"
              />
              {'NOT REGISTERED: 0'}
            </Col>

            {/* REGISTERED HEADER */}
            <Col className={`${styles.colBlockHeaderTitle} ${styles.borderLeft}`}>
              <img
                className={styles.icon}
                src={require('../../../assets/Registered.png')}
                alt="Staff who registered for the session"
              />
              {'REGISTERED: 0'}
            </Col>
          </Row>

          {/* Column Bodies */}
          <Row className={`${styles.listArea}`}>
            {/* NOT REGISTERED COLUMN */}
            <Col className={styles.listContainer}>
              {this.props.loading && (
                <div className={styles.loadingSpinner}>
                  <Spinner color="primary" />
                </div>
              )}
            </Col>

            {/* REGISTERED COLUMN */}
            <Col className={`${styles.listContainer} ${styles.borderLeft}`}>
              {this.props.loading && (
                <div className={styles.loadingSpinner}>
                  <Spinner color="primary" />
                </div>
              )}
            </Col>
          </Row>

          {/* 'Remind' or 'Attend' button*/}
          <Row className={styles.buttonRow}>
            <Col className={styles.actionButtonContainer}>
              <DigiButton
                id="remind-button"
                className={styles.actionBtn}
                disabled={true}
                aria-label="Click the Remind function to remind your staff to register for the upcoming session"
              >
                Remind
              </DigiButton>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    );
  };

  /**
   * Renders email panel
   */
  renderEmailPanel = () => {
    return (
      <EmailContent
        actionBtnText="Remind"
        pageId={this.props.pageId}
        staffCount={
          this.state.isRecommend
            ? this.selectedRecommendationStaffCount()
            : this.selectedReminderStaffCount()
        }
        courseDetails={this.props.session.courseDetails}
        onCloseClick={this.onCloseEmailContainer}
        sendEmails={
          this.state.isRecommend ? this.sendRecommendEmails : this.sendReminderEmails
        }
        isRecommend={this.state.isRecommend}
      />
    );
  };

  /**
   * Renders Remind button
   */
  renderRemindBtn = () => {
    return (
      <DigiButton
        id={'remind-button'}
        className={styles.actionBtn}
        disabled={!this.state.enableRemindBtn}
        aria-label="Click the Remind function to remind your staff to register for the upcoming session"
        onClick={() => {
          this.setState({
            showEmailContent: true,
            isRecommend: false
          });
        }}
      >
        Remind
      </DigiButton>
    );
  };

  /**
   * Renders Recommend button
   */
  renderRecommendBtn = () => {
    return (
      <RecommendButton
        disabled={!this.state.enableRecommendBtn}
        courseId={this.props.session.courseDetails.courseDetailsId}
        onClick={() => {
          this.setState({
            showEmailContent: true,
            isRecommend: true
          });
        }}
      />
    );
  };

  render() {
    //   No session in Sessions list
    if (this.isSessionNull()) return this.renderEmptyDetailsPage();

    // Show Email Content
    if (this.state.showEmailContent) return this.renderEmailPanel();

    // There is a session selected from the Sessions list
    return (
      <div className={styles.courseContainer}>
        <div className={styles.coursePageBanner}>
          <div className={styles.headerContainer}>
            {/* If loading, make title's font color same as background to make it look invisible */}
            <div
              sm={9}
              className={
                this.props.loading
                  ? `${styles.sessionTitle} ${styles.darkBlueColor}`
                  : styles.sessionTitle
              }
            >
              {this.props.session.courseDetails.courseName}
            </div>
            <span className={styles.mandatoryContainer}>
              {!this.props.loading &&
                this.props.session.courseDetails.course.mandatory && (
                  <MandatoryCircle />
                )}
            </span>
          </div>

          {/* Column Headers */}
          <Row className={styles.colHeaderRow}>
            {/* NOT REGISTERED HEADER */}
            <Col className={styles.colBlockHeaderTitle}>
              <img
                className={styles.icon}
                src={require('../../../assets/NotRegistered.png')}
                alt="Staff who did not register for the session"
              />
              {'NOT REGISTERED:'}
              {this.props.loading
                ? ' 0'
                : ` ${this.props.notRegisteredStaffList.length}`}
            </Col>

            {/* NOT REGISTERED (RECOMMENDED) HEADER */}
            {this.props.session.staffRecommendedCount > 0 && (
              <Col className={`${styles.colFlexHeaderTitle} ${styles.borderLeft}`}>
                <img
                  className={styles.icon}
                  src={require('../../../assets/RecIcon.png')}
                  alt="Staff who did not register for the session"
                />
                <div>
                  {'NOT REGISTERED :'}
                  {this.props.loading
                    ? ' 0'
                    : ` ${this.props.recommendedButNotRegisteredStaffList.length}`}
                  <br aria-hidden="true" />
                  {'(RECOMMENDED)'}
                </div>
                {this.state.loadingSent && (
                  <Spinner
                    size="sm"
                    color="primary"
                    className={styles.sentSpinner}
                  />
                )}
              </Col>
            )}

            {/* REGISTERED HEADER */}
            <Col className={`${styles.colBlockHeaderTitle} ${styles.borderLeft}`}>
              <img
                className={styles.icon}
                src={require('../../../assets/Registered.png')}
                alt="Staff who registered for the session"
              />
              {'REGISTERED:'}
              {this.props.loading
                ? ' 0'
                : ` ${this.props.session.staffRegistered.length}`}
            </Col>
          </Row>

          {/* Column Bodies */}
          <Row className={styles.listArea}>
            {/* NOT REGISTERED COLUMN */}
            <Col className={styles.listContainer}>{this.getNotRegistered()}</Col>

            {/* NOT REGISTERED (RECOMMENDED) COLUMN */}
            {this.props.session.staffRecommendedCount > 0 && (
              <Col className={`${styles.listContainer} ${styles.borderLeft}`}>
                {this.getRecommendedButNotRegistered()}
              </Col>
            )}

            {/* REGISTERED COLUMN */}
            <Col className={`${styles.listContainer} ${styles.borderLeft}`}>
              {this.getRegistered()}
            </Col>
          </Row>

          {/* 'Remind' button*/}

          {this.props.session.staffRecommendedCount > 0 ? (
            <Row className={styles.buttonRow}>
              <Col className={styles.actionButtonContainer}>
                {this.renderRecommendBtn()}
              </Col>
              <Col className={styles.actionButtonContainer}>
                {this.renderRemindBtn()}
              </Col>
              <Col></Col>
            </Row>
          ) : (
            <Row className={styles.buttonRow}>
              <Col className={styles.actionButtonContainer}>
                {this.renderRemindBtn()}
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

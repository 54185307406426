import React from 'react';
import ReactGA from 'react-ga';
import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button
} from 'reactstrap';
import classnames from 'classnames';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import JobShadowing from './JobShadowing';
import JobShadowingAddModify from './JobShadowingAddModify';
import AdminHomeTabs from '../AdminHomeTabs';
import style from './JobShadowing.module.css';
import { AdminTabs } from '../../../../enums/admintabs.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadCsv } from '../../../../CONSTANTS';
export default class AdminJobShadowingTab extends React.Component {
  constructor() {
    super();
    this.abortController = new AbortController();

    this.state = {
      activeTab: 0,
      all: [],
      searched: [],
      searchText: ''
    };
    this.toggle = this.toggle.bind(this);
    this.loadContent = this.loadContent.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.formRef = React.createRef();
    this.loadContent(true);
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  //search function filters our the all user list from db
  changeHandler(e) {
    let searchedItems;
    if (e) {
      this.setState({ searchText: e.target.value });
      searchedItems = this.state.all.filter(
        (el) =>
          el.shadowUser.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
          -1
      );
    } else {
      searchedItems = this.state.all.filter(
        (el) =>
          el.shadowUser.name
            .toLowerCase()
            .indexOf(this.state.searchText.toLowerCase()) !== -1
      );
    }
    this.setState({ searched: searchedItems });
  }
  //loads all the share it record from the db
  loadContent(doToast = false) {
    let toastId = null;
    if (doToast) {
      toastId = toastRequest.notify('Loading Share IT records.');
    }
    if (this.state.activeTab === 1) this.setState({ activeTab: 0 });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/ShareIT', {
      signal: this.abortController.signal
    })
      .then((response) => {
        if (!response.ok && doToast) {
          toast.update(toastId, {
            render:
              'An error occurred when attempting to load the Share IT Records. Please try again later.',
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else if (!response.ok) {
          toast(
            'An error occurred when attempting to load the Share IT Records. Please try again later.',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          return response.json();
        }
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast.update(toastId, {
            render: 'Error loading Share IT Records.',
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          this.setState({ all: myJson.data, searched: myJson.data });
          this.changeHandler();
          toast.update(toastId, {
            render: 'Share IT records loaded successfully.',
            type: toast.TYPE.SUCCESS,
            autoClose: 2000
          });
        }
      })
      .catch((e) => {
        toast.dismiss(toastId);
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  //chooses between VIEW and ADD tab
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  //download csv file for share IT
  downloadShareITPayload = () => {
    if (!this.state.all || !this.state.searched) {
      return;
    }
    let filename = `AdminShareIT_Data.csv`;
    let csv =
      'Id,Shadow email address,Shadow role,Shadow Branch,Shadow Report to,Host email address,Host role,Host Branch,Host Report to,Share IT Role,Start Date,End Date,Timeline, Notes\n';
    this.state.searched.forEach((p) => {
      //split the branch names having "," because csv formatting
      const shadowBranch = p.shadowBranch.replace(',', ''); //above mentioned reason
      //sql date to mm/dd/yyyy
      const startDate = this.sqlToJsDate(p.startDate);
      const endDate = this.sqlToJsDate(p.endDate);
      //each share it host having each row in csv
      p.shareItHost.forEach((host) => {
        const hostBranch = host.hostBranch.replace(',', ''); // csv formatting
        csv += `${p.shadowId},${p.shadowUser.email},${p.shadowCurrentRole},${shadowBranch},${p.shadowManager.email},${host.hostUser.email},${host.hostCurrentRole},${hostBranch},${host.hostManager.email},${p.shareITRole},${startDate},${endDate},${p.timeline.timelineDesc}, ${p.notes}\n`;
      });
    });
    //downloads file using blob
    downloadCsv(filename, csv);
  };

  //transforming the sql db date to the js date format
  sqlToJsDate(sqlDate) {
    //splitting the array of date in yyyy-mm-ddT00:00:00 format to mm/dd/yyyy format for csv
    var sqlDateArr1 = sqlDate.split('-');
    var sYear = sqlDateArr1[0];
    var sMonth = sqlDateArr1[1];
    var sqlDateArr2 = sqlDateArr1[2].split('T');
    var sDay = sqlDateArr2[0];
    return sMonth + '/' + sDay + '/' + sYear;
  }
  render() {
    return (
      <div className={style.adminRoot}>
        <AdminHomeTabs
          activetab={AdminTabs.ShareIT}
          switchtab={this.props.history.push}
        />
        <Container>
          <Row>
            <Col>
              <Nav tabs className={style.tabMargin}>
                {/*VIEW tab*/}
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 0 })}
                    id="viewTab"
                    onClick={() => {
                      this.toggle(0);
                    }}
                  >
                    VIEW
                  </NavLink>
                </NavItem>
                {/*ADD tab*/}
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 1 })}
                    id="addTab"
                    onClick={() => {
                      this.toggle(1);
                    }}
                  >
                    ADD
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={0}>
                  {/*TAB 1 SEARCH BAR*/}
                  <InputGroup>
                    <Label for="JobShadowingSearchBar" className="show-for-sr">
                      Job Shadowing search bar
                    </Label>
                    <Input
                      placeholder="Enter a Shadow's name"
                      id={'JobShadowingSearchBar'}
                      onChange={this.changeHandler}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>Search</InputGroupText>
                    </InputGroupAddon>
                    {/*csv button disabled if the none in searched*/}
                    <Button
                      disabled={!this.state.searched.length}
                      className={`${style.coursedisplaycsvbutton}`}
                      id={`download-csv-ShareIt`}
                      color="secondary"
                      size={'sm'}
                      outline
                      onClick={this.downloadShareITPayload}
                    >
                      CSV &nbsp;
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </InputGroup>
                  {this.state.searched.map((record) => (
                    <JobShadowing
                      manualReload={this.loadContent}
                      payload={record}
                      key={'Id-' + record.shadowId}
                    />
                  ))}
                </TabPane>
                <TabPane tabId={1}>
                  <JobShadowingAddModify
                    modModal={false}
                    ref={this.formRef}
                    manualReload={this.loadContent}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

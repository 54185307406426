import React from 'react';
import styles from './RecommendButton.module.css';
import AccessibleButton from '../accessibleButton/AccessibleButton';

const RecommendButton = ({ onClick, selected, disabled, area, courseId }) => {
  return (
    <>
      <AccessibleButton
        id={`recommend-button-${area || 'default'}-${courseId}`}
        onClick={onClick}
        disabled={disabled}
        aria-label="Click the Recommend function to recommend a session to your staff"
      >
        <div
          className={[
            styles.recommendButton,
            selected ? styles.selected : '',
            disabled ? styles.disabled : ''
          ].join(' ')}
        >
          <span className={styles.textSpan}>Recommend</span>
        </div>
      </AccessibleButton>
    </>
  );
};

export default RecommendButton;

import React from 'react';
import styles from './CancelButton.module.css';
import AccessibleButton from '../../../../../../components/accessibleButton/AccessibleButton';

const CancelButton = (props) => {
  return (
    <AccessibleButton {...props}>
      <span aria-hidden="true" className={styles.cancelIndicator}>
        ×
      </span>{' '}
      <span className={styles.cancelLabel}>CANCEL</span>
    </AccessibleButton>
  );
};

export default CancelButton;

import ReactGA from 'react-ga';

export const gaExcept = (description) => {
  try {
    ReactGA.exception({
      description: description.toString()
    });
  } catch {
    // Just as a last resort, don't want this to fail because it's frequently in catch blocks.
  }
};

export const urlToBase64 = async (url) => {
  const response = await fetch(url);
  const imgBlob = await response.blob();
  return new Promise((resolve, reject) => {
    try {
      var reader = new FileReader();
      reader.readAsDataURL(imgBlob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    } catch (e) {
      reject(e);
    }
  });
};

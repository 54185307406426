import React from 'react';
import styles from './SessionDetails.module.css';
import { Link } from 'react-router-dom';

import { Row, Col, Spinner } from 'reactstrap';
import MandatoryCircle from '../mandatoryCircle/mandatoryCircle';
import CloseButton from '../closeButton/CloseButton';
import PropTypes from 'prop-types';
import {
  SLType,
  readableDuration
} from '../../pages/sessions/components/SessionUtils';
import { NonLtcIcon } from '../nonLtcIcon/nonLtcIcon';

export default class SessionDetails extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    sessionWithDetails: PropTypes.object,
    closeable: PropTypes.bool,
    type: PropTypes.any,
    registered: PropTypes.bool,
    animateMaterials: PropTypes.bool,
    onMaterialsAnimated: PropTypes.func
  };

  static defaultProps = {
    visible: true,
    sessionWithDetails: null, // Session with more details (does not contain whether you are registered or not)
    closeable: true,
    registered: false,
    animateMaterials: false,
    onMaterialsAnimated: () => {}
  };

  render() {
    if (!this.props.visible) {
      return <></>;
    } else if (!this.props.sessionWithDetails) {
      return (
        <div className={styles.spinnerHolder}>
          <Spinner color="primary" />
        </div>
      );
    } else {
      const courseStart = this.props.sessionWithDetails.start;
      const courseEnd = this.props.sessionWithDetails.end;
      let presenterData = '..';

      if (
        this.props.sessionWithDetails.presenters &&
        this.props.sessionWithDetails.presenters.length > 1
      ) {
        presenterData = this.props.sessionWithDetails.presenters.map(
          (people, index) => [
            index < this.props.sessionWithDetails.presenters.length - 1 ? (
              <Link to={'/presenters/' + people.presenterId} key={index}>
                {people.name},{' '}
              </Link>
            ) : (
              <Link to={'/presenters/' + people.presenterId} key={index}>
                {people.name}{' '}
              </Link>
            )
          ]
        );
      } else if (
        this.props.sessionWithDetails.presenters &&
        this.props.sessionWithDetails.presenters.length === 1
      ) {
        presenterData = (
          <Link
            to={
              '/presenters/' +
              this.props.sessionWithDetails.presenters[0].presenterId
            }
          >
            {this.props.sessionWithDetails.presenters[0].name}
          </Link>
        );
        if (
          this.props.sessionWithDetails.presenters[0].name.toUpperCase() === 'TBD'
        ) {
          presenterData = 'No one';
        }
      } else {
        presenterData = 'No one';
      }

      return (
        <div className={[styles.courseContainer, this.props.className].join(' ')}>
          <div className={styles.coursePageBanner}>
            <div
              className={[
                styles.headerContainer,
                this.props.closeable ? '' : styles.rightP36
              ].join(' ')}
            >
              <div sm={9} className={styles.sessionTitle}>
                {this.props.sessionWithDetails.courseName}
              </div>
              {this.props.sessionWithDetails.mandatory === true && (
                <MandatoryCircle />
              )}
              {this.props.closeable && (
                <CloseButton
                  className={styles.closeButton}
                  onClick={this.props.closeButtonClicked}
                ></CloseButton>
              )}
            </div>
            <div className={styles.pillarContainer}>
              <b>Pillar: {this.props.sessionWithDetails.domain.name}</b>
            </div>
            <Row className={styles.courseInfoContainer}>
              <Col className={styles.logoHolder}>
                <img
                  className={styles.logoHolder}
                  src={this.props.sessionWithDetails.sessionType.imageLink}
                  alt={this.props.sessionWithDetails.sessionType.name + ' banner'}
                />
              </Col>
              <Col>
                <p className={styles.sessionType}>
                  <b>{this.props.sessionWithDetails.sessionType.name}</b>
                </p>
                <p className={styles.courseSubtext}>
                  <b>
                    {this.props.type !== SLType.ON_DEMAND && 'Date: '}
                  </b>
                  {this.props.type !== SLType.ON_DEMAND && courseStart.toLocaleString([], {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  })}
                </p>
                <p className={styles.courseSubtext}>
                  {this.props.type === SLType.ON_DEMAND ? (
                    <>
                      <b>Duration: </b>
                      {readableDuration(
                        this.props.sessionWithDetails.courseDuration
                      )}
                    </>
                  ) : (
                    <>
                      <b>Time: </b>
                      {courseStart.toLocaleTimeString([], {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}{' '}
                      -{' '}
                      {courseEnd.toLocaleTimeString([], {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                    </>
                  )}
                </p>
                {this.props.type !== SLType.ON_DEMAND && (
                  <p className={styles.courseSubtext}>
                    <b>Location: </b>
                    {this.props.sessionWithDetails.locations.length > 0
                      ? this.props.sessionWithDetails.locations[0].name
                      : '(No location)'}
                  </p>
                )}
              </Col>
              <Col xs="3" className={styles['icon-container']}>
                {!!this.props.sessionWithDetails.externalUrl && (
                  <NonLtcIcon detailsPage />
                )}
              </Col>
            </Row>
            <div className={styles.presenterContainer}>
              <p className={styles.courseSubtext}>
                <b>
                  {this.props.sessionWithDetails.presenters.length > 1
                    ? 'Presenters: '
                    : 'Presenter: '}
                </b>
                {presenterData}
              </p>

              {(this.props.type === SLType.PAST ||
                this.props.type === SLType.ON_DEMAND ) &&
                this.props.sessionWithDetails.materials[0] && (
                  <div
                    className={[
                      styles.courseSubtext,
                      this.props.animateMaterials ? styles.highlightTransition : ''
                    ].join(' ')}
                    onAnimationEnd={() => {
                      this.props.onMaterialsAnimated();
                    }}
                  >
                    <b>Session Materials: </b>
                    {this.props.sessionWithDetails.materials.map(
                      (material, index) => {
                        return (
                          <div className={styles.materialHolder} key={index}>
                            <a
                              href={
                                material.link.toLowerCase().startsWith('https://') ||
                                material.link.toLowerCase().startsWith('http://')
                                  ? material.link
                                  : `https://${material.link}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className={styles.material}>{material.name}</div>
                            </a>
                            {this.props.sessionWithDetails.materials.length - 1 !==
                              index && <hr />}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
            </div>
            {this.props.sessionWithDetails.mandatory &&
              this.props.sessionWithDetails.format.slug === SLType.ON_DEMAND &&
              !!this.props.sessionWithDetails.externalUrl && (
                <div className={styles.presenterContainer}>
                  <div
                    className={
                      this.props.sessionWithDetails.participants.length > 0 &&
                      this.props.sessionWithDetails.participants[0].attended
                        ? styles.courseSubtext
                        : styles.courseSubtextOutstanding
                    }
                  >
                    <b>Link to training session: </b>
                    <a
                      href={
                        this.props.sessionWithDetails.externalUrl
                          .toLowerCase()
                          .startsWith('https://') ||
                        this.props.sessionWithDetails.externalUrl
                          .toLowerCase()
                          .startsWith('http://')
                          ? this.props.sessionWithDetails.externalUrl
                          : `https://${this.props.sessionWithDetails.externalUrl}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        className={
                          this.props.sessionWithDetails.participants.length > 0 &&
                          this.props.sessionWithDetails.participants[0].attended
                            ? styles.linkCompleted
                            : styles.linkOutstanding
                        }
                      >
                        {this.props.sessionWithDetails.courseName}
                      </div>
                    </a>
                  </div>
                </div>
              )}
            <div className={styles.presenterContainer}>
              <p className={styles.courseSubtext}>
                <b>Session Description:</b>
              </p>
            </div>
            <div
              className={styles.sessionDesc}
              dangerouslySetInnerHTML={{
                __html: this.props.sessionWithDetails.details
              }}
            />
            {this.props.children}
          </div>
        </div>
      );
    }
  }
}

import React from 'react';
import ReactGA from 'react-ga';
import { Col, CustomInput, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { adalApiFetch } from '../../../../adalConfig';
import * as toastRequest from '../../utils/toastRequests';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import { stringToDate, forceCache } from '../../../../CONSTANTS';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './CourseAddModify.module.css';
import { ON_DEMAND_SLUG } from '../../../sessions/components/SessionUtils';
import GlobalContext from '../../../../context/GlobalContext.js';
import dynamicSort from '../../utils/sort';
import * as CONSTANTS from '../../../../CONSTANTS';

const originalState = {
  submitting: false,
  details: '',
  meetingInfo: '',
  modal: false,
  domains: [],
  formats: [],
  allPresenters: [],
  allLocations: [],
  allAttributes: [],
  allReps: [],
  sessionTypes: [],
  allProviders: [],
  courseName: '',
  courseStart: '',
  courseDuration: '',
  startDate: '',
  startTime: '',
  domainId: 'default',
  formatId: 'default',
  presenterId: 'default',
  locations: [],
  attributes: [],
  presenters: [],
  providers: [],
  repId: 'default',
  sessionTypeId: 'default',
  mandatory: false,
  active: true,
  managerOnly: false,
  maximumCapacity: true,
  externalUrl: '',
  disableLocation: false,
  promoteSession: true,
  nonLtc: false
};

export default class CourseAddModify extends React.Component {
  formId = _.uniqueId();

  static propTypes = {
    courseDetails: PropTypes.object,
    modify: PropTypes.bool,
    manualReload: PropTypes.func,
    onDemand: PropTypes.bool,
    parentCourse: PropTypes.object,
    repeating: PropTypes.bool,
    disableEdit: PropTypes.bool,
    isPast: PropTypes.bool
  };

  static defaultProps = {
    isPast: false,
    modify: false,
    onDemand: false,
    repeating: false,
    disableEdit: false,
    promoteSession: true
  };

  state = { ...originalState };

  abortController = new AbortController();

  componentDidMount() {
    this.reload();
  }

  reload = () => {
    this.setState(originalState, () => {
      this.loadContent();
      if (this.props.courseDetails != null) this.parsePayload();
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.courseDetails &&
      props.courseDetails.courseDetailsId !== state.courseDetailsId
    ) {
      return CourseAddModify.parseProps(props);
    }
    return null;
  }

  static parseProps(props) {
    const resultState = {};
    for (const [key, value] of Object.entries({
      ...props.parentCourse,
      ...props.courseDetails
    })) {
      // false is still valid
      if (value !== null && value !== 0) {
        resultState[key] = value;
      }

      if (key === 'courseStart') {
        var start = stringToDate(value);
        var d = `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(
          2,
          '0'
        )}-${String(start.getDate()).padStart(2, '0')}`;
        var t = `${String(start.getHours()).padStart(2, '0')}:${String(
          start.getMinutes()
        ).padStart(2, '0')}`;
        resultState.startDate = d;
        resultState.startTime = t;
      } else if (key === 'attributes') {
        var att = [];
        for (let i = 0; i < value.length; i++) {
          att.push(value[i].attributeId);
        }
        resultState.attributes = att;
      } else if (key === 'locations') {
        var loc = [];
        for (let i = 0; i < value.length; i++) {
          loc.push(value[i].locationId);
        }
        resultState.locations = loc;
      } else if (key === 'presenters') {
        var pres = [];
        for (let i = 0; i < value.length; i++) {
          pres.push(value[i].presenterId);
        }
        resultState.presenters = pres;
      } else if (key === 'providers') {
        var prov = [];
        for (let i = 0; i < value.length; i++) {
          prov.push(value[i].providerId);
        }
        resultState.providers = prov;
      } else if (key === 'details') {
        resultState.details = value;
      } else if (key === 'meetingInfo') {
        resultState.meetingInfo = value;
      } else if (key === 'maximumCapacity') {
        resultState.maximumCapacity = value || false;
      } else if (key === 'externalUrl') {
        resultState.nonLtc = !!value;
      }
    }
    return resultState;
  }

  /**
   * Generates a unique id for form elements. 4
   * (Can't use this component twice on the same page if we use hard-coded ids)
   */
  fid = (prefix) => {
    return prefix + this.formId;
  };

  isOnDemand = () => {
    return (
      (this.state.format && this.state.format.slug === ON_DEMAND_SLUG) ||
      this.props.onDemand
    );
  };

  updateRichText = (details) => {
    this.setState({ details });
  };

  // This function is based on https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/ (Authored by: Olayinka Omole, James Hibbard)
  // Modified by Daniel Sokic on (30 July, 2020)
  compareFormats(a, b) {
    // Use toUpperCase() to ignore character casing
    const formatA = a.name.toUpperCase();
    const formatB = b.name.toUpperCase();
    let comparison = 0;
    if (formatA > formatB) {
      comparison = 1;
    } else if (formatA < formatB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  resetFields = () => {
    this.setState({
      submitting: false,
      details: '',
      meetingInfo: '',
      modal: false,
      courseName: '',
      courseStart: '',
      courseDuration: '',
      startDate: '',
      startTime: '',
      domainId: 'default',
      formatId: this.state.formats[0].formatId,
      repId: 'default',
      attributes: [],
      presenters: [],
      providers: [],
      sessionTypeId: 'default',
      mandatory: false,
      active: true,
      managerOnly: false,
      disableLocation: true,
      promoteSession: true
    });
    this.loadContent();
  };

  fetchFormats = () => {
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Formats', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast(
            'An error occurred when attempting to load the list of Session Formats',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          const sortedData = myJson.data.sort(this.compareFormats);
          const formats = sortedData.filter(
            (value) =>
              (this.isOnDemand() && value.slug === ON_DEMAND_SLUG) ||
              (!this.isOnDemand() && value.slug !== ON_DEMAND_SLUG)
          );
          this.setState({
            formats: formats
          });
          if (this.props.onDemand)
            this.setState({
              formatId: formats[0].formatId
            });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  loadContent = () => {
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Domains', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when attempting to load the list of Domains', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          this.setState({ domains: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });

    this.fetchFormats();
    // Set the default format to virtual meeting.
    if (!this.isOnDemand()) {
      this.setState({ formatId: 2, locations: [9], disableLocation: true });
    }

    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Presenters', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when attempting to load the list of Presenters', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          this.setState({ allPresenters: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });

    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Locations', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when attempting to load the list of Locations', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          this.setState({ allLocations: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });

    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Attributes', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when attempting to load the list of Attributes', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          this.setState({ allAttributes: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });

    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Rep', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast(
            'An error occurred when attempting to load the list of L&D Representatives',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          this.setState({ allReps: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });

    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/SessionTypes',
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast(
            'An error occurred when attempting to load the list of session types',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          this.setState({ sessionTypes: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });

    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Providers', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when attempting to load the list of Providers', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          this.setState({ allProviders: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  genObjectArr = (ar, title) => {
    let result = [];
    for (let i = 0; i < ar.length; i++) {
      if (ar[i] !== 'default') result.push({ [title]: ar[i] });
    }
    return result;
  };

  checkFields = () => {
    let missing = '';
    if (this.state.courseName === '') missing += ', Name';

    if (!this.isOnDemand() && this.state.startDate === '') missing += ', Start Date';

    if (!this.isOnDemand() && this.state.startTime === '') missing += ', Start Time';

    if (this.state.courseDuration === '') missing += ', Duration';

    if (this.state.formatId === 'default') missing += ', Format';

    if (this.state.repId === 'default') missing += ', L+D Rep';

    if (!this.props.modify) {
      if (this.state.domainId === 'default') missing += ', Pillar';

      if (this.state.sessionTypeId === 'default') missing += ', Category';
    }

    if (this.state.nonLtc) {
      if (this.state.providers.length < 1) missing += ', Session Provider';
      const urlExpr = new RegExp(
        /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
      );
      if (
        this.state.externalUrl !== '' &&
        this.state.externalUrl !== 'noExternalUrl' &&
        !this.state.externalUrl.match(urlExpr)
      )
        missing += ', Valid URL for Non-LTC session registration';
    }

    if (missing !== '') {
      toast(`Please provide: "${missing.substring(2)}".`, {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return false;
    }
    return true;
  };

  parseForm = (courseId = null, courseDetailsId = null) => {
    if (!this.checkFields()) {
      return null;
    }

    let t = null;
    if (this.isOnDemand()) {
      t =
        this.props.modify &&
        this.props.courseDetails &&
        this.props.courseDetails.courseStart
          ? stringToDate(this.props.courseDetails.courseStart)
          : new Date();
    } else {
      t = new Date(this.state.startDate + 'T' + this.state.startTime);
    }

    let data = {
      courseName: this.state.courseName,
      courseDuration: this.state.courseDuration,
      details: this.state.details,
      meetingInfo: this.state.meetingInfo,
      locations: this.genObjectArr(this.state.locations, 'locationId'),
      attributes: this.genObjectArr(this.state.attributes, 'attributeId'),
      presenters: this.genObjectArr(this.state.presenters, 'presenterId'),
      providers: this.genObjectArr(this.state.providers, 'providerId'),
      mandatory: this.state.mandatory,
      active: this.state.active,
      managerOnly: this.state.managerOnly,
      promoteSession: this.state.promoteSession,
      envBaseUrl: process.env.REACT_APP_UI_BASE_DOMAIN,
      maximumCapacity: this.state.maximumCapacity ? CONSTANTS.MAX_CAPACITY : null,
      externalUrl: this.state.nonLtc
        ? this.state.externalUrl === ''
          ? 'noExternalUrl'
          : this.state.externalUrl
        : null
    };
    if (this.props.courseDetails && this.props.courseDetails.courseGuid)
      data.courseGuid = this.props.courseDetails.courseGuid;
    if (this.props.courseDetails && this.props.courseDetails.detailsGuid)
      data.detailsGuid = this.props.courseDetails.detailsGuid;
    if (t !== 'default') data.courseStart = t;
    if (this.state.formatId !== 'default') data.formatId = this.state.formatId;
    if (this.state.repId !== 'default') data.repId = this.state.repId;
    if (this.state.sessionTypeId !== 'default')
      data.sessionTypeId = this.state.sessionTypeId;
    if (this.state.domainId !== 'default') data.domainId = this.state.domainId;

    data = this.filterEmpty(data);
    if (courseId !== null) data.courseId = courseId;
    if (courseDetailsId !== null) data.courseDetailsId = courseDetailsId;

    return data;
  };

  addRepeating = () => {
    if (this.state.submitting) return;

    const data = this.parseForm(this.props.courseDetails.courseId);

    if (data === null) return;

    toastRequest.notify('Creating Repeating session');
    this.setState({ submitting: true });
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course/details',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success === true) {
          toastRequest.updateToast(
            myJson.success,
            myJson.error,
            'Repeating Session added successfully!'
          );

          this.props.manualReload();
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  add = () => {
    if (this.state.submitting) return;

    const data = this.parseForm();
    if (data === null) return;

    /**
     * This needs to be a promise chain to support adding the recurring session container and then adding the session
     * details to said container.
     */
    toastRequest.notify('Creating session');
    this.setState({ submitting: true });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success === true) {
          /**
           * This is where we add the session details, thats why we need to add the newly created courseID!
           */
          data.courseId = myJson.data.courseId;
          adalApiFetch(
            fetch,
            process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course/details',
            {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
              signal: this.abortController.signal
            }
          )
            .then((response) => {
              this.setState({ submitting: false });
              return response.json();
            })
            .then((myJson) => {
              toastRequest.updateToast(
                myJson.success,
                myJson.error,
                'Session added successfully!'
              );
              if (this.state.promoteSession && !this.props.onDemand) {
                this.sendPromotions(myJson.data.courseDetailsId);
              }
              if (myJson.success === true) {
                this.props.manualReload();
              }
              this.resetFields();
              window.scrollTo(0, 0);
            });
        } else {
          toastRequest.updateToast(false, myJson.error);
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  sendPromotions = (courseDetailsId) => {
    toastRequest.notify('Promoting session');
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course/promote',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          courseDetailsId: courseDetailsId,
          envBaseUrl: process.env.REACT_APP_UI_BASE_DOMAIN,
          sentOn: new Date(),
          sentByUserId: this.context.user.userId
        }),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (response.ok === true) {
          forceCache();
          return response.json();
        } else {
          throw new Error(`Request failed, Error ${response.status}`);
        }
      })
      .then((myJson) => {
        if (myJson.success === true) {
          toastRequest.updateToast(
            myJson.success,
            myJson.error,
            'New session has been promoted!'
          );
        } else {
          toastRequest.updateToast(
            false,
            'An error occurred while promoting the new session'
          );
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  update = (onSuccess) => {
    if (this.state.submitting) return;

    const dataCourseDetails = this.parseForm(
      this.props.courseDetails.courseId,
      this.props.courseDetails.courseDetailsId
    );

    if (
      !(this.props.courseDetails && this.props.parentCourse) ||
      dataCourseDetails === null
    )
      return;

    const dataCourse = {
      ...this.props.parentCourse,
      mandatory: this.state.mandatory,
      managerOnly: this.state.managerOnly
    };
    if (this.state.domainId !== 'default') dataCourse.domainId = this.state.domainId;
    if (this.state.sessionTypeId !== 'default')
      dataCourse.sessionTypeId = this.state.sessionTypeId;

    /**
     * This needs to be a promise chain to support adding the recurring session container and then adding the session
     * details to said container.
     */
    toastRequest.notify(`Updating session`);
    this.setState({ submitting: true });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataCourse),
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success === true) {
          /**
           * This is where we add the session details, thats why we need to add the newly created courseID!
           */
          adalApiFetch(
            fetch,
            process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course/details',
            {
              method: 'put',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(dataCourseDetails),
              signal: this.abortController.signal
            }
          )
            .then((response) => {
              this.setState({ submitting: false });
              return response.json();
            })
            .then((myJson) => {
              toastRequest.updateToast(
                myJson.success,
                myJson.error,
                `Session Updated successfully!`
              );
              if (onSuccess) onSuccess();
              if (myJson.success === true) {
                this.props.manualReload();
              }
            });
        } else {
          toastRequest.updateToast(false, 'An error occurred.');
        }
      })
      .catch((e) => {
        toastRequest.updateToast(false, 'An error occurred.');
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  filterEmpty = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (value === '') {
        delete obj[key];
      }
    }
    return obj;
  };

  toggleMandatory = () => {
    this.setState({ mandatory: !this.state.mandatory });
  };

  onModify = (e) => {
    // Automatically set location to virtual meeting when the format is set to Virtual Meeting
    const isFormatInput = e.target.name === 'formatId';
    const isVirtual = e.target.value === '2';
    this.setState({
      [e.target.name]: e.target.value,
      ...(isFormatInput && { locations: isVirtual ? [9] : [] }),
      ...(isFormatInput && { disableLocation: isVirtual })
    });
  };

  toggleManagerOnly = () => {
    this.setState({
      managerOnly: !this.state.managerOnly
    });
  };

  toggleMaxCapacity = () => {
    this.setState({
      maximumCapacity: !this.state.maximumCapacity
    });
  };

  onModifyMultiple = (e) => {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ [e.target.name]: value });
  };

  parsePayload() {
    const state = CourseAddModify.parseProps(this.props);
    const isVirtual = state.format.formatId === 2;
    if (isVirtual) {
      state.locations[0] = 9;
    }

    state.disableLocation = isVirtual;
    this.setState(state);
  }

  togglePromote = () => {
    this.setState({ promoteSession: !this.state.promoteSession });
  };

  toggleExternal = () => {
    if (this.state.nonLtc) {
      this.setState({ externalUrl: '' });
    }
    this.setState({ nonLtc: !this.state.nonLtc });
  };

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    return (
      <div>
        <Form onSubmit={(event) => event.preventDefault()} id={'addSessionForm'}>
          {/*TITLE*/}
          <FormGroup>
            <Label for="courseName">Session Name</Label>
            <Input
              type="text"
              value={this.state.courseName}
              name="courseName"
              id={'courseName'}
              onChange={this.onModify}
              disabled={this.props.disableEdit}
            />
          </FormGroup>
          {/*SESSION DETAILS*/}
          <FormGroup>
            <Label for="details">Session Details</Label>
            <ReactQuill
              id={'details'}
              value={this.state.details}
              onChange={(event) => {
                this.setState({ details: event });
              }}
              className={this.props.disableEdit ? styles.digiQuillDisabled : ''}
              disabled={this.props.disableEdit}
            />
          </FormGroup>
          {/*MEETING INFO*/}
          {!this.isOnDemand() && (
            <FormGroup>
              <Label for="meetingInfo">Meeting Info</Label>
              <ReactQuill
                id={'meetingInfo'}
                value={this.state.meetingInfo}
                onChange={(event) => {
                  this.setState({ meetingInfo: event });
                }}
                className={this.props.disableEdit ? styles.digiQuillDisabled : ''}
                disabled={this.props.disableEdit}
              />
            </FormGroup>
          )}
          {/*START DATETIME*/}
          {!this.isOnDemand() && (
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="startDate">Start Date</Label>
                  <Input
                    type="date"
                    value={this.state.startDate}
                    name="startDate"
                    id={'startDate'}
                    onChange={this.onModify}
                    disabled={this.props.disableEdit}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="startTime">Start Time</Label>
                  <Input
                    type="time"
                    value={this.state.startTime}
                    name="startTime"
                    id={'startTime'}
                    onChange={this.onModify}
                    disabled={this.props.disableEdit}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          {this.isOnDemand() && (
            <FormGroup check>
              <CustomInput
                type="switch"
                id={'active'}
                value={this.state.active}
                checked={this.state.active}
                label={this.state.active ? 'Active' : 'Deactivated'}
                onChange={() => {
                  this.setState({ active: !this.state.active });
                }}
                disabled={this.props.disableEdit}
              />
            </FormGroup>
          )}
          {/*DURATION*/}
          <FormGroup>
            <Label for="courseDuration">Duration</Label>
            <Input
              type="number"
              value={this.state.courseDuration}
              name="courseDuration"
              id={'courseDuration'}
              placeholder="# of minutes"
              onChange={this.onModify}
              disabled={this.props.disableEdit}
            />
          </FormGroup>
          {(!this.props.modify || (this.props.modify && !this.props.repeating)) && (
            <>
              {/*DOMAIN*/}
              <FormGroup>
                <Label for="domainSelect">Pillar</Label>
                <Input
                  value={this.state.domainId}
                  type="select"
                  name="domainId"
                  id={'domainSelect'}
                  placeholder={'select domain'}
                  onChange={this.onModify}
                  disabled={
                    this.props.disableEdit ||
                    this.props.repeating ||
                    (this.props.isPast && this.props.modify)
                  }
                >
                  <option disabled value={'default'} key={'default-key'}>
                    select a domain
                  </option>
                  {this.state.domains.map((domain) => (
                    <option
                      value={domain.domainId}
                      id={'domain-' + domain.domainId}
                      key={'domain-' + domain.domainId}
                    >
                      {domain.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              {/*SESSION TYPE*/}
              <FormGroup>
                <Label for="sessionTypeId">Category</Label>
                <Input
                  value={this.state.sessionTypeId}
                  type="select"
                  name="sessionTypeId"
                  id={'sessionTypeId'}
                  onChange={this.onModify}
                  disabled={
                    this.props.disableEdit ||
                    this.props.repeating ||
                    (this.props.isPast && this.props.modify)
                  }
                >
                  <option disabled value={'default'} key={'default-key'}>
                    select a category
                  </option>
                  {this.state.sessionTypes.map((session) => (
                    <option
                      value={session.sessionTypeId}
                      id={'session-' + session.sessionTypeId}
                      key={'session-' + session.sessionTypeId}
                    >
                      {session.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </>
          )}
          {/*FORMAT*/}
          <FormGroup>
            <Label for="formatId">Format</Label>
            <Input
              value={this.state.formatId}
              type="select"
              name="formatId"
              id={'formatId'}
              onChange={this.onModify}
              disabled={this.isOnDemand() || this.props.disableEdit}
            >
              <option disabled value={'default'} key={'default-key'}>
                select a format
              </option>
              {this.state.formats.map((format) => (
                <option
                  value={format.formatId}
                  id={'option-' + format.formatId}
                  key={'option-' + format.formatId}
                >
                  {format.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          {/*PRESENTER*/}
          <FormGroup>
            <Label for="presenters">Select Presenter(s)</Label>
            <Input
              type="select"
              value={this.state.presenters}
              name="presenters"
              id={'presenters'}
              multiple
              onChange={this.onModifyMultiple}
              disabled={this.props.disableEdit}
            >
              {this.state.allPresenters
                .sort(dynamicSort(['name']))
                .map((presenter) => (
                  <option
                    value={presenter.presenterId}
                    id={'presenter-' + presenter.presenterId}
                    key={'presenter-' + presenter.presenterId}
                  >
                    {presenter.name}
                  </option>
                ))}
            </Input>
          </FormGroup>
          {/*LOCATIONS*/}
          <FormGroup>
            <Label for="locationId">Select Locations</Label>
            <Input
              type="select"
              value={this.state.locations}
              name="locations"
              id={'locationId'}
              multiple
              onChange={this.onModifyMultiple}
              disabled={this.props.disableEdit || this.state.disableLocation}
            >
              {this.state.allLocations.map((location) => (
                <option
                  value={location.locationId}
                  id={'location-' + location.locationId}
                  key={'location-' + location.locationId}
                >
                  {location.name}
                </option>
              ))}
            </Input>
          </FormGroup>
                {/*ATTRIBUTES*/}
                {/*
                    <FormGroup>
                        <Label for="attributeId">Select Attributes</Label>
                        <Input
                            type="select"
                            value={this.state.attributes}
                            name="attributes"
                            id={'attributeId'}
                            multiple
                            onChange={this.onModifyMultiple}
                            disabled={this.props.disableEdit}
                        >
                            {this.state.allAttributes.map((attribute) => (
                                <option
                                    value={attribute.attributeId}
                                    id={'attribute-' + attribute.attributeId}
                                    key={'attribute-' + attribute.attributeId}
                                >
                                    {attribute.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
               */ }
          {/*PROVIDERS*/}
          <FormGroup>
            <Label for="providerId">Select Providers</Label>
            <Input
              type="select"
              value={this.state.providers}
              name="providers"
              id={'providerId'}
              multiple
              onChange={this.onModifyMultiple}
              disabled={this.props.disableEdit}
            >
              {this.state.allProviders
                .sort(dynamicSort(['name']))
                .map((provider) => (
                  <option
                    value={provider.providerId}
                    id={'provider-' + provider.providerId}
                    key={'provider-' + provider.providerId}
                  >
                    {provider.name}
                  </option>
                ))}
            </Input>
          </FormGroup>
          {/*REP*/}
          <FormGroup>
            <Label for="repId">L+D Rep</Label>
            <Input
              value={this.state.repId}
              type="select"
              name="repId"
              id={'repId'}
              onChange={this.onModify}
              disabled={this.props.disableEdit}
            >
              <option disabled value={'default'} key={'default-key'}>
                select a L+D Rep
              </option>
              {this.state.allReps.map((rep) => (
                <option
                  value={rep.repId}
                  id={'rep-' + rep.repId}
                  key={'rep-' + rep.repId}
                >
                  {rep.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          {(!this.props.repeating || !this.props.modify) && (
            <>
              <FormGroup check>
                <CustomInput
                  type="switch"
                  id={'mandatory'}
                  value={this.state.mandatory}
                  checked={this.state.mandatory}
                  label="Mandatory"
                  onChange={this.toggleMandatory}
                  disabled={this.props.disableEdit || this.props.repeating}
                />
              </FormGroup>
              <FormGroup check>
                <CustomInput
                  type="switch"
                  id={'managerOnly'}
                  value={this.state.managerOnly}
                  checked={this.state.managerOnly}
                  label="Manager Only"
                  onChange={this.toggleManagerOnly}
                  disabled={this.props.disableEdit}
                />
              </FormGroup>
            </>
          )}
          {!this.props.onDemand && (
            <>
              <FormGroup check>
                <CustomInput
                  type="switch"
                  id={'maximumCapacity'}
                  value={this.state.maximumCapacity}
                  checked={this.state.maximumCapacity}
                  label={`Set max capacity to ${CONSTANTS.MAX_CAPACITY}`}
                  onChange={this.toggleMaxCapacity}
                  disabled={this.props.disableEdit}
                />
              </FormGroup>
            </>
          )}
          {!this.props.repeating ? (
            <>
              <FormGroup check>
                <CustomInput
                  type="switch"
                  id="non-ltc-session"
                  value={this.state.nonLtc}
                  checked={this.state.nonLtc}
                  label="Non-LTC"
                  onChange={this.toggleExternal}
                />
              </FormGroup>
              {/*NON-LTC SESSION*/}
              <FormGroup>
                <Label for="externalUrl">URL for registration (optional)</Label>
                <Input
                  type="text"
                  value={this.state.externalUrl}
                  name="externalUrl"
                  id={'externalUrl'}
                  onChange={this.onModify}
                  disabled={!this.state.nonLtc}
                />
              </FormGroup>
            </>
          ) : (
            <>
              <FormGroup check>
                <CustomInput
                  type="switch"
                  id="non-ltc-session"
                  value={this.state.nonLtc}
                  checked={this.state.nonLtc}
                  label="Non-LTC"
                  onChange={this.toggleExternal}
                  disabled={true} //Repeating session for Non-Ltc can NOT be ltc and vice versa
                  //disabled = {this.props.disableEdit}      //Repeating session for Non-Ltc CAN be ltc and vice versa
                />
              </FormGroup>
              {/*NON-LTC SESSION*/}
              <FormGroup>
                <Label for="externalUrl">URL for registration (optional)</Label>
                <Input
                  type="text"
                  value={this.state.externalUrl}
                  name="externalUrl"
                  id={'externalUrl'}
                  onChange={this.onModify}
                  disabled={this.props.disableEdit || !this.state.nonLtc}
                />
              </FormGroup>
            </>
          )}
          {!this.props.repeating && !this.props.modify && !this.props.onDemand && (
            <FormGroup check className={styles.formButtonRight}>
              <CustomInput
                type="switch"
                id="promote-session"
                value={this.state.promoteSession}
                checked={this.state.promoteSession}
                label="Promote"
                onChange={this.togglePromote}
              />
            </FormGroup>
          )}
        </Form>
      </div>
    );
  }
}
CourseAddModify.contextType = GlobalContext;

import React, { useContext, useState, useRef } from 'react';
import Select from 'react-select';
import CancelButton from './../cancelButton/CancelButton';
import SendButton from './../sendButton/SendButton';
import { Input, Label } from 'reactstrap';
import { adalPost } from './../../../../../../adalConfig';
import { notify, updateToast } from './../../../../../admin/utils/toastRequests';
import GlobalContext from './../../../../../../context/GlobalContext';
import styles from './DeclineContainer.module.css';
import scrollIntoView from 'scroll-into-view-if-needed';

const colors = {
  colorDarkBlue: 'var(--color-dark-blue)',
  colorLightBlue2: 'var(--color-light-blue-2)',
  colorLightBlue: 'var(--color-light-blue)',
  colorPink: 'var(--color-pink)'
};

const selectorStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    borderColor: isFocused ? colors.colorPink : provided.borderColor,
    boxShadow: isFocused ? `0 0 0 1px ${colors.colorPink}` : provided.borderShadow,
    backgroundColor: colors.colorLightBlue2,
    borderRadius: 0,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    ':hover': {
      ...provided[':hover'],
      borderColor: isFocused ? colors.colorPink : provided.borderColor
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.colorDarkBlue
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.colorDarkBlue,
    ':hover': {
      color: colors.colorDarkBlue
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '-1px',
    padding: 0,
    borderRadius: 0,
    border: '1px solid #005EB8',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)'
  }),
  option: (provided, { isSelected }) => {
    let bgColor = provided.backgroundColor;

    if (isSelected) {
      bgColor = colors.colorLightBlue2;
    }

    return {
      ...provided,
      color: styles.colorDarkBlue,
      fontWeight: '600',
      backgroundColor: bgColor,
      ':active': {
        ...provided[':active'],
        backgroundColor: colors.colorLightBlue
      }
    };
  }
};

const DeclineContainer = ({
  notification,
  declineOpenChange,
  scrollToTop,
  selectorRef
}) => {
  const { notificationStatusReasons, reloadNotifications } = useContext(
    GlobalContext
  );
  const [selectedReason, selectedReasonChange] = useState(null);
  const characterLength = 100;
  const [otherReasonDescription, otherReasonDescriptionChange] = useState('');
  const declineRef = useRef(null);

  const decline = async () => {
    notify('Sending response...');
    const { success, error } = await adalPost(`/api/Notifications/decline`, {
      notificationId: notification.id,
      declinedReasonSlug: selectedReason.value,
      declinedReasonOther:
        selectedReason.value === 'other' ? otherReasonDescription : null
    });
    updateToast(
      success,
      error,
      'Thank you! Your response has been sent to your manager.'
    );
    reloadNotifications();
  };

  return (
    <div ref={declineRef} onAnimationEnd={scrollToTop}>
      <Select
        aria-label="Select a reason"
        ref={selectorRef}
        options={notificationStatusReasons.map(({ name, slug }) => ({
          label: name,
          value: slug
        }))}
        value={selectedReason}
        onChange={(value) => {
          selectedReasonChange(value);
        }}
        isSearchable={false}
        autoFocus
        onMenuOpen={() => {
          if (declineRef && declineRef.current) {
            const target = declineRef.current;
            scrollIntoView(target, {
              scrollMode: 'if-needed',
              behavior: 'smooth',
              block: 'start'
            });
          }
        }}
        menuShouldScrollIntoView={false} // This works in weird ways that are just not useful. Custom hack instead.
        placeholder={'Select the reason'}
        clearable={false}
        styles={selectorStyles}
      />
      <div className={styles.declineActions}>
        {selectedReason && selectedReason.value === 'other' && (
          <div className={styles.declineTextAreaContainer}>
            <Label
              for={`otherReasonDescription_${notification.id}`}
              className="show-for-sr"
            >
              Description of other reason
            </Label>
            <Input
              type="textarea"
              placeholder="Enter Text ..."
              maxLength={characterLength}
              rows={5}
              value={otherReasonDescription}
              name="otherReasonDescription"
              id={`otherReasonDescription_${notification.id}`}
              className={styles.declineTextArea}
              onChange={(e) => {
                otherReasonDescriptionChange(e.target.value);
              }}
            />
            <div
              className={[
                styles.characterCount,
                characterLength - otherReasonDescription.length <= 0 &&
                  styles.characterCountZero
              ].join(' ')}
            >
              <div className={styles.charactersLength}>
                {characterLength - otherReasonDescription.length} characters
                remaining
              </div>
            </div>
          </div>
        )}
        <div className={styles.declineButtons}>
          <CancelButton
            onClick={() => {
              declineOpenChange(false);
            }}
          ></CancelButton>
          <SendButton
            disabled={
              !(
                selectedReason &&
                (selectedReason.value !== 'other' ||
                  (selectedReason.value === 'other' &&
                    otherReasonDescription.trim().length > 0))
              )
            }
            onClick={() => {
              decline();
            }}
          ></SendButton>
        </div>
      </div>
    </div>
  );
};

export default DeclineContainer;

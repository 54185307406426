import React from 'react';
import { Table } from 'react-bootstrap';
import styles from './Points.module.css';
export default class PointsTable extends React.Component {
  render() {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    };
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th
              onClick={() => this.props.sortTable('awarded')}
              className={styles.tableHeader}
            >
              Date Awarded &nbsp;
              {this.props.sort.split('-')[0] === 'awarded' && (
                <i
                  className={[
                    styles.arrow,
                    this.props.sort === 'awarded-descending'
                      ? styles.down
                      : styles.up
                  ].join(' ')}
                />
              )}
            </th>
            <th
              onClick={() => this.props.sortTable('email')}
              className={styles.tableHeader}
            >
              Email Address &nbsp;
              {this.props.sort.split('-')[0] === 'email' && (
                <i
                  className={[
                    styles.arrow,
                    this.props.sort === 'email-descending' ? styles.down : styles.up
                  ].join(' ')}
                />
              )}
            </th>
            <th
              onClick={() => this.props.sortTable('type')}
              className={styles.tableHeader}
            >
              Achievement Type &nbsp;
              {this.props.sort.split('-')[0] === 'type' && (
                <i
                  className={[
                    styles.arrow,
                    this.props.sort === 'type-descending' ? styles.down : styles.up
                  ].join(' ')}
                />
              )}
            </th>
            <th
              onClick={() => this.props.sortTable('pillar')}
              className={styles.tableHeader}
            >
              Pillar &nbsp;
              {this.props.sort.split('-')[0] === 'pillar' && (
                <i
                  className={[
                    styles.arrow,
                    this.props.sort === 'pillar-descending' ? styles.down : styles.up
                  ].join(' ')}
                />
              )}
            </th>
            <th
              onClick={() => this.props.sortTable('provider')}
              className={styles.tableHeader}
            >
              Provider Info &nbsp;
              {this.props.sort.split('-')[0] === 'provider' && (
                <i
                  className={[
                    styles.arrow,
                    this.props.sort === 'provider-descending'
                      ? styles.down
                      : styles.up
                  ].join(' ')}
                />
              )}
            </th>
            <th
              onClick={() => this.props.sortTable('session')}
              className={styles.tableHeader}
            >
              Session Name &nbsp;
              {this.props.sort.split('-')[0] === 'session' && (
                <i
                  className={[
                    styles.arrow,
                    this.props.sort === 'session-descending'
                      ? styles.down
                      : styles.up
                  ].join(' ')}
                />
              )}
            </th>
            <th
              onClick={() => this.props.sortTable('points')}
              className={styles.tableHeader}
            >
              Points Value &nbsp;
              {this.props.sort.split('-')[0] === 'points' && (
                <i
                  className={[
                    styles.arrow,
                    this.props.sort === 'points-descending' ? styles.down : styles.up
                  ].join(' ')}
                />
              )}
            </th>
            <th
              onClick={() => this.props.sortTable('badges')}
              className={styles.tableHeader}
            >
              Badge Value &nbsp;
              {this.props.sort.split('-')[0] === 'badges' && (
                <i
                  className={[
                    styles.arrow,
                    this.props.sort === 'badges-descending' ? styles.down : styles.up
                  ].join(' ')}
                />
              )}
            </th>
            <th className={styles.tableHeader}>Detail/Comment &nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {this.props.users.map((user) => {
            let pillar = 'N/A';
            let badgeValue = 'N/A';
            if (user.achievement.achievementType.name === 'Brick') {
              pillar = user.achievement.name;
            }
            if (user.addedBy === '' || user.addedBy == null) {
              user.addedBy = 'None';
            }
            if (user.courseName === '' || user.courseName == null) {
              user.courseName = 'None';
            }
            if (user.achievement.achievementType.name === 'Badge') {
              badgeValue = user.achievement.name;
            }
            return (
              <tr key={`row-${user.id}`}>
                <td>{user.dateAwarded.toLocaleDateString('en-US', options)}</td>
                <td>{user.user.email}</td>
                <td>{user.achievement.achievementType.name}</td>
                <td>{pillar}</td>
                <td>{user.addedBy}</td>
                <td>{user.courseName}</td>
                <td>{user.achievement.pointValue}</td>
                <td>{badgeValue}</td>
                <td>{user.description}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

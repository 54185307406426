import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import sharedStyle from '../AdminAllTabs.module.css';
import DaysOff from './DaysOff';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import { forceCache, stringToDate } from '../../../../CONSTANTS';
import {
  Button,
  Col,
  Input,
  InputGroup,
  Label,
  Row,
  TabPane,
  Form,
  FormGroup
} from 'reactstrap';

const ViewTab = () => {
  const abortController = new AbortController();
  /**
   * Get the default start date (60 days ago)
   * @param {*} name  "start" -> start date
   */
  const defaultDateDisplay = (name) => {
    const defaultDate = new Date();
    if (name === 'startDate') {
      defaultDate.setDate(defaultDate.getDate() - 60);
    }
    return defaultDate.toISOString().substr(0, 10);
  };

  const [startDate, setStartDate] = useState(defaultDateDisplay('startDate')); // 'From' date
  const [endDate, setEndDate] = useState(''); // 'To' date
  const [searchString, setSearchString] = useState(''); // stores the value that the user types as event description
  const [events, setEvents] = useState([]); // this array stores all the events loaded on each call and is used to filter searched sessions
  const [filteredResults, setFilteredResults] = useState([]); // this array contains the events filtered on each search using event description

  // The following useEffect will run only once when the view tab is clicked as it has no variables in the dependency array
  useEffect(() => {
    reloadEvents();
    return () => {
      abortController.abort();
    };

    //The eslint disables the missing dependencies error as this useEffect is ran only once when the user clicks on View Tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // filterEvents is used called whenever user types something in the search bar, and gives the list of events matching the user input
  const filterEvents = () => {
    const searchedItems = events.filter(
      (el) =>
        el.eventDescription.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
    );
    setFilteredResults(searchedItems);
  };

  /**Following useEffect is fired everytime when either searchString or events is changed
   * Context:
   * searchString is changed each time when user types something in the search bar
   * events is changed each times "reloadEvents()" is called because it runs "setEvents()" inside it
   */
  useEffect(() => {
    filterEvents();

    //The eslint disables the missing dependency error for filterEvents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, events]);

  // This function calls the API to fetch all the events and return an array of events
  const getEvents = async () => {
    // use adalFetch to GET to your new API
    let toastId = toastRequest.notify(`Loading Events`);
    var list = [];

    try {
      // If the results are fetched then what to do
      const response = await adalApiFetch(
        fetch,
        process.env.REACT_APP_API_BASE_DOMAIN +
          `/api/DaysOff?startDate=${startDate}&endDate=${endDate}&desc=${searchString}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          },
          signal: abortController.signal
        }
      );

      if (response.ok) forceCache();
      const myJson = await response.json();

      if (!myJson.success && myJson.error) {
        toast.update(toastId, {
          render: `${myJson.error}`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      } else {
        list = myJson.data;
        toast.update(toastId, {
          render: `Events loaded successfully!`,
          type: toast.TYPE.SUCCESS,
          autoClose: 5000
        });
      }
    } catch (e) {
      toast.update(toastId, {
        render: `${e.toString()} `,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
      ReactGA.exception({
        description: e.toString()
      });
    }
    return list;
  };

  // This function is called whenever user changes either of the dates and then clicks on the "update" button
  // And it reloads ViewTab
  const reloadEvents = async () => {
    const list = await getEvents();
    setEvents(list);
    setFilteredResults(list);
  };

  // sortArray returns an array after sorting a given array using its date values
  const sortArray = (mergedList) => {
    return mergedList.sort((a, b) => {
      const getDateString = (item) => {
        return item.endDate;
      };
      const dateStringA = getDateString(a);
      const dateStringB = getDateString(b);
      if (!dateStringA || !dateStringB) return 0;
      const date1 = stringToDate(dateStringA);
      const date2 = stringToDate(dateStringB);
      return date2 - date1;
    });
  };

  return (
    <>
      <Form>
        <Row>
          <Col sm={5}>
            <FormGroup>
              <Label for="startDate"> From </Label>
              <Input
                id={'startDate'}
                name="startDate"
                value={startDate}
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm={5}>
            <FormGroup>
              <Label for="endDate"> To </Label>
              <Input
                id={'endDate'}
                name="endDate"
                value={endDate}
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm={2}>
            <Button
              color="primary"
              size="md"
              id="add-update-button"
              className={`${sharedStyle.submitBtn} ${sharedStyle.submitBtnMargin28}`}
              onClick={reloadEvents}
            >
              Update
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup>
              <Label for="eventSearchBar" className="show-for-sr">
                {' '}
                Event search bar
              </Label>
              <Input
                placeholder="search"
                id={'eventSearchBar'}
                onChange={(e) => setSearchString(e.target.value)}
                value={searchString}
              />
            </InputGroup>
          </Col>
        </Row>

        {/* The TabPane below shows the existing events */}
        <TabPane>
          {filteredResults.length === 0 ? (
            <h3>
              <br />
              No Results Found
            </h3>
          ) : (
            sortArray(filteredResults).map((result) => (
              <DaysOff
                key={`daysOff${result.eventId}`}
                result={result}
                reloadEvents={reloadEvents}
              ></DaysOff>
            ))
          )}
        </TabPane>
      </Form>
      <Row></Row>
    </>
  );
};

export default ViewTab;

export const ON_DEMAND_SLUG = 'on_demand';
export const TEN_POINTS_SLUG = 'grant10points';

export class SLType {
  static PAST = 'past';
  static UPCOMING = 'upcoming';
  static ON_DEMAND = ON_DEMAND_SLUG;
}

// Based on https://stackoverflow.com/a/8212878
export const readableDuration = (durationMinutes) => {
  const numberEnding = (number) => {
    return number > 1 ? 's' : '';
  };

  let temp = Math.floor(durationMinutes);
  let years = Math.floor(temp / 525600);
  if (years) {
    return years + ' year' + numberEnding(years);
  }
  //TODO: Months! Maybe weeks?
  let days = Math.floor((temp %= 31536000) / 1440);
  if (days) {
    return days + ' day' + numberEnding(days);
  }
  let hours = Math.floor((temp %= 86400) / 60);
  if (hours) {
    return hours + ' hour' + numberEnding(hours);
  }
  let minutes = Math.floor((temp %= 3600));
  if (minutes) {
    return minutes + ' minute' + numberEnding(minutes);
  }
  return 'less than a minute'; //'just now' //or other string you like;
};

export const isOnDemand = (session) =>
  session && session.format && session.format.slug === ON_DEMAND_SLUG;

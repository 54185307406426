import React from 'react';
import styles from './SquareCloseButton.module.css';
import AccessibleButton from '../../../../components/accessibleButton/AccessibleButton';

const SquareCloseButton = ({ onClick }) => {
  return (
    <>
      <AccessibleButton className={styles.closeButton} onClick={onClick}>
        <span className="show-for-sr">Close</span>
        <div className={styles.innerButton}></div>
      </AccessibleButton>
    </>
  );
};

export default SquareCloseButton;

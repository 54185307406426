import React from 'react';
import { Col, Container, Row, Spinner, Button } from 'reactstrap';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import styles from './OnDemandOutstanding.module.css';
import * as toastRequest from '../../utils/toastRequests';
import DataTable from '../../../../components/dataTableBase/DataTableBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadCsv } from '../../../../CONSTANTS';

const columns = [
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        wrap: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        wrap: true,
    },
    {
        name: 'Course Name',
        selector: row => row.courseName,
        sortable: true,
        wrap: true,
        grow: 3,
    },
    {
        name: 'Attended',
        selector: row => row.attended === 1 ? 'yes': 'No',
        sortable: true,
    },
];

export default class OnDemandOutstanding extends React.Component {
    constructor() {
        super();
        this.abortController = new AbortController();

        this.state = {
            users: [],
            sort: 'email-descending'
        };
        this.loadContent = this.loadContent.bind(this);

        // this.loadContent(true);
    }

    componentDidMount() {
        this.loadContent(true);
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    async loadContent() {
        const toastId = toast(
            <div>
                <Spinner className="toast-spinner" /> Loading Users...
            </div>,
            {
                type: toast.TYPE.INFO,
                autoClose: false
            }
        );
        try {
            const response = await adalApiFetch(
                fetch,
                process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course/onDemandMandatoryOutStandingCourses',
                { method: 'get', signal: this.abortController.signal }
            );
            let myJson = await response.json();

            this.setState({ users: myJson.data });
            toast.update(toastId, {
                render: 'Users loaded',
                type: toast.TYPE.SUCCESS,
                autoClose: true
            });
        } catch (e) {
            toast.dismiss(toastId);
        }
    }



    sendOutstandingMandatoryEmail = () => {
        if (
            window.confirm(
                'Are you sure you want to send outstanding reminder emails.'
            )
        ) {
            toastRequest.notify('Sending outstanding mandatory course notifications to users');
            adalApiFetch(
                fetch,
                process.env.REACT_APP_API_BASE_DOMAIN + '/api/Notifications/userOutstandingCourses',
                { method: 'get' }
            )
                .then((response) => {
                    return response.json();
                })
                .then((myJson) => {
                    toastRequest.updateToast(myJson.success);
                    this.loadContent();
                });
        }
    };

    //download csv file
    downloadCsv = () => {
        if (!this.state.users) {
            return;
        }
        let filename = `Staff_Mandatory_Outstanding_Courses.csv`;
        let csv =
            'Id,Email,Course Name, Attended\n';
        this.state.users.forEach((p) => {
            csv += `${p.userId},${p.email},${p.courseName},${p.attended}\n`;
        });
        //downloads file using blob
        downloadCsv(filename, csv);
    };

    render() {
        return (
            <div>
                <Button
                    className={`${styles.courseInlineButton}`}
                    id={'delete-button'}
                    size="sm"
                    outline
                    color="danger"
                    onClick={() => this.sendOutstandingMandatoryEmail()}
                > 
                    Send Notifications
                </Button>
                <Container>
                    <Row className={styles.margin}>
                        <Col>
                            <Button
                                className={styles.courseInlineButton}
                                size="sm"
                                outline
                                color="white"
                                onClick={this.downloadCsv}
                            >
                                CSV
                                <FontAwesomeIcon icon={faDownload} />
                            </Button>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <DataTable
                                columns={columns}
                                data={this.state.users}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

        );
    }
}

import React, { useContext, useRef, useState } from 'react';
import ShareButton from '../../../../components/share/ShareButton';
import MandatoryCircle from '../../../../components/mandatoryCircle/mandatoryCircle';
import { stringToDate } from '../../../../CONSTANTS';
import style from './UpcomingSessions.module.css';
import { Link, useHistory } from 'react-router-dom';
import { SLType, isOnDemand } from '../../../sessions/components/SessionUtils';
import RegisterButton from '../../../../components/registerButton/RegisterButton';
import ManagerOnlyTag from '../../../../components/ManagerOnlyTag/ManagerOnlyTag';
import PropTypes from 'prop-types';
import { getCourseLink } from '../../../../utils/courses';
import DigiButton from '../../../../components/digiButton/DigiButton';
import GlobalContext from '../../../../context/GlobalContext';
import AccessibleButton from '../../../../components/accessibleButton/AccessibleButton';
import WithdrawButton from '../../../../components/withdrawButton/WithdrawButton';
import { NonLtcIcon } from '../../../../components/nonLtcIcon/nonLtcIcon';

const UpcomingSessionsListItem = ({
  session,
  animateDict,
  stopAnimation,
  reloadSessions,
  addBadgeToList
}) => {
  const context = useContext(GlobalContext);
  const history = useHistory();
  const itemRef = useRef(null);
  return (
    <div
      ref={itemRef}
      className={[
        style.session,
        animateDict[session.courseDetailsId] ? style.animateBorder : ''
      ].join(' ')}
      onAnimationStart={() => {
        itemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }}
      onAnimationEnd={() => {
        stopAnimation(session.courseDetailsId);
      }}
    >
      <div className={style.contentHolder}>
        <div className={style.titleHolder}>
          <div>
            {session.course && session.course.managerOnly && <ManagerOnlyTag />}
            <Link
              to={getCourseLink(session)}
              id={'courseLink-' + session.courseDetailsId}
            >
              <h3>{session.courseName}</h3>
            </Link>
          </div>
          {!isOnDemand(session) && (
            <div className={style.timeContainer}>
              Date:{' '}
              {stringToDate(session.courseStart).toLocaleString([], {
                day: 'numeric',
                year: 'numeric',
                month: 'long'
              })}
              <br />
              Time:{' '}
              {stringToDate(session.courseStart).toLocaleTimeString([], {
                hour: 'numeric',
                minute: '2-digit'
              })}{' '}
              -{' '}
              {stringToDate(session.courseEnd).toLocaleTimeString([], {
                hour: 'numeric',
                minute: '2-digit'
              })}
            </div>
          )}
        </div>

        <div className={style.mandatoryContainer}>
          {/* {console.log(session)} */}
          {session?.mandatory && <MandatoryCircle />}
          {session?.externalUrl && <NonLtcIcon />}
        </div>
      </div>
      <div style={{ paddingBottom: '7px' }}>
        <div className={style.buttonHolder}>
          {isOnDemand(session) && (
            <DigiButton
              onClick={() => {
                context.toggleAnimateSessionMaterials(true, () => {
                  history.push(getCourseLink(session));
                });
              }}
            >
              Materials
            </DigiButton>
          )}
          {!isOnDemand(session) && (
            <RegisterButton
              area="upcoming-sessions"
              courseDetails={session}
              registered={session.registered}
              registrationId={session.registrationId}
              onUnregistered={reloadSessions}
            />
          )}
          <ShareButton
            session={session}
            type={SLType.UPCOMING}
            addBadgeToList={addBadgeToList}
          />
          {isOnDemand(session) && (
            <WithdrawButton
              courseDetails={session}
              registrationId={session.registrationId}
              onWithdraw={() => {
                reloadSessions('2');
              }}
            />
          )}
        </div>
      </div>
      <hr />
    </div>
  );
};

const UpcomingSessions = ({
    upcoming,
    onDemand,
    addBadgeToList,
    reloadSessions,
    animateDict,
    stopAnimation,
    upcomingSessionsActiveTab
}) => {
    const [activeTab, setActiveTab] = useState(upcomingSessionsActiveTab);
    const [showOnDemandInstruction, setShowOnDemandInstruction] = useState(false);

    return (
        <div className={style.historyContainer}>

      {/*< div className={style.navigator}>*/}
      {/*      <AccessibleButton*/}
      {/*          className={[style.nav, activeTab === '1' ? style.active : ''].join(' ')}*/}
      {/*          onClick={() => setActiveTab('1')}*/}
      {/*      >*/}
      {/*          MY UPCOMING*/}
      {/*      </AccessibleButton>*/}
      {/*      <div className={style.navSpliter}></div>*/}
      {/*      <AccessibleButton*/}
      {/*          className={[style.nav, activeTab === '2' ? style.active : ''].join(' ')}*/}
      {/*          onClick={() => setActiveTab('2')}*/}
      {/*      >*/}
      {/*          MY ON DEMAND*/}
      {/*      </AccessibleButton>*/}
      {/*      </div>*/}

      <div className={style.sessionHolder}>
        {activeTab === '1' ? (
          !(upcoming && upcoming.length > 0) ? (
            <div className={style.noContent}>
              You do not have any registered upcoming session.
              <br />
              <br />
              Please click on "UPCOMING SESSIONS" in the navigation bar to register.
            </div>
          ) : (
            <div className={style.sessionListHolder}>
              {upcoming.map((upcomingSession) => (
                <UpcomingSessionsListItem
                  key={`upcoming_session_${upcomingSession.courseDetailsId}`}
                  session={upcomingSession}
                  addBadgeToList={addBadgeToList}
                  reloadSessions={reloadSessions}
                  animateDict={animateDict}
                  stopAnimation={stopAnimation}
                />
              ))}
            </div>
          )
        ) : !(onDemand && onDemand.length > 0) ? (
          <div className={style.noContent}>
            You do not have any registered on demand session.
            <br />
            <br />
            Please click on "ON DEMAND SESSIONS" in the navigation bar to register.
          </div>
        ) : (
          <div className={style.sessionListHolder}>
            <div className={style.noteContainer}>
              {!showOnDemandInstruction && (
                <div>To close your On Demand session…</div>
              )}
              {showOnDemandInstruction && (
                <div>
                  To close your On Demand session, please complete the quiz in the
                  session material or withdraw from the session.
                </div>
              )}
              <div style={{ textAlign: 'end' }}>
                <button
                  className={style.readButton}
                  onClick={() =>
                    setShowOnDemandInstruction(!showOnDemandInstruction)
                  }
                >
                  {!showOnDemandInstruction && <div>Read More</div>}
                  {showOnDemandInstruction && <div>Read Less</div>}
                </button>
              </div>
            </div>
            {onDemand.map((onDemandSession) => (
              <UpcomingSessionsListItem
                key={`upcoming_session_${onDemandSession.courseDetailsId}`}
                session={onDemandSession}
                addBadgeToList={addBadgeToList}
                reloadSessions={reloadSessions}
                animateDict={animateDict}
                stopAnimation={stopAnimation}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

UpcomingSessions.propTypes = {
  upcoming: PropTypes.array,
  onDemand: PropTypes.array,
  reloadSessions: PropTypes.func,
  addBadgeToList: PropTypes.func
};

UpcomingSessions.defaultProps = {
  reloadSessions: () => {},
  addBadgeToList: () => {}
};

export default UpcomingSessions;

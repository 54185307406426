export const AdminTabs = {
  Sessions: 'sessions',
  SessionConfigurations: 'sessionconfigurations',
  LDCalendar: 'ldcalendar',
  Admins: 'admins',
  ManagerStaff: 'managerstaff',
  Achievements: 'achievements',
  Users: 'users',
  Reports: 'reports',
  ShareIT: 'shareit'
};

import React from 'react';
import ReactGA from 'react-ga';
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import RepAddModify from './RepAddModify';
import Rep from './Rep';
import { SessionTabs } from '../../../../enums/sessiontabs.enum';
import SessionConfigTab from '../SessionConfigTab/SessionConfigTab';
import { AdminTabs } from '../../../../enums/admintabs.enum';
import style from '../AdminAllTabs.module.css';

export default class AdminRepTab extends React.Component {
  constructor() {
    super();
    this.abortController = new AbortController();

    this.state = {
      activeTab: '0',
      all: [],
      searched: [],
      searchText: ''
    };
    this.toggle = this.toggle.bind(this);
    this.loadContent = this.loadContent.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.formRef = React.createRef();
    this.loadContent(true);
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  changeHandler(e) {
    this.setState({ searchText: e.target.value });
    let searchedItems = this.state.all.filter(
      (el) => el.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    );
    this.setState({ searched: searchedItems });
  }

  loadContent(doToast = false) {
    let toastId = null;
    if (doToast) {
      toastId = toastRequest.notify('Loading representative data');
    }
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Rep', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        if (!response.ok && doToast) {
          toast.update(toastId, {
            render:
              'An error occurred when attempting to load the representatives. Please try again later.',
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else if (!response.ok) {
          toast(
            'An error occurred when attempting to load the representatives. Please try again later.',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          return response.json();
        }
      })
      .then((myJson) => {
        if (myJson.success === false) {
          toast.update(toastId, {
            render: 'Error loading reps',
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          this.setState({ all: myJson.data, searched: myJson.data, searchText: '' });
          toast.update(toastId, {
            render: 'Representatives loaded successfully',
            type: toast.TYPE.SUCCESS,
            autoClose: 2000
          });
        }
      })
      .catch((e) => {
        toast.dismiss(toastId);
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <div className={style.adminRoot}>
        <SessionConfigTab
          activeSessiontab={SessionTabs.Reps}
          switchSessiontab={this.props.history.push}
          activetab={AdminTabs.SessionConfigurations}
          switchtab={this.props.history.push}
        />
        <Container>
          <Row>
            <Col>
              <Nav tabs className={style.tabMargin}>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '0' })}
                    id="viewTab"
                    onClick={() => {
                      this.toggle('0');
                    }}
                  >
                    VIEW
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    id="addTab"
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    ADD
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="0">
                  {/*TAB 1 SEARCH BAR*/}
                  <InputGroup>
                    <Label for="repSearchBar" className="show-for-sr">
                      {' '}
                      Representative search bar
                    </Label>
                    <Input
                      placeholder="search"
                      id={'repSearchBar'}
                      onChange={this.changeHandler}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>Search</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {this.state.searched.map((rep) => (
                    <Rep
                      manualReload={this.loadContent}
                      payload={rep}
                      key={rep.repId}
                    />
                  ))}
                </TabPane>
                <TabPane tabId="1">
                  <RepAddModify ref={this.formRef} manualReload={this.loadContent} />
                  <Button
                    color="primary"
                    size="lg"
                    className={style.submitBtn}
                    onClick={() => this.formRef.current.add()}
                  >
                    Submit{' '}
                    <span role={'img'} aria-label={'emoji'}>
                      &#128515;
                    </span>
                  </Button>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

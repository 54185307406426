import React from 'react';
import styles from './DigiHeadingBox.module.css';
import CloseButton from '../closeButton/CloseButton';
import { Spinner } from 'reactstrap';

const DigiHeadingBoxCustomTitleBg = ({
  title,
  children,
  customTitleBg,
  contentHolderClassName,
  wrapperClassName,
  closeable,
  onCloseClick,
  loading,
  titleLoading,
  moveInAnimation
}) => {
  return (
    <div
      className={[
        styles.wrapper,
        wrapperClassName,
        moveInAnimation ? styles.moveInAnimation : ''
      ].join(' ')}
    >
      <div className={styles.holder}>
        <div className={styles.titleHolder}>
          <div className={styles.title}>
            <h2 className={customTitleBg}>{title}</h2>
          </div>
          {titleLoading && <Spinner />}
          {closeable && <CloseButton onClick={onCloseClick}></CloseButton>}
        </div>
        <div className={[styles.contentHolder, contentHolderClassName].join(' ')}>
          {loading ? (
            <div className={styles.spinnerHolder}>
              <Spinner />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

export default DigiHeadingBoxCustomTitleBg;

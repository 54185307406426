import React, { useState, useEffect } from 'react';
import styles from './OfficeFilter.module.css';
import { adalApiFetch } from '../../../adalConfig';
import { toast } from 'react-toastify';
import AccessibleButton from '../../../components/accessibleButton/AccessibleButton';

class OfficeFilterStateStore {
  constructor() {
    this.filterSelection = []; // A trail to what is selected
    this.listeners = [];
    this.title = '';
    this.groupTitle = '';
  }

  // Listener is a callback function that takes filterSelection state
  listenToChanges(listener) {
    this.listeners.push(listener);
  }

  stopListening(listener) {
    const i = this.listeners.indexOf(listener);
    if (i >= 0) this.listeners.splice(i, 1);
  }

  setSelection(selection) {
    this.filterSelection = selection;
    this.listeners.forEach((listener) => listener(selection));
  }
}
const officeFilterStateStore = new OfficeFilterStateStore();
export { officeFilterStateStore };

export default function OfficeFilter(props) {
  const [leadershipTree, setLeadershipTree] = useState();
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    officeFilterStateStore.listenToChanges(setSelection);
    const abortController = new AbortController();
    const fetchLeadership = () => {
      adalApiFetch(
        fetch,
        `${process.env.REACT_APP_API_BASE_DOMAIN}/api/managerdashboard/leadership?startDate=${props.startDate}&endDate=${props.endDate}`,
        {
          method: 'get',
          signal: abortController.signal
        }
      )
        .then((response) => {
          if (!response.ok) {
            toast('Error loading offices and units', {
              type: toast.TYPE.ERROR,
              autoClose: true
            });
          }
          return response.json();
        })
        .then((jsonResponse) => {
          if (!jsonResponse.success) {
            toast('Error loading offices and units', {
              type: toast.TYPE.ERROR,
              autoClose: true
            });
          }
          if (jsonResponse.data.groupName) {
            officeFilterStateStore.title = jsonResponse.data.groupName;
          }
          if (jsonResponse.data.groupType) {
            officeFilterStateStore.groupTitle = `MY ${getGroupText(
              jsonResponse.data.groupType,
              true
            )}`;
          }
          officeFilterStateStore.setSelection([]);
          setLeadershipTree(jsonResponse.data);
        });
    };
    fetchLeadership();
    return () => {
      officeFilterStateStore.stopListening(setSelection);
    };
  }, [props.startDate, props.endDate]);

  const matchSelection = (item, index) => {
    // Does index even exist
    if (selection.length - 1 < index) return false;
    return selection[index] === item;
  };

  const getGroupText = (groupType, headerText = false) => {
    // Get filter text for specific group types
    var groupText = '';
    if (groupType) {
      switch (groupType.toLowerCase()) {
        case 'branch':
          if (headerText) {
            groupText = 'BRANCH';
          } else {
            groupText = 'OFFICES & UNITS';
          }
          break;
        case 'office':
          if (headerText) {
            groupText = 'OFFICE';
          } else {
            groupText = 'UNITS';
          }
          break;
        case 'unit':
          if (headerText) {
            groupText = 'UNIT';
          }
          break;
        default:
          break;
      }
    }
    return groupText;
  };

  const recurseDropdownData = (parents, matching = true) => {
    const parent = parents[parents.length - 1];
    const items = parent.group;
    return items.map((item, i) => {
      const match = matching && matchSelection(item, parents.length);
      const next =
        item.group.length > 0 ? (
          <div className={styles['menu-item__container']}>
            {recurseDropdownData([...parents, item], match)}
          </div>
        ) : null;
      return (
        <>
          <div
            className={`${styles['menu-item']} ${
              match ? styles['menu-item--selected'] : null
            }`}
          >
            {/* Select Box */}
            {!next ? <div className={styles['menu-item__select-box']} /> : null}
            {/* Content */}
            <AccessibleButton
              className={styles['menu-item__content']}
              onClick={() => officeFilterStateStore.setSelection([...parents, item])}
            >
              <div className={styles['menu-item__title']}>{item.groupName}</div>
              <div className={styles['menu-item__leader']}>
                ({`${item.leader.user.firstName} ${item.leader.user.lastName}`})
              </div>
            </AccessibleButton>
            {/* Arrow */}
            {next ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="17.647"
                viewBox="0 0 20 17.647"
              >
                {/* <use href={filterArrow}/> */}
                <path
                  id="Arrow"
                  d="M8.26,3.071a2,2,0,0,1,3.48,0l6.568,11.59a2,2,0,0,1-1.74,2.986H3.432a2,2,0,0,1-1.74-2.986Z"
                  transform="translate(20 17.647) rotate(180)"
                  fill="#00205b"
                />
              </svg>
            ) : null}
            {/* Invisible box to capture hovering */}
            <div
              className={styles['menu-item__hover']}
              onClick={() => {
                officeFilterStateStore.setSelection([...parents, item]);
              }}
            ></div>
            {/* Hook for next child panel */}
            <div
              className={`${styles['menu-item__reference']} ${
                i === 0
                  ? styles['menu-item__reference--top']
                  : styles['menu-item__reference--beside']
              }`}
            >
              {next}
            </div>
          </div>
        </>
      );
    });
  };

  return (
    leadershipTree?.leader?.role.slug !== 'manager' &&
    leadershipTree?.leader?.role.slug != null && (
      <div className={styles['officeFilter-holder']}>
        <div className={styles['officeFilter-holder__flex']}>
          {/* My Branch */}
          <AccessibleButton
            onClick={() => officeFilterStateStore.setSelection([])}
            className={`${styles['entry-button']} ${styles['no-hover']} ${
              styles['accessible-highlight']
            } ${selection.length === 0 ? styles['entry-button--selected'] : null}`}
          >
            MY {leadershipTree?.groupType?.toUpperCase()}
          </AccessibleButton>
          <div className={styles['divider']}></div>
          {/* My Offices and Units */}
          <div className={styles['show-drop-down']}>
            <AccessibleButton
              className={`${styles['entry-button']} ${
                styles['accessible-highlight']
              } ${selection.length > 0 ? styles['entry-button--selected'] : null}`}
            >
              MY {getGroupText(leadershipTree?.groupType)}
            </AccessibleButton>
            {leadershipTree ? (
              <div className={styles['drop-down']}>
                {leadershipTree.group.length === 0 && (
                  <div className={styles['no-manage']}>
                    You were not managing anyone at this time.
                  </div>
                )}
                {recurseDropdownData([leadershipTree])}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  );
}

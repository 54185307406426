import { toast } from 'react-toastify';
import React from 'react';
import { Spinner } from 'reactstrap';

let toastId;

export function notify(text) {
  toastId = toast(
    <div>
      <Spinner className="toast-spinner" /> {text}
    </div>,
    {
      type: toast.TYPE.INFO,
      autoClose: false
    }
  );
  return toastId;
}

export function success(text) {
  toast(<div>{text}</div>, {
    type: toast.TYPE.SUCCESS,
    autoClose: true
  });
}

export function error(text) {
  toast(<div>{text}</div>, {
    type: toast.TYPE.ERROR,
    autoClose: true
  });
}

export function updateToast(
  success,
  errormsg,
  successmsg = 'Done!',
  autoClose = 2000
) {
  toast.update(toastId, {
    render: success ? successmsg : 'Error: ' + errormsg,
    type: success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
    autoClose: autoClose
  });
}

export function dismissToast() {
  toast.dismiss(toastId);
}

import React from 'react';
import ReactGA from 'react-ga';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal
} from 'reactstrap';
import { validateUrl } from '../../../../CONSTANTS';
import { toast } from 'react-toastify';
import { adalApiFetch } from '../../../../adalConfig';
import delIcon from '../../images/garbage.svg';
import checked from '../../images/upload.svg';
import add from '../../images/add.svg';
import styles from './ResourceManagement.module.css';
import PropTypes from 'prop-types';

export default class ResourceManagement extends React.Component {
  static propTypes = {
    manualReload: PropTypes.func,
    payload: PropTypes.object
  };

  static defaultProps = {
    manualReload: () => {}
  };

  abortController = new AbortController();

  state = {
    adding: false,
    editing: null,
    deleteId: null,
    deleteName: null,
    currentName: '',
    currentLink: '',
    uploading: false,
    deleteModal: false
  };
  abortController = new AbortController();

  componentWillUnmount() {
    this.abortController.abort();
  }

  toggleAdding = () => {
    if (this.state.adding) {
      //  Validate URL entry
      if (!validateUrl(this.state.currentLink)) {
        toast('Please enter a valid URL', {
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        return;
      }
      //  Validate NAME entry
      if (this.state.currentName === '') {
        toast('Please enter a NAME', {
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        return;
      }
      // Submit
      const data = {
        courseId: this.props.payload.courseId,
        name: this.state.currentName,
        link: this.state.currentLink
      };
      this.add(data);
    }

    this.setState({
      editing: null,
      adding: !this.state.adding,
      currentName: '',
      currentLink: ''
    });
  };

  add = (data) => {
    this.setState({ uploading: true });
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/Course/${this.props.payload.courseId}/addmaterial`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        this.setState({ uploading: false });
        this.props.manualReload();
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success) {
          toast('Material info added successfully', {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000
          });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  delete = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal
    }));

    const data = {
      editing: null,
      materialId: this.state.deleteId,
      courseId: this.props.payload.courseId
    };
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/Course/${this.props.payload.courseId}/deletematerial`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        this.props.manualReload();
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success) {
          toast(`"${this.state.deleteName}" deleted successfully`, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000
          });
        }
        this.setState({ deleteId: null, deleteName: null });
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  onChangeHandler = (e) => {
    if (e.target.name === 'name') {
      this.setState({ currentName: e.target.value });
    } else if (e.target.name === 'link') {
      this.setState({ currentLink: e.target.value });
    }
  };

  toggleDeleteModal = (id = null, name = null) => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal
    }));
    if (id !== null && name !== null) {
      this.setState({ deleteId: id, deleteName: name });
    }
  };

  render() {
    return (
      <div>
        <Form>
          {this.props.payload &&
            this.props.payload.materials.map((material) => {
              if (!material.deleted) {
                return (
                  <div key={'material-' + material.materialId}>
                    <Card>
                      <CardHeader>
                        {material.name}
                        <Button
                          className={`${styles.courseInlineButton}`}
                          size="sm"
                          outline
                          color="danger"
                          onClick={() =>
                            this.toggleDeleteModal(
                              material.materialId,
                              material.name
                            )
                          }
                        >
                          <img
                            className={`${styles.courseInlineImage}`}
                            src={delIcon}
                            alt={'delete button'}
                          />
                        </Button>
                      </CardHeader>
                      <CardBody>
                        <CardText>
                          <a
                            href={
                              material.link.toLowerCase().startsWith('https://') ||
                              material.link.toLowerCase().startsWith('http://')
                                ? material.link
                                : `https://${material.link}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {material.link}
                          </a>
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                );
              } else {
                return null;
              }
            })}
          {this.state.adding && (
            <div>
              {/*NEW TITLE*/}
              <FormGroup>
                <Label for={'newMaterialName'}>Material Name</Label>
                <Input
                  type="text"
                  value={this.state.currentName}
                  name={'name'}
                  id={'newMaterialName'}
                  onChange={this.onChangeHandler}
                />
              </FormGroup>
              {/*NEW URL*/}
              <FormGroup>
                <Label for={'newMaterialLink'}>Material Link</Label>
                <Input
                  type="text"
                  value={this.state.currentLink}
                  name={'link'}
                  id={'newMaterialLink'}
                  onChange={this.onChangeHandler}
                />
              </FormGroup>
            </div>
          )}
          <Row>
            <Col align={'center'}>
              {this.state.editing === null && (
                <div
                  className={
                    this.state.adding === false
                      ? [styles.roundButton, styles.add].join(' ')
                      : [styles.roundButton, styles.confirm].join(' ')
                  }
                  onClick={this.toggleAdding}
                >
                  {this.state.uploading ? (
                    <Spinner className={styles.spinner} color="light" />
                  ) : (
                    <img
                      src={this.state.adding === false ? add : checked}
                      alt={this.state.adding === false ? 'add' : 'submit'}
                    />
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Form>
        {/*DELETE MODAL*/}
        <Modal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeleteModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>Confirm Deletion</ModalHeader>
          <ModalBody>
            Are you sure you want to delete material "{this.state.deleteName}"? This
            action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              DELETE
            </Button>{' '}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from 'react';
import MandatoryCircle from '../../../components/mandatoryCircle/mandatoryCircle';
import RecommendButton from '../../../components/recommendButton/RecommendButton';
import style from './SessionsList.module.css';
import DigiHeadingBox from '../../../components/DigiHeadingBox.jsx/DigiHeadingBox';
import CustomScroll from '../../../components/customScroll/CustomScroll';
import { stringToDate } from '../../../CONSTANTS.js';
import { Spinner } from 'reactstrap';
import AccessibleButton from '../../../components/accessibleButton/AccessibleButton';
import scrollIntoView from 'scroll-into-view-if-needed';

export default class SessionsList extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.scrollToSession) {
      this.scrollToElement(prevProps);
      this.props.setScroll(false);
    }
  }

  // scroll to selected session
  scrollToElement = (prevProps) => {
    if (prevProps && prevProps.selectedSession !== this.props.selectedSession) {
      let target = document.getElementById(
        this.props.selectedSession.courseDetails.courseDetailsId
      );
      if (target)
        scrollIntoView(target, {
          scrollMode: 'if-needed',
          behavior: 'smooth',
          block: 'start',
          inline: 'start'
        });
    }
  };

  isUpcoming = (course) => {
    return stringToDate(course.courseDetails.courseStart) > Date.now();
  };

  // check to see if the Recommend button should be displayed
  displayRecommend = (course) => {
    if (course) {
      return (
        this.props.pageId !== 'registeredUpcoming-details' &&
        this.isUpcoming(course) &&
        !course.courseDetails.managerOnly &&
        !course.courseDetails.course.mandatory
      );
    }
    return false;
  };

  formatDate = (date) => {
    let formattedDate = stringToDate(date);
    return String(
      formattedDate.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
    );
  };

  formatCourseTime = (course) => {
    let start = stringToDate(course.courseDetails.courseStart);
    let end = stringToDate(course.courseDetails.courseEnd);
    let formattedStart = String(
      start
        .toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit'
        })
        .replace('AM', '')
        .replace('PM', '')
    );
    let formattedEnd = String(
      end.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit'
      })
    );
    return `${formattedStart} - ${formattedEnd}`;
  };

  getAttendedSessionsDetailsMsg = () => {
    return (
      <>
        <p>Your staff did not attend any Session in the selected timeframe.</p>
        <p>Please select a different timeframe to view your staff attendance.</p>
      </>
    );
  };

  /**
   * Message to display when there is no data to show
   */
  getRegisteredUpcomingSessionsDetailsMsg = () => {
    // stat is 0
    if (this.props.noData === 0)
      return (
        <>
          <p>
            Your staff did not register for any Upcoming Session in the selected
            timeframe.
          </p>
          <div>Please try ONE of the following actions:</div>
          <ul>
            <li>
              Visit <u>Upcoming Sessions</u> to recommend sessions to your staff.
            </li>
            <li>
              Select a different future timeframe to view your staff registration to
              Upcoming Sessions.
            </li>
          </ul>
        </>
      );
    // stat is N/A
    return (
      <>
        <p>There is no Upcoming Session in the selected timeframe.</p>
        <p>Please select a future date to review your staff’s Upcoming Sessions.</p>
      </>
    );
  };

  /**
   * Message to display when there is no data to show
   */
  getRecommendedSessionsDetailsMsg = () => {
    return (
      <>
        <p>
          You have not recommended any Session to your staff in the selected
          timeframe.
        </p>
        <div>Please try ONE of the following actions:</div>
        <ul>
          <li>
            Visit <u>Upcoming Sessions</u> to recommend sessions to your staff.
          </li>
          <li>
            Select a different timeframe to review your staff registration to
            Recommended Sessions.
          </li>
        </ul>
      </>
    );
  };

  showSpinner = () => {
    return (
      <DigiHeadingBox
        wrapperClassName={style.sessionListWrapper}
        contentHolderClassName={style.contentHolder}
        title={`Sessions (0)`}
      >
        <CustomScroll className={style.sessionListHolder}>
          <div className={style.sessionList}>
            <div className={style.spinnerHolder}>
              <Spinner color="primary" />
            </div>
          </div>
        </CustomScroll>
      </DigiHeadingBox>
    );
  };

  showMessage = (msg) => {
    return (
      <DigiHeadingBox
        wrapperClassName={style.sessionListWrapper}
        contentHolderClassName={style.contentHolder}
        title={`Sessions (0)`}
      >
        <CustomScroll className={style.sessionListHolder}>
          <div className={style.sessionList}>
            <div className={style.msgHolder}>{msg}</div>
          </div>
        </CustomScroll>
      </DigiHeadingBox>
    );
  };

  getMessage = () => {
    if (this.props.pageId === 'sessionAttendance-details')
      return this.showMessage(this.getAttendedSessionsDetailsMsg());
    else if (this.props.pageId === 'registeredUpcoming-details')
      return this.showMessage(this.getRegisteredUpcomingSessionsDetailsMsg());
    else if (this.props.pageId === 'mandatoryAttendance-details')
      return this.showMessage(this.getMandatoryAttendanceDetailsMsg());
    else if (this.props.pageId === 'recommendedSessions-details')
      return this.showMessage(this.getRecommendedSessionsDetailsMsg());
  };

  render() {
    if (this.props.loading) return this.showSpinner();

    if (this.props.noData === 0 || this.props.noData === 'N/A')
      return this.getMessage();

    return (
      <DigiHeadingBox
        wrapperClassName={style.sessionListWrapper}
        contentHolderClassName={style.contentHolder}
        title={`Sessions (${this.props.sessions.length})`}
      >
        <CustomScroll className={style.sessionListHolder}>
          <div className={style.sessionList}>
            {this.props.sessions.length >= 0 ? (
              this.props.sessions.map((course, index) => {
                return (
                  <div key={index}>
                    <div
                      id={course.courseDetails.courseDetailsId}
                      className={style.session}
                    >
                      <div className={style.contentHolder}>
                        <div className={style.titleHolder}>
                          <div
                            className={
                              this.props.selectedSession === course
                                ? style.titleSelected
                                : style.title
                            }
                          >
                            <AccessibleButton
                              id={`sessions-list-${course.courseDetails.courseDetailsId}`}
                              onClick={() => this.props.selectSession(course)}
                              className={style.clickableTitle}
                            >
                              <h3>{course.courseDetails.courseName}</h3>
                            </AccessibleButton>
                          </div>
                          <div className={style.detailsHolder}>
                            <span>
                              <div
                                className={[
                                  style.domainContainer,
                                  !this.isUpcoming(course) ? style.past : ''
                                ].join(' ')}
                              >
                                {course.courseDetails.course.domain.name}
                              </div>
                              <div
                                className={
                                  !this.isUpcoming(course) ? style.past : ''
                                }
                              >
                                Date:{' '}
                                {this.formatDate(course.courseDetails.courseStart)}
                              </div>
                              {this.isUpcoming(course) && (
                                <div
                                  className={
                                    !this.isUpcoming(course) ? style.past : ''
                                  }
                                >
                                  Time: {this.formatCourseTime(course)}
                                </div>
                              )}
                            </span>
                            {course.courseDetails.course.mandatory && (
                              <span className={style.mandatoryContainer}>
                                <MandatoryCircle />
                              </span>
                            )}
                            {this.displayRecommend(course) && (
                              <RecommendButton
                                area={`sessions-list`}
                                courseId={course.courseDetails.courseDetailsId}
                                onClick={() => {
                                  this.props.openRecommendStaffList(
                                    course.courseDetails.courseDetailsId
                                  );
                                }}
                                selected={
                                  this.props.recommendSession &&
                                  this.props.recommendSession.courseDetails
                                    .courseDetailsId ===
                                    course.courseDetails.courseDetailsId
                                }
                              />
                            )}
                          </div>
                          {this.props.showRecommendedCount &&
                            course.staffRecommendedCount > 0 && (
                              <div className={style.recommendContainer}>
                                Session recommended to {course.staffRecommendedCount}{' '}
                                staff
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })
            ) : (
              <div className={style.spinnerHolder}>
                <Spinner color="primary" />
              </div>
            )}
          </div>
        </CustomScroll>
      </DigiHeadingBox>
    );
  }
}

import React from 'react';
import ReactGA from 'react-ga';
import styles from './statDetails.module.css';
import { Row, Col, Spinner } from 'reactstrap';
import DigiCheckbox from './../../../components/digiCheckbox/DigiCheckbox';
import DigiButton from './../../../components/digiButton/DigiButton';
import EmailContent from './EmailContent';
import { toast } from 'react-toastify';
import * as toastRequest from '../../admin/utils/toastRequests';
import { adalApiFetch } from '../../../adalConfig';
import MandatoryCircle from '../../../components/mandatoryCircle/mandatoryCircle';
import { stringToDate } from '../../../CONSTANTS';
import RecommendButton from '../../../components/recommendButton/RecommendButton';
export default class StatDetails extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.state = {
      selectedEmployees: {}, // Represents staff that manager selected to remind/share with
      selectAll: false,
      enableActionBtn: false,
      actionBtnText:
        this.props.pageId === 'sessionAttendance-details' ? 'Share' : 'Remind',
      showEmailContent: false,
      sent: {}, // Staff of manager with whom session has ALREADY been shared/reminded
      attendanceType: 'staffRegistered',
      loadingSent: false
    };
  }

  componentDidMount() {
    if (this.isSessionNull()) {
      return;
    }
    let upcomingSession = this.isUpcoming();
    this.setState(
      {
        attendanceType: upcomingSession ? 'staffRegistered' : 'staffAttended',
        actionBtnText: upcomingSession ? 'Remind' : 'Share'
      },
      () => {
        this.fetchCheckedStaff();
      }
    );
  }

  componentDidUpdate(prevProps) {
    // If no session in Sessions list, simply return & do not update any state
    if (this.isSessionNull()) {
      return;
    }

    // Update state if another session selected
    if (
      this.isSessionNull(prevProps) ||
      prevProps.session.courseDetails.courseDetailsId !==
        this.props.session.courseDetails.courseDetailsId
    ) {
      let upcomingSession = this.isUpcoming();
      this.setState(
        {
          attendanceType: upcomingSession ? 'staffRegistered' : 'staffAttended',
          actionBtnText: upcomingSession ? 'Remind' : 'Share'
        },
        () => {
          this.fetchCheckedStaff();
        }
      );
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  /**
   * Returns true if selected session is an upcoming session
   */
  isUpcoming = () => {
    return stringToDate(this.props.session.courseDetails.courseStart) > Date.now();
  };

  /**
   * Gets all session-share or session-reminder notifications sent by manager to their staff
   */
  fetchCheckedStaff = () => {
    let endpoint = '';

    switch (this.state.actionBtnText) {
      case 'Share':
        endpoint = `/api/Notifications/share?courseDetailsId=${this.props.session.courseDetails.courseDetailsId}`;
        break;
      case 'Remind':
        endpoint = `/api/Notifications/remind?courseDetailsId=${this.props.session.courseDetails.courseDetailsId}`;
        break;
      default:
        break;
    }

    this.setState({ loadingSent: true });

    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + endpoint, {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          this.setState({ loadingSent: false });
          toast(
            'Something went wrong while fetching sessions that were already shared!',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          let sent = {};
          myJson.data.forEach((sessionShare) => {
            this.props.notRegisteredOrAttendedStaffList.forEach((staff) => {
              if (staff.userId === sessionShare.userId) {
                sent[staff.userId] = true;
              }
            });
          });

          /* If a different session was selected from the Sessions list 
          or 'Share'/'Remind' button was hit, we unselect all checkboxes next to staff name */
          const employees = this.setCheckboxForAllStaff(false, sent);

          // Set state
          this.setState({
            selectedEmployees: employees,
            showEmailContent: false,
            enableActionBtn: false,
            sent: sent,
            selectAll: this.allStaffCheckboxed(sent),
            loadingSent: false
          });
        }
      })
      .catch((e) => {
        this.setState({ loadingSent: false });
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  /**
   * Sorts staff list by their name
   * @param staff: list of staff to be sorted by names
   */
  sortStaff = (staff) => {
    return staff.sort((a, b) => a.name.localeCompare(b.name));
  };

  /**
   * Selects or un-selects checkboxes for all staff
   * @param value: checks all boxes if true, un-checks all boxes if false
   */
  setCheckboxForAllStaff = (value, sent = this.state.sent) => {
    let employees = {};
    this.props.notRegisteredOrAttendedStaffList.forEach((staff) => {
      if (!(staff.userId in sent)) {
        employees[staff.userId] = value;
      } else {
        // if a staff has already been reminded/shared, they cannot be manually selected by manager. Hence false.
        employees[staff.userId] = false;
      }
    });
    return employees;
  };

  /**
   * Checks if checkboxes for all staff are not selected
   */
  noStaffSelected = () => {
    for (const staffUserId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(staffUserId)) {
        if (this.state.selectedEmployees[staffUserId]) {
          return false;
        }
      }
    }
    return true;
  };

  /**
   * Returns true if check box of at least one staff is selected.
   * False if no check box selected.
   */
  shouldEnableActionBtn = () => {
    return !this.noStaffSelected();
  };

  /**
   * Returns true if all of the staff have been reminded (or shared) of a session
   */
  allStaffCheckboxed = (noActionStaff) => {
    return (
      this.props.notRegisteredOrAttendedStaffList.length ===
      Object.keys(noActionStaff).length
    );
  };

  /**
   * Closes the email container.
   */
  onCloseEmailContainer = () => {
    this.setState({
      showEmailContent: false
    });
  };

  /**
   * Sends out an email to the selected staff recommending them a session
   */
  sendRecommendEmails = () => {
    const requestBody = [];
    for (const userId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(userId)) {
        if (this.state.selectedEmployees[userId]) {
          requestBody.push({
            userId: userId,
            courseDetailsId: this.props.session.courseDetails.courseDetailsId,
            envBaseUrl: process.env.REACT_APP_UI_BASE_DOMAIN
          });
        }
      }
    }

    // API call to send emails
    toastRequest.notify('Sending recommendations.');
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Notifications/recommendation/',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success === true) {
          toastRequest.updateToast(
            true,
            null,
            `Session ${this.props.session.courseDetails.courseName} has been recommended to your staff.`
          );

          // Re-load manager dashboard data after recommending a session to maintain consistency between all details pages
          this.setState(
            {
              enableActionBtn: false
            },
            () => {
              this.props.loadContent(
                this.props.session.courseDetails.courseDetailsId
              );
            }
          );
        } else {
          toastRequest.updateToast(false, 'Something went wrong!');
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });

    // Close the email container
    this.onCloseEmailContainer();
  };

  /**
   * Sends out an email to the selected staff sharing or reminding them about a session
   */
  sendReminderEmails = (subject, bodyFirstLine) => {
    const requestBody = [];
    for (const userId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(userId)) {
        if (this.state.selectedEmployees[userId]) {
          requestBody.push({
            userId: userId,
            courseDetailsId: this.props.session.courseDetails.courseDetailsId,
            domain: this.props.session.courseDetails.course.domain.name,
            subject: subject,
            firstLine: bodyFirstLine,
            isMandatory: this.props.session.courseDetails.course.mandatory,
            envBaseUrl: process.env.REACT_APP_UI_BASE_DOMAIN
          });
        }
      }
    }

    let endpoint = '';
    let toastText = '';
    switch (this.state.actionBtnText) {
      case 'Share':
        endpoint = `/api/Notifications/share`;
        toastText = `Session ${this.props.session.courseDetails.courseName} has been shared with your staff`;
        break;
      case 'Remind':
        endpoint = `/api/Notifications/remind`;
        toastText = `Reminder of Session ${this.props.session.courseDetails.courseName} has been sent to your staff`;
        break;
      default:
        break;
    }

    // API call to send emails
    toastRequest.notify('Sending notifications.');
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + endpoint, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody),
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success === true) {
          // Update staff list state after selected staff have been reminded/shared
          this.fetchCheckedStaff();
          toastRequest.updateToast(true, null, toastText);
        } else {
          toastRequest.updateToast(false, 'Something went wrong!');
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });

    // Close the email container
    this.onCloseEmailContainer();
  };

  /**
   * Gets the number of staff manager has selected (i.e. enabled checkbox for)
   */
  selectedStaffCount = () => {
    let count = 0;
    for (const userId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(userId)) {
        if (this.state.selectedEmployees[userId]) count += 1;
      }
    }
    return count;
  };

  /**
   * Checks if prop's session is null
   */
  isSessionNull = (prop = this.props) => {
    return prop.session === null;
  };

  /**
   * Checks if selected session has been shared (or reminded about) with userId
   */
  notificationSent = (userId) => {
    return userId in this.state.sent;
  };

  /**
   * Display the list of staff that registered/attended session
   */
  getRegisteredOrAttended = () => {
    // Show spinner if data is loading
    if (this.props.loading) {
      return (
        <div className={styles.loadingSpinner}>
          <Spinner color="primary" />
        </div>
      );
    }
    // Show staff list if data is loaded
    return (
      <>
        {this.sortStaff(this.props.session[this.state.attendanceType]).map(
          (staff, index) => {
            return (
              <div id={staff.userId} key={index} className={styles.staffRow}>
                {staff.name}
              </div>
            );
          }
        )}
      </>
    );
  };

  /**
   * Display the list of staff who did not register/attend session
   */
  getNotRegisteredOrAttended = () => {
    // Show spinner if data is loading
    if (this.props.loading) {
      return (
        <div className={styles.loadingSpinner}>
          <Spinner color="primary" />
        </div>
      );
    }
    // Show staff list if data is loaded
    return (
      <>
        {this.props.notRegisteredOrAttendedStaffList.length > 0 && (
          <>
            {/* SELECT ALL button to Remind/Share */}
            <div className={`${styles.staffRow} ${styles.selectAll}`}>
              <DigiCheckbox
                checked={!!this.state.selectAll}
                disabled={
                  this.state.loadingSent || this.allStaffCheckboxed(this.state.sent)
                }
                toggle={() => {
                  const employees = this.setCheckboxForAllStaff(
                    !this.state.selectAll
                  );
                  this.setState((prevState) => ({
                    selectedEmployees: employees,
                    selectAll: !prevState.selectAll,
                    enableActionBtn: !prevState.selectAll
                  }));
                }}
                label="Select all"
              ></DigiCheckbox>
            </div>

            {/* Staff list */}
            {this.sortStaff(this.props.notRegisteredOrAttendedStaffList).map(
              (staff, index) => {
                return (
                  <div id={staff.userId} key={index} className={styles.staffRow}>
                    <DigiCheckbox
                      userId={staff.userId}
                      checked={
                        this.notificationSent(staff.userId) ||
                        this.state.selectedEmployees[staff.userId]
                      }
                      disabled={
                        this.state.loadingSent || this.notificationSent(staff.userId)
                      }
                      toggle={() => {
                        this.setState(
                          (prevState) => ({
                            selectedEmployees: {
                              ...prevState.selectedEmployees,
                              [staff.userId]: !prevState.selectedEmployees[
                                staff.userId
                              ]
                            }
                          }),
                          () => {
                            this.setState({
                              enableActionBtn: this.shouldEnableActionBtn()
                            });
                          }
                        );
                      }}
                      label={`${staff.name}`}
                    ></DigiCheckbox>
                  </div>
                );
              }
            )}
          </>
        )}
      </>
    );
  };

  /**
   * Renders details area when there is no session in Sessions list
   */
  renderEmptyDetailsPage = () => {
    return (
      <div className={styles.courseContainer}>
        <div className={styles.coursePageBanner}>
          <div className={styles.headerContainer}>
            <div sm={9} className={styles.sessionTitle}>
              &nbsp;
            </div>
          </div>

          {/* Column Headers */}
          <Row className={styles.colHeaderRow}>
            {/* NOT REGISTERED/ NOT ATTENDED HEADER */}
            <Col className={`${styles.colHeaderTitle}`}>
              <img
                className={styles.icon}
                src={require('../../../assets/NotRegistered.png')}
                alt="Staff who did not register for the session"
              />
              {this.props.pageId === 'sessionAttendance-details'
                ? 'NOT ATTENDED: 0'
                : this.props.pageId === 'mandatoryAttendance-details' ? 'OUTSTANDING: 0' : 'NOT REGISTERED: 0'}
            </Col>

            {/* REGISTERED/ATTENDED HEADER */}
            <Col className={`${styles.colHeaderTitle} ${styles.borderLeft}`}>
              <img
                className={styles.icon}
                src={require('../../../assets/Registered.png')}
                alt="Staff who registered for the session"
              />
              {this.props.pageId === 'sessionAttendance-details'
                ? 'ATTENDED: 0'
                : this.props.pageId === 'mandatoryAttendance-details' ? 'COMPLETED: 0' : 'REGISTERED: 0'}
            </Col>
          </Row>

          {/* Column Bodies */}
          <Row className={`${styles.listArea}`}>
            {/* NOT REGISTERED/ NOT ATTENDED COLUMN */}
            <Col className={styles.listContainer}>
              {this.props.loading && (
                <div className={styles.loadingSpinner}>
                  <Spinner color="primary" />
                </div>
              )}
            </Col>

            {/* REGISTERED/ATTENDED COLUMN */}
            <Col className={`${styles.listContainer} ${styles.borderLeft}`}>
              {this.props.loading && (
                <div className={styles.loadingSpinner}>
                  <Spinner color="primary" />
                </div>
              )}
            </Col>
          </Row>

          {/* 'Remind' or 'Attend' button*/}
          <Row>
            <Col className={styles.actionButtonContainer}>
              <DigiButton
                className={styles.actionBtn}
                disabled={true}
                aria-label={
                  this.state.actionBtnText === 'Share'
                    ? `Click the Share function to share a past session materials with your staff`
                    : `Click the Remind function to remind your staff to register for the upcoming session`
                }
              >
                {this.state.actionBtnText}
              </DigiButton>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    );
  };

  /**
   * Renders email panel
   */
  renderEmailPanel = () => {
    return (
      <EmailContent
        actionBtnText={this.state.actionBtnText}
        pageId={this.props.pageId}
        staffCount={this.selectedStaffCount()}
        courseDetails={this.props.session.courseDetails}
        onCloseClick={this.onCloseEmailContainer}
        sendEmails={
          this.state.isRecommend ? this.sendRecommendEmails : this.sendReminderEmails
        }
        isRecommend={this.state.isRecommend}
      />
    );
  };

  /**
   * Renders Remind/Share button
   */
  renderActionBtn = () => {
    return (
      <DigiButton
        id={`action-button-${this.props.session.courseDetails.courseDetailsId}`}
        className={styles.actionBtn}
        disabled={!this.state.enableActionBtn}
        aria-label={
          this.state.actionBtnText === 'Share'
            ? `Click the Share function to share a past session materials with your staff`
            : `Click the Remind function to remind your staff to register for the upcoming session`
        }
        onClick={() => {
          this.setState({
            showEmailContent: true,
            isRecommend: false
          });
        }}
      >
        {this.state.actionBtnText}
      </DigiButton>
    );
  };

  /**
   * Renders Recommend button
   */
  renderRecommendBtn = () => {
    return (
      <RecommendButton
        disabled={!this.state.enableActionBtn}
        courseId={this.props.session.courseDetails.courseDetailsId}
        onClick={() => {
          this.setState({
            showEmailContent: true,
            isRecommend: true
          });
        }}
      />
    );
  };

  render() {
    //   No session in Sessions list
    if (this.isSessionNull()) return this.renderEmptyDetailsPage();

    // Show Email Content
    if (this.state.showEmailContent) return this.renderEmailPanel();

    // There is a session selected from the Sessions list
    return (
      <div className={styles.courseContainer}>
        <div className={styles.coursePageBanner}>
          <div className={styles.headerContainer}>
            {/* If loading, make title's font color same as background to make it look invisible */}
            <div
              sm={9}
              className={
                this.props.loading
                  ? `${styles.sessionTitle} ${styles.darkBlueColor}`
                  : styles.sessionTitle
              }
            >
              {this.props.session.courseDetails.courseName}
              {this.props.pageId === 'mandatoryAttendance-details' &&
              (this.props.session.futureCourse ||
                !this.props.session.allSessionsInTimeFrame) ? (
                <span className={styles['special-star']}> *</span>
              ) : (
                ''
              )}
            </div>
            <span className={styles.mandatoryContainer}>
              {!this.props.loading &&
                this.props.session.courseDetails.course.mandatory && (
                  <MandatoryCircle />
                )}
            </span>
          </div>

          {/* Column Headers */}
          <Row className={styles.colHeaderRow}>
            <Col className={styles.colHeaderTitle}>
              {this.isUpcoming() ? (
                <>
                  <img
                    className={styles.icon}
                    src={require('../../../assets/NotRegistered.png')}
                    alt="Staff who did not register for the session"
                  />
                  NOT REGISTERED:
                </>
              ) : (
                  this.props.pageId === 'mandatoryAttendance-details' ? 'OUTSTANDING:' : 'NOT ATTENDED:'
              )}
              {this.props.loading
                ? ' 0'
                : ` ${this.props.notRegisteredOrAttendedStaffList.length}`}
              {this.state.loadingSent && (
                <Spinner
                  id="loading-staff"
                  size="sm"
                  color="primary"
                  className={styles.sentSpinner}
                />
              )}
            </Col>

            <Col className={`${styles.colHeaderTitle} ${styles.borderLeft}`}>
              {this.isUpcoming() ? (
                <>
                  <img
                    className={styles.icon}
                    src={require('../../../assets/Registered.png')}
                    alt="Staff who registered for the session"
                  />
                  REGISTERED:
                </>
              ) : (
                   this.props.pageId === 'mandatoryAttendance-details' ? 'COMPLETED:' : 'ATTENDED:'
              )}
              {this.props.loading
                ? ' 0'
                : ` ${this.props.session[this.state.attendanceType].length}`}
            </Col>
          </Row>

          {/* Column Bodies */}
          <Row className={styles.listArea}>
            {/* NOT REGISTERED/ NOT ATTENDED COLUMN */}
            <Col className={styles.listContainer}>
              {this.getNotRegisteredOrAttended()}
            </Col>

            {/* REGISTERED/ATTENDED COLUMN */}
            <Col className={`${styles.listContainer} ${styles.borderLeft}`}>
              {this.getRegisteredOrAttended()}
            </Col>
          </Row>

          {/* 'Remind'/'Attend' or 'Recommend' button*/}
          <Row>
            <Col className={styles.actionButtonContainer}>
              {this.props.pageId === 'registeredUpcoming-details' &&
              !this.props.session.courseDetails.course.mandatory
                ? this.renderRecommendBtn()
                : this.renderActionBtn()}
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    );
  }
}

import React from 'react';
import ReactGA from 'react-ga';
import { Button, Input } from 'reactstrap';
import styles from './UpdateEmployee.module.css';
import { adalApiFetch } from '../../../../../adalConfig';
import { notify, updateToast } from '../../../utils/toastRequests';
import {
  stringDateToUtcString,
  TECHNICAL_ERROR_MESSAGE
} from '../../../../../CONSTANTS';

export default class UpdateEmployee extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: this.defaultDateDisplay(this.props.employee.startDate),
      endDate: this.defaultDateDisplay(this.props.employee.endDate),
      managerId: this.props.managerId,
      recordId: this.props.employee.Id
    };
  }

  componentDidMount = () => {};

  defaultDateDisplay = (oldDate) => {
    if (!oldDate) {
      return '';
    }

    var newDate = new Date(oldDate).toISOString().substr(0, 10);
    return newDate;
  };

  updateEmployeeDates = () => {
    const data = this.parseForm(this.state.managerId);

    notify('Updating employee.');
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + `/api/ManagerEmployee/date`,
      {
        method: 'put',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((myJson) => {
        updateToast(myJson.success, myJson.error, `Employee updated successfully!`);
        if (myJson.success) {
          this.props.updateEmployees();
          this.props.closeAllOtherAccordions();
          this.props.openChange('');
          this.props.managersReload();
        } else {
          this.setState({
            startDate: this.defaultDateDisplay(this.props.employee.startDate),
            endDate: this.defaultDateDisplay(this.props.employee.endDate)
          });
        }
      })
      .catch((e) => {
        updateToast(null, TECHNICAL_ERROR_MESSAGE);
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  parseForm = (managerId = null) => {
    let data = {
      userId: this.props.employee.userId,
      Id: this.state.recordId,
      startDate: stringDateToUtcString(this.state.startDate),
      endDate: stringDateToUtcString(this.state.endDate)
    };

    if (managerId !== null) data.managerId = managerId;

    return data;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div className={styles.employeeContainer}>
        <div className={styles.dateContainer}>
          <div className={styles.startDateContainer}>
            Start Date
            <Input
              //invalid={!this.isValid('startDate')}
              type="date"
              value={this.defaultDateDisplay(this.state.startDate)}
              name="startDate"
              id={'startDate' + this.props.employee.userId}
              onChange={this.handleChange}
            />
          </div>
          <div className={styles.endDateContainer}>
            End Date
            <Input
              type="date"
              value={this.defaultDateDisplay(this.state.endDate)}
              name="endDate"
              id={'endDate' + this.props.employee.userId}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            color="primary"
            size="xs"
            className={styles.buttons}
            onClick={() => this.updateEmployeeDates()}
          >
            Submit
          </Button>
          <Button
            color="danger"
            size="xs"
            className={styles.buttons}
            onClick={() => {
              this.props.openChange('');
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

import React from 'react';
import ReactGA from 'react-ga';
import { Form, FormGroup, Label } from 'reactstrap';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { forceCache, stringToDate } from '../../../../CONSTANTS';
import styles from './ManagerAddModify.module.css';
import { toast } from 'react-toastify';
import Select from 'react-select';
import dynamicSort from '../../utils/sort';

export default class ManagerAddModify extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.parseForm = this.parseForm.bind(this);

    this.state = {
      submitting: false,
      options: [],
      selectedUser: {},
      selectedRole: {},
      activeTab: '0',
      reportingManager: null
    };
    this.originalState = {
      submitting: false,
      selectedUser: {},
      selectedRole: {},
      activeTab: '0',
      reportingManager: null
    };
  }

  componentDidMount() {
    if (this.props.payload != null) this.parsePayload();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  handleChange = (selectedUser) => {
    this.setState({ selectedUser }, () => this.roleOptions());
  };

  handleRoleChange = (selectedRole) => {
    this.setState({ selectedRole });
  };

  parseForm(managerId = null) {
    if (Object.entries(this.state.selectedUser).length === 0) {
      toast('Please select an Email address.', {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return null;
    }

    if (Object.entries(this.state.selectedRole).length === 0) {
      const errorMessage =
        this.state.reportingManager &&
        this.state.reportingManager.role.slug === 'manager'
          ? 'Error: Missing "Role" value. Please remove the existing reporting relationship before adding the new leadership member'
          : 'Please select a role for this recipient.';
      toast(errorMessage, {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return null;
    }

    let data = {
      userId: this.state.selectedUser.userId,
      roleId: this.state.selectedRole.value
    };

    if (managerId !== null) data.managerId = managerId;
    return data;
  }

  add() {
    if (this.state.submitting) return null;

    const data = this.parseForm();
    if (data === null) return null;
    toastRequest.notify('Creating Manager');
    this.setState({ submitting: true });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Managers', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: this.abortController.signal
    })
      .then((response) => {
        if (response.ok) forceCache();
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `${this.state.selectedUser.name} has been saved to the ${this.state.selectedRole.label} role.`
        );
        if (myJson.success === true) {
          this.setState(this.originalState);
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  validateEmployee = (managerEmployee) => {
    const formattedStartDate = stringToDate(managerEmployee.startDate);
    const formattedEndDate = managerEmployee.endDate
      ? stringToDate(managerEmployee.endDate)
      : managerEmployee.endDate;
    const currentDate = new Date();
    return (
      formattedStartDate <= currentDate &&
      (formattedEndDate > currentDate || formattedEndDate == null) &&
      !managerEmployee.deleted
    );
  };

  roleOptions = () => {
    const managers = this.props.allEmployees.filter(
      (m) => this.validateEmployee(m) && this.state.selectedUser.userId === m.userId
    );
    if (managers.length) {
      const reportingManager = this.props.allManagers.filter(
        (m) => m.managerId === managers[0].managerId
      )[0];
      const options = this.props.allRoles
        .filter((role) => role.ranking < reportingManager.role.ranking)
        .map((role) => ({
          label: role.name,
          value: role.roleId
        }));
      this.setState({
        options: options.sort(dynamicSort(['label'])),
        reportingManager: reportingManager
      });
    } else {
      const hasCio = this.props.allManagers
        ? !!this.props.allManagers.filter((m) => m.role.slug === 'cio' && !m.deleted)
            .length
        : false;
      const options = this.props.allRoles
        ? this.props.allRoles
            .filter((role) => (hasCio ? role.slug !== 'cio' : true))
            .map((role) => ({
              label: role.name,
              value: role.roleId
            }))
        : [];
      this.setState({
        options: options.sort(dynamicSort(['label'])),
        reportingManager: this.originalState.reportingManager
      });
    }
  };

  parsePayload() {
    for (const [key, value] of Object.entries(this.props.payload)) {
      if (value != null) {
        this.setState({ [key]: value });
      }
    }
  }

  render() {
    if (
      this.props.activeTab !== this.state.activeTab &&
      Object.keys(this.state.selectedUser).length > 0
    ) {
      this.setState(
        {
          activeTab: this.props.activeTab,
          selectedRole: this.originalState.selectedRole
        },
        () => this.roleOptions()
      );
    }
    return (
      <div>
        <Form onSubmit={(event) => event.preventDefault()}>
          {/*USERNAME*/}
          <FormGroup>
            <Label for="recipientEmail">Manager Email</Label>
            <Select
              value={this.state.selectedUser}
              onChange={this.handleChange}
              inputId={'recipientEmail'}
              options={this.props.allUsers}
              isLoading={this.props.allUsers.length === 0}
            />
            {this.state.reportingManager ? (
              <small>
                <i>
                  Currently reporting to {this.state.reportingManager.user.name},{' '}
                  {this.state.reportingManager.role.name}
                </i>
                {this.state.reportingManager.role.slug === 'manager' ? (
                  <span className={styles.warningText}>
                    <br />
                    NOTE: Please remove the existing reporting relationship before
                    adding the new leadership member
                  </span>
                ) : (
                  ''
                )}
              </small>
            ) : (
              ''
            )}
            <br />
            <Label for="recipientRole">Role</Label>
            <Select
              value={this.state.selectedRole}
              onChange={this.handleRoleChange}
              isDisabled={
                this.state.reportingManager &&
                this.state.reportingManager.role.slug === 'manager'
              }
              inputId={'recipientRole'}
              options={this.state.options}
              isLoading={this.props.allUsers.length === 0}
              className={styles.roleSelect}
            />
          </FormGroup>
        </Form>
      </div>
    );
  }
}

import { Badges } from '../../../enums/badges.enum';

const badgeHtml = (badgeName, imageName = badgeName) => {
  return `<span class="innovatorDialogTextArea"><strong class="innovatorDialogBigFont">Congratulations!</strong><div class="innovatorDialogExtraMargin"></div>You have earned the <strong class="innovatorDialogYellowHightlight">${badgeName} Badge</strong><div class="innovatorBadgeImgArea"><img class="innovatorRegistrationBadge" 
  src="${require(`../../../assets/badges/${imageName}Badge.png`)}"></div></span>`;
};

export const innovatorTutorialMessagesForBadges = [
  {
    tutorialPurposeForBadge: Badges.Presenter,
    text: badgeHtml('Presenter'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.Eureka,
    text: badgeHtml('Eureka'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.Buddy,
    text: badgeHtml('Buddy'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.Registration,
    text: badgeHtml('Registration'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.Survey,
    text: badgeHtml('Survey'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.LtcToolkit,
    text: badgeHtml('LTC Toolkit', 'LTCToolkit'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.LtcTechnology,
    text: badgeHtml('LTC Technology', 'LTCTechnology'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.EmergingTechnology,
    text: badgeHtml('Emerging Technology', 'EmergingTechnology'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.NonTechnical,
    text: badgeHtml('Non Technical', 'NonTech'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.BusinessAcumen,
    text: badgeHtml('Business Acumen', 'BusinessAcumen'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.NonLtc,
    text: badgeHtml('Non-LTC Session', 'NonLtc'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.Shadow,
    text: badgeHtml('Shadow', 'Shadow'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  },
  {
    tutorialPurposeForBadge: Badges.Host,
    text: badgeHtml('Host', 'Host'),
    ref: 'badgeRef',
    isCloseButtonShow: true,
    displayLayout: {
      innovator: 'left'
    }
  }
];

import React from 'react';
import styles from './SendButton.module.css';
import AccessibleButton from '../../../../../../components/accessibleButton/AccessibleButton';

const SendButton = ({ disabled, ...otherProps }) => {
  return (
    <AccessibleButton
      {...otherProps}
      className={[
        styles.sendButton,
        disabled ? styles.inactive : styles.active
      ].join(' ')}
      disabled={disabled}
    >
      <span className={styles.label}>SEND</span>
    </AccessibleButton>
  );
};

export default SendButton;

import React, { Component } from 'react';
import style from './LearningHistory.module.css';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import ShareButton from '../../../../components/share/ShareButton';
import SurveyButton from '../../../../components/surveyButton/SurveyButton';
import MandatoryCircle from '../../../../components/mandatoryCircle/mandatoryCircle';
import GlobalContext from '../../../../context/GlobalContext';
import AccessibleButton from '../../../../components/accessibleButton/AccessibleButton';
import { NonLtcIcon } from '../../../../components/nonLtcIcon/nonLtcIcon';
import { Link } from 'react-router-dom';
import { canTakeSurvey, stringToDate } from '../../../../CONSTANTS';
import { SLType, isOnDemand } from '../../../sessions/components/SessionUtils';
import { getCourseLink } from '../../../../utils/courses';
import BlueSquaredScroll from '../../../../components/blueSquaredScroll/BlueSquaredScroll';
import { downloadCsv } from '../../../../CONSTANTS';
import DigiMenu from '../../../../components/digiMenu/DigiMenu';
const formatDate = (date) => {
  return stringToDate(date).toLocaleString([], {
    day: 'numeric',
    year: 'numeric',
    month: 'long'
  });
};

const ShowAchievements = ({ session, index }) => (
  <div key={index} className={style.session}>
    <div className={style.contentHolder}>
      <div className={style.titleHolder}>
        <h3>
          {session.courseName} | {session.domain.name}{' '}
        </h3>
        <div className={style.timeContainer}>
          Provided by: {session.provider}
          <br />
          Date:{' '}
          {stringToDate(session.dateAwarded).toLocaleString([], {
            day: 'numeric',
            year: 'numeric',
            month: 'long'
          })}
          <br />
          {session.achievement.pointValue} points
        </div>
      </div>
    </div>
    <hr className={style.endLine} />
  </div>
);

export default class LearningHistory extends Component {
  state = {
    activeTab: '0',
    sessions: [],
    outsideLearning: [],
    outsideAchievements: [],
    history: [],
    loading: true
  };
  sessionListHolderRef = React.createRef();

  componentDidMount() {
    this.fetchRegistered();
  }

  toggleClickHandler(tab) {
    this.setState(
      {
        activeTab: tab
      },
      () => {
        if (this.sessionListHolderRef) {
          this.sessionListHolderRef.current.focus();
          this.sessionListHolderRef.current.blur();
        }
      }
    );
  }

  downloadSessionsCsv = () => {
    const currentdate = new Date();
    const filteredHistory = this.filterAchievements(this.state.history);
    this.props.shareIT.map((item) => filteredHistory.push(item));
    const datetime =
      String(currentdate.getFullYear()) +
      String(currentdate.getMonth() + 1).padStart(2, '0') +
      String(currentdate.getDate()).padStart(2, '0') +
      '_' +
      String(currentdate.getHours()).padStart(2, '0') +
      String(currentdate.getMinutes()).padStart(2, '0') +
      String(currentdate.getSeconds()).padStart(2, '0');

    const username = this.context.user.firstName + this.context.user.lastName;
    const filename = `${username}_LearningHistory_${datetime}.csv`;
    const csv = [
      [
        'Completion Date',
        'Shadow/Host position',
        'Host or Shadow',
        'Hosts',
        'Shadow',
        'Session Name',
        'Type of learning',
        'Mandatory'
      ]
    ];

    this.sortMergedLists(filteredHistory).forEach((event) => {
      const newRow = [];

      // Completion date
      if (event?.achievement) {
        newRow.push(`"${formatDate(event.dateAwarded)}"`);
      } else if (event?.shadowId) {
        newRow.push(
          `"${new Date(event.startDate).toDateString() +
            '-' +
            new Date(event.endDate).toDateString()}"`
        );
      } else {
        newRow.push(`"${formatDate(event.courseStart)}"`);
      }
      //Shadow/Host position
      if (event?.shadowId) newRow.push(event.shareITRole);
      else newRow.push('');

      //Host or Shadow
      if (event?.shadowUser?.name === this.context.user?.name) newRow.push('shadow');
      //Shadow
      else if (event?.courseName) newRow.push('');
      else newRow.push('Host'); //host

      //Hosts
      if (event?.courseName) newRow.push('');
      else {
        const hostlist = event?.shareItHost.map((host) => `(${host.hostUser.name})`);
        newRow.push(`"${hostlist}"`);
      }

      //Shadows
      if (event?.courseName) newRow.push('');
      else {
        newRow.push(`"${event?.shadowUser.name}"`); //Shadow
      }

      // Session name
      if (event?.courseName) newRow.push(event?.courseName);
      else newRow.push('');

      // Attended Sessions / Outside Learning
      if (event?.achievement || event?.externalUrl) {
        newRow.push('Outside Learning');
      } else if (event?.shadowId) {
        //if we are looking at a shadow
        newRow.push('Share IT');
      } else {
        newRow.push('My Attended Sessions');
      }

      // Mandatory?
      if (event?.mandatory) newRow.push('Y');
      else newRow.push('N');

      csv.push(newRow);
    });

    const csvString = csv.map((csvRow) => csvRow.join(',')).join('\n');

    downloadCsv(filename, csvString);
  };

  fetchRegistered = () => {
    for (let session of this.props.sessions) {
      //recording the history
      this.setState((prevState) => ({
        history: [...prevState.history, session]
      }));

      // check for Non-LTC sessions
      if (session.externalUrl) {
        this.setState((prevState) => ({
          outsideLearning: [...prevState.outsideLearning, session]
        }));
      }

      // following is for LTC sessions
      else {
        this.setState((prevState) => ({
          sessions: [...prevState.sessions, session]
        }));
      }
    }

    // following is for outside learning achievements
    for (let session of this.context.points) {
      if (session.achievement.slug === 'outsidelearning') {
        this.setState((prevState) => ({
          outsideAchievements: [...prevState.outsideAchievements, session],
        //  history: [...prevState.history, session]
        }));
      }
    }
  };

  filterCustomSessionAchievements = (points) => {
    //points.filter will include points that are achievement type with the slug of Outside Learning
    return points.filter(
      (point) => point.achievement && point.achievement.slug === 'outsidelearning'
    );
  };

  mergeLists = (points, sessions) => {
    return this.sortMergedLists([
      ...this.filterCustomSessionAchievements(points),
      ...sessions
    ]);
  };

  filterAchievements = (list) => {
    if (!this.props.seeAll) {
      const startDate = new Date(this.props.startDate + 'T00:00:00'); // creates the date in the midnight in your current timezone
      const endDate = new Date(this.props.endDate + 'T23:59:59'); // creates the date in the midnight in your current timezone
      return list.filter((point) => {
        return point.achievement
          ? stringToDate(point.dateAwarded) >= startDate &&
              stringToDate(point.dateAwarded) <= endDate
          : stringToDate(point.courseStart) >= startDate &&
              stringToDate(point.courseStart) <= endDate;
      });
    } else return list;
  };

  sortMergedLists = (mergedList) => {
    return mergedList.sort((a, b) => {
      const getDateString = (item) => {
        if (item.achievement) return item.dateAwarded;
        else if (isOnDemand(item)) return item.attendedOn;
        else return item.courseStart;
      };
      const dateStringA = getDateString(a);
      const dateStringB = getDateString(b);
      if (!dateStringA || !dateStringB) return 0;
      const date1 = stringToDate(dateStringA);
      const date2 = stringToDate(dateStringB);
      return date2 - date1;
    });
  };

  listItem = (session) => (
    <div
      key={`learning_history_${session.courseDetailsId}`}
      className={style.session}
      id={session.courseDetailsId}
    >
      <div className={style.contentHolder}>
        <div className={style.titleHolder}>
          <Link to={getCourseLink(session)}>
                      <h3>{session.courseName}</h3>
          </Link>
          <div className={style.timeContainer}>
            Date:{' '}
            {formatDate(
              isOnDemand(session) && session.attendedOn
                ? session.attendedOn
                : session.courseStart
            )}
          </div>
        </div>
        <div className={style.mandatoryContainer}>
          {session?.mandatory && <MandatoryCircle />}
          {session?.externalUrl && <NonLtcIcon />}
        </div>
      </div>
      <div className={style.buttonHolder}>
        <ShareButton
          session={session}
          type={SLType.PAST}
          addBadgeToList={this.props.addBadgeToList}
        />
        {canTakeSurvey(session, this.context.user.userId, true) && (
          <SurveyButton session={session} />
        )}
      </div>
      <hr className={style.endLine} />
    </div>
  );

  render() {
    const userName = this.context.user.name; // this get's the name from the profile link, on the navigation bar.
    // the function below is structured as,
    //name that should appear
    //func which is the html which should be displayed for that session
    const myLearningHistoryList = [
      {
        name: 'REGISTERED SESSIONS',
        func:
            this.state.sessions.length !== 0 || this.state.outsideLearning.length !== 0 ? (
            <>
              {/*Following code will show the registered courses*/}
                    {[...this.state.outsideLearning, ...this.state.sessions].map(this.listItem)
               }
            </>
          ) : (
            <div
              className={style.outsideSessionList}
              ref={this.emptyAttendedListRef}
              tabIndex={'-1'}
            >
              <p>
                You have not registered any sessions. <br />
                <br />
                Once you have registered a session, you will see your history here.
              </p>
            </div>
          )
      },
      {
        name: 'OUTSIDE LEARNING',
        func:
          //this.state.outsideLearning.length !== 0 ||
          this.state.outsideAchievements.length !== 0 ? (
            <>
              {/* Following code merges Non-LTC courses with Outside Learning courses, sorts them and then they're displayed in 
                        a sorted order */}
              {//this.mergeLists(
                this.filterAchievements(this.state.outsideAchievements)
                //this.state.outsideLearning
              .map((item, index) => {
                return item.achievement ? (
                  <ShowAchievements
                    session={item}
                    key={`custom_session_${index}`}
                  ></ShowAchievements>
                ) : (
                  this.listItem(item)
                );
              })}
            </>
          ) : (
            <div
              className={style.outsideSessionList}
              ref={this.emptyOutsideListRef}
              tabIndex={'-1'}
            >
              <p>
                You do not have any approved Outside Learning sessions. <br />
                <br />
                Please contact your{' '}
                <a href="mailto: LTC.PCL@ontario.ca?">L+D team</a> when you have
                completed non-L+D sessions to collect your points.
              </p>
            </div>
          )
      },
      {
        name: 'MY SHARE IT',
        func:
          this.props.shareIT.length !== 0 ? (
            <div className={style.sessionListHolder}>
              {this.props.shareIT.map((item, index) => {
                return (
                  <div key={index} className={style.session}>
                    <h3>{item?.shareITRole}</h3>
                    {/* item holds the individual information for each share it the user is part of*/}
                    {/* so data can be changed here , just as a note we are making a list of hosts, as there can be multiple hosts*/}
                    <div className={style.darkBlueContent}>
                      {item?.shadowUser.name === userName ? (
                        <p>{'Shadow: ' + item.shadowUser.name}</p>
                      ) : (
                        <>
                          <p>{'Host : ' + userName}</p>
                          {item.shareItHost.map((item, index) => {
                            if (item.hostUser.name !== userName)
                              //this is done so that, your user name is displayed first.
                              return (
                                <p key={index}>{'Host : ' + item.hostUser.name}</p>
                              );
                            else return <p key={index}></p>; //necessary as mapping function requires all statements to have a return.
                          })}
                        </>
                      )}
                      <hr />
                      {/* this is the portion where Host or shadow is displayed*/}
                      {/*this was for the rest of the host or shadow */}
                      {// we are under the assumption that the user data is already presented
                      item?.shadowUser.name === userName ? (
                        item.shareItHost.map((item, index) => {
                          //means that the user was a shadow
                          //shows the hosts
                          return <p key={index}>{'Host : ' + item.hostUser.name}</p>;
                        })
                      ) : (
                        <p>{'Shadow : ' + item.shadowUser.name}</p>
                      )}
                    </div>
                    <hr />
                    <div className={style.contentHolder}>
                      {new Date(item.startDate).toDateString() +
                        ' — ' +
                        new Date(item.endDate).toDateString()}
                    </div>
                    {/* time live here*/}
                    <hr className={style.endLine} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className={style.outsideSessionList}
              ref={this.emptyOutsideListRef}
              tabIndex={'-1'}
            >
              <div>You do not have any approved ShareIT Learning Sessions.</div>
              <br />
              <div>Please contact the Admins to get one approved now.</div>
            </div>
          )
        },
        {
            name: 'ONDEMAND SESSIONS',
            func:
                [...this.state.sessions, ...this.state.outsideLearning].filter(s => s.formatId == 4 && s.attended).length !== 0 ? (
                    <>
                        {/*Following code will show the ONDEMAND completed courses*/}
                        {[...this.state.sessions, ...this.state.outsideLearning].filter(s => s.formatId == 4 && s.attended).map(this.listItem)}
                    </>
                ) : (
                    <div
                        className={style.outsideSessionList}
                        ref={this.emptyAttendedListRef}
                        tabIndex={'-1'}
                    >
                        <p>
                            You do not have any approved On Demand sessions. <br />
                            <br />
                                Please contact your{' '}
                                <a href="mailto: LTC.PCL@ontario.ca?">L+D team</a> when you have
                                completed On Demand sessions to collect your points.
                        </p>
                    </div>
                )
        }
    ]; //end of the list

    return (
      <div className={style.historyContainer}>
        <div className={style.navigator}>
          <div className={style.active}>
            {myLearningHistoryList[parseInt(this.state.activeTab)].name}
          </div>
          <DigiMenu design={[faCaretDown, faCaretUp]} id="dropdownmenu">
            {myLearningHistoryList.map((item, index) => {
              if (index !== parseInt(this.state.activeTab))
                // the -1 is because of the way the
                return (
                  <div key={index}>
                    <AccessibleButton
                      className={style.nav}
                      onClick={() => this.toggleClickHandler(index.toString())}
                    >
                      {item.name}
                    </AccessibleButton>
                  </div>
                );
              else return <div key={index} />;
            })}
          </DigiMenu>
        </div>
        <BlueSquaredScroll>
          <div
            className={style.sessionListHolder}
            ref={this.sessionListHolderRef}
            tabIndex={'-1'}
          >
            {myLearningHistoryList[parseInt(this.state.activeTab)].func}
          </div>
        </BlueSquaredScroll>
      </div>
    );
  }
}
LearningHistory.contextType = GlobalContext;

import React from 'react';
import styles from './StatCard.module.css';
import { Card, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import AccessibleButton from '../../../components/accessibleButton/AccessibleButton';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import CustomScroll from '../../../components/customScroll/CustomScroll';

export default function StatCard(props) {
  /**
   *
   * @param {Stat} stat
   * @returns Div and Tippy for stat
   */
  const renderStat = (stat, style) => {
    const details = Array.isArray(stat.details)
      ? stat.details.reduce((result, detail, index) => {
          const transformed_detail = stat.transform_filter(detail);
          transformed_detail &&
            result.push(
              <p key={'detail-' + index}>
                {transformed_detail}
                <br />
              </p>
            );
          return result;
        }, [])
      : [];

    return (
      <>
        <div>
          <Tippy
            // Hover content
            content={
              <CustomScroll className={styles.scrollableTippy}>
                <div className={styles.tippy}>{details}</div>
              </CustomScroll>
            }
            interactive={true}
            disabled={props.loading || details.length === 0}
          >
            <div className={style}>
              <h1
                className={`${styles.textShadow} ${styles.stat}`}
                id={`${props.id}-stat`}
              >
                {stat.value}
              </h1>
              {stat.label.length > 0 ? (
                <h2 className={`${styles.textShadow} ${styles.statLabel}`}>
                  {stat.label}
                </h2>
              ) : (
                ''
              )}
            </div>
          </Tippy>
        </div>
        {/* Read out the list of courses for screen readers since this content is shown on hover */}
        <span className="show-for-sr">{details}</span>
      </>
    );
  };

  const getCardDescription = () => {
    switch (props.id) {
      case 'registeredUpcoming':
        return 'Number of upcoming sessions that the staff have registered for in the selected timeframe';
      case 'attendedSessions':
        return 'Number of sessions that the staff have attended in the selected timeframe';
      case 'recommendedSessions':
        return 'Number of sessions that you have recommended to staff in the selected timeframe';
      case 'mandatoryAttendance':
        return 'Percentage of staff who attended mandatory sessions in the selected timeframe';
      case 'registeredAndAttended':
        return 'Percentage of staff who attended sessions they were registered for in the selected timeframe';
      case 'attendedOnDemand':
        return 'Number of on demand sessions that the staff have completed in the selected timeframe';
      default:
        return '';
    }
  };

  const showDetailsButton = () => {
    return props.id !== 'registeredAndAttended' && props.id !== 'attendedOnDemand';
  };

  return (
    <div className={styles.divContainer}>
      <Card body id={props.id} className={`text-center ${styles.cardContainer}`}>
        {/* Info Icon */}
        <div className={styles.iconContainer}>
          <OverlayTrigger
            key={`card-tooltip-${props.id}`}
            placement="left-start"
            overlay={<Tooltip>{getCardDescription()}</Tooltip>}
          >
            <AccessibleButton className={`${styles.infoIcon}`} aria-hidden="true">
              <img
                src={require('../../../assets/Info.png')}
                alt="Hover over the information icon to review description of the stats"
              />
            </AccessibleButton>
          </OverlayTrigger>
        </div>

        {/* Stat Body */}
        <div className={styles.statHolder} data-html="true">
          <h1 className={`${styles.cardText} ${styles.textShadow}`}>
            {props.statLabel}
          </h1>
          {/* Read out the description for screen readers since this content is shown on hover */}
          <span className="show-for-sr">{getCardDescription()}</span>
          {props.loading ? (
            <Spinner className={styles.spinner} />
          ) : props.stats?.length === 2 ? (
            <div className={styles['divider-grid']}>
              {renderStat(props.stats[0], styles['divider-grid-item'])}
              <div className={styles.divider} />
              {renderStat(props.stats[1], styles['divider-grid-item'])}
            </div>
          ) : (
            renderStat(props.stats[0])
          )}
        </div>

        {/* Details Button */}
        {props.loading || !showDetailsButton() ? (
          ''
        ) : (
          <Link
            to={(loc) => `${loc.pathname}${props.detailsRoute}`}
            aria-hidden={!showDetailsButton()}
            className={showDetailsButton() ? styles.button : styles.hideButton}
            id={`${props.id}Details`}
            disabled={
              props.disableDetailsBtn ||
              props.id === 'registeredAndAttended' ||
              props.id === 'attendedOnDemand'
            }
          >
            Details
          </Link>
        )}
      </Card>
    </div>
  );
}

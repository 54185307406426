import React from 'react';
import AccessibleButton from '../accessibleButton/AccessibleButton';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const DigiButton = ({
  selected,
  onClick,
  className,
  children,
  lightBlue,
  colorTheme,
  loading,
  ...otherProps
}) => {
  return (
    <AccessibleButton
      {...otherProps}
      onClick={onClick}
      className={[
        className,
        'dw-button',
        (() => {
          if (colorTheme === 'lightBlue') return selected ? 'lightBlue' : 'medBlue';
          if (colorTheme === 'darkBlue')
            return selected ? 'active darkBlue' : 'darkBlue';
          if (colorTheme === 'red') return 'red';
          // Default
          return selected ? 'active' : '';
        })()
      ].join(' ')}
    >
      {loading ? (
        <Spinner size="sm" color={selected && lightBlue ? 'light' : 'primary'} />
      ) : (
        children
      )}
    </AccessibleButton>
  );
};

DigiButton.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool,
  lightBlue: PropTypes.bool
};

export default DigiButton;

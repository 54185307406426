import React from 'react';
import GlobalContext from '../../../context/GlobalContext';
import { Nav, NavItem } from 'reactstrap';
import AccessibleButton from '../../../components/accessibleButton/AccessibleButton';
import classnames from 'classnames';
import { AdminTabs } from '../../../enums/admintabs.enum';
import PropTypes from 'prop-types';
import style from './AdminAllTabs.module.css';

class AdminHomeTabs extends React.Component {
  static propTypes = {
    activetab: PropTypes.string
  };
  static defaultProps = {
    activetab: ''
  };
  render() {
    return (
      <div>
        <Nav tabs className={style.adminFirstNav}>
          <NavItem>
            <AccessibleButton
              className={classnames({
                [style.active]: this.props.activetab === AdminTabs.Sessions
              })}
              id={'sessions-tab-admin'}
              onClick={() => {
                this.props.switchtab('/admin/' + AdminTabs.Sessions);
              }}
            >
              Sessions
            </AccessibleButton>
          </NavItem>
          <NavItem>
            <AccessibleButton
              className={classnames({
                [style.active]:
                  this.props.activetab === AdminTabs.SessionConfigurations
              })}
              id={'sessionconfigurations-tab-admin'}
              onClick={() => {
                this.props.switchtab('/admin/' + AdminTabs.SessionConfigurations);
              }}
            >
              Session Configurations
            </AccessibleButton>
          </NavItem>

          <NavItem>
            <AccessibleButton
              className={classnames({
                [style.active]: this.props.activetab === AdminTabs.LDCalendar
              })}
              id={'ldcalendar-tab-admin'}
              onClick={() => {
                this.props.switchtab('/admin/' + AdminTabs.LDCalendar);
              }}
            >
              L+D Calendar
            </AccessibleButton>
          </NavItem>

          <NavItem>
            <AccessibleButton
              className={classnames({
                [style.active]: this.props.activetab === AdminTabs.Admins
              })}
              id={'admins-tab-admin'}
              onClick={() => {
                this.props.switchtab('/admin/' + AdminTabs.Admins);
              }}
            >
              Admins
            </AccessibleButton>
          </NavItem>
          <NavItem>
            <AccessibleButton
              className={classnames({
                [style.active]: this.props.activetab === AdminTabs.ManagerStaff
              })}
              id={'managers-staff-tab-admin'}
              onClick={() => {
                this.props.switchtab('/admin/' + AdminTabs.ManagerStaff);
              }}
            >
              Managers & Staff
            </AccessibleButton>
          </NavItem>
          <NavItem>
            <AccessibleButton
              className={classnames({
                [style.active]: this.props.activetab === AdminTabs.ShareIT
              })}
              id={'shareit-tab-admin'}
              onClick={() => {
                this.props.switchtab('/admin/' + AdminTabs.ShareIT);
              }}
            >
              Share IT
            </AccessibleButton>
          </NavItem>
          <NavItem>
            <AccessibleButton
              className={classnames({
                [style.active]: this.props.activetab === AdminTabs.Achievements
              })}
              id={'achievements-tab-admin'}
              onClick={() => {
                this.props.switchtab('/admin/' + AdminTabs.Achievements);
              }}
            >
              Achievements
            </AccessibleButton>
          </NavItem>
          <NavItem>
            <AccessibleButton
              className={classnames({
                [style.active]: this.props.activetab === AdminTabs.Users
              })}
              id={'users-tab-admin'}
              onClick={() => {
                this.props.switchtab('/admin/' + AdminTabs.Users);
              }}
            >
              Users
            </AccessibleButton>
          </NavItem>
          <NavItem>
            <AccessibleButton
              className={classnames({
                [style.active]: this.props.activetab === AdminTabs.Reports
              })}
              id={'reports-tab-admin'}
              onClick={() => {
                this.props.switchtab('/admin/' + AdminTabs.Reports);
              }}
            >
              Reports
            </AccessibleButton>
          </NavItem>
        </Nav>
      </div>
    );
  }
}
AdminHomeTabs.contextType = GlobalContext;

export default AdminHomeTabs;

import React, { Component } from 'react';
import ReactGA from 'react-ga';
import style from './Sessions.module.css';
import { adalApiFetch } from '../../adalConfig';
import { Row, Col } from 'reactstrap';

import { deepcopy, stringToDate } from '../../CONSTANTS';

//Custom Components
import SessionList from './components/SessionList.jsx';
import SessionCalendar from './components/SessionCalendar.jsx';
import SessionFilter from './components/SessionFilter';

// Constants
import Course from '../course/Course';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import GlobalContext from '../../context/GlobalContext';
import AccessibleButton from '../../components/accessibleButton/AccessibleButton';
import { SLType } from './components/SessionUtils';
import RecommendContainer from './components/recommendContainer/RecommendContainer';

export default class Sessions extends Component {
  pillarFilters = [];
  categoryFilters = [];

  state = {
    sessions: [],
    filteredSessions: [],
    moreSession: null,
    calendarSessions: [],
    loading: true,
    filterHidden: true,
    url: '',
    changeDate: null,
    clickedCourseId: 0,
    mandatory: false,
    managerOnly: false,
    selectedDomain: null,
    recommendSession: null,
    holidays: []
  };

  abortController = new AbortController();
  courseRef = React.createRef();
  mandatoryCheckboxRef = React.createRef();
  managerOnlyCheckboxRef = React.createRef();

  isPastSession = () => {
    return this.props.type === SLType.PAST;
  };

  componentDidMount() {
    this.setState({
      innovatorTutorials: this.props.newBadgesTutorials
    });

    (async () => {
      await this.initializePage();
    })();

    // this.sketchyCSSHeightAdjustments();
    // window.addEventListener('resize', () => {
    //   this.sketchyCSSHeightAdjustments();
    // });
  }

  // componentDidUpdate() {
  //   this.sketchyCSSHeightAdjustments();
  // }

  componentWillUnmount() {
    this.abortController.abort();
  }

  // sketchyCSSHeightAdjustments() {
  //   const sessionView = document.getElementById('col-sessionView');
  //   const sessionList = document.getElementById('col-sessionList');
  //   sessionList.style.height = sessionView.clientHeight + 'px';
  // }

  checkSessionExpired(data, id) {
    let expired = true;
    for (let session of data) {
      if (session.courseDetailsId.toString() === id) {
        expired = false;
      }
    }
    if (expired) this.props.history.push('/pastsessions/' + id);
  }

  initializePage = async () => {
    let api = null;
    let daysOffApi = null;

    const currentDate = new Date();
    const LastDay = currentDate.toISOString().split('T')[0];
    switch (this.props.type) {
      case SLType.PAST:
        api = '/api/course/past';
        daysOffApi = `/api/DaysOff?endDate=${LastDay}`;
        break;
      case SLType.ON_DEMAND:
        api = '/api/course/ondemand';
        break;
      case SLType.UPCOMING:
      default:
        api = '/api/course/upcomingcourseswithregistered';
        daysOffApi = `/api/DaysOff`;
        break;
    }

    const getEvents = async () => {
      const abortController = new AbortController();

      try {
        // If the results are fetched then what to do
        const response = await adalApiFetch(
          fetch,
          process.env.REACT_APP_API_BASE_DOMAIN + daysOffApi,
          {
            method: 'get',
            headers: {
              'Content-Type': 'application/json'
            },
            signal: abortController.signal
          }
        );

        const myJson = await response.json();
        const eventList = [];
        const order = this.sortArrayByDate(myJson.data);

        for (let course of order) {
          let endDate = stringToDate(course.endDate);
          endDate.setDate(endDate.getDate() + 1); // FullCalendar end is exclusive, so add one day to the end date

          //attributes required for FullCalendar
          eventList.push({
            title: course.eventDescription,
            start: course.startDate.split('T')[0],
            end: endDate.toISOString().split('T')[0],
            classNames: style.holiday
          });
        }
        return eventList;
      } catch (e) {
        ReactGA.exception({
          description: e.toString()
        });
      }
      return [];
    };
    let list = await getEvents();
    this.setState({ holidays: list });

    const getSessions = async () => {
      try {
        const response = await adalApiFetch(
          fetch,
          process.env.REACT_APP_API_BASE_DOMAIN + api,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
            signal: this.abortController.signal
          }
        );
        const myJson = await response.json();
        // Create a unique entry for every single child of a parent session.
        const allChildrenSessions = [];
        for (let i = 0; i < myJson.data.length; i++) {
          for (let j = 0; j < myJson.data[i].courseDetails.length; j++) {
            let childSessionWithParentDetails = {
              ...myJson.data[i],
              ...myJson.data[i].courseDetails[j]
            }; // Include parent and this child's information all in one object

            if (myJson.data[i].managerOnly)
              childSessionWithParentDetails.managerOnly = true;

            allChildrenSessions.push(childSessionWithParentDetails);
          }
        }
        let order = this.sortArrayByDate(allChildrenSessions);

        this.setState({ sessions: [] });
        let sessionList = [];

        for (let course of order) {
          //renaming some items because its required for calendar
          course.title = course.courseName;

          course.selected = false;
          course.start = stringToDate(course.courseStart);
          course.end = stringToDate(course.courseEnd);

          course.startEditable = false;

          sessionList.push(course);
        }

        const initializedSessionList = this.initListSessions(sessionList);

        if (this.props.match.params.courseDetailsId) {
          this.checkSessionExpired(
            initializedSessionList,
            this.props.match.params.courseDetailsId
          );
        }
        this.setState({
          sessions: sessionList,
          filteredSessions: sessionList,
          calendarSessions: this.sessionsListForCalendar(initializedSessionList),
          loading: false,
          searchText: ''
        });

        //set value in to context
        this.context.setSessionList(initializedSessionList);

        // Was a session ID provided in the URL?
        if (this.props.match.params.courseDetailsId) {
          this.context.sessionList.forEach((session) => {
            if (
              session.courseDetailsId ===
              parseInt(this.props.match.params.courseDetailsId, 10)
            ) {
              this.setState({
                moreSession: session,
                clickedCourseId: session.courseDetailsId,
                recommendSession: null
              }); // No need to focus on more section if it is shown on page load
              return;
            }
          });
        } else {
          if (
            this.props.type === SLType.ON_DEMAND &&
            initializedSessionList.length > 0
          ) {
            this.openSession(initializedSessionList[0]);
          }
        }

        //is filter active from homepage?
        if (this.props.location.state !== undefined) {
          this.setState({
            selectedDomain: this.props.location.state.fromHome
          });
          this.filterClick();
        } else {
          this.setState({ selectedDomain: -1 });
        }
      } catch (e) {
        ReactGA.exception({
          description: e.toString()
        });
      }
    };

    await getSessions();
  };

  openSession = (courseDetails) => {
    let url = '';
    switch (this.props.type) {
      case SLType.ON_DEMAND:
        url += '/ondemand/';
        break;
      case SLType.PAST:
        url += '/past/';
        break;
      case SLType.UPCOMING:
        url += '/upcoming/';
        break;
      default:
        return;
    }
    url += courseDetails.courseDetailsId;
    this.props.history.push(url);
    this.setState({
      moreSession: courseDetails,
      clickedCourseId: courseDetails.courseDetailsId,
      recommendSession: null
    });
  };

  openRecommendSession = (courseDetailsId) => {
    this.context.sessionList.forEach((session) => {
      if (session.courseDetailsId === courseDetailsId) {
        this.setState({ recommendSession: session });
      }
    });
    if (!this.context.employees) this.context.reloadEmployees();
  };

  closeRecommendSession = () => {
    this.setState({
      recommendSession: null
    });
  };

  sessionsListForCalendar(sessions) {
    let calendarSessions = deepcopy(sessions);

    calendarSessions = calendarSessions.map((session) => {
      if (session.mandatory && session.managerOnly) {
        session.title = session.title + ' (Manager, Mandatory)';
      } else if (session.managerOnly) {
        session.title = session.title + ' (Manager)';
      }

      // Manager only > NonLTC > mandatory > normal

      session.color = session.managerOnly
        ? '#E0F5FF'
        : session.externalUrl
        ? '#00205B'
        : session.mandatory
        ? '#F3D250'
        : // session NORMAL
          '#0069D9';

      session.textColor = session.managerOnly
        ? '#00205B'
        : session.externalUrl
        ? '#F3D250'
        : session.mandatory
        ? '#000000'
        : // session NORMAL
          '#FFFFFF';

      if (session.managerOnly) {
        session.borderColor = '#00205B';
      }
      return session;
    });
    return calendarSessions;
  }

    compareAttended(a, b) {
        if (a.name < b.name) {
            return -1
        }
        if (a.name == b.name) { // you were making mistake here by a = b instead of a==b
            return 0
        }
        if (a.name > b.name) {
            return 1
        }
    }

  initListSessions(sessions) {
    let sessionList = [];
    let currentDate = new Date();
      for (let session of sessions) {
      if (this.props.type === SLType.UPCOMING) {
        if (session.start > currentDate) {
          sessionList.push(session);
        }
      } else if (this.props.type === SLType.PAST) {
        if (session.start < currentDate) {
          sessionList.push(session);
        }
      } else if (this.props.type === SLType.ON_DEMAND) {
            sessionList.push(session);
          }
      }
    return sessionList;
  }

  filterClick = (event = null) => {
    if (event) {
      event.persist();
    }
    this.setState({ filterHidden: !this.state.filterHidden }, () => {
      if (!this.state.filterHidden) {
        if (event && event.screenX === 0 && event.screenY === 0) {
          // If keyboard event
          this.mandatoryCheckboxRef.current.focus();
        }
      }
    });
  };

  filterChange = (domain, category) => {
    if (this.props.match.params.courseDetailsId) this.closeButton();
    this.pillarFilters = domain;
    this.categoryFilters = category;
    let filteredSessions = [];
    if (!this.state.loading) {
      if (domain.length === 0 && category.length === 0) {
        filteredSessions = this.state.sessions.filter(
          (session) =>
            session.title
              .toLowerCase()
              .indexOf(this.state.searchText.toLowerCase()) !== -1 &&
            (!this.state.mandatory || session.mandatory) &&
            (!this.state.managerOnly || session.managerOnly)
          );
          //seort filtered list for outstanding courses on top
          this.state.mandatory && filteredSessions //.sort((a, b) => b.mandatory - a.mandatory)
              .sort(function (a, b) {
                  const aRegistrationRecord = a.participants[0] ? a.participants[0].attended : null;
                  const bRegistrationRecord = b.participants[0] ? b.participants[0].attended : null;
                  const aOnDemandAttended = aRegistrationRecord ? true : false;
                  const bOnDemandAttended = bRegistrationRecord ? true : false;
                  return (aOnDemandAttended - bOnDemandAttended)
              });
        this.setState({
          filteredSessions: this.state.sessions,
          calendarSessions: this.sessionsListForCalendar(
            this.initListSessions(this.removeDupes(filteredSessions))
          ),
          recommendSession: null
        });
        this.context.setSessionList(this.initListSessions(filteredSessions));
      } else {
        let sessionList = this.state.sessions;

          if (this.state.mandatory) {
              sessionList = sessionList.filter((session) => {
            return !!session.mandatory;
          });
        }

        if (this.state.managerOnly) {
          sessionList = sessionList.filter((session) => {
            return !!session.managerOnly;
          });
        }

        for (let cat of category) {
          let categoryFilteredSessions = sessionList.filter((session) => {
            return session.sessionTypeId === cat;
          });
          filteredSessions = filteredSessions.concat(categoryFilteredSessions);
        }

        for (let dom of domain) {
          let domainFilteredSessions = sessionList.filter((session) => {
            return session.domain.domainId === dom;
          });
          filteredSessions = filteredSessions.concat(domainFilteredSessions);
        }

        filteredSessions = filteredSessions.filter(
          (session) =>
            session.title
              .toLowerCase()
              .indexOf(this.state.searchText.toLowerCase()) !== -1
        );

        filteredSessions = this.removeDupes(filteredSessions);
      }

      this.setState({
        filteredSessions: filteredSessions,
        calendarSessions: this.sessionsListForCalendar(
          this.initListSessions(filteredSessions)
        ),
        recommendSession: null
      });

      this.context.setSessionList(this.initListSessions(filteredSessions));
      if (this.props.type === SLType.ON_DEMAND) {
          if (filteredSessions.length > 0) {
            this.openSession(filteredSessions[0]);
        }
      }
    }
  };

  removeDupes(array) {
    let a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }
    return this.sortArrayByDate(a);
  }

  sortArrayByDate(array) {
    return array.sort((a, b) => {
      let c = a.courseStart ? stringToDate(a.courseStart) : stringToDate(a.endDate);

      let d = b.courseStart ? stringToDate(b.courseStart) : stringToDate(b.endDate);

      return (
        (c.getTime() - d.getTime()) * (this.props.type !== SLType.UPCOMING ? -1 : 1)
      );
    });
  }

  callbackFunction = (childData, event = null) => {
    if (event) {
      event.preventDefault();
    }

    this.context.sessionList.forEach((session) => {
      if (session.courseDetailsId === childData) {
        this.setState(
          {
            moreSession: session,
            clickedCourseId: childData,
            recommendSession: null
          },
          () => {
            this.courseRef.current && this.courseRef.current.focus();
          }
        );
      }
    });

    let url = this.getUrl(childData);
    this.props.history.push(url);
    this.setState({ closeButtonShowCalander: false });
  };

  getUrl = (childData = '') => {
    switch (this.props.type) {
      case SLType.PAST:
        return '/pastsessions/' + childData;
      case SLType.ON_DEMAND:
        return '/ondemand/' + childData;
      case SLType.UPCOMING:
      default:
        return '/sessions/' + childData;
    }
  };

  //The close button in the search field
  closeButton = () => {
    let url = this.getUrl();
    this.props.history.push(url);
    this.setState({ moreSession: null, clickedCourseId: 0 });
  };

  searchChangeHandler = (e) => {
    this.setState({ searchText: e.target.value });
  };

  //Activates when the search icon is clicked
  clickHandler = () => {
    if (this.props.match.params.courseDetailsId) this.closeButton();
    this.filterChange(this.pillarFilters, this.categoryFilters);
  };
  //Activates when the user presses enter in the search field
  onEnter = (e) => {
    if (e.key === 'Enter') {
      if (this.props.match.params.courseDetailsId) this.closeButton();
      this.filterChange(this.pillarFilters, this.categoryFilters);
    }
  };

  listClickHandler = (target) => {
    this.setState({ changeDate: target });
  };

  mandatoryClick = () => {
    if (this.props.match.params.courseDetailsId) this.closeButton();
      let a = this.state.mandatory;
    this.setState({ mandatory: !a }, () => {
      this.filterChange(this.pillarFilters, this.categoryFilters);
    });
  };

  managerOnlyClick = () => {
    if (this.props.match.params.courseDetailsId) this.closeButton();
    let a = this.state.managerOnly;
    this.setState({ managerOnly: !a }, () => {
      this.filterChange(this.pillarFilters, this.categoryFilters);
    });
  };

  render() {
    return (
      <div className={style.upcomingSessionsHolder}>
        <Row>
          <Col xs={3}>
            <div className={[style.titleHolder, 'flex'].join(' ')}>
              <h1>
                {this.props.type === SLType.UPCOMING && 'Upcoming '}
                {this.props.type === SLType.PAST && 'Past '}
                {this.props.type === SLType.ON_DEMAND && 'On Demand '}
                Sessions
              </h1>
            </div>
          </Col>
          <Col>
            <AccessibleButton
              className={style.filter}
              onClick={(event) => this.filterClick(event)}
            >
              Filters{' '}
              <img
                className={style.filterImg}
                src={require('../../assets/FilterIcon.png')}
                alt="filter icon for session list"
              />
            </AccessibleButton>
          </Col>
          <Col xs="auto">
            <div className={style.toggles}>
              {this.context.user.isManager && (
                <>
                  <span className={style.switch}>
                    <label>
                      <span className="show-for-sr">switch for manager only</span>
                      <input
                        type="checkbox"
                        onChange={this.managerOnlyClick}
                        checked={this.state.managerOnly}
                        ref={this.managerOnlyCheckboxRef}
                      />
                    </label>
                    <span
                      onClick={this.managerOnlyClick}
                      className={[
                        style.slider,
                        this.state.managerOnly ? style.checked : ''
                      ].join(' ')}
                    ></span>
                  </span>
                  <span
                    className={[
                      style.managerSliderText,
                      this.state.managerOnly
                        ? style.sliderTextSelected
                        : style.sliderText
                    ].join(' ')}
                  >
                    Manager
                  </span>
                </>
              )}
              <span className={style.switch}>
                <label>
                  <span className="show-for-sr">switch for mandatory</span>
                  <input
                    type="checkbox"
                    onChange={this.mandatoryClick}
                    checked={this.state.mandatory}
                    ref={this.mandatoryCheckboxRef}
                  />
                </label>
                <span
                  onClick={this.mandatoryClick}
                  className={[
                    style.slider,
                    this.state.mandatory ? style.checked : ''
                  ].join(' ')}
                ></span>
              </span>
              <span
                className={
                  this.state.mandatory ? style.sliderTextSelected : style.sliderText
                }
              >
                Mandatory
              </span>
            </div>
          </Col>
          <Col xs="auto">
            <div className={style.searchBar} role="search">
              <label>
                <input
                  type="search"
                  placeholder=" Search..."
                  className={style.inputField}
                  onChange={this.searchChangeHandler}
                  onKeyPress={this.onEnter}
                  id={'sessionsSearch'}
                />
                <span className="show-for-sr">Search keywords</span>
              </label>
              <AccessibleButton
                onClick={this.clickHandler}
                className={style.searchButton}
              >
                <FontAwesomeIcon icon={faSearch} className={style.searchIcon} />
                <span className="show-for-sr">Search</span>
              </AccessibleButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="2" hidden={this.state.filterHidden}>
            {this.state.selectedDomain !== null && (
              <SessionFilter
                parentFilter={this.filterChange}
                selectedDomain={this.state.selectedDomain}
              />
            )}
          </Col>
                {/* <Col id="col-sessionList" xs="4"> */}
                <Col xs="4"> 
            <SessionList
              parentCallback={this.callbackFunction}
              sessions={this.context.sessionList}
              loading={this.state.loading}
              type={this.props.type}
              itemClickHandler={this.listClickHandler}
              clickedCourseId={this.state.clickedCourseId}
              addBadgeToList={this.props.addBadgeToList}
              openRecommendSession={this.openRecommendSession}
              recommendSession={this.state.recommendSession}
            />
          </Col>
          {this.state.recommendSession && (
            <div className={style.recommendDiv}>
              <RecommendContainer
                onCloseClick={this.closeRecommendSession}
                session={this.state.recommendSession}
              ></RecommendContainer>
            </div>
          )}
          {!this.state.recommendSession && (
            // <Col id="col-sessionView">
            <Col>
              {this.props.type !== SLType.ON_DEMAND && (
                <div
                  style={{ height: '72vh' }}
                  className={[
                    style.calendarHolder,
                    this.state.moreSession ? 'noDisplay' : ''
                  ].join(' ')}
                >
                  <div>
                    <SessionCalendar
                      parentCallback={this.callbackFunction}
                      sessions={this.state.calendarSessions}
                      holidays={this.state.holidays}
                      getChangedMonth={this.callBackFromMonthChange}
                      isPastSession={this.isPastSession()}
                      changeDate={this.state.changeDate}
                    />
                  </div>
                </div>
              )}
              {this.state.moreSession && !this.state.recommendSession && (
                <div ref={this.courseRef} tabIndex={'-1'}>
                  <Course
                    closeable={this.props.type !== SLType.ON_DEMAND}
                    session={this.state.moreSession}
                    type={this.props.type}
                    closeButtonClicked={this.closeButton}
                    addBadgeToList={this.props.addBadgeToList}
                  />
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
Sessions.contextType = GlobalContext;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AccessibleButton from '../accessibleButton/AccessibleButton';

class BackButton extends Component {
  clickHandler = () => {
    // if( Number.isNaN(parseInt(document.referrer.toString().split('/').slice(-1), 10))  ){
    //   this.props.history.push('/sessions/')
    // }
    this.props.history.go(-1);
  };

  render() {
    return (
      <AccessibleButton className="dw-button lg2 active" onClick={this.clickHandler}>
        {' '}
        <b>Back</b>{' '}
      </AccessibleButton>
    );
  }
}
const Back = withRouter(BackButton);
export default Back;

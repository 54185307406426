export const Badges = {
  // The number is the slug of each badges
  Welcome: 'firstlogin',
  Registration: 'registered',
  Buddy: 'buddy',
  Presenter: 'presenterbadge',
  Survey: 'survey',
  Eureka: 'eureka',
  LtcToolkit: 'ltctoolkit',
  LtcTechnology: 'ltctechnology',
  EmergingTechnology: 'emergingtechnology',
  NonTechnical: 'non-technical',
  BusinessAcumen: 'businessacumen',
  NonLtc: 'nonltc',
  Shadow: 'shadow',
  Host: 'host'
};

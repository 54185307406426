import React, { Component } from 'react';
import ReactGA from 'react-ga';

//Set Global value as Context
import { GlobalContext } from './GlobalContext';
import { adalApiFetch, adalGet } from '../adalConfig';

export default class GlobalContextController extends Component {
  constructor(props) {
    super(props);

    this.setUser = (newUser, callback = undefined) => {
      //newUser.isAdmin = true;
      this.setState(
        {
          user: newUser
        },
        () => {
          callback && callback();
        }
      );
    };

    this.setBadges = (newBadges, callback = undefined) => {
      this.setState(
        {
          badges: newBadges
        },
        () => {
          callback && callback();
        }
      );
    };

    this.setPoints = (newPoints, callback = undefined) => {
      this.setState(
        {
          points: newPoints
        },
        () => {
          callback && callback();
        }
      );
    };

    this.setParticipantsListForCourse = (courseDetailsId, participantsList) => {
      let newSessionList = this.state.sessionList;
      const courseIndex = newSessionList.findIndex(
        (item) => item.courseDetailsId === courseDetailsId
      );
      if (newSessionList[courseIndex]) {
        newSessionList[courseIndex].participants = participantsList;
        this.setState({
          sessionList: newSessionList
        });
      }
    };

    this.setSessionList = (sessionList) => {
      this.setState({
        sessionList: sessionList
      });
    };

    this.state = {
      user: null,
      setUser: this.setUser,

      badges: null,
      setBadges: this.setBadges,

      points: null,
      setPoints: this.setPoints,

      sessionList: [],
      setParticipantsListForCourse: this.setParticipantsListForCourse,
      setSessionList: this.setSessionList,
      animateSessionMaterials: false,
      toggleAnimateSessionMaterials: this.toggleAnimateSessionMaterials,

      employees: null,
      reloadEmployees: this.reloadEmployees,

      notifications: null,
      reloadNotifications: this.reloadNotifications,
      setNotificationAnimation: this.setNotificationAnimation,
      notificationAnimations: {},

      notificationStatusReasons: null,
      reloadNotificationStatusReasons: this.reloadNotificationStatusReasons
    };
  }

  toggleAnimateSessionMaterials = (value, callback = undefined) => {
    this.setState(
      {
        animateSessionMaterials: value === undefined ? !this.state.value : value
      },
      () => {
        callback && callback();
      }
    );
  };

  reloadNotifications = async () => {
    const { success, data: notifications } = await adalGet(
      '/api/Notifications/notifications'
    );
    if (success) {
      this.setState({ notifications });
    }
  };

  reloadNotificationStatusReasons = async () => {
    const { success, data: notificationStatusReasons } = await adalGet(
      '/api/NotificationStatusReason'
    );
    if (success) {
      this.setState({ notificationStatusReasons });
    }
  };

  setNotificationAnimation = (key, value) => {
    this.setState(({ notificationAnimations }) => ({
      notificationAnimations: { ...notificationAnimations, [key]: value }
    }));
  };

  reloadEmployees = () => {
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/ManagerEmployee/recommend?userId=${this.state.user.userId}`,
      { method: 'get' }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          throw new Error(myJson);
        } else {
          this.setState({ employees: myJson.data });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

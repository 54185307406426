import React from 'react';
import { Button } from 'reactstrap';
import resourceIcon from '../../images/document.svg';
import styles from './Course.module.css';

const ResourceButton = ({ parentSession, onClick }) => (
  <Button
    className={styles.courseInlineButton}
    size="sm"
    outline
    color="white"
    onClick={onClick}
  >
    <img
      className={styles.courseInlineImage}
      src={resourceIcon}
      alt={'resources button'}
    />
    {parentSession.materials.length > 0 && (
      <sup style={{ fontWeight: 'bold' }}>{parentSession.materials.length}</sup>
    )}
  </Button>
);

export default ResourceButton;

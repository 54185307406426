import React from 'react';
import ReactGA from 'react-ga';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Table
} from 'reactstrap';
import { adalApiFetch } from '../../../../adalConfig';
import * as toastRequest from '../../utils/toastRequests';
import style from './AdminReportsTab.module.css';
import { validate, makeFilterType } from '../../utils/dateFilter';
import Report from './Report';
import { AdminTabs } from '../../../../enums/admintabs.enum';
import AdminHomeTabs from '../AdminHomeTabs';
import sharedStyle from '../AdminAllTabs.module.css';

export default class AdminReportsTab extends React.Component {
  // Ensure the reports are put here ALPHABETICALLY
  reports = [
    {
      name: 'Badges sorted by the most engagement (User Counts)',
      endpoint: 'badgeusercounts',
      csvTitle: 'UserCountsBadges'
    },
    {
      name: 'Bricks earned per Pillars (User Counts)',
      endpoint: 'brickstats',
      csvTitle: 'UserCountsBricksEarnedPerPillars'
    },
    {
      name: 'Session Analytics (High Level)',
      endpoint: 'sessionanalytics',
      csvTitle: 'HighLevelAnalytics'
    },
    {
      name:
        'Top Learners (with the highest SCORES + the most number of achieved BADGES)',
      endpoint: 'toplearners',
      csvTitle: 'TopLearners'
    }
  ].sort((a, b) => a.name.localeCompare(b.name));

  constructor() {
    super();
    this.abortController = new AbortController();

    this.state = {
      showDefault: true, // report type
      fromDate: this.defaultDateDisplay(),
      toDate: this.defaultDateDisplay(),
      filters: [
        {
          label: 'Fiscal',
          value: 'Fiscal Year and Quarter'
        },
        {
          label: 'Date',
          value: 'Date Range'
        }
      ],
      years: [],
      quarters: [
        {
          value: 'Q1',
          label: 'Q1'
        },
        {
          value: 'Q2',
          label: 'Q2'
        },
        {
          value: 'Q3',
          label: 'Q3'
        },
        {
          value: 'Q4',
          label: 'Q4'
        }
      ],
      selectedFilter: '',
      selectedYear: '',
      fromQuarter: 'Q1',
      toQuarter: 'Q4'
    };
  }

  componentDidMount() {
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + `/api/Report/fiscalyears`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        },
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `Fiscal years loaded successfully!`
        );

        let years = [];
        for (let i = 0; i < myJson.data.length; i++) {
          years.push({
            value: myJson.data[i].fiscalYear,
            label: myJson.data[i].fiscalYear
          });
        }
        this.setState({ years: years, selectedYear: years[0].value });
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toastRequest.updateToast(
          false,
          `Could not load fiscal years. \n Error: ${e}`
        );
        toastRequest.dismissToast();
      });
  }

  defaultDateDisplay = () => {
    var newDate = new Date().toISOString().substr(0, 10);
    return newDate;
  };

  componentWillUnmount() {
    this.abortController.abort();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value === 'Fiscal Year and Quarter') {
      this.setState({ showDefault: true });
    } else if (e.target.value === 'Date Range') {
      this.setState({ showDefault: false });
    }
  };

  render() {
    return (
      <div className={sharedStyle.adminRoot}>
        <AdminHomeTabs
          activetab={AdminTabs.Reports}
          switchtab={this.props.history.push}
        />
        <Container>
          <div className={style.formContainer}>
            <FormGroup>
              <Form>
                <Row>
                  <Col>
                    <Label for="filterBy">Filtered By</Label>
                    <Input
                      type="select"
                      name="selectedFilter"
                      value={this.state.selectedFilter}
                      id="filterBy"
                      options={this.state.filters}
                      onChange={this.handleChange}
                    >
                      <option disabled value={'default'} key={'default-key'}>
                        Select a filter
                      </option>
                      {this.state.filters.map((filter) => (
                        <option value={filter.value} key={'option-' + filter.label}>
                          {filter.value}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
                <Row style={{ display: this.state.showDefault ? 'flex' : 'none' }}>
                  <Col>
                    <Label for="fiscalYear">Fiscal Year</Label>
                    <Input
                      type="select"
                      name="selectedYear"
                      value={this.state.selectedYear}
                      id="fiscalYear"
                      options={this.state.years}
                      onChange={this.handleChange}
                    >
                      <option disabled value={'default'} key={'default-key'}>
                        Select a year
                      </option>
                      {this.state.years.map((year) => (
                        <option value={year.value} key={'option-' + year.label}>
                          {year.value}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Label for="fiscalFrom">From Quarter</Label>
                    <Input
                      type="select"
                      name="fromQuarter"
                      value={this.state.fromQuarter}
                      id="fiscalFrom"
                      options={this.state.quarters}
                      onChange={this.handleChange}
                    >
                      <option disabled value={'default'} key={'default-key'}>
                        Select a quarter
                      </option>
                      {this.state.quarters.map((q) => (
                        <option value={q.value} key={'option-' + q.label}>
                          {q.value}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Label for="fiscalTo">To Quarter</Label>
                    <Input
                      type="select"
                      name="toQuarter"
                      value={this.state.toQuarter}
                      id="fiscalTo"
                      options={this.state.quarters}
                      onChange={this.handleChange}
                    >
                      <option disabled value={'default'} key={'default-key'}>
                        Select a quarter
                      </option>
                      {this.state.quarters.map((q) => (
                        <option value={q.value} key={'option-' + q.label}>
                          {q.value}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
                <Row style={{ display: this.state.showDefault ? 'none' : 'flex' }}>
                  <Col>
                    <Label for="fromDate">From</Label>
                    <Input
                      type="date"
                      name="fromDate"
                      value={this.state.fromDate}
                      id="fromDate"
                      onChange={this.handleChange}
                    ></Input>
                  </Col>
                  <Col>
                    <Label for="toDate">To</Label>
                    <Input
                      type="date"
                      name="toDate"
                      value={this.state.toDate}
                      id="toDate"
                      onChange={this.handleChange}
                    ></Input>
                  </Col>
                </Row>
              </Form>
            </FormGroup>
            <Table size="sm" striped className={style.reportTable}>
              <thead>
                <tr>
                  <th>Reports</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {this.reports.map((report, i) => {
                  return (
                    <Report
                      key={i}
                      name={report.name}
                      endpoint={report.endpoint}
                      csvTitle={report.csvTitle}
                      validate={() => validate(this.state)}
                      filter={makeFilterType(this.state)}
                    />
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    );
  }
}

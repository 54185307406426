import React from 'react';

export function displayFireWorks() {
  return (
    <div className="innovatorAnimationBox">
      <div className="IApyro">
        <div className="IAbefore"></div>
        <div className="IAafter"></div>
      </div>
    </div>
  );
}

export function displayConfetti() {
  return <div className="innovatorAnimationBox">{createDivForConfetti()}</div>;
}

function createDivForConfetti() {
  let divTag = [];
  for (let i = 0; i < 150; i++) {
    let thisClassname = 'IAconfetti-' + i;
    divTag.push(<div key={i} className={thisClassname}></div>);
  }
  return divTag;
}

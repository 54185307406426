import React from 'react';
import ReactGA from 'react-ga';
import styles from './PresenterShared.module.css';
import { adalApiFetch } from '../../adalConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSquareFull } from '@fortawesome/free-solid-svg-icons';
import './PresenterShared.module.css';
import { validateNoReply } from '../../CONSTANTS'    

export default class PresenterCard extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.state = {
      picture: undefined,
      overflow: false,
      activeButtonNum: 0,
      buttonAmount: 0,
      clickedColor: false,
      unClickedColor: false,
      active: [1, 0, 0, 0] //maximum 4 buttons for scroll the overflowing text
    };

    this.bios = React.createRef();
    this.desc = React.createRef();
    this.buttonIcon = React.createRef();
  }

  componentDidMount() {
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        '/api/User/usergraph?username=' +
        this.props.payload.email,
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success && myJson.data.photo !== null) {
          this.setState({ picture: myJson.data.photo });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });

    //set default height
    this.desc.current.style.top = '0px';
    //set quntities of button
    this.defineButtonNum();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  //maximum 4 buttons for scroll the overflowing text
  defineButtonNum() {
    if (
      this.bios.current.scrollHeight > 240 &&
      this.bios.current.scrollHeight <= 480
    ) {
      this.setState({ overflow: true });
      this.setState({ buttonAmount: 2 });
    } else if (
      this.bios.current.scrollHeight > 480 &&
      this.bios.current.scrollHeight <= 720
    ) {
      this.setState({ overflow: true });
      this.setState({ buttonAmount: 3 });
    } else if (
      this.bios.current.scrollHeight > 720 &&
      this.bios.current.scrollHeight <= 1200
    ) {
      this.setState({ overflow: true });
      this.setState({ buttonAmount: 4 });
    } else {
      this.setState({ overflow: false });
    }
  }

  createButton(num) {
    var buttons = [];
    for (let i = 0; i < num; i++) {
      buttons.push(
        <FontAwesomeIcon
          ref={this.buttonIcon}
          icon={faSquareFull}
          color={this.state.active[i] === 1 ? '#00205B' : '#90CCF4'}
          className={styles.navigation}
          onClick={() => this.clickHandler(i)}
          key={i}
        />
      );
    }
    return buttons;
  }

  clickHandler(index) {
    this.desc.current.style.position = 'relative';
    this.desc.current.style.top = '-' + index * 240 + 'px';
    this.desc.current.style.transition = 'top 2s';
    if (!this.state.clickedColor) {
      this.setState({ clickedColor: true });
    }
    if (this.state.clickedColor) {
      this.setState({ clickedColor: false });
    }

    for (let k = 0; k < this.state.buttonAmount; k++) {
      if (k === index) {
        let newActive = this.state.active;
        newActive[index] = 1;
        this.setState({
          active: newActive
        });
        this.forceUpdate();
      } else {
        let newActive = this.state.active;
        newActive[k] = 0;
        this.setState({
          active: newActive
        });
        this.forceUpdate();
      }
    }
  }

  render() {
    const descButton = this.createButton(this.state.buttonAmount);
    return (
      <div className={styles.card}>
        <div className={styles.imgholder}>
          {typeof this.state.picture !== 'undefined' ? (
            <img
              src={`data:image/jpeg;base64,${this.state.picture}`}
              alt={this.props.payload.name}
              className={styles.picture}
            />
          ) : (
            <>
              <FontAwesomeIcon
                icon={faUser}
                color={'#00205B'}
                backgroundcolor={'#DDDFE0'}
                className={styles.defaultPicture}
              />
              <span className="show-for-sr">{this.props.payload.name}</span>
            </>
          )}
        </div>
        <div className={styles.textLimit} ref={this.bios}>
          <div className={styles.bioText} ref={this.desc}>
            {this.props.payload.bio}
          </div>
        </div>
        {this.state.overflow && (
          <div className={styles.buttonContainer}>
            {descButton.map((bio) => {
              return bio;
            })}
          </div>
        )}
        <div className={styles.cardBody}>
          <span className={styles.name}>
            <b>{this.props.payload.name}</b>
          </span>
          <br />
          <span className={styles.jobDetail}>{this.props.payload.jobTitle}</span>
          <br />
          <span className={styles.jobDetail}>{this.props.payload.branch}</span>
          <br />
                <span className={styles.email}>{!validateNoReply(this.props.payload.email)?this.props.payload.email : null}</span>
        </div>
      </div>
    );
  }
}

import React from 'react';
import style from './../navbar/Header.module.css';
import GlobalContext from '../../context/GlobalContext';

class Points extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPoints: 0
    };
  }
  componentDidMount() {
    var total = 0;
    if (this.context.points !== undefined) {
      this.context.points.forEach((point) => {
        // key=point.
        total += point.achievement.pointValue;
      });
    }
    this.setState({
      totalPoints: total
    });
  }
  render() {
    return (
      <span
        //   className={'header-link'}
        className={style.secondHeaderLink}
        //   ref={this.props.pointRef.current}
      >
        {this.state.totalPoints === 1 ? <span>POINT</span> : <span>POINTS</span>}
        <br />
        {/* <span ref={this.props.pointRef.current}> */}
        <span id="innovatorPointRef">
          {this.state.totalPoints}
          {/* 0 */}
        </span>
      </span>
    );
  }
}
Points.contextType = GlobalContext;

export default Points;

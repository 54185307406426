import React from 'react';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { tableCustomStyles } from './DataTableBaseStyle';

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };



function DataTableBase(props) {
    return (
        <DataTable
            pagination
            customStyles={tableCustomStyles}
            selectableRowsComponent={Checkbox}
    selectableRowsComponentProps = { selectProps }
    sortIcon = { sortIcon }
    dense
            {...props}
    />
    );
}

export default DataTableBase;
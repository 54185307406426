import React from 'react';
import { Button } from 'reactstrap';
import style from './AdminReportsTab.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FilterType } from './FilterType.js';
import { useAdalFetch } from '../../../../adalConfig';
import { downloadCsv } from '../../../../CONSTANTS';
import * as toastRequest from '../../utils/toastRequests';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

function downloadResponsesAsCsv(filename, data) {
  const processRow = (row) => {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j].toString();

      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  const headers = data.headers;
  const responses = data.responses;
  let csvFile = '\uFEFF';

  if (!responses || !responses.length || responses.length === 0) {
    csvFile += processRow(['No report data!']);
  } else {
    csvFile += processRow(headers);
    for (let i = 0; i < responses.length; i++) {
      csvFile += processRow(responses[i]);
    }
  }

  downloadCsv(filename, csvFile);
}

/*
  Props:
    filter: {
        type: FilterType,
        data: {}
    }
    name: 'Session Analytics (High Level)'
    csvTitle: HighLevelAnalytics
    validate: filter validation function
    endpoint: 'sessionanalytics'    ( will hit /api/report/daterange/sessionanalytics 
                                            or /api/report/fiscalyear/sessionanalytics)
*/
function Report(props) {
  const adalFetch = useAdalFetch();

  const downloadCSV = async () => {
    // Validate
    if (!props.validate()) return null;
    let endpoint = null;
    let title = 'Report.csv';
    const today = new Date();
    const hour = today.getHours();
    const min = today.getMinutes();
    const sec = today.getSeconds();
    const filterData = props.filter.data;
    switch (props.filter.type) {
      case FilterType.DateRange:
        endpoint =
          process.env.REACT_APP_API_BASE_DOMAIN +
          `/api/Report/daterange/${props.endpoint}?` +
          (filterData.userId ? `userId=${filterData.userId}&` : '') +
          `fromDate=${filterData.fromDate}&` +
          `toDate=${filterData.toDate}`;
        title = `DW_${props.csvTitle}_${filterData.fromDate}_${filterData.toDate}_h${hour}m${min}s${sec}.csv`;
        break;
      case FilterType.FiscalYear:
        endpoint =
          process.env.REACT_APP_API_BASE_DOMAIN +
          `/api/Report/fiscalyear/${props.endpoint}?` +
          (filterData.userId ? `userId=${filterData.userId}&` : '') +
          `year=${filterData.selectedYear}&` +
          `qFrom=${filterData.fromQuarter}&` +
          `qTo=${filterData.toQuarter}`;
        title = `DW_${props.csvTitle}_Fiscal_${filterData.selectedYear}_${filterData.fromQuarter}_${filterData.toQuarter}_h${hour}m${min}s${sec}.csv`;
        break;
      default:
        ReactGA.exception({
          description: 'Not a valid filter type'
        });
        toastRequest.updateToast(
          false,
          `Could not download data. \n Not valid filterType`
        );
        toastRequest.dismissToast();
        break;
    }
    const toastId = toastRequest.notify(`Downloading ${title}`);
    adalFetch(
      endpoint,
      {
        method: 'get',
        headers: {
          'Content-Type': 'applcation/json'
        }
      },
      function userAbort() {
        toast.dismiss(toastId);
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `${props.name} downloaded successfully!`
        );
        downloadResponsesAsCsv(title, myJson.data);
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toastRequest.updateToast(
          false,
          `Could not download ${props.name}  data. \n Error: ${e}`
        );
        toastRequest.dismissToast();
      });
  };

  if (props.outsideUse === true) {
    return (
      <Button
        className={style.courseInlineButtonOutside}
        size="sm"
        outline
        color="white"
        onClick={downloadCSV}
      >
        CSV
        <FontAwesomeIcon icon={faDownload} />
      </Button>
    );
  } else {
    return (
      <tr>
        <td className={style.reportName}>{props.name}</td>
        <td>
          <Button
            className={style.courseInlineButton}
            size="sm"
            outline
            color="white"
            onClick={downloadCSV}
          >
            CSV
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </td>
      </tr>
    );
  }
}

export default Report;

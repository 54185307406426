import React from 'react';
import DataTable from '../../../components/dataTableBase/DataTableBase';
import ExpandedStaffData from './ExpandedStaffData';
import { Col, Container, Row } from 'reactstrap';
import styles from './StaffReport.module.css'; 
const columns = [
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Last Login',
        selector: row => getDate(row.lastLogin),
        sortable: true,
    },
    {
        name: 'MTO/OPS Mandatory Compliance',
        selector: row => row.compliance === true ? 'Compliant' : 'Non Compliant',
        sortable: true,
    },
];

const getDate = (val) => {
    const dt = new Date(val)
    const options = {
    //    weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    //    hour: 'numeric',
    //    minute: '2-digit'
    };
    if (typeof dt === 'object' && dt !== null && 'toLocaleDateString' in dt) {
        return (dt.toLocaleDateString('en-US', options))
    }
    return null;
};


export default function StaffReport(props) {
    const data = props.staff.map(item => ({ ...item, showSubTable: true }));
    //    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
    return (
        <div className={styles.graphContainer}>
            <div className={styles.chartHeader}>
                <Container className={styles.canvasContainer}>
                    <Row>
                        <Col>
                            <DataTable
                                columns={columns}
                                data={data}
                                expandableRows
                                expandableRowsComponent={ExpandedStaffData}
                                expandableRowsComponentProps={{ "mandatoryCourses": props, "userId": data }}                     
            />

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
import React from 'react';
import Select from 'react-select';
import ReactGA from 'react-ga';
import {
  Form,
  FormGroup,
  Label,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Collapse,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from 'reactstrap';
import delIcon from '../../images/garbage.svg';
import editIcon from '../../images/pencil.svg';
import calendarIcon from '../../images/calendar.svg';
import capOnIcon from '../../images/capOn.svg';
import { adalApiFetch } from '../../../../adalConfig';
import CourseAddModify from './CourseAddModify';
import * as toastRequest from '../../utils/toastRequests';
import styles from './Course.module.css';
import { toast } from 'react-toastify';
import MandatoryCircle from '../../../../components/mandatoryCircle/mandatoryCircle';
import { deepcopy, stringToDate } from '../../../../CONSTANTS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ManagerOnlyTag from '../../../../components/ManagerOnlyTag/ManagerOnlyTag';
import PropTypes from 'prop-types';
import ResourceButton from './ResourceButton';
import { ON_DEMAND_SLUG } from '../../../sessions/components/SessionUtils';
import GlobalContext from '../../../../context/GlobalContext.js';
import { downloadCsv, forceCache } from '../../../../CONSTANTS';
import PromoteHistory from './PromoteHistory';
import sharedStyle from '../AdminAllTabs.module.css';
import { Spinner } from 'reactstrap';

export default class Course extends React.Component {
  static defaultProps = {
    onMaterialsButtonClick: () => {},
    showMaterialsButton: false
  };

  static propTypes = {
    courseDetails: PropTypes.object,
    manualReload: PropTypes.func
  };

  abortController = new AbortController();
  formRef = React.createRef();

  state = {
    modalOpen: false,
    modModal: false,
    collapse: false,
    detailsCollapse: false,
    attendance: null,
    manuallyAdded: null,
    manuallyAddedBoolean: false,
    tempAttendance: [], //tempurary attendence list before pressing grant points button
    tempManuallyAdded: [],
    tempManuallyRemove: [],
    usersList: [],
    tagging: false,
    grantingPoints: false,
    sort: 'name-ascending',
    promotionHistory: [],
    expandPromotionHistory: false,
    selectedUser: null,
    loadingParticipants: true,
    countUsers: 0 //initially 0 for every course because we don't load any
  };

  isOnDemand = () => {
    return (
      this.props.courseDetails &&
      this.props.courseDetails.format.slug === ON_DEMAND_SLUG
    );
  };

  /**
   *
   * Fetch the participants / registrations for this course
   */
  fetchParticipants = () => {
    this.apiFetchLoadUsers(); ///fetch the users from the db
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/Course/participants/${this.props.courseDetails.courseDetailsId}`,
      {
        method: 'get',
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (!response.ok) {
          toast(
            'An error occurred when attempting to load participants. Please try again later.',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          return response.json();
        }
      })
      .then((myJson) => {
        if (myJson.success === false) {
          toast('Error loading participants.', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          const attendance = myJson.data.sort((a, b) =>
            a.user.name.localeCompare(b.user.name)
          );
          this.setState({ attendance });
          this.setState({
            countUsers: this.state.attendance.length,
            loadingParticipants: false
          });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toast('An error occurred!', {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      });
  };

  componentDidMount() {
    let length = this.props.courseDetails.promotions.length;
    if (length > 0) {
      this.updateLastPromotedDate(this.props.courseDetails.promotions);
    }
  } //

  componentWillUnmount() {
    this.abortController.abort();
  } //

  //  Load users from API of Database
  apiFetchLoadUsers() {
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/dropdown',
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when loading User list', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          let userList = [];
          for (let i = 0; i < myJson.data.length; i++) {
            userList.push({
              value: myJson.data[i].userId,
              label: myJson.data[i].email
            });
          }
          this.setState({ usersList: userList });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toast('An error occurred when loading User list.', {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      });
  }

  SubmitAttendance = () => {
    toastRequest.notify('Updating Attendance...');
    if (this.state.tempAttendance.length < 1) {
      toastRequest.updateToast(
        false,
        `Please select user(s) to update attendance for.`
      );
      return;
    }
    var tempAttendanceList = this.state.tempAttendance;
    this.setState({
      tempAttendance: []
    });
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Register/submitattendance',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(tempAttendanceList),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed, Error ${response.status}!`);
        }
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `Points granted successfully.`
        );
        this.fetchParticipants();
      })
      .catch((e) => {
        toastRequest.error(`Granting points failed! ${e.toString()}`);
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  deleteRegistrations = () => {
    //helps admin to deregister participants from a course
    toastRequest.notify('Updating Participants...');
    var tempManuallyRemove = this.state.tempManuallyRemove;
    this.setState({
      tempManuallyRemove: []
    });
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Register/deleteregistrations',
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(tempManuallyRemove),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed, Error ${response.status}!`);
        }
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'The selected LTC registrants have been removed from the session.'
        );
        this.fetchParticipants();
      })
      .catch((e) => {
        toastRequest.error(`Participants removal failed!`);
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen
    }));
  };

  toggleModifyModal = () => {
    this.setState((prevState) => ({
      modModal: !prevState.modModal
    }));
  };

  toggleCollapse = () => {
    if (!this.state.attendance) {
      this.fetchParticipants();
    }
    this.setState((state) => ({
      collapse: !state.collapse,
      detailsCollapse: false
    }));
  };

  toggleDetailsCollapse = () => {
    this.setState((state) => ({
      detailsCollapse: !state.detailsCollapse,
      collapse: false
    }));
  };

  countAttended = () => {
    return this.state.attendance?.filter((item) => item.attended).length || 0;
  };

  countManuallyAdded = () => {
    return this.state.attendance?.filter((item) => item.manuallyAdded).length || 0;
  };

  generateRow = (title, data) => (
    <tr>
      <td>{title}</td>
      <td>
        {data.map((d, index) => (
          <Badge
            color="secondary"
            size={'sm'}
            key={
              'attribute-' +
              title +
              d.name +
              this.props.courseDetails.courseDetailsId +
              this.props.courseDetails.courseId +
              index
            }
          >
            {d.name}
          </Badge>
        ))}
      </td>
    </tr>
  );

  updateCourse = () => {
    this.formRef.current.update(() => {
      this.toggleModifyModal();
    });
  };

  deleteCourse = () => {
    toastRequest.notify('Deleting Course');
    this.toggleDeleteModal();

    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/Course/Details/${this.props.courseDetails.courseDetailsId}`,
      { method: 'delete', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `Session "${this.props.courseDetails.courseName}" deleted successfully!`
        );
        if (myJson.success === true) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  };
  resendCalendarInvites = () => {
    if (stringToDate(this.props.courseDetails.courseStart) < Date.now()) {
      toast('Error: you cannot send calendar updates for a past session', {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
    } else {
      toastRequest.notify('Re-sending calendar invites');
      adalApiFetch(
        fetch,
        process.env.REACT_APP_API_BASE_DOMAIN +
          `/api/Course/${this.props.courseDetails.courseDetailsId}/sendupdatedinvites`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          },
          signal: this.abortController.signal
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          toastRequest.updateToast(
            myJson.success,
            myJson.error,
            `Session "${this.props.courseDetails.courseName}" invite resent successfully.`
          );
        })
        .catch((e) => {
          ReactGA.exception({
            description: e.toString()
          });
          toastRequest.dismissToast();
        });
    }
  };

  // This function is based on https://stackoverflow.com/a/24922761 (author: Xavier John)
  // Modified by Kindeep Singh Kargil on (March 6, 2020)
  downloadResponsesAsCsv(filename, responses) {
    const processRow = (row) => {
      let finalVal = '';
      for (let j = 0; j < row.length; j++) {
        let innerValue = !row[j] ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        if (j > 0) finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    let csvFile = '\uFEFF';

    if (!responses || !responses.length || responses.length === 0) {
      csvFile += processRow(['No survey data!']);
    } else {
      const header = ['Name', 'Email', 'Date and Time'];
      header.push(...responses[0].questions.map((q) => q.question));
      csvFile += processRow(header);
    }
    for (let i = 0; i < responses.length; i++) {
      const response = responses[i];
      const row = [response.userName, response.userEmail, response.responseDateTime];
      row.push(...response.questions.map((q) => q.answer));
      csvFile += processRow(row);
    }

    downloadCsv(filename, csvFile);
  }

  downloadSurveyCSV = () => {
    toastRequest.notify(
      `Downloading survey responses for ${this.props.courseDetails.courseName}`
    );
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/Survey/csv/${this.props.courseDetails.courseDetailsId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        },
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `Survey data for ${this.props.courseDetails.courseName} downloaded successfully!`
        );
        this.downloadResponsesAsCsv(
          `session_(${this.props.courseDetails.courseName})_survey_data.csv`,
          myJson.data
        );
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toastRequest.updateToast(
          false,
          `Could not fetch survey data for ${this.props.courseDetails.courseName}. \n Error: ${e}`
        );
        toastRequest.dismissToast();
      });
  };

  isPastCourse = () => {
    return (
      this.props.courseDetails &&
      stringToDate(this.props.courseDetails.courseStart).getTime() <
        new Date().getTime()
    );
  };

  isUpcomingSession = () => {
    return (
      stringToDate(this.props.courseDetails.courseStart).getTime() >
      new Date().getTime()
    );
  };

  updateLastPromotedDate = (promotionDates) => {
    // Reverse the dates to display them in decreasing order
    const promotionHistoryObj = promotionDates.reverse();
    let promotionHistory = [];
    for (let i = 0; i < promotionHistoryObj.length; i++) {
      const dateObj = promotionHistoryObj[i].sentOn;
      const dateStr = stringToDate(dateObj)
        .toString()
        .substring(4, 15);
      promotionHistory.push(dateStr);
    }
    this.setState({
      promotionHistory: promotionHistory
    });
  };

  promoteSession = () => {
    let data = {
      courseDetailsId: this.props.courseDetails.courseDetailsId,
      envBaseUrl: process.env.REACT_APP_UI_BASE_DOMAIN,
      sentOn: new Date(),
      sentByUserId: this.context.user.userId
    };

    toastRequest.notify('Promoting session');
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course/promote',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (response.ok === true) {
          forceCache();
          return response.json();
        } else {
          throw new Error(`Request failed, Error ${response.status}`);
        }
      })
      .then((myJson) => {
        if (myJson.success === true) {
          // Get the new list of Promotion History & update the state
          adalApiFetch(
            fetch,
            process.env.REACT_APP_API_BASE_DOMAIN +
              `/api/Course/promote?CourseDetailsId=${this.props.courseDetails.courseDetailsId}`,
            {
              method: 'get',
              signal: this.abortController.signal
            }
          )
            .then((response) => {
              if (response.ok === true) {
                return response.json();
              } else {
                throw new Error(`Request failed, Error ${response.status}`);
              }
            })
            .then((myJson) => {
              if (myJson.success === true) {
                this.updateLastPromotedDate(myJson.data);
              }
            });

          toastRequest.updateToast(
            myJson.success,
            myJson.error,
            `${this.props.courseDetails.courseName} has been promoted!`
          );
        } else {
          toastRequest.updateToast(
            false,
            `An error occurred while promoting session ${this.props.courseDetails.courseName}`
          );
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  downloadParticipantsCsv = () => {
    if (!this.state.attendance) {
      return;
    }

    let filename = `${this.props.courseDetails.courseName} - Session Participants.csv`;
    let csv = 'Name,Email,Branch,Attended,Manually Added\n';

    this.state.attendance.forEach((p) => {
      csv += `"${p.user.name}",${p.user.email},${p.user.branch},${
        p.attended ? '1' : '0'
      },${p.manuallyAdded ? '1' : '0'}\n`;
    });

    downloadCsv(filename, csv);
  };

  render() {
    return (
      <Card
        className={this.props.courseDetails.courseName}
        data-id={this.props.courseDetails.courseDetailsId}
      >
        <CardHeader>
          <h6 className={`${sharedStyle.cardTitle} ${styles.floatLeft}`}>
            {this.props.courseDetails.courseName}
          </h6>
          {this.props.parentCourse && this.props.parentCourse.mandatory && (
            <div className={styles.mandatoryCircle}>
              <MandatoryCircle />
            </div>
          )}
          {/*DELETE BUTTON*/}
          <Button
            className={styles.courseInlineButton}
            size="sm"
            id={'course-delete-' + this.props.courseDetails.courseDetailsId}
            outline
            color="danger"
            onClick={this.toggleDeleteModal}
          >
            <img
              className={styles.courseInlineImage}
              src={delIcon}
              alt={'delete button'}
              width="18px"
            />
          </Button>
          {/*EDIT BUTTON*/}
          <Button
            className={styles.courseInlineButton}
            size="sm"
            outline
            color="white"
            id={`edit-button-${this.props.courseDetails.courseId}`}
            onClick={this.toggleModifyModal}
          >
            <img
              className={styles.courseInlineImage}
              src={editIcon}
              alt={'edit button'}
            />
          </Button>
          {/*RESEND INVITES BUTTON*/}
          {this.props.courseDetails.format.slug !== ON_DEMAND_SLUG && (
            <Button
              className={styles.courseInlineButton}
              size="sm"
              outline
              color="white"
              onClick={this.resendCalendarInvites}
            >
              <img
                className={styles.courseInlineImage}
                src={calendarIcon}
                alt={'Resend Invite'}
                title={'Resend Invite'}
              />
            </Button>
          )}

          <Button
            className={styles.courseInlineButton}
            size="sm"
            outline
            color="white"
            onClick={this.downloadSurveyCSV}
            disabled={!this.isPastCourse()}
          >
            Survey
            <FontAwesomeIcon icon={faDownload} />
          </Button>

          {this.props.showMaterialsButton && (
            <ResourceButton
              className={styles.courseInlineButton}
              parentSession={this.props.parentCourse}
              onClick={this.props.onMaterialsButtonClick}
            ></ResourceButton>
          )}
          {this.props.parentCourse && this.props.parentCourse.managerOnly && (
            <div>
              <ManagerOnlyTag />
            </div>
          )}
        </CardHeader>
        <CardBody className={styles.thinCard}>
          <div className={styles.courseBottomButton}>
            <div className={styles.courseBottomButton}>
              {/*DETAILS BUTTON*/}
              <Button
                className={styles.courseDisplayTableButton}
                data-id={`session-details-${this.props.courseDetails.courseId}`}
                color="secondary"
                size={'sm'}
                outline
                onClick={this.toggleDetailsCollapse}
              >
                {this.state.detailsCollapse ? 'Hide Details' : 'Show Details'}
              </Button>
              {/*REGISTRATION AND ATTENDANCE BUTTON*/}
              <Button
                className={styles.courseDisplayTableButton}
                data-id={`session-participants-${this.props.courseDetails.courseId}`}
                color="secondary"
                size={'sm'}
                outline
                onClick={this.toggleCollapse}
              >
                {!this.state.collapse ? `Show participants` : `Hide participants`}
              </Button>
              {/* Max Capacity Enforced */}
              {!this.isOnDemand() &&
                (this.props.courseDetails.maximumCapacity ? (
                  <span
                    title={`Max capacity at ${this.props.courseDetails.maximumCapacity}`}
                  >
                    <img
                      className={styles.maxCapacityIndicator}
                      src={capOnIcon}
                      alt={`Max capacity at ${this.props.courseDetails.maximumCapacity}`}
                    />
                  </span>
                ) : (
                  <span title="Max capacity off"></span>
                ))}
            </div>
            <div
              className={
                (styles.courseBottomButton, styles.courseBottomButtons__right)
              }
            >
              {/*PROMOTE BUTTON*/}
              {this.isUpcomingSession() && (
                <>
                  <span>
                    {this.state.promotionHistory.length === 0 ? (
                      ''
                    ) : (
                      <PromoteHistory
                        courseDetailsId={this.props.courseDetails.courseId}
                        promotionHistory={this.state.promotionHistory}
                        promotionCount={this.state.promotionHistory.length}
                      />
                    )}
                  </span>
                  <Button
                    className={
                      (styles.courseDisplayTableButton, styles.buttonMargin)
                    }
                    id={`promote-button-${this.props.courseDetails.courseId}`}
                    color="secondary"
                    size={'sm'}
                    outline
                    onClick={this.promoteSession}
                  >
                    Promote
                  </Button>
                </>
              )}
            </div>
          </div>
          {/*DETAILS AND ATTENDANCE TABLES ABSTRACTED INTO METHODS FOR READABILITY*/}
          {this.state.detailsCollapse && this.displayDetails()}
          {this.state.collapse && this.displayParticipants()}
        </CardBody>

        {/*DELETE MODAL*/}
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleDeleteModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>Confirm Deletion</ModalHeader>
          <ModalBody>
            Are you sure you want to delete Session "
            {this.props.courseDetails.courseName}
            "? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              id="delete-modal-confirm-delete"
              onClick={this.deleteCourse}
            >
              DELETE
            </Button>{' '}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/*MODIFY MODAL*/}
        <Modal
          size={'lg'}
          isOpen={this.state.modModal}
          toggle={this.toggleModifyModal}
          className={this.props.className}
        >
          {this.state.modModal && (
            <>
              <ModalHeader toggle={this.toggleModifyModal}>
                Update this Session
              </ModalHeader>
              <ModalBody>
                <CourseAddModify
                  courseDetails={this.props.courseDetails}
                  ref={this.formRef}
                  modify={true}
                  isPast={this.isPastCourse()}
                  manualReload={this.props.manualReload}
                  parentCourse={this.props.parentCourse}
                  repeating={
                    this.props.parentCourse &&
                    this.props.parentCourse.courseDetails.length > 1
                  }
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  id="modify-button"
                  color="primary"
                  onClick={this.updateCourse}
                >
                  Modify
                </Button>
                <Button
                  color="secondary"
                  id="exitEdit"
                  onClick={this.toggleModifyModal}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </Modal>
      </Card>
    );
  }

  displayDetails = () => {
    let startTime = stringToDate(this.props.courseDetails.courseStart);
    let endTime = stringToDate(this.props.courseDetails.courseEnd);

    return (
      <Collapse isOpen={this.state.detailsCollapse} className={styles.collapse}>
        <Card className={styles.innerCourseCard}>
          <CardBody>
            <Table size="sm" striped>
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Session Details</td>
                  <td>
                    <CardText
                      dangerouslySetInnerHTML={{
                        __html: this.props.courseDetails.details
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Meeting Info</td>
                  <td>
                    <CardText
                      dangerouslySetInnerHTML={{
                        __html: this.props.courseDetails.meetingInfo
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Start</td>
                  <td>
                    {startTime.toDateString() +
                      ', ' +
                      startTime.toLocaleTimeString()}
                  </td>
                </tr>
                <tr>
                  <td>End</td>
                  <td>
                    {endTime.toDateString() + ', ' + endTime.toLocaleTimeString()}
                  </td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>{this.props.courseDetails.courseDuration} min</td>
                </tr>
                <tr>
                  <td>Pillar</td>
                  <td>{this.props.domain.name}</td>
                </tr>
                <tr>
                  <td>Categories</td>
                  <td>{this.props.sessionType.name}</td>
                </tr>
                <tr>
                  <td>Format</td>
                  <td>{this.props.courseDetails.format.name}</td>
                </tr>
                <tr>
                  <td>L+D Rep</td>
                  <td>{this.props.courseDetails.rep.name}</td>
                </tr>
                {this.props.courseDetails.format.slug === ON_DEMAND_SLUG && (
                  <tr>
                    <td>Active</td>
                    <td>{this.props.courseDetails.active ? 'Yes' : 'No'}</td>
                  </tr>
                )}
                {this.generateRow('Attributes', this.props.courseDetails.attributes)}
                {this.generateRow('Locations', this.props.courseDetails.locations)}
                {this.generateRow('Presenters', this.props.courseDetails.presenters)}
                {this.generateRow('Providers', this.props.courseDetails.providers)}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Collapse>
    );
  };

  sortParticipants = (key, ascending) => {
    if (!this.state.attendance) {
      return;
    }

    const participants = deepcopy(this.state.attendance);
    participants.sort(
      (a, b) => a.user[key].localeCompare(b.user[key]) * (ascending ? -1 : 1)
    );
    this.setState({
      attendance: participants,
      sort: ascending ? `${key}-descending` : `${key}-ascending`
    });
  };

  sortManuallyAdded() {
    if (!this.state.attendance) {
      return;
    }

    const participants = deepcopy(this.state.attendance);
    participants.sort((a, b) => a.manuallyAdded - b.manuallyAdded).reverse();
    this.setState({
      attendance: participants
    });
  }

  register(courseId, courseDetailsId) {
    //registersausermanually

    if (this.parseForm() == null) {
      //checks if everything in the form is ok
      return;
    }
    const data = {
      userId: this.parseForm(),
      courseId: courseId,
      courseDetailsId: courseDetailsId,
      manuallyAdded: true
    };

    if (this.isUpcomingSession()) {
      data.InviteSent = true;
    }
    // REGISTER
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Register/adminaddparticipant',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
      .then(
        (response) => {
          return response.json();
        },
        () => {
          toast('An Error occurred!', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        }
      )
      .then(() => {
        toast(
          `Participant ${this.state.selectedUser.label} added to the session successfully.`,
          {
            type: toast.TYPE.SUCCESS,
            autoClose: true
          }
        );
        this.fetchParticipants();
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toast('An error occurred!', {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      });
  }

  //helps to detect if there are no email address or if the participant is already in the list
  parseForm() {
    if (this.state.attendance == null) {
      return null;
    }

    if (!this.state.selectedUser) {
      //if no email address are selected
      toast('Please select an Email address.', {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return null;
    }

    const found = this.state.attendance.find(
      (element) => element.user.userIdentity === this.state.selectedUser.label
    );
    if (found != null && this.state.selectedUser.label === found.user.userIdentity) {
      //checks for already registered user
      toast(
        'This email address exists in the participant list. Please enter another email address.',
        {
          type: toast.TYPE.ERROR,
          autoClose: true
        }
      );
      return null;
    }

    //user id
    return this.state.selectedUser.value;
  }

  handleChange = (selectedUser) => {
    this.setState({ selectedUser });
  };

  displayParticipants = () => (
    <Collapse isOpen={this.state.collapse} className={styles.collapse}>
      <Card className={styles.innerCourseCard}>
        <CardBody>
          <div>
            Total: {this.state.countUsers}{' '}
            {this.state.loadingParticipants && <Spinner />}
          </div>
          <div
            style={{ display: 'flex', flexFlow: 'row-reverse', marginBottom: '6px' }}
          >
            {this.state.attendance?.length > 0 && (
              <Button
                className={styles.exportParticipantListCsvButton}
                id={`download-csv-${this.props.courseDetails.courseId}`}
                color="secondary"
                size={'sm'}
                outline
                onClick={this.downloadParticipantsCsv}
              >
                CSV &nbsp;
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            )}
          </div>
          {
            <div style={{ borderTop: '1px solid grey', mariginTop: '3px' }}>
              <Form onSubmit={(event) => event.preventDefault()}>
                {/*USERNAME*/}
                <Label
                  style={{
                    margin: '7px 0px 0px 0px',
                    paddingTop: '1px',
                    paddingBottom: '1px'
                  }}
                  for="recipientEmail"
                >
                  Add Participant
                </Label>
                <FormGroup>
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                      width: '100%'
                    }}
                  >
                    <div style={{ width: '85%' }}>
                      <Select
                        value={
                          this.state.selectedUser != null
                            ? this.state.selectedUser
                            : 'Select an email address'
                        }
                        Selected="Select An Email Address"
                        onChange={this.handleChange}
                        inputId="recipientEmail"
                        options={this.state.usersList}
                        isLoading={this.state.usersList.length === 0}
                      />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                      <Button
                        disabled={this.state.selectedUser?.value == null}
                        className={styles.participantbuttonsubmit}
                        onClick={() =>
                          this.register(
                            this.props.courseDetails.courseId,
                            this.props.courseDetails.courseDetailsId
                          )
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </FormGroup>
              </Form>
            </div>
          }
          {this.state.attendance?.length > 0 && (
            <Table size="sm" striped>
              <thead>
                <tr>
                  <th
                    onClick={() =>
                      this.sortParticipants(
                        'email',
                        this.state.sort === 'email-ascending'
                      )
                    }
                    className={styles.hoverPointer}
                  >
                    Email &nbsp;
                    {this.state.sort.substring(0, 5) === 'email' && (
                      <i
                        className={[
                          styles.arrow,
                          this.state.sort === 'email-descending'
                            ? styles.down
                            : styles.up
                        ].join(' ')}
                      />
                    )}
                  </th>
                  <th
                    onClick={() =>
                      this.sortParticipants(
                        'branch',
                        this.state.sort === 'branch-ascending'
                      )
                    }
                    className={styles.hoverPointer}
                  >
                    Branch &nbsp;
                    {this.state.sort.substring(0, 6) === 'branch' && (
                      <i
                        className={[
                          styles.arrow,
                          this.state.sort === 'branch-descending'
                            ? styles.down
                            : styles.up
                        ].join(' ')}
                      />
                    )}
                  </th>
                  <th
                    onClick={() => this.sortManuallyAdded()}
                    className={styles.hoverPointer}
                  >
                    {this.countManuallyAdded()} Added Manually
                  </th>
                  <th>{this.countAttended()} Attended</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {this.state.attendance?.map((participant) => {
                  return (
                    <tr key={participant.userId}>
                      <td>{participant.user.email}</td>
                      <td>{participant.user.branch}</td>
                      <td>
                        <>
                          <Label
                            style={{ display: 'none' }}
                            for={`checkboxManuallyAdded-${this.props.courseDetails.courseId}-${participant.user.email}`}
                          >
                            {participant.manuallyAdded
                              ? 'Manually Added'
                              : 'Not Manually Added'}
                          </Label>
                          <Input
                            className={styles.aligncellitemscourse}
                            type="checkbox"
                            id={`checkboxManuallyAdded-${this.props.courseDetails.courseId}-${participant.user.email}`}
                            checked={
                              participant.manuallyAdded ||
                              !!this.state.tempManuallyAdded.find(
                                (element) => element.userId === participant.userId
                              )
                            }
                            disabled={participant.manuallyAdded}
                            onChange={() => {
                              var participantToUpdate = this.state.tempManuallyAdded.find(
                                (element) => element.userId === participant.userId
                              );
                              const newTempManuallyAdded = JSON.parse(
                                JSON.stringify(this.state.tempManuallyAdded)
                              );
                              if (participantToUpdate) {
                                newTempManuallyAdded.splice(
                                  newTempManuallyAdded.findIndex(
                                    (element) =>
                                      element.userId === participant.userId
                                  ),
                                  1
                                ); //if it was in the list->remove
                              } else {
                                newTempManuallyAdded.push(participant);
                              }
                              this.setState({
                                tempManuallyAdded: newTempManuallyAdded
                              });
                            }}
                          />
                        </>
                      </td>
                      <td>
                        <>
                          <Label
                            style={{ display: 'none' }}
                            for={`checkboxAttended-${this.props.courseDetails.courseId}-${participant.user.email}`}
                          >
                            {participant.attended
                              ? 'Participant Attended'
                              : 'Participant Not Attended'}
                          </Label>
                          <Input
                            className={styles.aligncellitemscourse}
                            type="checkbox"
                            id={`checkboxAttended-${this.props.courseDetails.courseId}-${participant.user.email}`}
                            checked={
                              participant.attended ||
                              !!this.state.tempAttendance.find(
                                (element) => element.userId === participant.userId
                              )
                            }
                            disabled={!this.isPastCourse() || participant.attended}
                            onChange={() => {
                              var participantToUpdate = this.state.tempAttendance.find(
                                (element) => element.userId === participant.userId
                              );
                              const newTempAttendance = JSON.parse(
                                JSON.stringify(this.state.tempAttendance)
                              );
                              if (participantToUpdate) {
                                newTempAttendance.splice(
                                  newTempAttendance.findIndex(
                                    (element) =>
                                      element.userId === participant.userId
                                  ),
                                  1
                                ); //if it was in the list->remove
                              } else {
                                newTempAttendance.push(participant);
                              }
                              this.setState({
                                tempAttendance: newTempAttendance
                              });
                            }}
                          />
                        </>
                      </td>
                      <td>
                        <>
                          <Label
                            style={{ display: 'none' }}
                            for={`checkboxManuallyRemove-${this.props.courseDetails.courseId}-${participant.user.email}`}
                          >
                            {participant.registered
                              ? 'Participant registered'
                              : 'Participant not registered'}
                          </Label>
                          <Input
                            className={styles.aligncellitemscourse}
                            // Mandatory, on-demand courses can have participation removed since achievements are not granted
                            disabled={
                              !(
                                this.props.parentCourse.mandatory &&
                                this.props.courseDetails.format.slug == 'on_demand'
                              ) && participant.attended
                            }
                            type="checkbox"
                            id={`checkboxManuallyRemove-${this.props.courseDetails.courseId}-${participant.user.email}`}
                            checked={
                              !!this.state.tempManuallyRemove.find(
                                (element) => element.userId === participant.userId
                              )
                            }
                            onChange={() => {
                              var participantToUpdate = this.state.tempManuallyRemove.find(
                                (element) => element.userId === participant.userId
                              );
                              const newTempManuallyRemove = JSON.parse(
                                JSON.stringify(this.state.tempManuallyRemove)
                              );
                              if (participantToUpdate) {
                                newTempManuallyRemove.splice(
                                  newTempManuallyRemove.findIndex(
                                    (element) =>
                                      element.userId === participant.userId
                                  ),
                                  1
                                ); //if it was in the list->remove
                              } else {
                                newTempManuallyRemove.push(participant);
                              }
                              this.setState({
                                tempManuallyRemove: newTempManuallyRemove
                              });
                            }}
                          />
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      {this.state.attendance?.length > 0 && (
        <div style={{ float: 'right' }}>
          <Button
            id={`grant-points-${this.props.courseDetails.courseId}`}
            disabled={this.isUpcomingSession()}
            onClick={this.SubmitAttendance}
          >
            Grant Points
          </Button>
          <Button
            disabled={!this.state.tempManuallyRemove.length}
            className={`${styles.participantbuttonsubmit} ${styles.buttonMargin}`}
            onClick={() => this.deleteRegistrations()}
          >
            Remove
          </Button>
        </div>
      )}
    </Collapse>
  );
}
Course.contextType = GlobalContext;

export default function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
  }
  return function(a, b) {
    let object1 = a;
    let object2 = b;

    //Expand and set for sorting by nested properties
    for (let i = 0; i < property.length; i++) {
      if (property[i] !== '-') {
        object1 = object1[property[i]];
        object2 = object2[property[i]];
      }
    }

    if (
      (typeof object1 === 'string' || object1 instanceof String) &&
      (typeof object2 === 'string' || object2 instanceof String)
    ) {
      object1 = object1.toLowerCase();
      object2 = object2.toLowerCase();
    }
    var result;

    if (object1 == null) {
      result = 1;
    } else if (object2 == null) {
      result = -1;
    } else {
      result = object1 < object2 ? -1 : object1 > object2 ? 1 : 0;
    }

    return result * sortOrder;
  };
}

import { adalApiFetch } from '../../adalConfig';

export function GetWelcomeBadge() {
  return adalApiFetch(
    fetch,
    process.env.REACT_APP_API_BASE_DOMAIN +
      '/api/Achievements/grantfirstloginachievement',
    { method: 'get' }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
}

export function GetRegistrationBadge() {
  return adalApiFetch(
    fetch,
    process.env.REACT_APP_API_BASE_DOMAIN +
      '/api/Achievements/grantregisteredachievement',
    { method: 'get' }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
}

export function GetBuddyBadge() {
  return adalApiFetch(
    fetch,
    process.env.REACT_APP_API_BASE_DOMAIN +
      '/api/Achievements/grantbuddyachievement',
    { method: 'get' }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
}

export function GetSurveyBadge() {
  return adalApiFetch(
    fetch,
    process.env.REACT_APP_API_BASE_DOMAIN +
      '/api/Achievements/grantsurveyachievement',
    { method: 'get' }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
}

export function GetNotLtcBadge() {
  return adalApiFetch(
    fetch,
    process.env.REACT_APP_API_BASE_DOMAIN +
      '/api/Achievements/grantnonltcachievement',
    { method: 'get' }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
}

export function GetHostBadge() {
  return adalApiFetch(
    fetch,
    process.env.REACT_APP_API_BASE_DOMAIN + '/api/Achievements/granthostachievement',
    { method: 'get' }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
}

export default function GetShadowBadge() {
  return adalApiFetch(
    fetch,
    process.env.REACT_APP_API_BASE_DOMAIN +
      '/api/Achievements/grantshadowachievement',
    { method: 'get' }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
}

export function GetAllActiveAchievements(abortController) {
  let signal;
  if (abortController) {
    signal = abortController.signal;
  }
  return adalApiFetch(
    fetch,
    process.env.REACT_APP_API_BASE_DOMAIN + '/api/Achievements/active',
    { method: 'get', signal }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
}

import React from 'react';
import { Link } from 'react-router-dom';
import style from './../navbar/Header.module.css';
import { checkInnovatorStatus } from './../../CONSTANTS';
import { UncontrolledTooltip } from 'reactstrap';
import GlobalContext from '../../context/GlobalContext';

class Badges extends React.Component {
  state = {
    badges: []
  };

  componentDidMount() {
    this.getSortedBadges();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.didJustReceiveNewBadge !== this.props.didJustReceiveNewBadge)
      this.getSortedBadges();
  }

  getSortedBadges = () => {
    if (this.context.badges !== undefined) {
      var sortedBadges = this.context.badges.slice(0);
      sortedBadges.sort(function(a, b) {
        var x = a.dateAwarded.toLowerCase();
        var y = b.dateAwarded.toLowerCase();
        return x > y ? -1 : x > y ? 1 : 0;
      });
      this.setState({
        badges: sortedBadges
      });
    }
  };

  visibleBadges() {
    const MAX_BADGES_TO_SHOW = 3;

    let visibleBadges = [];
    this.state.badges.forEach((currentBadge) => {
      if (
        checkInnovatorStatus(
          this.context.user.tutorialStatus,
          currentBadge.achievement.slug
        )
      ) {
        // Innovator has appeared already
        visibleBadges.push(
          <span
            key={currentBadge.achievement.slug}
            id={`navBadge${currentBadge.achievement.slug}`}
          >
            <img
              className={[
                style.navIconLink,
                this.props.didJustReceiveNewBadge ===
                  currentBadge.achievement.slug && style.mostRecentBadgeOnNavbar
              ].join(' ')}
              src={require('../../assets/badges/' +
                currentBadge.achievement.imageLink)}
              alt={currentBadge.achievement.name + ' Badge'}
            />
            <UncontrolledTooltip
              placement="bottom"
              target={`navBadge${currentBadge.achievement.slug}`}
            >
              {currentBadge.achievement.description +
                ' ' +
                currentBadge.dateAwarded.split('T')[0]}
            </UncontrolledTooltip>
          </span>
        );
      }
    });

    if (visibleBadges.length > MAX_BADGES_TO_SHOW) {
      visibleBadges.splice(MAX_BADGES_TO_SHOW); // Keep first three badges
      visibleBadges.push(
        <Link key="moreLink" to="/dashboard">
          <img
            className={style.navIconLink}
            src={require('../../assets/more.png')}
            alt="Show more badges on My Dashboard link logo"
          />
        </Link>
      );
    }

    visibleBadges.splice(1, 0, <span key="badgeRef" id="badgeRef" />); // Add the innovator pointer reference after the first badge

    return visibleBadges;
  }

  render() {
    return <span className={style.badgeSet}>{this.visibleBadges()}</span>;
  }
}
Badges.contextType = GlobalContext;

export default Badges;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import DigiHeadingBox from '../../../../components/DigiHeadingBox.jsx/DigiHeadingBox';
import GlobalContext from '../../../../context/GlobalContext';
import DigiCheckbox from '../../../../components/digiCheckbox/DigiCheckbox';
import styles from './RecommendContainer.module.css';
import RecommendButton from '../../../../components/recommendButton/RecommendButton';
import { adalPost, adalGet } from '../../../../adalConfig';
import { deepcopy, stringToDate } from '../../../../CONSTANTS';
import { getCourseLink } from '../../../../utils/courses';
import * as toastRequest from '../../../admin/utils/toastRequests';
import CustomScroll from '../../../../components/customScroll/CustomScroll';
import dwLogo from '../../../../assets/logo-email.png';
import { urlToBase64 } from '../../../../utils/images';
import { isOnDemand } from '../SessionUtils';
import ReactGA from 'react-ga';

const RecommendContainer = ({ session, onCloseClick, onCloseAndReload }) => {
  const [selectedEmployees, selectedEmployeesChange] = useState({}); // Represents the employees that the email will actually be sent to
  const [selectAll, selectAllChange] = useState(false);
  const { employees } = useContext(GlobalContext);
  const [dwUri, dwUriChange] = useState(null);
  const [sentDict, sentDictChange] = useState({});
  const [declinedDict, declinedDictChange] = useState({});
  const [disableInput, disableInputChange] = useState(false);
  const [loadingRecommendations, loadingRecommendationsChange] = useState(false);
  const [participants, setPartipcipants] = useState(null);

  const fetchImages = async () => {
    dwUriChange(await urlToBase64(dwLogo));
  };

  const fetchRecommendations = useCallback(
    async (abortController) => {
      loadingRecommendationsChange(true);
      disableInputChange(true);
      const { success, data: recommendations, error } = await adalGet(
        '/api/Notifications/recommendation',
        {
          courseDetailsId: session.courseDetailsId
        },
        abortController
      );
      if (!success) {
        toastRequest.error(
          `Something went wrong while fetching sent recommendations!`
        );
        ReactGA.exception({
          description: error
        });
      } else {
        const newDict = {};
        const secondDict = {};
        recommendations.forEach((recommendation) => {
          newDict[recommendation.userId] = true;
          if (recommendation.status === 'declined')
            secondDict[recommendation.userId] = true;
        });

        sentDictChange(newDict);
        declinedDictChange(secondDict);
        disableInputChange(false);
      }
      loadingRecommendationsChange(false);
    },
    [session]
  );

  useEffect(() => {
    const abortController = new AbortController();
    async function fetchRegistrations() {
      const { success, data: records, error } = await adalGet(
        `/api/Register/courseregistrations?sessionId=${session.courseId}`,
        null,
        abortController
      );

      if (!success) {
        toastRequest.error(`Something went wrong while fetching staff!`);
        ReactGA.exception({
          description: error
        });
      } else {
        const participants = [];
        records.forEach((record) => {
          participants.push(record);
        });
        setPartipcipants(participants);
        // console.log(participants)
      }
    }

    fetchRegistrations();
    return () => {
      abortController.abort();
    };
  }, [session]);

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    fetchRecommendations(abortController);
    selectedEmployeesChange({});
    return () => {
      abortController.abort();
    };
  }, [fetchRecommendations]);

  const buttonDisabled = () =>
    !Object.keys(selectedEmployees).some((userId) => selectedEmployees[userId]);

  const postEmail = async (requestBody) => {
    toastRequest.notify('Sending recommendations.');
    const jsonResponse = await adalPost(
      '/api/Notifications/recommendation/',
      requestBody
    );
    fetchRecommendations();
    if (jsonResponse && jsonResponse.success) {
      // Success
      toastRequest.updateToast(true, null, 'Email(s) sent successfully.');
    } else {
      // Failed
      toastRequest.updateToast(false, 'Something went wrong!');
    }

    // Reload manager dashboard data after recommeding a session
    if (onCloseAndReload) onCloseAndReload();
    else onCloseClick();
  };

  const sendEmails = () => {
    const requestBody = [];
    if (employees) {
      employees.forEach(({ user: { userId } }) => {
        if (selectedEmployees[userId]) {
          requestBody.push({
            userId,
            courseDetailsId: session.courseDetailsId,
            envBaseUrl: process.env.REACT_APP_UI_BASE_DOMAIN
          });
        }
      });
    }
    postEmail(requestBody);
  };

  /** Outlook image sizes cannot be trusted...
   * If you want two images the same size, they should be the same size in
   * pixels in the raw image. If you wanna set the size of the image, also
   * gotta do that in pixels on the actual image asset. */
  const emailContentHtmlString = (firstName = '(Staff Name)') => {
    const { courseName, courseStart } = session;
    const courseStartObj = stringToDate(courseStart);

    const date = String(
      courseStartObj.toLocaleDateString('en-US', {
        month: 'long',
        weekday: 'long',
        day: 'numeric',
        year: 'numeric'
      })
    );

    const time = String(
      courseStartObj.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    );

    const link = `${process.env.REACT_APP_UI_BASE_DOMAIN}/${getCourseLink(session)}`;

    const fixedWidth = (content) => `
    <table border="0" cellspacing="0" width="100%">
      <tr>
        <td></td>
        <td width="460">
        ${content}  
        </td>
        <td></td>
      </tr>
    </table> 
      `;

    return `
    <div>
    ${fixedWidth(`
      <table border="0" cellspacing="0" width="100%">
        <tr>
          <td width="100"></td>
          <td width="260"></td>
          <td width="100" style="text-align: center;"><img src="${dwUri}" alt="Digital World Logo"></td>
        </tr>
      </table>
      `)}
      ${fixedWidth(`
        <div style="text-align: center; font-weight: bold; font-size: 1.1em; line-height: 1.2;">
          <br>
          YOUR MANAGER<br>
          <i>RECOMMENDS YOU</i><br>
          TO ATTEND A SESSION!<br>
        </div>  
      `)}
      ${fixedWidth(`
            <br>
            Dear ${firstName},
            <br><br>
            Your manager recommends that you attend the following session:
            <br><br>
            <p>
            ${courseName}<br>
            ${
              !isOnDemand(session)
                ? `            
            ${date}<br>
            ${time}<br>`
                : ''
            }
            <a href="${link}">${link}</a>
            <br><br>
              Should you have any questions, please arrange to speak with your manager.
            <br><br>
            Your L+D Team
        `)}
    </div>`;
  };

  const toggleAllCheckboxes = (value = true) => {
    if (employees) {
      const newState = {};
      employees.forEach(({ user: { userId } }) => {
        if (!sentDict[userId] && !isRegistered(userId)) {
          newState[userId] = value;
        }
      });
      selectedEmployeesChange(deepcopy(newState));
    }
  };

  const stringCompareVal = (str1, str2) => {
    if (str1 < str2) return -1;
    else if (str1 > str2) return 1;
    else return 0;
  };

  const isRegistered = (userId) => participants?.some((p) => p.userId === userId);

  const render = () => {
    return (
      <>
        <div className={styles.containerRow}>
          <DigiHeadingBox
            loading={!employees}
            titleLoading={loadingRecommendations}
            title="STEP 1"
            wrapperClassName={styles.step1Container}
            contentHolderClassName={styles.step1Content}
            moveInAnimation
          >
            <CustomScroll className={styles.selectAllScroll}>
              <div className={styles.selectAllContainer}>
                {employees &&
                employees.length > 0 && ( // checks if there are employees
                    <DigiCheckbox
                      checked={!!selectAll}
                      toggle={() => {
                        toggleAllCheckboxes(!selectAll);
                        selectAllChange(!selectAll);
                      }}
                      disabled={disableInput}
                      label="Select all"
                    ></DigiCheckbox>
                  )}
                {employees && employees.length === 0 && (
                  <div className={styles.noStaffContainer}>
                    You do not have Staff listed in Digital World. For assistance,
                    please contact{' '}
                    <a href="mailto: LTC.PCL@ontario.ca?">
                      LTC.PCL@ontario.ca
                    </a>
                  </div>
                )}
              </div>
              {employees &&
                employees
                  .sort(
                    (
                      { user: { firstName: firstName1, lastName: lastName1 } },
                      { user: { firstName: firstName2, lastName: lastName2 } }
                    ) => {
                      const cmp1 = stringCompareVal(lastName1, lastName2);
                      return cmp1 === 0
                        ? stringCompareVal(firstName1, firstName2)
                        : cmp1;
                    }
                  )
                  .map(({ user: { name, userId } }) => (
                    <div
                      key={`${userId}_recommend_checkbox`}
                      className={styles.employeeContainer}
                    >
                      <DigiCheckbox
                        userId={userId}
                        checked={
                          selectedEmployees[userId] ||
                          sentDict[userId] ||
                          isRegistered(userId)
                        }
                        disabled={
                          sentDict[userId] || disableInput || isRegistered(userId)
                        }
                        toggle={() => {
                          selectedEmployeesChange({
                            ...selectedEmployees,
                            [userId]: !selectedEmployees[userId]
                          });
                        }}
                        label={`${name}`}
                      ></DigiCheckbox>
                      {(isRegistered(userId) || sentDict[userId]) && (
                        <>
                          <div className={styles.disabledMessage}>
                            {isRegistered(userId) && <>Already registered</>}
                            {!isRegistered(userId) &&
                              sentDict[userId] &&
                              !declinedDict[userId] && <>Outstanding</>}
                            {!isRegistered(userId) && declinedDict[userId] && (
                              <>Declined</>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
            </CustomScroll>
          </DigiHeadingBox>
          <DigiHeadingBox
            title="STEP 2"
            wrapperClassName={styles.step2Container}
            contentHolderClassName={styles.step2Content}
            onCloseClick={onCloseClick}
            closeable
            moveInAnimation
          >
            <div className={styles.messageInfoBox}>
              The following message will be sent to your staff.
            </div>
            <div className={styles.emailContainer}>
              <CustomScroll className={styles.emailScroll}>
                <div
                  dangerouslySetInnerHTML={{ __html: emailContentHtmlString() }}
                ></div>
              </CustomScroll>
            </div>
            <div className={styles.recommendButtonContainer}>
              <RecommendButton
                area="email"
                courseId={session.courseDetailsId}
                disabled={buttonDisabled()}
                onClick={sendEmails}
              ></RecommendButton>
            </div>
          </DigiHeadingBox>
        </div>
      </>
    );
  };

  return render();
};

export default RecommendContainer;

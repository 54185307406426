import React, { Component } from 'react';
import ReactGA from 'react-ga';
import style from './Home.module.css';
import '../../components/innovator/InnovatorDialog.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { adalApiFetch } from '../../adalConfig';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import GlobalContext from '../../context/GlobalContext';
import AccessibleButton from '../../components/accessibleButton/AccessibleButton';
import DigiTooltip from '../../components/digiTooltip/DigiTooltip';
import { PillarsAlt } from './../../enums/PillarsAlt.enum';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pillarList: null,
      bricks: null,
      pillarLoadingStatus: 0, // 0 = Loading, 1 = success, 2 = error
      isTooltip1Show: [false, false, false, false, false],
      isTooltip2Show: false
    };
    this.abortController = new AbortController();
    this.insertBottomBlock = this.insertBottomBlock.bind(this);
    this.handleClickTooltip1 = this.handleClickTooltip1.bind(this);
    this.handleClickTooltip2 = this.handleClickTooltip2.bind(this);
    this.handleClickXButtonOnTooltip2 = this.handleClickXButtonOnTooltip2.bind(this);
    this.displayPillars = this.displayPillars.bind(this);
  }

  componentDidMount() {
    // Load domains to prepare the bridge visual
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Domains', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.success) {
          const sortedPillars = data.data.sort((a, b) =>
            a.order > b.order ? 1 : -1
          );
          this.setState(
            {
              pillarList: sortedPillars
            },
            () => {
              this.hideLoaderIfComplete();
            }
          );
        } else {
          this.handleError();
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        this.handleError();
      });

    // Fetch your current bricks
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/bricks', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.success) {
          this.setState(
            {
              bricks: data.data.bricks
            },
            () => {
              this.hideLoaderIfComplete();
            }
          );
        } else {
          this.handleError();
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        this.handleError();
      });
  }

  /**
   * After each of the preliminary API calls, check to see if they have all completed successfully (all of the data is in the state) and update the state if so
   */
  hideLoaderIfComplete() {
    if (this.state.pillarList && this.state.bricks) {
      this.setState({ pillarLoadingStatus: 1 });
    }
  }

  handleError() {
    this.setState(
      {
        pillarLoadingStatus: 2
      },
      () => {
        toast('A technical error occurred. Please refresh the page.', {
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      }
    );
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  insertBottomBlock(index, domain) {
    let bottomBlockTags = [];
    let bottomBlockRef = 'Innovator' + domain.name.replace(/\s/g, '') + 'Ref';
    const bottomButtonRef = 'Button' + domain.name.replace(/\s/g, '') + 'Ref';
    bottomBlockTags.push(
      <div className={style.bottomBlockEdge} key={index} >
        { !this.state.isTooltip1Show[index] && 
        this.getDescriptionTooltipBox(domain.description)}
        <AccessibleButton 
          id={bottomButtonRef}
          className={[
            style.bottomBlock,
            this.state.isTooltip1Show[index] ||
            this.props.hasPillarNameHighlight[index]
              ? style.selected
              : ''
          ].join(' ')}
          onClick={() => this.handleClickTooltip1(index)}
        >
          <span id={bottomBlockRef}></span>
          <span>{domain.name}</span>
        </AccessibleButton> 
        <DigiTooltip
          placement="top"
          isOpen={this.state.isTooltip1Show[index]}
          target={bottomButtonRef}
        >
          <AccessibleButton
            className={style.digiTooltipButton}
            onClick={() => this.showFilteredSession(domain.domainId)}
          >
          Upcoming Sessions
          </AccessibleButton> 
          
          <AccessibleButton
            onClick={() => this.handleClickTooltip2()}
            className={[
              this.state.isTooltip2Show && style.selected,
              style.digiTooltipButton
            ].join(' ')}
            id={style.testTooltip2}
          >
            Outside Learning
          </AccessibleButton>
          {this.state.isTooltip2Show && this.showTooltip2(index)}
        </DigiTooltip>
      </div>
    );
    return bottomBlockTags;
  }

  displayPillars() {
    let pillarsImgTags = [];
    this.state.pillarList.forEach((currentPillar, index) => {
      let numBricks = this.state.bricks.filter(
        (obj) => obj.domainId === currentPillar.domainId
      ).length;

      if (numBricks > 6) {
        numBricks = 6;
      }

      const altText =
        numBricks +
        ' brick' +
        (numBricks === 1 ? '' : 's') +
        ' out of 6 for ' +
        currentPillar.name +
        ' pillars';

      pillarsImgTags.push(
        <div key={index} id={index === 2 ? style.centerArea : undefined}>
          {index === 2 && (
            <span className={style.center} id="innovatorHomeCenter"></span>
          )}
          <div className={style.showBridgeTooltip}>
            <img
              id={'targetId' + index}
              className={[
                style.bridgePillarImg,
                numBricks === 0 ? style.fading : ''
              ].join(' ')}
              src={require('../../assets/bridgePillar' +
                (numBricks >= 6 ? 0 : numBricks) +
                '.png')}
              alt={altText}
            />
            {this.getTooltipBox(numBricks, currentPillar)}
            {this.getImageBox(numBricks, currentPillar)}
          </div>
        </div>
      );
    });

    return pillarsImgTags;
  }

  getImageBox = (numBricks, currentPillar) => {
    let altText = '';
    let imgName = '';
    if (numBricks === 6) {
      switch (currentPillar.altName) {
        case PillarsAlt.LtcToolkit:
          altText = 'LTC Toolkit Badge icon';
          imgName = 'LTCToolkitBadge.png';
          break;
        case PillarsAlt.LtcTechnology:
          altText = 'LTC Technology Badge icon';
          imgName = 'LTCTechnologyBadge.png';
          break;
        case PillarsAlt.EmergingTechnology:
          altText = 'Emerging Technology Badge icon';
          imgName = 'EmergingTechnologyBadge.png';
          break;
        case PillarsAlt.NonTechnical:
          altText = 'Non Technical Badge icon';
          imgName = 'NonTechBadge.png';
          break;
        case PillarsAlt.BusinessAcumen:
          altText = 'Business Acumen Badge icon';
          imgName = 'BusinessAcumenBadge.png';
          break;
        default:
          altText = 'Default Badge icon';
          imgName = 'DefaultBadge.png';
      }
    }

    return numBricks === 6 ? (
      <>
        <img
          className={style.pillarBadge}
          alt={altText}
          aria-hidden="true"
          src={require('../../assets/badges/' + imgName)}
        />
      </>
    ) : (
      <></>
    );
  };

  getDescriptionTooltipBox = (description) => (
    <>
      <div
        className={`${style.tooltipBox} ${style.tooltipBoxW}`}
        >
        <div className={style.tooltipContent}>
        {description}
        </div>
      </div>
    </>
  );

  getTooltipBox = (numBricks, currentPillar) => (
    <>
      <div
        className={[
          style.tooltipBox,
          numBricks === 6 ? style.tooltipBoxB : style.tooltipBoxW
        ].join(' ')}
      >
        <div className={style.tooltipContent}>
          {numBricks === 6 && (
            <>
              <span>Congratulations!</span>
              <br />
            </>
          )}
          {this.getTooltip(numBricks, currentPillar)}
        </div>
      </div>
    </>
  );

  getTooltip(bricks, currentPillar) {
    if (bricks === 6) return `You have completed the ${currentPillar.name} pillar!`;
    else if (bricks === 5) return 'You are 1 brick away from finishing this pillar';
    else
      return 'You are ' + (6 - bricks) + ' bricks away from finishing this pillar';
  }
  showFilteredSession(index) {
    this.props.history.push({
      pathname: '/sessions',
      state: { fromHome: index }
    });
  }

  showTooltip2(index) {
    if (index < 3) {
      return (
        <span className={style.tooltip2} id={style.tooltip2Right}>
          <div>
            Please contact your{' '}
            <a href="mailto: LTC.PCL@ontario.ca?">L+D team</a> when you have
            completed non-L+D sessions to collect your points
          </div>
          <AccessibleButton
            id={style.tooltip2CloseIcon}
            onClick={() => this.handleClickXButtonOnTooltip2()}
          >
            <FontAwesomeIcon icon={faTimes} role="button" />
          </AccessibleButton>
        </span>
      );
    } else {
      return (
        <span className={style.tooltip2} id={style.tooltip2Left}>
          <FontAwesomeIcon
            icon={faTimes}
            size={'2x'}
            id={style.tooltip2CloseIcon}
            onClick={() => this.handleClickXButtonOnTooltip2()}
          />
          <div>
            Please contact your{' '}
            <a href="mailto: LTC.PCL@ontario.ca?">L+D team</a> when you have
            completed non-L+D sessions to collect your points
          </div>
        </span>
      );
    }
  }

  handleClickTooltip1(index) {
    this.setState({
      isTooltip1Show: [
        index === 0 ? !this.state.isTooltip1Show[index] : false,
        index === 1 ? !this.state.isTooltip1Show[index] : false,
        index === 2 ? !this.state.isTooltip1Show[index] : false,
        index === 3 ? !this.state.isTooltip1Show[index] : false,
        index === 4 ? !this.state.isTooltip1Show[index] : false
      ]
    });
    if (!this.state.isTooltip1Show[index]) {
      this.setState({
        isTooltip2Show: false
      })
    }



  }

  handleClickTooltip2() {
    this.setState({
      isTooltip2Show: !this.state.isTooltip2Show
    });
  }

  handleClickXButtonOnTooltip2() {
    this.setState({
      isTooltip2Show: false
    });
  }

  render() {
    return (
      <>
        <div id={style.wrapper}>
          {this.state.pillarLoadingStatus === 1 ? (
            <>
              <h1 className="show-for-sr">Homepage</h1>
              <div id={style.homeArea}>
                <div id={style.bridgeTop}>
                  <div className="flex">
                    <div
                      className={[style.bridgeTopLeft, 'flex-auto'].join(' ')}
                    ></div>
                    <img
                      className={[style.bridgeTopImg, 'flex-fixed'].join(' ')}
                      src={require('../../assets/topBridge.png')}
                      alt="bridge top"
                    />
                    <div
                      className={[style.bridgeTopRight, 'flex-auto'].join(' ')}
                    ></div>
                  </div>
                </div>
                <div id={style.bridgeMiddle}>{this.displayPillars()}</div>
                <div id={style.bottomWrapper}>
                  <div id={style.bridgeBottom}>
                    {this.state.pillarList.map((currentPillar, index) =>
                      this.insertBottomBlock(index, currentPillar)
                      
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : this.state.pillarLoadingStatus === 0 ? (
            <div className="flex center full-width full-height">
              <Spinner color="white" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}
Home.contextType = GlobalContext;

import React from 'react';
import _ from 'lodash';
import { CustomInput } from 'reactstrap';

const DigiCheckbox = ({ userId, label, checked, toggle, disabled, className }) => {
  const id = _.uniqueId();
  return (
    <CustomInput
      type="checkbox"
      id={`digi_checkbox_${userId || id}`}
      label={label}
      checked={checked}
      onChange={toggle}
      disabled={disabled}
      className={className}
    ></CustomInput>
  );
};

DigiCheckbox.defaultProps = {
  checked: false,
  disabled: false
};

export default DigiCheckbox;

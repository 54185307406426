import React from 'react';
import ReactGA from 'react-ga';
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import ManagerAddModify from './ManagerAddModify';
import Manager from './Manager';
import UploadStaff from './UploadStaff';
import DigilandFilter from '../../../../components/digilandFilter/DigilandFilter';
import dynamicSort from '../../utils/sort';
import { deepcopy } from '../../../../CONSTANTS';
import AdminHomeTabs from '../AdminHomeTabs';
import { AdminTabs } from '../../../../enums/admintabs.enum';
import style from '../AdminAllTabs.module.css';
import  UploadUsersCompliance from './UploadUsersCompliance';


export default class AdminManagerTab extends React.Component {
  constructor() {
    super();
    this.abortController = new AbortController();

    this.state = {
      activeTab: '0',
      all: [], // List of all Managers
      searched: [],
      searchText: '',
      allUsers: [],
      allEmployees: [],
      openAccordions: [], // List of all open accordions
      roles: null,
      time: null,
      selectedRoleFilter: 'All'
    };
      this.formRef = React.createRef();
      this.uploadStaffFormRef = React.createRef();
      this.uploadUsersCompliance = React.createRef();
  }

  componentDidMount() {
    this.toggle = this.toggle.bind(this);
    this.loadManagers = this.loadManagers.bind(this);
    this.loadContent = this.loadContent.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.loadContent(true);

    if (this.props.payload != null) this.parsePayload();

    toastRequest.notify('Loading Manager teams.');
    //  Load users
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/dropdown',
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          throw new Error(myJson);
        } else {
          let allUsers = [];
          for (let i = 0; i < myJson.data.length; i++) {
            allUsers.push({
              value: myJson.data[i].email,
              label: myJson.data[i].email,
              userId: myJson.data[i].userId,
              name: `${myJson.data[i].lastName}, ${myJson.data[i].firstName}`
            });
          }
          this.setState({ allUsers: allUsers });
        }
        toastRequest.dismissToast();
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        toastRequest.updateToast(null, 'An error occurred when loading User list');
      });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  /**
   * Called from the Manager component to toggle whether its staff list is open or not
   * @param {*} accordionId  the ID which represents the accordion
   */
  toggleOpenAccordion(accordionId) {
    const newOpenAccordions = deepcopy(this.state.openAccordions);
    var index = newOpenAccordions.indexOf(accordionId);
    if (index === -1) newOpenAccordions.push(accordionId);
    else newOpenAccordions.splice(index, 1);
    this.setState({
      openAccordions: newOpenAccordions
    });
  }

  /**
   * Close all staff lists that are open for all managers except the one passed in as an argument
   * @param {*} accordionId  the ID which represents the accordion
   */
  closeAllOtherAccordions(accordionId = null) {
    accordionId
      ? this.setState({
          openAccordions: [accordionId]
        })
      : this.setState({
          openAccordions: []
        });
  }

  changeHandler(e) {
    this.setState({ searchText: e.target.value });
    let searchedItems = this.state.all.filter(
      (el) =>
        el.user.email.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    );
    this.setState({ searched: searchedItems });
    this.closeAllOtherAccordions();
  }

  loadManagers(doToast = false, parentToast = null) {
    let toastId = null;
    const errorMessageManagers =
      'An error occurred when attempting to load the managers. Please try again later.';
    this.setState({ ...this.state, time: null });
    if (doToast) {
      toastId =
        parentToast == null
          ? toastRequest.notify('Loading manager data')
          : parentToast;
    }
    const startTime = new Date().getTime();
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Managers', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        if (!response.ok && doToast) {
          toast.update(toastId, {
            render: errorMessageManagers,
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else if (!response.ok) {
          toast(errorMessageManagers, {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          return response.json();
        }
      })
      .then((myJson) => {
        if (myJson.success === false) {
          toast.update(toastId, {
            render: errorMessageManagers,
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          const timeTaken = (new Date().getTime() - startTime) / 1000;
          this.setState({
            all: myJson.data,
            searched: this.state.searchText
              ? myJson.data.filter(
                  (m) =>
                    m.user.email
                      .toLowerCase()
                      .indexOf(this.state.searchText.toLowerCase()) !== -1
                )
              : myJson.data,
            time: timeTaken.toFixed(3)
          });
        }
      })
      .catch((e) => {
        toast.update(toastId, {
          render: errorMessageManagers,
          type: toast.TYPE.ERROR,
          autoClose: 2000
        });
        ReactGA.exception({
          description: e.toString()
        });
      });
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + `/api/ManagerEmployee/`,
      {
        method: 'get',
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((myJson) => {
        this.setState({ allEmployees: myJson.data });
        if (doToast) {
          toast.update(toastId, {
            render: 'Managers loaded successfully',
            type: toast.TYPE.SUCCESS,
            autoClose: 2000
          });
        }
      })
      .catch((e) => {
        toast.update(toastId, {
          render: errorMessageManagers,
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  loadContent(doToast = false) {
    let toastId = null;
    const errorMessageFilter =
      'An error occurred when attempting to load roles for filter. Please try again later.';
    if (doToast) {
      toastId = toastRequest.notify('Loading manager data');
    }
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/Managers/roles',
      {
        method: 'get',
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (!response.ok && doToast) {
          toast.update(toastId, {
            render: errorMessageFilter,
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else if (!response.ok) {
          toast(errorMessageFilter, {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          return response.json();
        }
      })
      .then((myJson) => {
        if (myJson.success === false) {
          toast.update(toastId, {
            render: errorMessageFilter,
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          this.setState({ ...this.state, roles: myJson.data });
        }
      })
      .catch((e) => {
        toast.update(toastId, {
          render: errorMessageFilter,
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        ReactGA.exception({
          description: e.toString()
        });
      });
    this.loadManagers(doToast, toastId);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
      return (
      <div className={style.adminRoot}>
        <AdminHomeTabs
          activetab={AdminTabs.ManagerStaff}
          switchtab={this.props.history.push}
        />
        <Container>
          <Row>
            <Col>
              <Nav tabs className={style.tabMargin}>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '0' })}
                    id="viewTab"
                    onClick={() => {
                      this.toggle('0');
                    }}
                  >
                    VIEW
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    id="addTab"
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    ADD
                  </NavLink>
                </NavItem>
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: this.state.activeTab === '2' })}
                                      id="loadStaffTab"
                                      onClick={() => {
                                          this.toggle('2');
                                      }}
                                  >
                                      Upload Staff
                                  </NavLink>
                              </NavItem>  
                              {/*<NavItem>*/}
                              {/*    <NavLink*/}
                              {/*        className={classnames({ active: this.state.activeTab === '3' })}*/}
                              {/*        id="loadUserComplianceTab"*/}
                              {/*        onClick={() => {*/}
                              {/*            this.toggle('3');*/}
                              {/*        }}*/}
                              {/*    >*/}
                              {/*        Upload Users Compliance*/}
                              {/*    </NavLink>*/}
                              {/*</NavItem>  */}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="0">
                  {/*TAB 1 SEARCH BAR*/}
                  <InputGroup>
                    <Label for="managerSearchBar" className="show-for-sr">
                      {' '}
                      Manager search bar
                    </Label>
                    <Input
                      placeholder="search"
                      id={'managerSearchBar'}
                      onChange={this.changeHandler}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>Search</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className={style.subText}>
                    {this.state.time
                      ? `Results found in ${this.state.time} seconds`
                      : `Loading results...`}
                  </div>
                  {this.state.roles ? (
                    <DigilandFilter
                      optionchange={(option) =>
                        this.setState({ ...this.state, selectedRoleFilter: option })
                      }
                      options={['All', ...this.state.roles.map((role) => role.name)]}
                    />
                  ) : (
                    <></>
                  )}
                  {this.state.searched
                    .sort(dynamicSort(['user', 'lastName']))
                    .map((manager) => (
                      <Manager
                        visible={
                          this.state.selectedRoleFilter === 'All' ||
                          manager.role.name === this.state.selectedRoleFilter
                        }
                        accordionIsOpen={
                          this.state.openAccordions.indexOf(manager.managerId) !== -1
                        }
                        toggleOpenAccordion={() => {
                          this.toggleOpenAccordion(manager.managerId);
                        }}
                        closeAllOtherAccordions={() => {
                          this.closeAllOtherAccordions(manager.managerId);
                        }}
                        manualReload={this.loadContent}
                        managersReload={this.loadManagers}
                        payload={manager}
                        reportingManager={manager.reportingManager}
                        key={manager.managerId}
                        allManagers={this.state.all}
                        allUsers={this.state.allUsers}
                        allRoles={this.state.roles}
                      />
                    ))}
                </TabPane>
                <TabPane tabId="1">
                  <ManagerAddModify
                    ref={this.formRef}
                    manualReload={this.loadContent}
                    allUsers={this.state.allUsers}
                    allManagers={this.state.all}
                    allRoles={this.state.roles}
                    allEmployees={this.state.allEmployees}
                    activeTab={this.state.activeTab}
                  />
                  <Button
                    color="primary"
                    size="lg"
                    className={style.submitBtn}
                                      onClick={() => this.formRef.current.add()}
                  >
                    Submit
                  </Button>
                </TabPane>
                              <TabPane tabId="2">
                                  <UploadStaff
                                      ref={this.uploadStaffFormRef} 
                                      manualReload={this.loadContent}
                                      allUsers={this.state.allUsers}
                                      activeTab={this.state.activeTab}
                                  />
                                  <Button
                                      color="primary"
                                      size="lg"
                                      className={style.submitBtn}
                                      onClick={() => this.uploadStaffFormRef.current.add()}
                                  >
                                      Submit
                                  </Button>
                              </TabPane>

                              {/*<TabPane tabId="3">*/}
                              {/*    <UploadUsersCompliance*/}
                              {/*        ref={this.uploadUsersCompliance}*/}
                              {/*        manualReload={this.loadContent}*/}
                              {/*        activeTab={this.state.activeTab}*/}
                              {/*    />*/}
                              {/*    <Button*/}
                              {/*        color="primary"*/}
                              {/*        size="lg"*/}
                              {/*        className={style.submitBtn}*/}
                              {/*        onClick={() => this.uploadUsersCompliance.current.add()}*/}
                              {/*    >*/}
                              {/*        Submit*/}
                              {/*    </Button>*/}
                              {/*</TabPane>*/}
              </TabContent>
            </Col>
          </Row>
        </Container>
          </div>
              );
  }
}

import { Badges } from '../../../enums/badges.enum';
import { PagePaths, PageName } from '../../../enums/pagePaths.enum';
import { Pillars } from '../../../enums/pillars.enum';

export const innovatorTutorialMessagesForPages = [
  {
    displayOnPages: PagePaths.Homepage,
    displayPageName: PageName.Homepage,
    content: [
      {
        text:
          '<span class="innovatorDialogTextArea">Hi! Welcome to <strong>LTC Digital World</strong>.<br/>I\'m the <strong class="innovatorDialogYellowHightlight">INNOVATOR</strong>. I\'m here as your guide.<br/>Let me show you more!</span>',
        ref: 'innovatorHomeCenter',
        buttonName: ['OKAY'],
        displayLayout: {
          innovator: 'left'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea"><strong class="innovatorDialogBigFont">Congratulations!</strong><div class="innovatorDialogExtraMargin"></div>You have earned your first badge -<br/> the <strong class="innovatorDialogYellowHightlight">Welcome Badge</strong>!</span>',
        ref: 'innovatorHomeCenter',
        tutorialPurposeForBadge: Badges.Welcome,
        buttonName: ['NEXT'],
        displayLayout: {
          innovator: 'left'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">As you earn badges, they will appear here.</span>',
        ref: 'badgeRef',
        isCloseButtonShow: true,
        buttonName: ['NEXT'],
        displayLayout: {
          innovator: 'left',
          arrow: 'up'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">In <strong class="innovatorDialogYellowHightlight">OnDemand</strong> discover your mandatory training.<br/> Please complete them promptly.</span>',
         ref: 'innovatorOnDemandSessionRef',
         tutorialPurposeForPage: PageName.OnDemandSessions,
         isCloseButtonShow: true,
         buttonName: ['NEXT'],
         displayLayout: {
            innovator: 'left',
            arrow: 'up'
         }
        },
      {
        text:
          '<span class="innovatorDialogTextArea">As you progress through your learning<br/>journey, you will see your points adding up<br/>here. Would you like to learn how to play?</span>',
        ref: 'innovatorPointRef',
        isCloseButtonShow: true,
        buttonName: ['NOT NOW', 'SURE'],
        displayLayout: {
          innovator: 'left',
          arrow: 'up'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">Below is the outline of a bridge. As you<br/>close the knowledge gap, the pieces of<br/>your bridge will begin to appear. The more<br/>sessions you attend the faster your bridge<br/>will be built. The bridge is held up by five<br/>pillars. Let me explain them below.</span>',
        ref: 'innovatorHomeCenter',
        isCloseButtonShow: true,
        buttonName: ['NOT NOW', 'SURE'],
        displayLayout: {
          innovator: 'left',
          arrow: 'down'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">This is the <strong class="innovatorDialogYellowHightlight">LTC Toolkit Pillar</strong>. Here you will<br/>find learning sessions that are related to<br/>LTC projects and learning priorities.</span>',
        ref: 'InnovatorLTCToolkitRef',
        purpose: Pillars.LtcToolkit,
        isCloseButtonShow: true,
        buttonName: ['NEXT'],
        displayLayout: {
          innovator: 'left',
          arrow: 'down'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">This is the <strong class="innovatorDialogYellowHightlight">LTC Technology Pillar</strong>. Here you<br/>will find learning sessions that relate to<br/>technologies that are currently used in LTC.</span>',
        ref: 'InnovatorLTCTechnologyRef',
        purpose: Pillars.LtcTechnology,
        isCloseButtonShow: true,
        buttonName: ['NEXT'],
        displayLayout: {
          innovator: 'left',
          arrow: 'down'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">This is the <strong class="innovatorDialogYellowHightlight">Emerging Technology Pillar</strong>.<br/>Here you will find learning sessions that<br/>focus on new technologies being explored.</span>',
        ref: 'InnovatorEmergingTechnologyRef',
        purpose: Pillars.EmergingTechnology,
        isCloseButtonShow: true,
        buttonName: ['NEXT'],
        displayLayout: {
          innovator: 'left',
          arrow: 'down'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">This is the <strong class="innovatorDialogYellowHightlight">Business Acumen Pillar</strong>. Here<br/>you will find learning sessions that focus<br/>on increasing business awareness.</span>',
        ref: 'InnovatorBusinessAcumenRef',
        purpose: Pillars.BusinessAcumen,
        isCloseButtonShow: true,
        buttonName: ['NEXT'],
        displayLayout: {
          innovator: 'right',
          arrow: 'down'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">This is the <strong class="innovatorDialogYellowHightlight">Non-Technical Pillar</strong>. Here you<br/>will find learning sessions related to personal<br/>wellness, or other non-technical topics.</span>',
        ref: 'InnovatorNon-TechnicalRef',
        purpose: Pillars.NonTechnical,
        isCloseButtonShow: true,
        buttonName: ['NEXT'],
        displayLayout: {
          innovator: 'right',
          arrow: 'down'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">Begin your journey by registering for a<br/>session through <strong class="innovatorDialogYellowHightlight">Upcoming Sessions</strong>.<div class="innovatorDialogExtraMargin"></div>Track your progress through<br/><strong class="innovatorDialogYellowHightlight">My Dashboard</strong>.<div class="innovatorDialogExtraMargin"></div><strong class="innovatorDialogYellowHightlight">Past Sessions</strong> will allow you to view<br/>all previous session meterials.</span>',
        ref: 'innovatorUpcomingSessionRef',
        tutorialPurposeForPage: PageName.Homepage,
        isCloseButtonShow: true,
        displayLayout: {
          innovator: 'left',
          arrow: 'up'
        }
      },
      {
        text:
          '<span class="innovatorDialogTextArea">If you need me again, you can find<br/>me here.</span>',
        ref: 'innovatorIconRef',
        isCloseButtonShow: true,
        displayLayout: {
          innovator: 'right',
          arrow: 'up'
        }
      }
    ]
  }
];

import React, { Component } from 'react';
import styles from './DigiTooltip.module.css';
import { Tooltip } from 'reactstrap';

export default class DigiTooltip extends Component {
  render() {
    return (
      <>
        <Tooltip
          {...this.props}
          className={styles.tooltipContainer}
          popperClassName={styles.tooltipPopper}
          arrowClassName={styles.tooltipArrow}
          innerClassName={styles.tooltipBody}
        >
          {this.props.children}
        </Tooltip>
      </>
    );
  }
}

import { stringToDate } from '../CONSTANTS';
import { ON_DEMAND_SLUG } from '../pages/sessions/components/SessionUtils';

export function sortArrayByDateKey(
  array,
  dateKey = (obj) => obj.date,
  desc = false
) {
  const safeDateKey = (obj) => {
    try {
      return dateKey(obj) || '';
    } catch {
      return '';
    }
  };
  return array.sort(
    (a, b) => (desc ? -1 : 1) * safeDateKey(a).localeCompare(safeDateKey(b))
  );
}

export const registrationRecord = (course, userId) => {
  return course
    ? course.participants.find((participant) => participant.userId === userId)
    : null;
};

export const getCourseLink = (courseDetails) => {
  const today = new Date();
  const courseStart = stringToDate(courseDetails.courseStart);

  let type = null;

  if (isOnDemand(courseDetails)) {
    type = 'ondemand';
  } else {
    if (today < courseStart) {
      type = 'sessions';
    } else {
      type = 'pastsessions';
    }
  }

  return `${type}/${courseDetails.courseDetailsId}`;
};

export const isOnDemand = (session) => {
  return session && session.format && session.format.slug === ON_DEMAND_SLUG;
};

export const isPastSession = (courseDetails) => {
  return (
    courseDetails &&
    courseDetails.courseStart &&
    stringToDate(courseDetails.courseStart) < new Date()
  );
};

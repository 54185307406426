import React from 'react';
import ReactGA from 'react-ga';
import { Form, FormGroup, Label } from 'reactstrap';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { forceCache } from '../../../../CONSTANTS';
import { toast } from 'react-toastify';
import Select from 'react-select';

export default class AdminAddModify extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.onModify = this.onModify.bind(this);
    this.parseForm = this.parseForm.bind(this);

    this.state = {
      submitting: false,
      allUsers: [],
      selectedUser: {}
    };
    this.originalState = {
      submitting: false,
      selectedUser: {}
    };
  }

  componentDidMount() {
    if (this.props.payload != null) this.parsePayload();

    //  Load users
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + '/api/User/dropdown',
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          toast('An error occurred when loading User list', {
            type: toast.TYPE.ERROR,
            autoClose: false
          });
        } else {
          let userList = [];
          for (let i = 0; i < myJson.data.length; i++) {
            userList.push({
              value: myJson.data[i].userId,
              label: myJson.data[i].email
            });
          }
          this.setState({ allUsers: userList });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  handleChange = (selectedUser) => {
    this.setState({ selectedUser });
  };

  parseForm(adminId = null) {
    if (Object.entries(this.state.selectedUser).length === 0) {
      toast('Please select an Email address.', {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return null;
    }

    let data = {
      userId: this.state.selectedUser.value
    };

    if (adminId !== null) data.adminId = adminId;
    return data;
  }

  add() {
    if (this.state.submitting) return null;

    const data = this.parseForm();
    if (data === null) return null;

    toastRequest.notify('Creating Admin');
    this.setState({ submitting: true });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Admins', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: this.abortController.signal
    })
      .then((response) => {
        if (response.ok) forceCache();
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'Admin added successfully!'
        );
        if (myJson.success === true) {
          this.setState(this.originalState);
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  update() {
    const data = this.parseForm(this.props.payload.adminId);
    if (data === null) return null;
    this.props.closeModal();

    toastRequest.notify('Updating Admin');
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Admins', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
      // signal: this.abortController.signal
    })
      .then((response) => {
        if (response.ok) forceCache();
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'Admin edited successfully!'
        );
        if (myJson.success === true) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  onModify(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  parsePayload() {
    for (const [key, value] of Object.entries(this.props.payload)) {
      if (value != null) {
        this.setState({ [key]: value });
      }
    }
  }

  render() {
    return (
      <div>
        <Form onSubmit={(event) => event.preventDefault()}>
          {/*USERNAME*/}
          <FormGroup>
            <Label for="recipientEmail">Admin Email</Label>
            <Select
              value={this.state.selectedUser}
              onChange={this.handleChange}
              id="recipientEmail"
              options={this.state.allUsers}
              isLoading={this.state.allUsers.length === 0}
            />
          </FormGroup>
        </Form>
      </div>
    );
  }
}

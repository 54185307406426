import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import style from './../navbar/Header.module.css';

export default class Contact extends Component {
  mailToData() {
      return `mailto: LTC.PCL@ontario.ca?`;
  }

  render() {
    return (
      <Nav.Link
        className={style.headerLink}
        onClick={() => {
          window.location = this.mailToData();
        }}
      >
        CONTACT
      </Nav.Link>
    );
  }
}

import React from 'react';
import ReactGA from 'react-ga';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  CardFooter,
  Media
} from 'reactstrap';
import delIcon from '../../images/garbage.svg';
import editIcon from '../../images/pencil.svg';
import { adalApiFetch } from '../../../../adalConfig';
import * as toastRequest from '../../utils/toastRequests';
import PresenterAddModify from './PresenterAddModify';
import sharedPresenterStyles from '../../../presenterGallery/PresenterShared.module.css';
import styles from './Presenter.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export default class Presenter extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleModifyModal = this.toggleModifyModal.bind(this);
    this.updatePresenter = this.updatePresenter.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.deletePresenter = this.deletePresenter.bind(this);
    this.formRef = React.createRef();
    this.state = {
      modalOpen: false,
      modModal: false,
      collapse: false,
      picture: undefined
    };
  }

  componentDidMount() {
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        '/api/User/usergraph?username=' +
        this.props.payload.email,
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success && myJson.data.photo !== null) {
          this.setState({ picture: myJson.data.photo });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  toggleDeleteModal() {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen
    }));
  }

  toggleModifyModal() {
    this.setState((prevState) => ({
      modModal: !prevState.modModal
    }));
  }

  toggleCollapse() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  updatePresenter() {
    this.formRef.current.update();
    // this.toggleModifyModal();
  }

  deletePresenter() {
    toastRequest.notify('Deleting Presenter');
    this.toggleDeleteModal();

    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/Presenters/${this.props.payload.presenterId}`,
      { method: 'delete', signal: this.abortController.signal }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          `Presenter "${this.props.payload.name}" deleted successfully!`
        );
        if (myJson.success === true) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <h5
            className={'card-title presenter-card-title'}
            style={{ float: 'left' }}
          >
            {this.props.payload.name}{' '}
            <Badge color="secondary">id:{this.props.payload.presenterId}</Badge>
          </h5>
          <Button
            size="sm"
            outline
            color="danger"
            style={{ float: 'right' }}
            id={'delete-presenter-' + this.props.payload.email}
            onClick={this.toggleDeleteModal}
          >
            <img style={{ height: '24px' }} src={delIcon} alt={'delete button'} />
          </Button>
          <Button
            id={`presenter-edit-button-${this.props.payload.presenterId}`}
            size="sm"
            outline
            color="white"
            style={{ float: 'right' }}
            onClick={this.toggleModifyModal}
          >
            <img style={{ height: '24px' }} src={editIcon} alt={'delete button'} />
          </Button>
        </CardHeader>
        <CardBody>
          <Media>
            <Media left className={styles.presenterImageContainer}>
              {typeof this.state.picture !== 'undefined' ? (
                <img
                  src={`data:image/jpeg;base64,${this.state.picture}`}
                  alt="presenter avatar"
                  className={sharedPresenterStyles.picture}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faUser}
                  color={'gray'}
                  className={sharedPresenterStyles.defaultPicture}
                />
              )}
            </Media>
            <Media body>
              <CardText>{this.props.payload.bio}</CardText>
            </Media>
          </Media>
        </CardBody>
        {this.props.payload.email !== '' && (
          <CardFooter>{this.props.payload.email}</CardFooter>
        )}

        {/*DELETE MODAL*/}
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleDeleteModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>Confirm Deletion</ModalHeader>
          <ModalBody>
            Are you sure you want to delete Presenter "{this.props.payload.name}"?
            This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              id="delete-presenter-confirm"
              color="danger"
              onClick={this.deletePresenter}
            >
              DELETE
            </Button>{' '}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/*MODIFY MODAL*/}
        <Modal
          isOpen={this.state.modModal}
          toggle={this.toggleModifyModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModifyModal}>
            Update this presenter
          </ModalHeader>
          <ModalBody>
            <PresenterAddModify
              payload={this.props.payload}
              ref={this.formRef}
              manualReload={this.props.manualReload}
              closeModal={this.toggleModifyModal}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.updatePresenter}
              data-id={`presenter-modify-button-${this.props.payload.presenterId}`}
            >
              Modify
            </Button>
            <Button color="secondary" onClick={this.toggleModifyModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    );
  }
}

import React from 'react';
import style from './ManagerOnlyTag.module.css';

class ManagerOnlyTag extends React.Component {
  render() {
    return <div className={style.managerHolder}>Manager</div>;
  }
}

export default ManagerOnlyTag;

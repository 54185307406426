import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import scrollIntoView from 'scroll-into-view-if-needed';
import GlobalContext from '../../context/GlobalContext';

import styles from './Feedback.module.css';
//import './Feedback.module.css';
import { adalApiFetch } from '../../adalConfig';
import * as CONSTANTS from '../../CONSTANTS';
import AccessibleButton from '../../components/accessibleButton/AccessibleButton';
import { GetSurveyBadge } from '../../components/badges/BadgesAPI';
import { Badges } from '../../enums/badges.enum';
import CustomLoader from '../../components/customLoader/CustomLoader';
import { registrationRecord } from '../../utils/courses';
import { ON_DEMAND_SLUG } from '../sessions/components/SessionUtils';

// !!!correct error handling for if the api call fails for this page
// if the call fails we wanna make sure we dont delete their input, and allow for resubmission

export default class Feedback extends Component {
  //used to check if there are any missing mandatory fields after submit is pressed
  submitted = false;

  q1 = CONSTANTS.QUESTION1;
  q2 = CONSTANTS.QUESTION2;
  q3 = CONSTANTS.QUESTION3;
  q4 = CONSTANTS.QUESTION4;
  q5 = CONSTANTS.QUESTION5;
  q6 = CONSTANTS.QUESTION6;
  q7 = CONSTANTS.QUESTION7;
  q8 = CONSTANTS.QUESTION8;

  constructor(props) {
    super(props);

    this.abortController = new AbortController();
    this.onTextModify = this.onTextModify.bind(this);
    this.onRadioModify = this.onRadioModify.bind(this);
    this.onCheckModify = this.onCheckModify.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
    this.scrollToElement = this.scrollToElement.bind(this);

    this.state = {
      a1: '',
      a2: '',
      a3: '',
      a4: '',
      a5: '',
      a6: '',
      a7: '',
      a8: '',
      a9: true,
      submitted: false,
      courseData: null,
      completed: false,
      closed: false,
      loading: true
    };
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  showSurvey() {
    let d = null;
    if (
      this.state.courseData &&
      this.state.courseData.format.slug === ON_DEMAND_SLUG
    ) {
      const _registrationRecord = registrationRecord(
        this.state.courseData,
        this.context.user.userId
      );
      // Fallback to courseStart if attendedOn is null
      d = _registrationRecord.attendedOn
        ? _registrationRecord.attendedOn
        : this.state.courseData.courseStart;
    } else {
      d = this.state.courseData.courseStart;
    }
    d = CONSTANTS.stringToDate(d);
    let currentDate = new Date();

    // Might wanna make this a global variable so the window for feedback can be changed in one location
    currentDate.setDate(currentDate.getDate() - 10);
    let isClosed = d < currentDate;
    this.setState({ closed: isClosed });
  }

  componentDidMount() {
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        '/api/Course/' +
        this.props.match.params.courseId,
      {
        method: 'get',
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        for (let i = 0; i < myJson.data.courseDetails.length; i++) {
          if (
            myJson.data.courseDetails[i].courseDetailsId ===
            parseInt(this.props.match.params.courseDetailsId)
          ) {
            this.setState(
              {
                courseData: myJson.data.courseDetails[i],
                success: true,
                loading: false,
                anonymous: true
              },
              () => {
                this.showSurvey();
              }
            );
          }
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  getSurveyBadgeAndComplete = () => {
    if (this.context.badges.indexOf(Badges.Survey) !== -1) {
      this.markSurveyAsCompleted();
    } else {
      GetSurveyBadge()
        .then((response) => {
          if (response && response.success) {
            this.broadcastSurveyCompleted(response.data);
          }
          this.markSurveyAsCompleted();
        })
        .catch((e) => {
          ReactGA.exception({
            description: e.toString()
          });
        });
    }
  };

  broadcastSurveyCompleted = (responseData) => {
    var bc = new BroadcastChannel(CONSTANTS.NEW_BADGE_BROADCAST_CHANNEL);
    bc.postMessage({
      badgeInfo: responseData
    });
  };

  markSurveyAsCompleted() {
    this.setState({
      completed: true
    });
  }

  // do we need error handling if the submission fails, since this is user inputted data?
  // losing your input because the call fails would be annoying.
  submitFeedback() {
    this.setState({ loading: true });

    const data = {
      courseId: this.props.match.params.courseDetailsId,
      q1: this.q1,
      a1: this.state.a1,
      q2: this.q2,
      a2: this.state.a2,
      q3: this.q3,
      a3: this.state.a3,
      q4: this.q4,
      a4: this.state.a4,
      q5: this.q5,
      a5: this.state.a5,
      q6: this.q6,
      a6: this.state.a6,
      q7: this.q7,
      a7: this.state.a7,
      q8: this.q8,
      a8: this.state.a8
    };

    if (this.state.a9) {
      adalApiFetch(
        fetch,
        process.env.REACT_APP_API_BASE_DOMAIN + '/api/Survey/anonymous',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
          signal: this.abortController.signal
        }
      )
        .then((response) => {
          this.setState({ loading: false });
          return response.json();
        })
        .then((myJson) => {
          if (myJson.success) {
            this.getSurveyBadgeAndComplete();
          } else {
            // TODO handle error
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          ReactGA.exception({
            description: e.toString()
          });
        });
    } else {
      adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Survey', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        signal: this.abortController.signal
      })
        .then((response) => {
          this.setState({ loading: false });
          return response.json();
        })
        .then((myJson) => {
          if (myJson.success) {
            this.getSurveyBadgeAndComplete();
          } else {
            // TODO handle error
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          ReactGA.exception({
            description: e.toString()
          });
        });
    }
  }

  onTextModify(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onRadioModify(e) {
    //q3 is a conditional question answer of q3 if q2 no longer meets that condition, might need a alert to user?
    if (e.target.name === 'a2' && e.target.value === 'yes') {
      this.setState({ a3: '' });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  onCheckModify(e) {
    let value = !this.state[e.target.name];
    this.setState({ [e.target.name]: value });
    if (this.state.anonymous) this.setState({ anonymous: false });
    else this.setState({ anonymous: true });
  }

  scrollToElement(elementId) {
    scrollIntoView(document.getElementById(elementId), {
      duration: 150,
      block: 'center',
      behavior: 'smooth'
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });

    // question 3 and 8 are the only optional questions in the survey
    if (
      this.state.a1 === '' ||
      this.state.a2 === '' ||
      this.state.a4 === '' ||
      this.state.a5 === '' ||
      this.state.a6 === '' ||
      this.state.a7 === ''
    ) {
      // scroll to the first missing field
      if (this.state.a1 === '') {
        this.scrollToElement('q1');
      } else if (this.state.a2 === '') {
        scrollIntoView(document.getElementById('q2'), {
          duration: 150,
          block: 'center',
          behavior: 'smooth'
        });
      } else if (this.state.a4 === '') {
        scrollIntoView(document.getElementById('q4'), {
          duration: 150,
          block: 'center',
          behavior: 'smooth'
        });
      } else if (this.state.a5 === '') {
        scrollIntoView(document.getElementById('q5'), {
          duration: 150,
          block: 'center',
          behavior: 'smooth'
        });
      } else if (this.state.a6 === '') {
        scrollIntoView(document.getElementById('q6'), {
          duration: 150,
          block: 'center',
          behavior: 'smooth'
        });
      } else if (this.state.a7 === '') {
        scrollIntoView(document.getElementById('q7'), {
          duration: 150,
          block: 'center',
          behavior: 'smooth'
        });
      }
      toast('Please answer all mandatory questions.', {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
    } else {
      this.submitFeedback();
    }
  }

  render() {
    //used for checking mandatory fields
    let wasSubmitted = this.state.submitted
      ? styles.feedbackMandatory
      : styles.feedbackQuestion;

    return (
      <>
        {this.state.loading && <CustomLoader />}
        <div className={styles.feedback} hidden={this.state.loading}>
          <div className={styles.banner}>
            L+D Session Survey -{' '}
            {this.state.courseData !== null && this.state.courseData.courseName}
          </div>

          <div className={styles.feedbackPage}>
            <div
              className={styles.feedbackContainer}
              hidden={this.state.completed || this.state.closed}
            >
              <p className={styles.questionText}>* Denotes a mandatory field</p>

              <div className={styles.feedbackPageContent}>
                <Form onSubmit={this.handleSubmit} className={styles.questionText}>
                  <div
                    id="q1"
                    className={
                      this.state.a1 === '' ? wasSubmitted : styles.feedbackQuestion
                    }
                  >
                    <FormGroup tag="fieldset">
                      <Label>1. {this.q1} *</Label>
                      <Col sm={10}>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q1-yes"
                              type="radio"
                              name="a1"
                              onChange={this.onRadioModify}
                              value="yes"
                              checked={this.state.a1 === 'yes'}
                            />
                            {' Yes '}
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q1-no"
                              type="radio"
                              name="a1"
                              onChange={this.onRadioModify}
                              value="no"
                              checked={this.state.a1 === 'no'}
                            />
                            {' No '}
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </div>

                  <div
                    id="q2"
                    className={
                      this.state.a2 === '' ? wasSubmitted : styles.feedbackQuestion
                    }
                  >
                    <FormGroup tag="fieldset">
                      <Label>2. {this.q2} *</Label>
                      <Col sm={10}>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q2-yes"
                              type="radio"
                              name="a2"
                              onChange={this.onRadioModify}
                              value="yes"
                              checked={this.state.a2 === 'yes'}
                            />{' '}
                            Yes
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q2-no"
                              type="radio"
                              name="a2"
                              onChange={this.onRadioModify}
                              value="no"
                              checked={this.state.a2 === 'no'}
                            />{' '}
                            No
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </div>

                  <div id="q3" className={styles.feedbackQuestion}>
                    <FormGroup>
                      <Label>3. {this.q3}</Label>
                      <Col sm={10}>
                        <Input
                          type="textarea"
                          maxLength="200"
                          name="a3"
                          id="q3-optional-text"
                          placeholder="Optional"
                          onChange={this.onTextModify}
                          disabled={this.state.a2 !== 'no'}
                          value={this.state.a3}
                        />
                      </Col>
                    </FormGroup>
                  </div>

                  <div
                    id="q4"
                    className={
                      this.state.a4 === '' ? wasSubmitted : styles.feedbackQuestion
                    }
                  >
                    <FormGroup>
                      <Label>4. {this.q4} *</Label>
                      <Col sm={10}>
                        <Input
                          type="textarea"
                          maxLength="200"
                          name="a4"
                          id="q4-text"
                          onChange={this.onTextModify}
                          value={this.state.a4}
                        />
                      </Col>
                    </FormGroup>
                  </div>

                  <div
                    id="q5"
                    className={
                      this.state.a5 === '' ? wasSubmitted : styles.feedbackQuestion
                    }
                  >
                    <FormGroup tag="fieldset">
                      <Label>5. {this.q5} *</Label>
                      <Col sm={10}>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q5-yes"
                              type="radio"
                              name="a5"
                              onChange={this.onRadioModify}
                              value="yes"
                              checked={this.state.a5 === 'yes'}
                            />{' '}
                            Yes
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q5-no"
                              type="radio"
                              name="a5"
                              onChange={this.onRadioModify}
                              value="no"
                              checked={this.state.a5 === 'no'}
                            />{' '}
                            No
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </div>

                  <div
                    id="q6"
                    className={
                      this.state.a6 === '' ? wasSubmitted : styles.feedbackQuestion
                    }
                  >
                    <FormGroup tag="fieldset">
                      <Label>6. {this.q6} *</Label>
                      <Col sm={10}>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q6-yes"
                              type="radio"
                              name="a6"
                              onChange={this.onRadioModify}
                              value="yes"
                              checked={this.state.a6 === 'yes'}
                            />{' '}
                            Yes
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q6-no"
                              type="radio"
                              name="a6"
                              onChange={this.onRadioModify}
                              value="no"
                              checked={this.state.a6 === 'no'}
                            />{' '}
                            No
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </div>

                  <div
                    id="q7"
                    className={
                      this.state.a7 === '' ? wasSubmitted : styles.feedbackQuestion
                    }
                  >
                    <FormGroup tag="fieldset">
                      <Label>7. {this.q7} *</Label>
                      <Col sm={10}>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q7-yes"
                              type="radio"
                              name="a7"
                              onChange={this.onRadioModify}
                              value="yes"
                              checked={this.state.a7 === 'yes'}
                            />{' '}
                            Yes
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              id="q7-no"
                              type="radio"
                              name="a7"
                              onChange={this.onRadioModify}
                              value="no"
                              checked={this.state.a7 === 'no'}
                            />{' '}
                            No
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </div>

                  <div id="q8" className={styles.feedbackQuestion}>
                    <FormGroup>
                      <Label>8. {this.q8}</Label>
                      <Col sm={10}>
                        <Input
                          type="textarea"
                          maxLength="200"
                          name="a8"
                          id="q8-optional-text"
                          placeholder="Optional"
                          onChange={this.onTextModify}
                          value={this.state.a8}
                        />
                      </Col>
                    </FormGroup>
                  </div>

                  <div id="q9" className={styles.feedbackQuestion}>
                    <Label>
                      Would you like the L+D team to contact you regarding your
                      feedback?
                      <br />
                      Please note, if you select yes, your response will no longer be
                      anonymous
                    </Label>
                    <FormGroup check>
                      <Label check className={styles.questionText}>
                        <Input
                          type="checkbox"
                          id="q9-checkbox"
                          name="a9"
                          onChange={this.onCheckModify}
                        />{' '}
                        Yes, please contact me
                      </Label>
                    </FormGroup>
                  </div>

                  <AccessibleButton
                    id="submit-button"
                    className="dw-button medBlue"
                    type="submit"
                    style={{ marginLeft: '90%' }}
                  >
                    Submit
                  </AccessibleButton>
                </Form>
              </div>
            </div>

            <div className={styles.feedbackComplete} hidden={!this.state.completed}>
              {this.state.anonymous ? (
                <p id="feedback-success-message">
                  Thank you for your feedback! Your survey has been successfully
                  submitted.
                </p>
              ) : (
                <p id="feedback-success-message">
                  Thank you for your feedback! The L+D team will be in contact to
                  discuss your responses.
                </p>
              )}
            </div>

            <div className={styles.feedbackComplete} hidden={!this.state.closed}>
              <p>Feedback for this session is closed.</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
Feedback.contextType = GlobalContext;

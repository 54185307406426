import React from 'react';
import ReactGA from 'react-ga';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { validateEmail } from '../../../../CONSTANTS';
import { toast } from 'react-toastify';

export default class RepAddModify extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.onModify = this.onModify.bind(this);
    this.parseForm = this.parseForm.bind(this);

    this.state = {
      submitting: false,
      name: '',
      email: ''
    };
    this.originalState = {
      submitting: false,
      name: '',
      email: ''
    };
  }

  componentDidMount() {
    if (this.props.payload != null) this.parsePayload();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  parseForm(repId = null) {
    //Validate EMAIL
    if (!validateEmail(this.state.email)) {
      toast('Please enter a valid Email address.', {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return null;
    }

    let data = {
      name: this.state.name,
      email: this.state.email
    };

    if (repId !== null) data.repId = repId;
    return data;
  }

  add() {
    if (this.state.submitting) return null;

    const data = this.parseForm();
    if (data === null) return null;

    toastRequest.notify('Creating representative');
    this.setState({ submitting: true });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Rep', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: this.abortController.signal
    })
      .then((response) => {
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'L+D Rep added successfully!'
        );
        if (myJson.success === true) {
          this.setState(this.originalState);
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  update() {
    const data = this.parseForm(this.props.payload.repId);
    if (data === null) return null;
    this.props.closeModal();

    toastRequest.notify('Updating Representative');
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Rep', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
      // signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'L+D Rep edited successfully!'
        );
        if (myJson.success === true) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  onModify(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  parsePayload() {
    for (const [key, value] of Object.entries(this.props.payload)) {
      if (value != null) {
        this.setState({ [key]: value });
      }
    }
  }

  render() {
    return (
      <div>
        <Form onSubmit={(event) => event.preventDefault()}>
          {/*NAME*/}
          <FormGroup>
            <Label for="repName">Rep Name</Label>
            <Input
              type="text"
              value={this.state.name}
              name="name"
              id="repName"
              onChange={this.onModify}
            />
          </FormGroup>
          {/*EMAIL*/}
          <FormGroup>
            <Label for="repEmail">Email</Label>
            <Input
              type="text"
              value={this.state.email}
              name="email"
              id="repEmail"
              onChange={this.onModify}
            />
          </FormGroup>
        </Form>
      </div>
    );
  }
}

import React, { Component } from 'react';
import style from './AccessibleButton.module.css';

class AccessibleButton extends Component {
  render() {
    const { buttonRef, children, className, ...otherProps } = this.props;
    let buttonClasses = [style.accessibleButton];
    if (className) {
      buttonClasses.push(className);
    }

    return (
      <button {...otherProps} ref={buttonRef} className={buttonClasses.join(' ')}>
        <span tabIndex="-1">{children}</span>
      </button>
    );
  }
}
export default AccessibleButton;

import React, { useState } from 'react';
import ReactGA from 'react-ga';
import sharedStyle from '../AdminAllTabs.module.css';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import { forceCache } from '../../../../CONSTANTS';
import { Button, Col, Input, Label, Row, Form, FormGroup } from 'reactstrap';
import { useEffect } from 'react';

const AddTab = () => {
  const [eventDescription, setEventDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const abortController = new AbortController();

  // onSubmit() is called whenever user submits a new event on the "Add" tab
  const onSubmit = async () => {
    // use adalFetch to POST API
    let toastId = toastRequest.notify(`Adding ${eventDescription}`);

    try {
      const response = await adalApiFetch(
        fetch,
        process.env.REACT_APP_API_BASE_DOMAIN + `/api/DaysOff`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            eventDescription: eventDescription,
            startDate: startDate,
            endDate: endDate
          }),
          signal: abortController.signal
        }
      );

      if (response.ok) forceCache();
      const myJson = await response.json();

      if (!myJson.success && myJson.error) {
        toast.update(toastId, {
          render: `${myJson.error} `,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      } else {
        toast.update(toastId, {
          render: `${myJson.data.eventDescription} added successfully.`,
          type: toast.TYPE.SUCCESS,
          autoClose: 5000
        });
      }
    } catch (e) {
      toast.update(toastId, {
        render: `${e.toString()} `,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
      ReactGA.exception({
        description: e.toString()
      });
    }
  };

  // Following useEffect() is ran only once when the user switches to "Add" tab
  useEffect(() => {
    return () => {
      abortController.abort();
    };

    // The following line of code disables the missing dependencies error as this useEffect is ran only once when the user clicks
    // on Add Tab

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form>
      <Row>
        <Col>
          <FormGroup>
            <Label for="description">Event Description</Label>
            <Input
              id="description"
              name="description"
              type="text"
              value={eventDescription}
              onChange={(e) => setEventDescription(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="startDate"> From </Label>
            <Input
              id={'startDate'}
              name="startDate"
              value={startDate}
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="endDate"> To </Label>
            <Input
              id={'endDate'}
              name="endDate"
              value={endDate}
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            color="primary"
            size="lg"
            id="add-submit-button"
            className={sharedStyle.submitBtn}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddTab;

import React from 'react';
import ReactGA from 'react-ga';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';

export default class AttributeAddModify extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.onModify = this.onModify.bind(this);
    this.parseForm = this.parseForm.bind(this);

    this.state = {
      submitting: false,
      name: ''
    };
    this.originalState = {
      submitting: false,
      name: ''
    };
  }

  componentDidMount() {
    if (this.props.payload != null) this.parsePayload();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  parseForm(attributeId = null) {
    let data = {
      name: this.state.name
    };

    if (attributeId !== null) data.attributeId = attributeId;
    return data;
  }

  add() {
    if (this.state.submitting) return null;
    toastRequest.notify('Creating Attribute');
    const data = this.parseForm();

    this.setState({ submitting: true });
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Attributes', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: this.abortController.signal
    })
      .then((response) => {
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'Attribute added successfully'
        );
        if (myJson.success === true) {
          this.setState(this.originalState);
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  update() {
    toastRequest.notify('Updating Attribute');
    const data = this.parseForm(this.props.payload.attributeId);
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Attributes', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
      // signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(
          myJson.success,
          myJson.error,
          'Attribute edited successfully!'
        );
        if (myJson.success === true) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  onModify(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  parsePayload() {
    for (const [key, value] of Object.entries(this.props.payload)) {
      if (value != null) {
        this.setState({ [key]: value });
      }
    }
  }

  render() {
    return (
      <div>
        <Form onSubmit={(event) => event.preventDefault()}>
          {/*NAME*/}
          <FormGroup>
            <Label for="attributeName">Attribute Name</Label>
            <Input
              type="text"
              value={this.state.name}
              name="name"
              id="attributeName"
              onChange={this.onModify}
            />
          </FormGroup>
        </Form>
      </div>
    );
  }
}

import React from 'react';

export const GlobalContext = React.createContext({
  user: null,
  setUser: () => {},

  badges: null,
  setBadges: () => {},

  points: null,
  setPoints: () => {},

  sessionList: [],
  setParticipantsListForCourse: () => {},
  setSessionList: () => {},

  animateSessionMaterials: false,
  toggleAnimateSessionMaterials: () => {},

  employees: [],
  reloadEmployees: () => {},

  notifications: null,
  reloadNotifications: () => {},
  notificationAnimations: {},

  notificationStatusReasons: null,
  reloadNotificationStatusReasons: () => {}
});

export default GlobalContext;

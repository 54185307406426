import React from 'react';
import ReactGA from 'react-ga';
import styles from './RecommendedSessionsDetails.module.css';
import { Row, Col, Spinner } from 'reactstrap';
import DigiCheckbox from './../../../components/digiCheckbox/DigiCheckbox';
import DigiButton from './../../../components/digiButton/DigiButton';
import EmailContent from './EmailContent';
import { toast } from 'react-toastify';
import * as toastRequest from '../../admin/utils/toastRequests';
import { adalApiFetch } from '../../../adalConfig';
import { stringToDate } from '../../../CONSTANTS';

export default class RecommendedSessionsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.state = {
      selectedEmployees: {}, // Represents staff that manager selected to remind/share with
      selectAll: false,
      enableRemindBtn: false,
      actionBtnText: 'Remind',
      showEmailContent: false,
      sent: {}, // Staff of manager with whom session has already been shared/reminded
      loadingSent: false,
      recommended: {
        actioned: [],
        outstanding: []
      },
      registeredOrAttended: {
        recommended: [],
        selfRegistered: []
      },
      declined: {
        outOfOffice: [],
        schedulingConflict: [],
        other: []
      }
    };
  }

  componentDidMount() {
    if (this.isSessionNull()) {
      return;
    }
    this.setState(
      {
        actionBtnText: this.isUpcoming() ? 'Remind' : 'Share'
      },
      () => {
        this.fetchCheckedStaff();
        this.getRecommendationResponses();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.isSessionNull()) {
      return;
    }

    // Update view if selected session changed
    if (
      this.isSessionNull(prevProps) ||
      prevProps.session.courseDetails.courseDetailsId !==
        this.props.session.courseDetails.courseDetailsId
    ) {
      this.setState(
        {
          actionBtnText: this.isUpcoming() ? 'Remind' : 'Share'
        },
        () => {
          this.fetchCheckedStaff();
          this.getRecommendationResponses();
        }
      );
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  /**
   * Gets all session-share or session-reminder notifications sent by manager to their staff
   */
  fetchCheckedStaff = () => {
    let endpoint = '';

    switch (this.state.actionBtnText) {
      case 'Share':
        endpoint = `/api/Notifications/share?courseDetailsId=${this.props.session.courseDetails.courseDetailsId}`;
        break;
      case 'Remind':
        endpoint = `/api/Notifications/remind?courseDetailsId=${this.props.session.courseDetails.courseDetailsId}`;
        break;
      default:
        break;
    }

    this.setState({ loadingSent: true });

    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + endpoint, {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          this.setState({ loadingSent: false });
          toast(
            'Something went wrong while fetching sessions that were already shared!',
            {
              type: toast.TYPE.ERROR,
              autoClose: true
            }
          );
        } else {
          let sent = {};
          myJson.data.forEach((sessionShare) => {
            this.state.recommended.outstanding.forEach((staff) => {
              if (staff.userId === sessionShare.userId) {
                sent[staff.userId] = true;
              }
            });
          });

          const employees = this.setCheckboxForAllStaff(false, sent);

          // Set state
          this.setState({
            selectedEmployees: employees,
            showEmailContent: false,
            enableRemindBtn: false,
            sent: sent,
            selectAll: this.allStaffCheckboxed(sent),
            loadingSent: false
          });
        }
      })
      .catch((e) => {
        this.setState({ loadingSent: false });
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  isUpcoming = () => {
    return stringToDate(this.props.session.courseDetails.courseStart) > Date.now();
  };

  /**
   * Sorts staff into their appropriate categories
   */
  getRecommendationResponses = () => {
    let registeredStaff = this.props.session.staffRegistered.map((x) => x.userId);
    let attendedStaff = this.props.session.staffRegistered
      .filter((x) => x.attended)
      .map((x) => x.userId);

    let recommended = {
      actioned: [],
      outstanding: []
    };
    let registeredOrAttended = {
      recommended: [],
      selfRegistered: []
    };
    let declined = {
      outOfOffice: [],
      schedulingConflict: [],
      other: []
    };

    this.props.session.recommendationResponses.forEach((response) => {
      // recommended + registered (upcoming session)
      if (
        this.isUpcoming(this.props.session) &&
        registeredStaff.includes(response.userId)
      ) {
        recommended.actioned.push(this.getStaffById(response.userId));
        registeredOrAttended.recommended.push(this.getStaffById(response.userId));
      }
      // recommended + attended (past session)
      else if (
        !this.isUpcoming(this.props.session) &&
        attendedStaff.includes(response.userId)
      ) {
        recommended.actioned.push(this.getStaffById(response.userId));
        registeredOrAttended.recommended.push(this.getStaffById(response.userId));
      }
      // recommended + declined
      else if (response.status === 'declined') {
        recommended.actioned.push(this.getStaffById(response.userId));
        if (response.statusReasonId === 1)
          declined.outOfOffice.push(this.getStaffById(response.userId));
        else if (response.statusReasonId === 2)
          declined.schedulingConflict.push(this.getStaffById(response.userId));
        else declined.other.push(this.getStaffById(response.userId));
      }
      // recommended + no action taken (outstanding)
      else {
        recommended.outstanding.push(this.getStaffById(response.userId));
      }
    });

    // self-registered for upcoming session (not recommended but registered)
    let recommendedUserIds = this.props.session.staffRecommended.map(
      (x) => x.userId
    );

    registeredOrAttended.selfRegistered = this.props.session.staffRegistered.filter(
      (registered) => !recommendedUserIds.includes(registered.userId)
    );

    // for past sessions, limit self-registrations to those who attended
    if (!this.isUpcoming(this.props.session))
      registeredOrAttended.selfRegistered = registeredOrAttended.selfRegistered.filter(
        (x) => x.attended
      );

    // sort staff alphabetically
    recommended.outstanding = this.sortStaff(recommended.outstanding);
    recommended.actioned = this.sortStaff(recommended.actioned);
    registeredOrAttended.recommended = this.sortStaff(
      registeredOrAttended.recommended
    );
    registeredOrAttended.selfRegistered = this.sortStaff(
      registeredOrAttended.selfRegistered
    );
    declined.outOfOffice = this.sortStaff(declined.outOfOffice);
    declined.schedulingConflict = this.sortStaff(declined.schedulingConflict);
    declined.other = this.sortStaff(declined.other);

    // set state
    this.setState({
      recommended: recommended,
      registeredOrAttended: registeredOrAttended,
      declined: declined
    });
  };

  getStaffById = (id) => {
    return this.props.staff.find((s) => s.userId === id);
  };

  sortStaff = (staff) => {
    return staff.sort((a, b) => a.name.localeCompare(b.name));
  };

  /**
   * Checks if checkboxes for all staff are not selected
   */
  noStaffSelected = () => {
    for (const staffUserId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(staffUserId)) {
        if (this.state.selectedEmployees[staffUserId]) {
          return false;
        }
      }
    }
    return true;
  };

  /**
   * Returns true if check box of at least one staff is selected.
   * False if no check box selected.
   */
  shouldEnableRemindBtn = () => {
    return !this.noStaffSelected();
  };

  /**
   * Returns true if all staff has been reminded of a session
   */
  allStaffCheckboxed = (noRemindStaff) => {
    return (
      this.state.recommended.outstanding.length === Object.keys(noRemindStaff).length
    );
  };

  /**
   * Closes the email container.
   */
  onCloseEmailContainer = () => {
    this.setState({
      showEmailContent: false
    });
  };

  /**
   * Sends out an email to the selected staff sharing or reminding them about a session
   */
  sendEmails = (subject, bodyFirstLine) => {
    const requestBody = [];
    for (const userId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(userId)) {
        if (this.state.selectedEmployees[userId]) {
          requestBody.push({
            userId: userId,
            courseDetailsId: this.props.session.courseDetails.courseDetailsId,
            domain: this.props.session.courseDetails.course.domain.name,
            subject: subject,
            firstLine: bodyFirstLine,
            isMandatory: this.props.session.courseDetails.course.mandatory,
            envBaseUrl: process.env.REACT_APP_UI_BASE_DOMAIN
          });
        }
      }
    }

    let endpoint = '';
    let toastText = '';
    switch (this.state.actionBtnText) {
      case 'Share':
        endpoint = `/api/Notifications/share`;
        toastText = `Session ${this.props.session.courseDetails.courseName} has been shared with your staff`;
        break;
      case 'Remind':
        endpoint = `/api/Notifications/remind`;
        toastText = `Reminder of Session ${this.props.session.courseDetails.courseName} has been sent to your staff`;
        break;
      default:
        break;
    }

    // API call to send emails about shared session
    toastRequest.notify('Sending notifications.');
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + endpoint, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody),
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.success === true) {
          this.fetchCheckedStaff();
          toastRequest.updateToast(true, null, toastText);
        } else {
          toastRequest.updateToast(false, 'Something went wrong!');
        }
      })
      .catch((e) => {
        toastRequest.dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
    // Close the email container
    this.onCloseEmailContainer();
  };

  /**
   * Gets the number of staff manager has selected (i.e. enabled checkbox for)
   */
  selectedStaffCount = () => {
    let count = 0;
    for (const userId in this.state.selectedEmployees) {
      if (this.state.selectedEmployees.hasOwnProperty(userId)) {
        if (this.state.selectedEmployees[userId]) count += 1;
      }
    }
    return count;
  };

  /**
   * Checks if prop's session is null
   */
  isSessionNull = (prop = this.props) => {
    return prop.session === null;
  };

  /**
   * Selects or un-selects checkboxes for all staff
   * @param value: checks all boxes if true, un-checks all boxes if false
   */
  setCheckboxForAllStaff = (value, sent = this.state.sent) => {
    let employees = {};
    this.state.recommended.outstanding.forEach((staff) => {
      if (!(staff.userId in sent)) {
        employees[staff.userId] = value;
      } else {
        // if a staff has already been reminded/shared, they cannot be manually selected by manager. Hence false.
        employees[staff.userId] = false;
      }
    });
    return employees;
  };

  /**
   * Checks if selected session has been shared (or reminded about) with userId
   */
  notificationSent = (userId) => {
    return userId in this.state.sent;
  };

  getRecommendedColumn = () => {
    // Show spinner if data is loading
    if (this.props.loading) {
      return (
        <div className={styles.loadingSpinner}>
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <>
        {/* OUTSTANDING */}
        {this.state.recommended.outstanding.length > 0 && (
          <>
            <DigiCheckbox
              checked={!!this.state.selectAll}
              disabled={
                this.state.loadingSent || this.allStaffCheckboxed(this.state.sent)
              }
              toggle={() => {
                const employees = this.setCheckboxForAllStaff(!this.state.selectAll);
                this.setState((prevState) => ({
                  selectedEmployees: employees,
                  selectAll: !prevState.selectAll,
                  enableRemindBtn: !prevState.selectAll
                }));
              }}
              label={
                <>
                  Outstanding &nbsp;&nbsp;
                  <img
                    className={styles.calendarIcon}
                    src={require('../../../assets/Calendar.png')}
                    alt="Staff who has not yet taken action on the recommended session"
                  />
                </>
              }
              className={styles.listHeadingCheckbox}
            />
            {this.state.recommended.outstanding.map((staff, index) => {
              return (
                <div
                  id={staff.userId}
                  key={index}
                  className={styles.staffRowCheckbox}
                >
                  <DigiCheckbox
                    userId={staff.userId}
                    checked={
                      this.notificationSent(staff.userId) ||
                      this.state.selectedEmployees[staff.userId]
                    }
                    disabled={
                      this.state.loadingSent || this.notificationSent(staff.userId)
                    }
                    toggle={() => {
                      this.setState(
                        (prevState) => ({
                          selectedEmployees: {
                            ...prevState.selectedEmployees,
                            [staff.userId]: !prevState.selectedEmployees[
                              staff.userId
                            ]
                          }
                        }),
                        () => {
                          this.setState({
                            enableRemindBtn: this.shouldEnableRemindBtn()
                          });
                        }
                      );
                    }}
                    label={staff.name}
                  />
                </div>
              );
            })}
            <div className={styles.sectionBreak} />
          </>
        )}

        {/* ACTIONED */}
        {this.state.recommended.actioned.length > 0 && (
          <>
            <div className={styles.listHeading}>Actioned</div>
            {this.state.recommended.actioned.map((staff, index) => {
              return (
                <div id={staff.userId} key={index} className={styles.staffRow}>
                  {staff.name}
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  getRegisteredOrAttendedColumn = () => {
    // Show spinner if data is loading
    if (this.props.loading) {
      return (
        <div className={styles.loadingSpinner}>
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <>
        {/* RECOMMENDED */}

        {this.state.registeredOrAttended.recommended.length > 0 && (
          <>
            <div className={styles.listHeading}>Recommended</div>
            {this.isUpcoming(this.props.session)
              ? this.state.registeredOrAttended.recommended.map((staff, index) => {
                  return (
                    <div id={staff.userId} key={index} className={styles.staffRow}>
                      {staff.name}
                    </div>
                  );
                })
              : this.state.registeredOrAttended.recommended.map((staff, index) => {
                  return (
                    <div id={staff.userId} key={index} className={styles.staffRow}>
                      {staff.name}
                    </div>
                  );
                })}
            <div className={styles.sectionBreak} />
          </>
        )}

        {/* SELF-REGISTERED */}
        {this.state.registeredOrAttended.selfRegistered.length > 0 && (
          <>
            <div className={styles.listHeading}>Self-Registered</div>

            {this.state.registeredOrAttended.selfRegistered.map((staff, index) => {
              return (
                <div id={staff.userId} key={index} className={styles.staffRow}>
                  {staff.name}
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  getDeclinedColumn = () => {
    // Show spinner if data is loading
    if (this.props.loading) {
      return (
        <div className={styles.loadingSpinner}>
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <>
        {/* OUT OF OFFICE */}
        {this.state.declined.outOfOffice.length > 0 && (
          <>
            <div className={styles.listHeading}>Out of Office</div>
            {this.state.declined.outOfOffice.map((staff, index) => {
              return (
                <div id={staff.userId} key={index} className={styles.staffRow}>
                  {staff.name}
                </div>
              );
            })}
            <div className={styles.sectionBreak} />
          </>
        )}

        {/* SCHEDULING CONFLICT */}
        {this.state.declined.schedulingConflict.length > 0 && (
          <>
            <div className={styles.listHeading}>Scheduling Conflict</div>
            {this.state.declined.schedulingConflict.map((staff, index) => {
              return (
                <div id={staff.userId} key={index} className={styles.staffRow}>
                  {staff.name}
                </div>
              );
            })}
            <div className={styles.sectionBreak} />
          </>
        )}

        {/* OTHER */}
        {this.state.declined.other.length > 0 && (
          <>
            <div className={styles.listHeading}>Other</div>
            {this.state.declined.other.map((staff, index) => {
              return (
                <div id={staff.userId} key={index} className={styles.staffRow}>
                  {staff.name}
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  render() {
    //   No selected session
    if (this.isSessionNull()) {
      return (
        <div className={styles.detailsContainer}>
          <div className={styles.innerDetailsContainer}>
            <div className={styles.sessionTitle}>&nbsp;</div>

            {/* Column Headers */}
            <Row className={styles.colHeaderRow}>
              <Col className={styles.colHeaderTitle}>
                <img
                  className={styles.icon}
                  src={require('../../../assets/RecIcon.png')}
                  alt="Staff who were recommended to the session"
                />
                RECOMMENDED: 0
              </Col>
              <Col
                className={`${styles.colHeaderTitle} ${styles.colVerticalBorder}`}
              >
                <img
                  className={styles.icon}
                  src={require('../../../assets/Registered.png')}
                  alt="Staff who registered for the session"
                />
                REGISTERED: 0
              </Col>
              <Col className={styles.colHeaderTitle}>
                <img
                  className={styles.icon}
                  src={require('../../../assets/Declined.png')}
                  alt="Staff who declined the recommendation due to the following reasons"
                />
                DECLINED: 0
              </Col>
            </Row>

            {/* Column Bodies */}
            <Row className={styles.staffListColumns}>
              {/* RECOMMENDED COLUMN */}
              <Col className={styles.staffListContainer}>
                <div className={styles.staffCol}>
                  {this.props.loading ? (
                    <div className={styles.loadingSpinner}>
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>

              {/* REGISTERED COLUMN */}
              <Col
                className={`${styles.staffListContainer} ${styles.colVerticalBorder}`}
              >
                <div className={styles.staffCol}>
                  {this.props.loading ? (
                    <div className={styles.loadingSpinner}>
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>

              {/* DECLINED COLUMN */}
              <Col className={styles.staffListContainer}>
                <div className={styles.staffCol}>
                  {this.props.loading ? (
                    <div className={styles.loadingSpinner}>
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>

            {/* REMIND/SHARE button */}
            <Row>
              <Col className={styles.remindButtonContainer}>
                <DigiButton
                  disabled={true}
                  className={styles.remindButton}
                  aria-label={
                    this.state.actionBtnText === 'Share'
                      ? `Click the Share function to share a past session materials with your staff`
                      : `Click the Remind function to remind your staff to register for the upcoming session`
                  }
                >
                  {this.state.actionBtnText}
                </DigiButton>
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
          </div>
        </div>
      );
    }

    // Show Email Content
    if (this.state.showEmailContent) {
      return (
        <EmailContent
          actionBtnText={this.state.actionBtnText}
          pageId={'recommendedSessions-details'}
          staffCount={this.selectedStaffCount()}
          courseDetails={this.props.session.courseDetails}
          onCloseClick={this.onCloseEmailContainer}
          sendEmails={this.sendEmails}
        />
      );
    }

    // There is a session selected from the Sessions list
    return (
      <div className={styles.detailsContainer}>
        <div className={styles.innerDetailsContainer}>
          {/* If loading, make title's font color same as background to make it look invisible */}
          <div
            sm={9}
            className={
              this.props.loading
                ? `${styles.sessionTitle} ${styles.darkBlueColor}`
                : styles.sessionTitle
            }
          >
            {this.props.session.courseDetails.courseName}
          </div>

          {/* Column Headers */}
          <Row className={styles.colHeaderRow}>
            <Col className={styles.colHeaderTitle}>
              <img
                className={styles.icon}
                src={require('../../../assets/RecIcon.png')}
                alt="Staff who were recommended to the session"
              />
              RECOMMENDED:{' '}
              {this.props.loading
                ? 0
                : this.state.recommended.actioned.length +
                  this.state.recommended.outstanding.length}
              {this.state.loadingSent && (
                <Spinner size="sm" color="primary" className={styles.sentSpinner} />
              )}
            </Col>
            <Col className={`${styles.colHeaderTitle} ${styles.colVerticalBorder}`}>
              {this.isUpcoming(this.props.session) ? (
                <>
                  <img
                    className={styles.icon}
                    src={require('../../../assets/Registered.png')}
                    alt="Staff who registered for the session"
                  />
                  REGISTERED:{' '}
                  {this.props.loading
                    ? 0
                    : this.props.session.staffRegistered.length}
                </>
              ) : (
                <>
                  <img
                    className={styles.icon}
                    src={require('../../../assets/Attended.png')}
                    alt="Staff who attended the session"
                  />
                  ATTENDED:{' '}
                  {
                    this.props.session.staffRegistered.filter((x) => x.attended)
                      .length
                  }
                </>
              )}
            </Col>
            <Col className={styles.colHeaderTitle}>
              <>
                <img
                  className={styles.icon}
                  src={require('../../../assets/Declined.png')}
                  alt="Staff who declined the recommendation due to the following reasons"
                />
                DECLINED:{' '}
                {this.props.loading
                  ? 0
                  : this.state.recommended.actioned.length -
                    this.state.registeredOrAttended.recommended.length}
              </>
            </Col>
          </Row>

          {/* Column Bodies */}
          <Row className={styles.staffListColumns}>
            {/* Recommended */}
            <Col className={styles.staffListContainer}>
              <div className={styles.staffCol}>{this.getRecommendedColumn()}</div>
            </Col>

            {/* Registered*/}
            {!this.isSessionNull() && (
              <Col
                className={`${styles.staffListContainer} ${styles.colVerticalBorder}`}
              >
                <div className={styles.staffCol}>
                  {this.getRegisteredOrAttendedColumn()}
                </div>
              </Col>
            )}

            {/* Declined */}
            <Col className={styles.staffListContainer}>
              <div className={styles.staffCol}>{this.getDeclinedColumn()}</div>
            </Col>
          </Row>

          {/* 'Remind' or 'Share' button*/}
          <Row>
            <Col className={styles.remindButtonContainer}>
              <DigiButton
                id={`action-button-${this.props.session.courseDetails.courseDetailsId}`}
                className={styles.remindButton}
                disabled={!this.state.enableRemindBtn}
                aria-label={
                  this.state.actionBtnText === 'Share'
                    ? `Click the Share function to share a past session materials with your staff`
                    : `Click the Remind function to remind your staff to register for the upcoming session`
                }
                onClick={() => {
                  this.setState({
                    showEmailContent: true
                  });
                }}
              >
                {this.state.actionBtnText}
              </DigiButton>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    );
  }
}

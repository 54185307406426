import React, { Component } from 'react';
import styles from './CloseButton.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AccessibleButton from '../accessibleButton/AccessibleButton';

export default class CloseButton extends Component {
  state = {
    showContent: true
  };

  static defaultProps = {
    onClick: () => {}
  };

  render() {
    return (
      <AccessibleButton
        className={[styles.closeIcon, this.props.className].join(' ')}
        onClick={() => this.props.onClick()}
      >
        <FontAwesomeIcon icon={faTimes} role="button" />
        <span className="show-for-sr">Close</span>
      </AccessibleButton>
    );
  }
}

import React from 'react';
import DataTable from '../../../components/dataTableBase/DataTableBase';
import { getFormattedDate } from '../../../utils/date';
import EmptyDataTableBase from '../../../components/dataTableBase/EmptyDataTableBase';

const columns = [
    {
        name: 'Registered Sessions',
        selector: row => row.courseDetails.courseName,
        sortable: true,
        wrap: true,
        grow: 3,
        compact: false,
    },
    
    {
        name: 'Date of Session',
        selector: row => getFormattedDate(row.courseDetails.courseStart),
        sortable: true,
        compact: false,
    },
];

export default function StaffRegisteredSessions(props) {

    const data = props.registeredCourses;
//    console.log("StaffRegisteredSessions: ", props);
    const reg = data.filter(item => item.staffRegistered.some(staff => staff.userId === props.userId));
//    console.log("StaffRegisteredSessions With filter : ", reg);
            

    return <DataTable //title="Mandatory Courses"
        columns={columns}
        data={reg}
        defaultPageSize={5}
        noDataComponent={EmptyDataTableBase(columns)}
        style={{
            height: "100px"
        }}
    />;
}
import React, { Component } from 'react';
import DataTable from '../../../components/dataTableBase/DataTableBase';
import EmptyDataTableBase from '../../../components/dataTableBase/EmptyDataTableBase';


const columns = [
    {
        name: 'Mandatory Course Name',
        selector: row => row.courseDetails.courseName,
        sortable: true,
        wrap: true,
        grow: 3,
        compact:false,
    },
    
    {
        name: 'Status',
        selector: row => (row.staffAttended && row.staffAttended.length > 0 && row.staffAttended.some(obj => obj['userId'] === row.currentUserId) ? 'Completed' : 'Outstanding'),
        sortable: true,
        compact: false,
    },
    
];

export default class StaffMandatoryCourses extends Component {

    render() {
        const data = this.props.mandatoryCourses.filter(i => this.props.isManager || !i.courseDetails.course.managerOnly)
            .map(item => ({ ...item, currentUserId: this.props.userId, isManager: this.props.isManager }));
        
        return (<DataTable
            columns={columns}
            data={data}
            defaultPageSize={5}
            noDataComponent={EmptyDataTableBase(columns)}
        />);
    }
}
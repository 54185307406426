import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import querystring from 'querystring';
import { cookieValue } from './CONSTANTS';

export const adalConfig = {
  tenant: 'cddc1229-ac2a-4b97-b78a-0e5cacb5865c',
  clientId: process.env.REACT_APP_CLIENT_ID,
  endpoints: {
    api: process.env.REACT_APP_CLIENT_ID
  },
  cacheLocation: 'localStorage',
  redirectUri: window.location.origin + '/aad-callback'
};

export const authContext = new AuthenticationContext(adalConfig);

export const useAdalFetch = () => {
  const abort = useRef(() => {});
  useEffect(
    () =>
      function cleanup() {
        abort.current();
      }
  );
  return (url, options, userAbort) => {
    const abortController = new AbortController();
    abort.current = () => {
      abortController.abort.bind(abortController)();
      userAbort && userAbort();
    };
    return adalApiFetch(fetch, url, {
      ...options,
      signal: abortController.signal
    });
  };
};

export const adalApiFetch = (fetch, url, options) => {
  // Special exception for automated tests: Skip ADAL authentication and use a custom token instead (this comes from a cookie)
  if (cookieValue('automatedTestingToken')) {
    return fetch(url, {
      ...options,
      headers: {
        'Content-type': 'application/json', // Required in order to prevent a 400
        Authorization: 'AutomatedTestKey ' + cookieValue('automatedTestingToken')
      }
    });
  }

  return new Promise((resolve, reject) => {
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options)
      .then((response) => {
        // Store request timing to Google Analytics.
        setTimeout(() => {
          // The Adal requests generate two fetch instances. Ensure that the durations for both are included. (The second request is not immediately available, which is why a setTimeout is used)
          const mostRecentRequestInfo = window.performance
            .getEntriesByType('resource')
            .filter((item) => item.initiatorType === 'fetch' && item.name === url)
            .slice(-2);
          if (mostRecentRequestInfo) {
            let totalDuration = 0;
            mostRecentRequestInfo.forEach((currentRequestItem) => {
              totalDuration += currentRequestItem.duration;
            });
            totalDuration = Math.round(totalDuration);

            ReactGA.timing({
              category: 'Load Times',
              variable: new URL(url).pathname.replace(/@/gi, '[AT_SYMBOL]'), // react-ga will not log items that appear to be email addresses
              value: totalDuration, // in milliseconds
              label: 'adalApiFetch'
            });
          }
        }, 0);

        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const adalGet = async (path, params, abortController = undefined) => {
  try {
    let signal;
    if (abortController) {
      signal = abortController.signal;
    }
    let url = process.env.REACT_APP_API_BASE_DOMAIN + path;
    if (params) {
      url = url + '?' + querystring.stringify(params);
    }
    const response = await adalApiFetch(fetch, url, { method: 'get', signal });
    return response.ok
      ? await response.json()
      : { success: false, error: 'Request failed!' };
  } catch (e) {
    return { success: false, error: e.toString() };
  }
};

export const adalPost = async (path, body, abortController = undefined) => {
  try {
    let signal;
    if (abortController) {
      signal = abortController.signal;
    }
    const url = process.env.REACT_APP_API_BASE_DOMAIN + path;
    const response = await adalApiFetch(fetch, url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      signal,
      body: JSON.stringify(body)
    });
    return response.ok
      ? await response.json()
      : { success: false, error: 'Request failed!' };
  } catch (e) {
    return { success: false, error: e.toString() };
  }
};

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

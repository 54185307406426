import React from 'react';
import ReactGA from 'react-ga';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Spinner,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap';
import delIcon from '../../images/garbage.svg';
import editIcon from '../../images/pencil.svg';
import { adalApiFetch } from '../../../../adalConfig';
import { notify, error, dismissToast, updateToast } from '../../utils/toastRequests';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  forceCache,
  TECHNICAL_ERROR_MESSAGE,
  stringToDate,
  stringDateToUtcString
} from '../../../../CONSTANTS';
import DigiCheckbox from '../../../../components/digiCheckbox/DigiCheckbox';
import UpdateEmployee from './update-employee/UpdateEmployee';
import styles from './Manager.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import '../../../../App.css';
import dynamicSort from '../../utils/sort';
import AccessibleButton from '../../../../components/accessibleButton/AccessibleButton';
import sharedStyle from '../AdminAllTabs.module.css';

export default class Manager extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleEmpModal = this.toggleEmpModal.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.deleteSelf = this.deleteSelf.bind(this);
    this.formRef = React.createRef();
    this.state = {
      modalOpen: false,
      empModal: false,
      expandUpdate: '',
      empDelete: null,
      selectedUser: {},
      employees: null,
      submitting: false,
      selected: false,
      endDate: '',
      enableEndDate: false,
      startDate: this.defaultDateDisplay(),
      oldManagerId: 0,
      oldManagerEmail: '',
      showTransferManagerPrompt: false,
      sort: 'name-descending',
      selectedRole: {
        label: props.payload.role.name,
        value: props.payload.role.roleId
      },
      editMode: false
    };
  }

  /**
   * Keep an eye on the accordionIsOpen property, and update the staff list every time the accordion is flagged to open
   * @param {*} prevProps  previous properties
   */
  componentDidUpdate(prevProps) {
    if (prevProps.accordionIsOpen !== this.props.accordionIsOpen) {
      // It changed
      if (this.props.accordionIsOpen) {
        this.updateEmployees();
      } else {
        this.abortController.abort();
        this.abortController = new AbortController();
      }
    }
  }
  componentWillUnmount() {
    this.abortController.abort();
  }

  handleChange = (selectedUser) => {
    this.setState({ selectedUser, showTransferManagerPrompt: false });

    if (!this.state.selected) {
      this.setState({ selected: !this.state.selected });
    }
  };

  handleRoleChange = (selectedRole) => {
    this.setState({ selectedRole });
  };

  handleFormChange = (date) => {
    this.setState({ [date.target.name]: date.target.value });
  };

  toggleEmpModal = (employee) => {
    this.setState((prevState) => ({
      empModal: !prevState.empModal,
      empDelete: employee
    }));
  };

  toggleDeleteModal() {
    !this.state.modalOpen && this.updateEmployees();
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen
    }));
  }

  deleteTeamMember = (employee) => {
    this.toggleEmpModal();
    notify('Deleting member from team.');
    let data = {
      Id: employee.Id,
      managerId: employee.managerId,
      userId: employee.userId
    };
    this.setState({ employees: null });

    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN + `/api/ManagerEmployee/`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        dismissToast();
        this.setState({ empDelete: null });
        this.updateEmployees();
        this.props.managersReload();
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        updateToast(null, TECHNICAL_ERROR_MESSAGE);
        this.setState({ empDelete: null });
        this.updateEmployees();
      });
  };

  updateSelf() {
    this.formRef.current.update();
  }

  isValid = (name) => {
    return this.state[name] !== '';
  };

  deleteSelf() {
    notify('Deleting manager');
    this.toggleDeleteModal();

    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/Managers/${this.props.payload.managerId}`,
      { method: 'delete', signal: this.abortController.signal }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      // .catch(updateToast(false, "An error occurred when attempting to delete this Manager"))
      .then((myJson) => {
        updateToast(
          myJson.success,
          myJson.error,
          `Manager "${this.props.payload.user.email}" deleted successfully!`
        );
        if (myJson.success === true) {
          this.props.manualReload();
        }
      })
      .catch((e) => {
        dismissToast();
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  updateEmployees = (doToast = true) => {
    if (doToast) {
      notify('Loading employees.');
    }
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/ManagerEmployee/id?userId=${this.props.payload.userId}`,
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          throw new Error(myJson);
        } else {
          if (doToast) {
            dismissToast();
          }
          this.setState({ employees: myJson.data, sort: 'name-descending' });
          this.sortTable(['user', 'name']);
        }
      })
      .catch((e) => {
        if (doToast) {
          e.name === 'AbortError'
            ? dismissToast()
            : updateToast(null, TECHNICAL_ERROR_MESSAGE);
        }
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  updateManagerRole = () => {
    const successMsg =
      this.props.payload.user.name +
      ' has been updated to the ' +
      this.state.selectedRole.label +
      ' role.';
    const data = {
      managerId: this.props.payload.managerId,
      userId: this.props.payload.userId,
      roleId: this.state.selectedRole.value
    };
    notify('Updating manager role.');
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + `/api/Managers`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((myJson) => {
        updateToast(myJson.success, myJson.error, successMsg, 5000);
        if (myJson.success) {
          this.props.managersReload();
          this.toggleManagerRoleEdit();
        }
      })
      .catch((e) => {
        updateToast(null, TECHNICAL_ERROR_MESSAGE);
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  convertDateDisplay = (date) => {
    var dateObj = stringToDate(date);
    const newDate = String(
      dateObj.toLocaleDateString('en-US', {
        month: 'long',
        weekday: 'long',
        day: 'numeric',
        year: 'numeric'
      })
    );
    return newDate;
  };

  isMaxDate = (date) => {
    var dateObj = stringToDate(date);
    return dateObj.getFullYear() === 9999;
  };

  defaultDateDisplay = () => {
    var newDate = new Date().toISOString().substr(0, 10);
    return newDate;
  };

  toggleEmployeeUpdate = (manId, usId, ind) => {
    this.setState(() => ({ expandUpdate: manId + '-' + usId + '-' + ind }));
  };

  toggleManagerRoleEdit = () => {
    this.setState({
      selectedRole: {
        label: this.props.payload.role.name,
        value: this.props.allRoles.filter(
          (role) => role.name === this.props.payload.role.name
        )[0].roleId
      },
      editMode: !this.state.editMode
    });
  };

  closeAllOtherAccordions = () => {
    alert('Manager.jsx: Call Parent closeAllOtherAccordions()');
    this.props.closeAllOtherAccordions();
  };

  sortTable(context) {
    let sortType = this.state.sort;
    if (sortType.includes('descending')) {
      this.setState({
        sort: context[context.length - 1] + '-ascending',
        employees:
          this.state.employees == null
            ? this.state.employees
            : this.state.employees.sort(dynamicSort(context))
      });
    } else {
      this.setState({
        sort: context[context.length - 1] + '-descending',
        employees:
          this.state.employees == null
            ? this.state.employees
            : this.state.employees.sort(dynamicSort(['-'].concat(context)))
      });
    }
  }

  displaysEmployees = () => {
    return (
      <Collapse
        isOpen={this.props.accordionIsOpen}
        className={styles.collapseContainer}
      >
        <Card className={'inner-employees-card'}>
          <CardBody>
            {!this.state.employees && <Spinner color="primary" />}
            {this.state.employees && (
              <Table size="sm" striped>
                <thead>
                  <tr>
                    <th
                      onClick={() => this.sortTable(['user', 'name'])}
                      className={'tableHeader'}
                    >
                      Name &nbsp;
                      {this.state.sort.split('-')[0] === 'name' && (
                        <i
                          className={[
                            'arrow',
                            this.state.sort === 'name-descending' ? 'down' : 'up'
                          ].join(' ')}
                        />
                      )}
                    </th> 

                  { /*
                    <th
                      onClick={() => this.sortTable(['roleName'])}
                      className={'tableHeader'}
                    >
                      Role &nbsp;
                      {this.state.sort.split('-')[0] === 'roleName' && (
                        <i
                          className={[
                            'arrow',
                            this.state.sort === 'roleName-descending' ? 'down' : 'up'
                          ].join(' ')}
                        />
                      )}
                    </th>
                    <th
                      onClick={() => this.sortTable(['startDate'])}
                      className={'tableHeader'}
                    >
                      Start Date &nbsp;
                      {this.state.sort.split('-')[0] === 'startDate' && (
                        <i
                          className={[
                            'arrow',
                            this.state.sort === 'startDate-descending'
                              ? 'down'
                              : 'up'
                          ].join(' ')}
                        />
                      )}
                    </th>
                    <th
                      onClick={() => this.sortTable(['endDate'])}
                      className={'tableHeader'}
                    >
                      End Date &nbsp;
                      {this.state.sort.split('-')[0] === 'endDate' && (
                        <i
                          className={[
                            'arrow',
                            this.state.sort === 'endDate-descending' ? 'down' : 'up'
                          ].join(' ')}
                        />
                      )}
                    </th>
                    */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.employees.map((employee, index) => {
                    let manager = this.props.allManagers.filter(
                      (m) => m.user.userId === employee.user.userId
                    );
                    let role = manager.length > 0 ? manager[0].role.name : '';
                    employee.roleName = role;
                    return (
                      <React.Fragment
                        key={
                          'tr-employee-' +
                          this.props.payload.managerId +
                          '-' +
                          employee.userId +
                          '-' +
                          index
                        }
                      >
                        <tr>
                          <td
                            id={
                              'employee-name-' +
                              this.props.payload.managerId +
                              '-' +
                              employee.userId +
                              '-' +
                              index
                            }
                          >
                            {employee.user.name}
                          </td>
                        {/*
                          <td
                            id={
                              'employee-role-' +
                              this.props.payload.managerId +
                              '-' +
                              employee.userId +
                              '-' +
                              index
                            }
                          >
                            {employee.roleName}
                          </td>
                          <td
                            id={
                              'employee-startDate-' +
                              this.props.payload.managerId +
                              '-' +
                              employee.userId +
                              '-' +
                              index
                            }
                          >
                            {this.convertDateDisplay(employee.startDate)}
                          </td>
                          <td
                            id={
                              'employee-endDate-' +
                              this.props.payload.managerId +
                              '-' +
                              employee.userId +
                              '-' +
                              index
                            }
                          >
                            {employee.endDate
                              ? this.convertDateDisplay(employee.endDate)
                              : 'N/A'}
                          </td>
                          <td>
                            <Button
                              className={styles.optionButtons}
                              size="xs"
                              disabled={this.state.expandUpdate !== ''}
                              outline
                              color="primary"
                              title="Edit this manager's role"
                              onClick={() =>
                                this.toggleEmployeeUpdate(
                                  this.props.payload.managerId.toString(),
                                  employee.userId.toString(),
                                  index.toString()
                                )
                              }
                            >
                              <img
                                className={styles.iconButton}
                                src={editIcon}
                                alt={'edit button'}
                              />
                            </Button>
                          </td>
                          <td>
                            <Button
                              className={styles.optionButtons}
                              id={
                                'employee-deleteBtn-' +
                                this.props.payload.managerId +
                                '-' +
                                employee.userId +
                                '-' +
                                index
                              }
                              size="xs"
                              disabled={this.state.expandUpdate !== ''}
                              outline
                              color="danger"
                              onClick={() => this.toggleEmpModal(employee)}
                            >
                              <img
                                className={styles.iconButton}
                                src={delIcon}
                                alt={'delete button'}
                              />
                            </Button>
                          </td>
                          */}
                        </tr>
                        {this.state.expandUpdate ===
                          this.props.payload.managerId.toString() +
                            '-' +
                            employee.userId.toString() +
                            '-' +
                            index.toString() && (
                          <tr
                            key={
                              'tr-update-' +
                              this.props.payload.managerId +
                              '-' +
                              employee.userId +
                              '-' +
                              index
                            }
                          >
                            <td colSpan="5">
                              <UpdateEmployee
                                employee={employee}
                                managerId={this.props.payload.managerId}
                                updateEmployees={this.updateEmployees}
                                openChange={(value) => {
                                  this.setState({ expandUpdate: value });
                                }}
                                closeAllOtherAccordions={
                                  this.props.closeAllOtherAccordions
                                }
                                managersReload={() => this.props.managersReload()}
                              ></UpdateEmployee>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
          )}

                    {/*
            <Row>
              <Col>
                <Label for={'employeeName' + this.props.payload.managerId}>
                  Employee E-mail
                </Label>
                <Select
                  value={this.state.selectedUser}
                  className={
                    this.state.showTransferManagerPrompt ? styles.redBorder : ''
                  }
                  onChange={this.handleChange}
                  inputId={'employeeName' + this.props.payload.managerId}
                  options={this.props.allUsers.filter((user) =>
                    this.canEmployeeBeAssigned(this.props.payload, user)
                  )}
                />
              </Col>
              <Col>
                <Label
                  className={styles.dateLabels}
                  for={'startDate' + this.props.payload.managerId}
                >
                  Start Date
                </Label>
                <Input
                  invalid={!this.isValid('startDate')}
                  type="date"
                  value={this.state.startDate}
                  name="startDate"
                  label="Employee Start Date Select"
                  id={'startDate' + this.props.payload.managerId}
                  onChange={this.handleFormChange}
                />
                <div>
                  <DigiCheckbox
                    label="Select End Date (Optional)"
                    checked={this.state.enableEndDate}
                    toggle={() => {
                      this.setState(({ enableEndDate }) => ({
                        endDate: '',
                        enableEndDate: !enableEndDate
                      }));
                    }}
                  />
                </div>
                {this.state.enableEndDate && (
                  <>
                    <Label
                      className={styles.dateLabels}
                      for={'endDate' + this.props.payload.managerId}
                    >
                      End Date
                    </Label>
                    <Input
                      invalid={!this.isValid('endDate')}
                      type="date"
                      value={this.state.endDate}
                      name="endDate"
                      id={'endDate' + this.props.payload.managerId}
                      onChange={this.handleFormChange}
                    />
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                {this.state.showTransferManagerPrompt && (
                  <div
                    className={'Toastify__toast--success ' + styles.optionButtons}
                    id={'msg' + this.props.payload.managerId}
                  >
                    Would you like to transfer user '{this.state.selectedUser.value}'
                    from Manager '{this.state.oldManagerEmail}' to Manager '
                    {this.props.payload.user.email}'?
                  </div>
                )}
              </Col>
              <Col>
                {!this.state.showTransferManagerPrompt && (
                  <Button
                    color="primary"
                    disabled={
                      !this.state.selected ||
                      !this.canEmployeeBeAssigned(
                        this.props.payload,
                        this.state.selectedUser
                      )
                    }
                    size="lg"
                    className={sharedStyle.submitBtn}
                    onClick={() => this.checkEmployeeStatus()}
                    id={'addBtn' + this.props.payload.managerId}
                  >
                    Add
                  </Button>
                )}
                {this.state.showTransferManagerPrompt && (
                  <>
                    <Button
                      color="primary"
                      size="lg"
                      className={sharedStyle.submitBtn + styles.confirmButtons}
                      id={'okBtn' + this.props.payload.managerId}
                      onClick={() => {
                        this.setState({
                          showTransferManagerPrompt: false
                        });
                        this.addToTeam(false); // value of false (or true) does not matter. It's not being used in the back-end function
                      }}
                    >
                      Ok
                    </Button>
                    <Button
                      color="primary"
                      size="lg"
                      className={sharedStyle.submitBtn + styles.confirmButtons}
                      id={'cancelBtn' + this.props.payload.managerId}
                      onClick={() =>
                        this.setState({
                          showTransferManagerPrompt: false
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            */}
          </CardBody>
        </Card>
      </Collapse>
    );
  };

  checkEmployeeStatus = () => {
    let endDateToCheck = stringDateToUtcString(this.state.endDate);
    endDateToCheck = endDateToCheck ? endDateToCheck : '';
    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/ManagerEmployee/checkemployee?userId=${
          this.state.selectedUser.userId
        }&managerId=${
          this.props.payload.managerId
        }&startDate=${stringDateToUtcString(
          this.state.startDate
        )}&endDate=${endDateToCheck}`,
      { method: 'get', signal: this.abortController.signal }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((myJson) => {
        if (!myJson.success) {
          this.addToTeam(false);
        } else {
          // Transfer question
          this.setState({
            showTransferManagerPrompt: true,
            oldManagerId: myJson.data.managerId,
            oldManagerEmail: myJson.data.user.email
          });
        }
      })
      .catch((e) => {
        updateToast(null, TECHNICAL_ERROR_MESSAGE);
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  addToTeam = (isTransfer) => {
    if (this.state.submitting) {
      error('Already submitting');
      return;
    }

    if (this.state.startDate === '') {
      error('Please provide a valid Start Date value.');
      return;
    }

    if (this.state.enableEndDate && this.state.endDate === '') {
      error('Please provide a valid End Date value.');
      return;
    }

    const data = this.parseForm(this.props.payload.managerId);
    if (data === null) return null;
    notify('Adding Member to team.');
    this.setState({ submitting: true, employees: null });

    adalApiFetch(
      fetch,
      process.env.REACT_APP_API_BASE_DOMAIN +
        `/api/ManagerEmployee?isTransfer=${isTransfer}&existMan=${this.state.oldManagerId}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        this.setState({ submitting: false });
        return response.json();
      })
      .then((myJson) => {
        updateToast(
          myJson.success,
          myJson.error,
          `Employee "${this.state.selectedUser.value}" added to manager "${this.props.payload.user.email}" successfully!`,
          5000
        );
        this.props.closeAllOtherAccordions();
        forceCache()
          .then((response) => {
            if (!response.ok) {
              throw new Error(response);
            }
            if (myJson.success) {
              this.setState({
                selectedUser: {},
                endDate: '',
                startDate: this.defaultDateDisplay(),
                selected: !this.state.selected
              });
              this.props.managersReload();
            }
            this.props.toggleAccordion();
          })
          .catch((e) => {
            // No message in UI just because the cache could not be cleared
            this.updateEmployees();
            ReactGA.exception({
              description: e.toString()
            });
          });
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
        updateToast(null, TECHNICAL_ERROR_MESSAGE);
      });
  };

  parseForm = (managerId = null) => {
    if (Object.entries(this.state.selectedUser).length === 0) {
      toast('Please select a name.', {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return null;
    }

    let data = {
      userId: this.state.selectedUser.userId,
      startDate: stringDateToUtcString(this.state.startDate),
      endDate: stringDateToUtcString(this.state.endDate)
    };

    if (managerId !== null) data.managerId = managerId;

    return data;
  };

  validateRoles = () => {
    if (this.props.reportingManager != null) {
      const reportingManagerRoleRanking = this.props.reportingManager.role.ranking;
      const validRoles = this.props.allRoles
        .filter(
          (role) =>
            role.ranking < reportingManagerRoleRanking &&
            role.ranking > this.props.payload.maxStaffRank
        )
        .map((role) => ({ label: role.name, value: role.roleId }));
      return validRoles.sort(dynamicSort(['label']));
    } else {
      const hasCio = !!this.props.allManagers.filter(
        (m) => m.role.slug === 'cio' && !m.deleted
      ).length;
      const validRoles = this.props.allRoles
        .filter(
          (role) =>
            (hasCio ? role.slug !== 'cio' : true) &&
            role.ranking > this.props.payload.maxStaffRank
        )
        .map((role) => ({
          label: role.name,
          value: role.roleId
        }));
      return validRoles.sort(dynamicSort(['label']));
    }
  };

  canEmployeeBeAssigned = (parent, child) => {
    const childManager = this.props.allManagers.find(
      (m) => m.userId === child.userId
    );
    return (
      child.value !== parent.user.email &&
      (!childManager || parent.role.ranking > childManager.role.ranking)
    );
  };

  render() {
    return this.props.visible ? (
      <Card>
        <CardHeader>
          <h5 className={styles.cardTitle}>
            {this.props.payload.user.name}
            {''}
            <br />
            {this.state.editMode ? (
              <Container className={styles.editRoleMenu} fluid={true}>
                <Row
                  className="no-gutters"
                  style={{
                    display: 'flex'
                  }}
                >
                  <Label
                    for={'employeeRole' + this.props.payload.managerId}
                    className={'sr-only'}
                  >
                    Select Manager Role
                  </Label>
                  <Select
                    defaultValue={this.state.selectedRole}
                    className={styles.editRoleSelect}
                    onChange={this.handleRoleChange}
                    inputId={'employeeRole' + this.props.payload.managerId}
                    options={this.validateRoles()}
                  />

                  <Button
                    className={styles.editRoleButton}
                    id={'cancelRoleBtn' + this.props.payload.managerId}
                    disabled={
                      this.state.selectedRole.label === this.props.payload.role.name
                    }
                    color="primary"
                    size="sm"
                    onClick={() => this.updateManagerRole()}
                  >
                    Save
                  </Button>

                  <Button
                    className={styles.editRoleButton}
                    id={'saveRoleBtn-' + this.props.payload.managerId}
                    color="danger"
                    size="sm"
                    onClick={() => this.toggleManagerRoleEdit()}
                  >
                    Cancel
                  </Button>
                </Row>
              </Container>
            ) : (
              <small className={styles.cardSubHead}>
                <i>{this.props.payload.role.name}</i>

                <AccessibleButton
                  size="xs"
                  disabled={this.state.expandUpdate !== ''}
                  onClick={() => this.toggleManagerRoleEdit()}
                >
                  <img
                    className={styles.iconButton}
                    src={editIcon}
                    alt={'edit button'}
                  />
                </AccessibleButton>
              </small>
            )}
          </h5>
          {this.props.reportingManager ? (
            <div aria-label={'Reports to ' + this.props.reportingManager.user.name}>
              <h5 aria-hidden="true" className={styles.cardSubHead}>
                &nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon
                  icon={faAngleRight}
                  title="This manager directly reports to someone"
                />{' '}
                {this.props.reportingManager.user.name}
                <br />
                <small>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <i>{this.props.reportingManager.role.name}</i>
                </small>
              </h5>
            </div>
          ) : (
            ''
          )}
          <Button
            className={styles.optionButtons}
            size="sm"
            outline
            color="danger"
            onClick={this.toggleDeleteModal}
          >
            <img className={styles.iconButton} src={delIcon} alt={'delete button'} />
          </Button>
          {/*DISPLAY MANAGER EMPLOYEES*/}
          <Button
            className={styles.displayEmployeesButton}
            id={'show-hide-button-id-' + this.props.payload.managerId}
            color="secondary"
            size="sm"
            outline
            onClick={this.props.toggleOpenAccordion}
          >
            {this.props.accordionIsOpen ? 'Hide Staff' : 'Show Staff'}
          </Button>
          <br />
          <br />
          <br />
          {this.displaysEmployees()}
        </CardHeader>

        {/*DELETE EMPLOYEE MODAL*/}
        <Modal
          isOpen={this.state.empModal}
          toggle={this.toggleEmpModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleEmpModal}>
            Confirm Employee Deletion
          </ModalHeader>
          <ModalBody>
            Are you sure you want to remove employee from this team? This action
            cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              id={'delete-modal-manager-employee-confirm'}
              onClick={() => this.deleteTeamMember(this.state.empDelete)}
            >
              DELETE
            </Button>{' '}
            <Button color="secondary" onClick={this.toggleEmpModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {/*DELETE MODAL*/}
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleDeleteModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>Confirm Deletion</ModalHeader>
          {(() => {
            const hasNoEmployees =
              !!this.state.employees && this.state.employees.length === 0;
            return (
              <>
                <ModalBody>
                  {hasNoEmployees
                    ? `Are you sure you want to delete Manager ${this.props.payload.user.email}? This action cannot be undone.`
                    : `This manager cannot be deleted while managing other staff.`}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    onClick={this.deleteSelf}
                    disabled={!hasNoEmployees}
                  >
                    DELETE
                  </Button>{' '}
                  <Button color="secondary" onClick={this.toggleDeleteModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </>
            );
          })()}
        </Modal>
      </Card>
    ) : null;
  }
}

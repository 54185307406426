import React from 'react';
import ReactGA from 'react-ga';
import { Form, Label } from 'reactstrap';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { forceCache } from '../../../../CONSTANTS';
import styles from './UploadStaff.module.css';
import { toast } from 'react-toastify';

import * as XLSX from 'xlsx';

export default class UploadStaff extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();
  //  this.parseForm = this.parseForm.bind(this);

    this.state = {
      submitting: false,
      options: [],
      selectedUser: {},
      activeTab: '2',
        selectedFile: null,
    };
    this.originalState = {
      submitting: false,
      selectedUser: {},
        activeTab: '2',
        selectedFile : null
      };
  }
  componentDidMount() {
    if (this.props.payload != null) this.parsePayload();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

onChangeHandler = event => {
        var _extension = event.target.files[0].name.lastIndexOf('.xlsx');
        var _type = event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (_extension === -1 || !_type) {
            toast('Please select valid excel file with ".xlsx" file extension ', {
                type: toast.TYPE.ERROR,
                autoClose: true
            });
            return null;
        }
//        console.log(event.target.files[0].name.lastIndexOf('.xlsx'))
//        console.log(_type)
        this.setState({
            selectedFile: event.target.files[0]
        });
 //       console.log(event.target.files[0])
};

  readUploadFile = (e) => {
      e.preventDefault();
      //const data = this.parseForm(e.target.files);
      // if (data === null) return null;

      var file = e.target.files;
     /* alert(file[0]);
      if (file[0].type !== 'xlsx') {
          toast('Only excel files are allowed. ', {
              type: toast.TYPE.ERROR,
              autoClose: true
          });
          return null;
      }
      */
      if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
            //    console.log(json);
   
                if (json.length === 0) {
                    toast('Please select file with valid employee and manager email addresses under column with headings "Employee" and "Manager" ', {
                        type: toast.TYPE.ERROR,
                        autoClose: true
                    });
                    return null;
                }

      toastRequest.notify('Upload Staff');
      this.setState({ submitting: true });
      adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/ManagerEmployee/RefreshEmployee', {
          method: 'post',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(json),
          signal: this.abortController.signal
      })
          .then((response) => {
              if (response.ok) forceCache();
              this.setState({ submitting: false });
              return response.json();
          })
          .then((myJson) => {
              toastRequest.updateToast(
                  myJson.success,
                  myJson.error,
                  `File has been uploaded`
              );
              if (myJson.success === true) {
                  this.setState(this.originalState);
                  this.props.manualReload();
              }
          })
          .catch((e) => {
              toastRequest.dismissToast();
              ReactGA.exception({
                  description: e.toString()
              });
          });
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }

    }

    parseForm(file) {

        if (file.length === 0) {
            toast('Please select file with valid employee and manager email addresses.', {
                type: toast.TYPE.ERROR,
                autoClose: true
            });
            return null;

        }
     
  }


  parsePayload() {
    for (const [key, value] of Object.entries(this.props.payload)) {
      if (value != null) {
        this.setState({ [key]: value });
      }
    }
    }

    add() {
        var file = this.state.selectedFile;
    //    console.log(file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
        //        console.log(json);

                if (json.length === 0) {
                    toast('Please select file with valid employee and manager email addresses under column with headings "Employee" and "Manager" ', {
                        type: toast.TYPE.ERROR,
                        autoClose: true
                    });
                    return null;
                }

                toastRequest.notify('Upload Staff');
                this.setState({ submitting: true });
                adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/ManagerEmployee/RefreshEmployee', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(json),
                    signal: this.abortController.signal
                })
                    .then((response) => {
                        if (response.ok) forceCache();
                        this.setState({ submitting: false });
                        return response.json();
                    })
                    .then((myJson) => {
                        toastRequest.updateToast(
                            myJson.success,
                            myJson.error,
                            `File has been uploaded`
                        );
                        if (myJson.success === true) {
                            this.setState(this.originalState);
                            this.props.manualReload();
                        }
                    })
                    .catch((e) => {
                        toastRequest.dismissToast();
                        ReactGA.exception({
                            description: e.toString()
                        });
                    });
            };
            reader.readAsArrayBuffer(file);
        }

    }

  render() {
    if (
      this.props.activeTab !== this.state.activeTab &&
      Object.keys(this.state.selectedUser).length > 0
    ) {
      this.setState({activeTab: this.props.activeTab });
    }
    return (
        <div>
            <Form onSubmit={(event) => event.preventDefault()}>
                <Label>Please select an Excel file that contains valid email addresses for employees and managers.
                    Make sure the file has a column with headings "Employee" and "Manager".</Label>
                <div className={styles.input_container}>
                    <input
                        type="file"
                        accept=".xlsx"
                        name="upload"
                        id="upload"
                        onChange={this.onChangeHandler}
                        className={styles.file_label}
                    />
                </div>
        </Form>
      </div>
    );
  }
}

import React from 'react';
import ReactGA from 'react-ga';
import style from './AdminCourseTab.module.css';
import sharedStyle from '../AdminAllTabs.module.css';
import { AdminTabs } from '../../../../enums/admintabs.enum';
import {
  Button,
  Col,
  Container,
  Card,
  CardHeader,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import classnames from 'classnames';
import Course from './Course';
import AdminHomeTabs from '../AdminHomeTabs';
import { adalApiFetch } from '../../../../adalConfig';
import ErrorModal from './ErrorModal';
import * as toastRequest from '../../utils/toastRequests';
import { toast } from 'react-toastify';
import MandatoryCircle from '../../../../components/mandatoryCircle/mandatoryCircle';
import delIcon from '../../images/garbage.svg';
import editIcon from '../../images/pencil.svg';
import AddRepeatingSession from './AddRepeatingSession';
import ResourceManagement from './ResourceManagement';
import AccessibleButton from '../../../../components/accessibleButton/AccessibleButton';
import CourseAddModify from './CourseAddModify';
import ResourceButton from './ResourceButton';
import EditRecurringSession from './EditRecurringSession';
import ManagerOnlyTag from '../../../../components/ManagerOnlyTag/ManagerOnlyTag';
import styles from './Course.module.css';
import PageButton from '../../../../components/pageButton/PageButton';
import { Spinner } from 'reactstrap';
import OnDemandOutstanding from './OnDemandOutstanding';
const PAGE_LIMIT = 10;
export default class AdminCourseTabs extends React.Component {
  formRef = React.createRef();
  state = {
    activeTab: '0',
    allCourses: [],
    searchedCourses: [],
    domains: [],
    mandatory: false,
    managerOnly: false,
    errorModal: false,
    errorMsg: '',
    searchText: '',
    recurringSessionEditModal: false,
    modalSession: null,
    resourceModal: false,
    filter: 'all',
    lastPage: false,
    searchTimeout: null,
    loading: true
  };
  abortController = new AbortController();
  activePage = 0;

  componentDidMount() {
    this.loadContent(true);
  }

  componentWillUnmount() {
    this.abortController.abort();
    toast.dismiss(this.toastId);
  }

  changeHandler = (e) => {
      const searchString = e.target.value.toLowerCase();
    if (this.state.searchTimeout) clearTimeout(this.state.searchTimeout);
    this.activePage = 0;
    this.setState({
      searchText: searchString,
      searchTimeout: setTimeout(this.loadContent, 300)
    });
  };

  loadContent = (doToast = false) => {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = new AbortController();
    }
    this.setState({ loading: true });
    var corsereq =
      '/getNCourses/' + (PAGE_LIMIT + 1) + '/' + PAGE_LIMIT * this.activePage;
    switch (this.state.filter) {
      case 'upcoming': {
        corsereq += '/1';
        break;
      }
      case 'past': {
        corsereq += '/2';
        break;
      }
      case 'on_demand': {
        corsereq += '/3';
        break;
      }
      default: {
        corsereq += '/0';
        break;
      }
    }
      if (this.state.searchText !== '') corsereq += '/' + encodeURIComponent(this.state.searchText);//this.state.searchText;
    let toastId = null;
    if (doToast) {
      toastId = toastRequest.notify('Loading session data');
    }
    adalApiFetch(
      fetch,
        process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course' + corsereq,
      {
        method: 'get',
        signal: this.abortController.signal
      }
    )
      .then((response) => {
        if (!response.ok && doToast) {
          toast.update(toastId, {
            render:
              'An error occurred when attempting to load sessions. Please try again later.',
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          return response.json();
        }
      })
      .then((myJson) => {
        if (myJson.success === false) {
          toast.update(toastId, {
            render: 'Error loading sessions',
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          if (myJson.data.length > 0 || this.state.searchText !== '')
                this.setState({ allCourses: myJson.data, searchedCourses: myJson.data });
          this.setState({
            lastPage: myJson.data.length < PAGE_LIMIT + 1,
            loading: false
          });
          toast.update(toastId, {
            render: 'Sessions loaded successfully',
            type: toast.TYPE.SUCCESS,
            autoClose: 2000
          });
        }
      })
      .catch((e) => {
        ReactGA.exception({
          description: e.toString()
        });
      });
  };

  tgl = () => {
    this.setState((prevState) => ({
      errorModal: !prevState.errorModal
    }));
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  onModify = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  deleteRecurringSession = (sessionId) => {
    if (
      window.confirm(
        'Are you sure you want to delete this recurring session? This will delete all child sessions.'
      )
    ) {
      toastRequest.notify('Deleting recurring session ' + sessionId);
      adalApiFetch(
        fetch,
        process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course/' + sessionId,
        {
          method: 'DELETE',
          signal: this.abortController.signal
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          toastRequest.updateToast(myJson.success);
          this.loadContent();
        });
    }
  };

  toggleRecurringEditModal = (session) => {
    this.setState({
      recurringCourse: session,
      recurringEditOpen: !this.state.recurringEditOpen
    });
  };

  toggleResourceModal = (sessionId = null) => {
    let session = this.state.allCourses.find((obj) => obj.courseId === sessionId);
    if (typeof session != 'undefined') {
      this.setState({
        recurringCourseId: sessionId,
        recurringMaterials: session.materials,
        resourceModal: !this.state.resourceModal,
        modalSession: sessionId
      });
    } else {
      this.setState({
        resourceModal: !this.state.resourceModal,
        modalSession: sessionId
      });
    }
  };

  submitRecurringUpdate = () => {
    let session = this.state.allCourses.find(
      (obj) => obj.courseId === this.state.modalSession
    );
    toastRequest.notify('Updating recurring session');
    let data = {
      ...session,
      courseId: this.state.recurringCourseId,
      sessionTypeId: this.state.recurringSessionTypeId,
      domainId: this.state.recurringDomainId,
      mandatory: this.state.mandatory,
      managerOnly: this.state.managerOnly
    };
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Course', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: this.abortController.signal
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        toastRequest.updateToast(myJson.success, myJson.error);
        this.toggleRecurringEditModal();
        this.loadContent();
      });
  };

  sessionRecurrence = (session) => {
    if (session.courseDetails.length <= 0) return null;
    if (session.courseDetails.length === 1) {
      return (
        <Course
          manualReload={this.loadContent}
          courseDetails={session.courseDetails[0]}
          parentCourse={session}
          key={session.courseDetails[0].courseDetailsId}
          domain={session.domain}
          sessionType={session.sessionType}
          showMaterialsButton={true}
          onMaterialsButtonClick={() => this.toggleResourceModal(session.courseId)}
        />
      );
    }
    return (
      <Card
        key={session.courseId + session.courseGuid}
        data-id={'recurring-session:-' + session.courseId}
      >
        <CardHeader
          onClick={() =>
            this.state.openCollapse === session.courseId
              ? this.setState({ openCollapse: null })
              : this.setState({ openCollapse: session.courseId })
          }
          style={{ cursor: 'pointer', backgroundColor: '#DDDDDD' }}
        >
          {/*DELETE BUTTON*/}
          <Button
            className={`${styles.courseInlineButton}`}
            id={'delete-button'}
            size="sm"
            outline
            color="danger"
            onClick={() => this.deleteRecurringSession(session.courseId)}
          >
            <img
              className={`${styles.courseInlineImage}`}
              src={delIcon}
              alt={'delete button'}
              width="18px"
            />
          </Button>
          {/*EDIT BUTTON*/}
          <Button
            className={`${styles.courseInlineButton}`}
            size="sm"
            outline
            color="white"
            onClick={(e) => {
              this.toggleRecurringEditModal(session);
              e.stopPropagation();
            }}
          >
            <img
              className={`${styles.courseInlineImage}`}
              src={editIcon}
              alt={'edit button'}
            />
          </Button>
          {/*RESOURCES BUTTON*/}
          <ResourceButton
            parentSession={session}
            onClick={() => this.toggleResourceModal(session.courseId)}
          ></ResourceButton>
          <h6 className={'card-title'} id={'session-title'} style={{ float: 'top' }}>
            {session.courseDetails[0].courseName}
            {' Recurring Session: ID ' + session.courseId}
          </h6>
          {session.managerOnly && <ManagerOnlyTag />}
          {session.mandatory && (
            <div className={style.mandatoryCircle}>
              <MandatoryCircle />
            </div>
          )}
        </CardHeader>
        <Collapse isOpen={this.state.openCollapse === session.courseId}>
          <CardBody>
            {session.courseDetails.map((course) => {
              return (
                <Course
                  manualReload={this.loadContent}
                  parentCourse={session}
                  courseDetails={course}
                  key={course.courseDetailsId}
                  domain={session.domain}
                  sessionType={session.sessionType}
                />
              );
            })}
          </CardBody>
        </Collapse>
      </Card>
    );
  };

  resourceModal = () => {
    let session = this.state.allCourses.find(
      (obj) => obj.courseId === this.state.modalSession
    );

    return (
      <Modal
        size={'lg'}
        isOpen={this.state.resourceModal}
        toggle={this.toggleResourceModal}
      >
        <ModalHeader toggle={this.toggleResourceModal}>
          Manage Session Materials
        </ModalHeader>
        <ModalBody>
          <ResourceManagement payload={session} manualReload={this.loadContent} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggleResourceModal}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    return (
      <div className={sharedStyle.adminRoot}>
        <AdminHomeTabs
          activetab={AdminTabs.Sessions}
          switchtab={this.props.history.push}
        />
        <h1 className="show-for-sr">Admin - Sessions</h1>
        <Container>
          <Row>
            <Col>
              <Nav tabs className={sharedStyle.tabMargin}>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '0' })}
                    id="viewTab"
                    onClick={() => {
                      this.toggle('0');
                    }}
                  >
                    VIEW
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                    id="addSessions"
                  >
                    ADD
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    id="addRepeatingSessions"
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    ADD REPEATING SESSIONS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    id={'addOnDemandSessions'}
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    ADD ON DEMAND SESSIONS
                  </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === '4' })}
                        id={'viewMandatoryOnDemandOutStandingCourses'}
                        onClick={() => {
                            this.toggle('4');
                        }}
                    >
                     VIEW MAND. ON-DEMAND OUTSTANDING COURSES
                    </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                activeTab={this.state.activeTab}
                className={style.scrollable}
              >
                {/*MAIN COURSE TAB*/}
                <TabPane tabId="0">
                  {this.state.activeTab === '0' && (
                    <Row>
                      <Col sm="12">
                        <div className={style.stable}>
                          <InputGroup>
                            <Label for="courseSearchBar" className="show-for-sr">
                              {' '}
                              course search bar
                            </Label>
                            <Input
                              placeholder="search"
                              id={'courseSearchBar'}
                              onChange={this.changeHandler}
                              value={this.state.searchText}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>Search</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <div className={style.row}>
                            <div className={style.resultsColumn}>
                              <div className={sharedStyle.subText}>
                                {PAGE_LIMIT} results per page
                              </div>
                            </div>
                            <div className={style.column}>
                              <PageButton
                                className={'previous'}
                                label={'previous'}
                                disabled={this.activePage === 0}
                                onClick={() => {
                                  if (this.activePage > 0) this.activePage--;
                                  this.loadContent();
                                }}
                                id={'previous'}
                              ></PageButton>
                            </div>
                            <div className={style.column}>
                              <PageButton
                                className={'next'}
                                label={'next'}
                                disabled={this.state.lastPage}
                                onClick={() => {
                                  this.activePage++;
                                  this.loadContent();
                                }}
                                id={'next'}
                              ></PageButton>
                            </div>
                            <div className={style.spinner}>
                              {this.state.loading && <Spinner />}
                            </div>
                          </div>
                          <div className={style.filters}>
                            <AccessibleButton
                              className={
                                this.state.filter === 'all'
                                  ? style.filterActive
                                  : null
                              }
                              onClick={() => {
                                this.activePage = 0;
                                this.setState({ filter: 'all' }, this.loadContent);
                              }}
                              id={'allButton'}
                            >
                              All
                            </AccessibleButton>
                            <AccessibleButton
                              className={
                                this.state.filter === 'upcoming'
                                  ? style.filterActive
                                  : null
                              }
                              onClick={() => {
                                this.activePage = 0;
                                this.setState(
                                  { filter: 'upcoming' },
                                  this.loadContent
                                );
                              }}
                              id={'upcomingButton'}
                            >
                              Upcoming
                            </AccessibleButton>
                            <AccessibleButton
                              className={
                                this.state.filter === 'past'
                                  ? style.filterActive
                                  : null
                              }
                              onClick={() => {
                                this.activePage = 0;
                                this.setState({ filter: 'past' }, this.loadContent);
                              }}
                              id={'pastButton'}
                            >
                              Past
                            </AccessibleButton>
                            <AccessibleButton
                              className={
                                this.state.filter === 'on_demand'
                                  ? style.filterActive
                                  : null
                              }
                              onClick={() => {
                                this.activePage = 0;
                                this.setState(
                                  { filter: 'on_demand' },
                                  this.loadContent
                                );
                              }}
                              id={'on_demandButton'}
                            >
                              On Demand
                            </AccessibleButton>
                          </div>
                        </div>

                        {/*RENDER COURSES*/}
                        {this.state.searchedCourses.map((courseR) =>
                          this.sessionRecurrence(courseR)
                        )}
                        {!this.state.loading &&
                          this.state.searchedCourses &&
                          this.state.searchedCourses.length === 0 && (
                            <div className={sharedStyle.subText}>
                              No results found
                            </div>
                          )}
                      </Col>
                    </Row>
                  )}
                </TabPane>
                {/*ADD TAB*/}
                <TabPane tabId="1">
                  {this.state.activeTab === '1' && (
                    <Row>
                      <Col sm="12">
                        {/*TAB 2 CONTENT*/}
                        <CourseAddModify
                          ref={this.formRef}
                          manualReload={this.loadContent}
                        />
                        <Button
                          color="primary"
                          size="lg"
                          id="add-submit-button"
                          className={sharedStyle.submitBtn}
                          onClick={() => this.formRef.current.add()}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {this.state.activeTab === '2' && (
                    <Row>
                      <Col sm="12">
                        {/*TAB 3 CONTENT*/}
                        <AddRepeatingSession
                          manualReload={this.loadContent}
                          allCourses={this.state.allCourses}
                        />
                      </Col>
                    </Row>
                  )}
                </TabPane>
                <TabPane tabId="3">
                  {this.state.activeTab === '3' && (
                    <Row>
                      <Col sm="12">
                        {/*TAB 2 CONTENT*/}
                        <CourseAddModify
                          ref={this.formRef}
                          manualReload={this.loadContent}
                          onDemand={true}
                        />
                        <Button
                          color="primary"
                          size="lg"
                          className={sharedStyle.submitBtn}
                          id={'submit-button'}
                          onClick={() => this.formRef.current.add()}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  )}
                  </TabPane>
                            <TabPane tabId="4">
                                {this.state.activeTab === '4' && (
                                    <Row>
                                        <Col sm="12">
                                            {/*TAB 4 CONTENT*/}
                                            <OnDemandOutstanding
                                                ref={this.formRef}
                                                manualReload={this.loadContent}
                                                onDemand={true}
                                            />
                                            
                                        </Col>
                                    </Row>
                                )}
                            </TabPane>
              </TabContent>
            </Col>
          </Row>

          <ErrorModal open={this.state.errorModal} toggle={this.tgl}>
            {this.state.errorMsg}
          </ErrorModal>
        </Container>

        {/*  MODALS*/}
        {/*MODIFY MODAL*/}
        <EditRecurringSession
          open={this.state.recurringEditOpen}
          openChange={(value = this.state.recurringEditOpen) => {
            this.setState({ recurringEditOpen: value });
          }}
          parentCourse={this.state.recurringCourse}
          manualReload={this.loadContent}
        />
        {/*RESOURCE MODAL*/}
        {this.resourceModal()}
      </div>
    );
  }
}

import React from 'react';
import style from './TimeFrame.module.css';
import { Input, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import AccessibleButton from '../accessibleButton/AccessibleButton';
import reloadButton from '../../assets/Reload_button_state.png';
import { joinClasses } from '../../utils/styles';

export default class TimeFrame extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();

    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      enableRefresh: false
    };
  }

  isValid = (name) => {
    // catch incomplete/invalid date inputs
    return (
      new Date(this.state[name]) !== 'Invalid Date' &&
      !isNaN(new Date(this.state[name]))
    );
  };

  onDateChange = (date) => {
    //update date displayed
    this.setState({ [date.target.name]: date.target.value });

    // enable refresh button if start or end date has been changed
    if (date.target.value !== this.props[date.target.name]) {
      this.setState({ enableRefresh: true });
      if (this.props.theme === 'dashboard') this.props.refreshed(true); // Set My Dashboard's refreshed value to enableRefresh
    } else {
      this.setState({ enableRefresh: false });
      if (this.props.theme === 'dashboard') this.props.refreshed(false); // Set My Dashboard's refreshed value to enableRefresh
    }
  };

  submitForm = () => {
    // validate timeframe input and show error for invalid date input
    if (!this.isValid('startDate') || !this.isValid('endDate')) {
      toast('Error: The date you provided is invalid. Please provide a valid date', {
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return;
    } else if (!this.isDateRangeValid()) {
      toast(
        'Error: FROM Date cannot be in the future of TO Date. Please select a new timeframe.',
        {
          type: toast.TYPE.ERROR,
          autoClose: true
        }
      );
      return;
    }

    this.setState({ enableRefresh: false });
    if (this.props.theme === 'dashboard') this.props.refreshed(false); //Set My Dashboard's refreshed value to enableRefresh

    // Make API call to load stats after start/end dates changed
    this.props.updateTimeframe(this.state.startDate, this.state.endDate);
  };

  isDateRangeValid = () => {
    // ensure start date is before end date
    return Date.parse(this.state.startDate) <= Date.parse(this.state.endDate);
  };

  render() {
    return (
      // NOTE: this.props.theme down below is an indicator of whether or not we're on the 'My Dashboard Page'
      <div
        className={`${style.container} ${style[this.props.theme]} ${
          style[this.props.faded]
        }`}
      >
        <div
          className={style.timeframe}
          aria-label={`The current selected timeframe is from ${this.props.startDate} to ${this.props.endDate}`}
        >
          TIMEFRAME
        </div>
        <div className={`${style.input} ${style.startDate}`}>
          <Label for="startDate" className={`${style.label}`}>
            From
          </Label>
          <Input
            disabled={this.props.seeAll} // inputs disabled if My Dashboard's See All toggle is ON
            id={'startDate'}
            name="startDate"
            value={this.state.startDate}
            invalid={!this.isValid('startDate')}
            type="date"
            onChange={this.onDateChange}
          />
        </div>
        <div className={style.input}>
          <Label for="endDate" className={style.label}>
            To
          </Label>
          <Input
            disabled={this.props.seeAll} // inputs disabled if My Dashboard's See All toggle is ON
            id={'endDate'}
            name="endDate"
            value={this.state.endDate}
            invalid={!this.isValid('endDate')}
            type="date"
            onChange={this.onDateChange}
          />
        </div>
        <div
          className={
            this.props.theme === 'dashboard'
              ? joinClasses(
                  style.refreshButtonHolder, // border
                  this.state.enableRefresh &&
                    !this.props.loading &&
                    !this.props.seeAll &&
                    style.enabled
                )
              : ''
          }
        >
          <AccessibleButton
            id="refreshButton"
            className={`
            ${
              this.state.enableRefresh && !this.props.loading && !this.props.seeAll
                ? style.refreshButton
                : style.disabledrefreshButton
            }`}
            disabled={
              !this.state.enableRefresh || this.props.loading || this.props.seeAll
            } // inputs disabled if My Dashboard's See All toggle is ON
            onClick={this.submitForm}
            aria-label="Click the Refresh function to refresh the page with the new timeframe"
          >
            <img src={reloadButton} alt="Refresh button" />
          </AccessibleButton>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import AccessibleButton from '../accessibleButton/AccessibleButton';

export default class SurveyButton extends Component {
  surveyClick() {
    window.open(
      '/pastsessions/' +
        this.props.session.courseDetailsId +
        '/' +
        this.props.session.courseId +
        '/feedback'
    );
  }

  render() {
    return (
      <AccessibleButton
        id={`survey-button-${this.props.session.courseId}`}
        className="dw-button"
        onClick={() => this.surveyClick()}
      >
        Survey
      </AccessibleButton>
    );
  }
}

import React, { Component } from 'react';

import { GetBuddyBadge } from '../badges/BadgesAPI.jsx';
import { hasBadge } from '../badges/BadgesFunctions.jsx';
import { Badges } from '../../enums/badges.enum.js';
import GlobalContext from '../../context/GlobalContext.js';
import { stringToDate } from '../../CONSTANTS.js';
import { SLType } from '../../pages/sessions/components/SessionUtils.js';
import DigiButton from '../digiButton/DigiButton.jsx';

export default class ShareButton extends Component {
  static defaultProps = {
    type: SLType.UPCOMING,
    buttonComponent: DigiButton
  };

  isPastSession = () => {
    return this.props.type === SLType.PAST;
  };

  courseStart = () => {
    if (this.props.type === SLType.ON_DEMAND)
      return this.formatDateOnDemand(this.props.session.courseStart);
    else return this.formatDate(this.props.session.courseStart);
  };

  formatDate(courseStart) {
    let formattedDate = stringToDate(courseStart);
    return String(
      formattedDate.toLocaleDateString('en-US', {
        month: 'long',
        weekday: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    );
  }

  formatDateOnDemand(courseStart) {
    let formattedDate = stringToDate(courseStart);
    return String(
      formattedDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        weekday: 'long',
        year: 'numeric'
      })
    );
  }

  buddyBadgeHandler = () => {
    if (!hasBadge(this.context.badges, Badges.Buddy)) {
      GetBuddyBadge().then((myJson) => {
        if (myJson.success) {
          this.props.addBadgeToList(myJson.data);
        }
      });
    }
  };

  mailToData() {
    let body = ``;
    if (!this.isPastSession()) {
      body = `Hello,
      %0D%0A 
      %0D%0A 
      ${
        this.context.user.firstName
      } thought you would be interested in attending the following ${
        this.props.type === SLType.ON_DEMAND ? 'On Demand' : ''
      } session:
      %0D%0A 
      %0D%0A 
      Name: ${this.props.session.courseName} ${
        this.props.session.managerOnly ? '(MANAGER)' : ''
      }
      %0D%0A 
      ${
        this.props.type === SLType.ON_DEMAND ? 'Posted Date' : 'Date'
      }: ${this.courseStart()}
      %0D%0A 
      %0D%0A 
      ${process.env.REACT_APP_UI_BASE_DOMAIN +
        (this.props.type === SLType.ON_DEMAND ? '/ondemand/' : '/sessions/') +
        this.props.session.courseDetailsId}
      %0D%0A 
      %0D%0A 
      To see all the ways that you can earn points towards building your bridge, check out the full L+D calendar in Digital World.
      %0D%0A 
      %0D%0A 
      Happy learning,
      %0D%0A 
      Your L+D Team`;
    } else {
      body = `Hello,
      %0D%0A 
      %0D%0A 
      ${
        this.context.user.firstName
      } thought you would be interested in the material from the following L+D session:
      %0D%0A 
      %0D%0A 
      Name: ${this.props.session.courseName}
      %0D%0A 
      Date: ${this.courseStart()}
      %0D%0A 
      %0D%0A 
      ${process.env.REACT_APP_UI_BASE_DOMAIN +
        '/pastsessions/' +
        this.props.session.courseDetailsId}
      %0D%0A 
      %0D%0A 
      To see all the ways that you can earn points towards building your bridge, check out the full L+D calendar in Digital World.
      %0D%0A 
      %0D%0A 
      Happy learning,
      %0D%0A 
      Your L+D Team`;
    }

    let subject = `${this.context.user.firstName} ${this.context.user.lastName} has shared a L+D Session "${this.props.session.courseName}" with you`;

    return `mailto: ?subject=${subject}&body=${body}`;
  }

  shareButtonHandler = () => {
    this.buddyBadgeHandler();
    window.location = this.mailToData();
  };

  render() {
    const ButtonComponent = this.props.buttonComponent;
    return (
      <ButtonComponent
        className={this.props.className}
        data-id={`share-button-session-list-${this.props.session.courseDetailsId}`}
        onClick={() => this.shareButtonHandler()}
      >
        Share
      </ButtonComponent>
    );
  }
}

ShareButton.contextType = GlobalContext;

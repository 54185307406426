import React, { useState, useEffect } from 'react';
import { adalApiFetch } from '../../../adalConfig';
import { Button, Container, Row, Input, FormGroup, Label } from 'reactstrap';
import querystring from 'querystring';
import { GetAllActiveAchievements } from '../../../components/badges/BadgesAPI';
import DetailedBadge from '../../../components/detailedBadge/DetailedBadge';
import { getInnovatorPageIndex } from '../../../CONSTANTS';

function transformBadge(badge) {
  const rightbadge = {
    name: badge.name.toUpperCase().replace(' BADGE', ''),
    desc: badge.description,
    image: badge.imageLink,
    slug: badge.slug,
    achievementId: badge.achievementId
  };
  return rightbadge;
}

/**
 * This is an extremely simple component used to clear the application cache.
 * This is a utility that should be used by administrators only.
 */
const SuperAdmin = () => {
  const [log, logChange] = useState('Nice!');
  const [badges, setBadges] = useState([]);
  const [userId, setUserId] = useState('');
  const [resetTutorialStatus, resetTutorialStatusChange] = useState(true);
  const writeLog = (str) => {
    logChange((prevLog) => prevLog + '\nroot@totallyARealShell:~$ ' + str);
  };

  const doGet = (path, params) => {
    let url = process.env.REACT_APP_API_BASE_DOMAIN + path;
    if (params) {
      url = url + '?' + querystring.stringify(params);
    }

    writeLog('Fetch: ' + url);
    adalApiFetch(fetch, url)
      .then((response) => {
        writeLog('Request completed!');
        return response.json();
      })
      .then((json) => {
        writeLog(`Done! JSON:${JSON.stringify(json)})`);
      })
      .catch((e) => {
        try {
          writeLog(`Failed! ${e.toString()}`);
        } catch {
          writeLog(`Failed! Couldn't parse error!`);
        }
      });
  };
  const getAchievements = () => {
    let allActiveBadgesFromAPI = [];
    GetAllActiveAchievements(new AbortController()).then((data) => {
      for (let achievement of data.data.reverse()) {
        achievement.achievementType.achievementTypeId === 1 &&
          allActiveBadgesFromAPI.push(achievement);
      }
      setBadges(allActiveBadgesFromAPI.map(transformBadge));
    });
  };

  useEffect(() => {
    getAchievements();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Row>
          <FormGroup>
            <Label for="userId">
              User ID from table (Yes, I know. It's no longer email. But that's the
              way it goes.)
            </Label>
            <Input
              type="text"
              name="userId"
              id="userId"
              placeholder="Type away..."
              value={userId}
              onChange={(e) => {
                setUserId(e.target.value);
              }}
            />
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="resetTutorialStatus"
                  onChange={() => {
                    resetTutorialStatusChange((old) => !old);
                  }}
                  checked={resetTutorialStatus}
                />
                Reset tutorial status
              </Label>
            </FormGroup>
            <Button
              onClick={() => {
                doGet('/api/AdminFunctions/resetuserbadges', {
                  userId,
                  resetTutorialStatus: resetTutorialStatus
                });
              }}
            >
              Reset Badges
            </Button>
            <br />
            <br />
            <div>
              {badges.map((badge) => {
                var badgeIndexbi;
                if (getInnovatorPageIndex(badge.slug) != null) {
                  badgeIndexbi = Math.pow(2, getInnovatorPageIndex(badge.slug));
                } else {
                  badgeIndexbi = -1;
                }
                return (
                  <div
                    key={badge.slug}
                    onClick={() => {
                      doGet('/api/AdminFunctions/resetuserbadge', {
                        userId,
                        slug: badge.slug,
                        badgeIndex: badgeIndexbi,
                        resetTutorialStatus: resetTutorialStatus
                      });
                    }}
                  >
                    <DetailedBadge
                      key={`detailed_badge_${badge.name}`}
                      badge={badge}
                    ></DetailedBadge>
                  </div>
                );
              })}
            </div>
            <br />
            <br />
            <Button
              onClick={() => {
                doGet('/api/AdminFunctions/resetuserbricks', {
                  userId,
                  resetTutorialStatus: resetTutorialStatus
                });
              }}
            >
              Reset Bricks
            </Button>
            <br />
            <br />
            <Button
              onClick={() => {
                doGet('/api/AdminFunctions/resetuserpoints', {
                  userId,
                  resetTutorialStatus: resetTutorialStatus
                });
              }}
            >
              Reset Points
            </Button>
            <br />
            <br />
            <Button
              onClick={() => {
                doGet('/api/AdminFunctions/resetuserachievements', {
                  userId,
                  resetTutorialStatus: resetTutorialStatus
                });
              }}
            >
              Reset Points, Bricks, Badges and Clear Cache
            </Button>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <Button
              onClick={() => {
                doGet('/api/Cache/clearall');
              }}
            >
              Clear Cache
            </Button>
          </FormGroup>
        </Row>

        <pre
          style={{
            backgroundColor: '#000000',
            color: '#00ff00',
            padding: '5px',
            maxHeight: '70vh',
            overflow: 'auto'
          }}
        >
          {log}
        </pre>
      </Container>
    </>
  );
};

export default SuperAdmin;

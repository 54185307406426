import React from 'react';
import GlobalContext from '../../../../context/GlobalContext';
import { Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import AccessibleButton from '../../../../components/accessibleButton/AccessibleButton';
import { SessionTabs } from '../../../../enums/sessiontabs.enum';
import AdminHomeTabs from '../AdminHomeTabs';
import style from '../AdminAllTabs.module.css';

class SessionConfigTab extends React.Component {
  static propTypes = {
    activeSessiontab: PropTypes.string
  };
  static defaultProps = {
    activeSessiontab: ''
  };
  render() {
    return (
      <div>
        <div className={style.adminRoot}>
          <AdminHomeTabs
            activetab={this.props.activetab}
            switchtab={this.props.switchtab}
          />
          <Nav tabs className={style.adminFirstNav}>
            <NavItem>
              <AccessibleButton
                className={classnames({
                  [style.active]:
                    this.props.activeSessiontab === SessionTabs.Locations
                })}
                id={'locations-tab-session-admin'}
                onClick={() => {
                  this.props.switchSessiontab(
                    '/admin/sessionconfigurations/' + SessionTabs.Locations
                  );
                }}
              >
                Locations
              </AccessibleButton>
            </NavItem>
            <NavItem>
              <AccessibleButton
                className={classnames({
                  [style.active]:
                    this.props.activeSessiontab === SessionTabs.Presenters
                })}
                id={'presenters-tab-session-admin'}
                onClick={() => {
                  this.props.switchSessiontab(
                    '/admin/sessionconfigurations/' + SessionTabs.Presenters
                  );
                }}
              >
                Presenters
              </AccessibleButton>
            </NavItem>
            <NavItem>
              <AccessibleButton
                className={classnames({
                  [style.active]:
                    this.props.activeSessiontab === SessionTabs.Providers
                })}
                id={'providers-tab-session-admin'}
                onClick={() => {
                  this.props.switchSessiontab(
                    '/admin/sessionconfigurations/' + SessionTabs.Providers
                  );
                }}
              >
                Providers
              </AccessibleButton>
                    </NavItem>
                    {/*
            <NavItem>
              <AccessibleButton
                className={classnames({
                  [style.active]:
                    this.props.activeSessiontab === SessionTabs.Attributes
                })}
                id={'attributes-tab-session-admin'}
                onClick={() => {
                  this.props.switchSessiontab(
                    '/admin/sessionconfigurations/' + SessionTabs.Attributes
                  );
                }}
              >
                Attributes
              </AccessibleButton>
                    </NavItem>
            */}
            <NavItem>
              <AccessibleButton
                className={classnames({
                  [style.active]: this.props.activeSessiontab === SessionTabs.Reps
                })}
                id={'reps-tab-session-admin'}
                onClick={() => {
                  this.props.switchSessiontab(
                    '/admin/sessionconfigurations/' + SessionTabs.Reps
                  );
                }}
              >
                L+D Reps
              </AccessibleButton>
            </NavItem>
          </Nav>
        </div>
      </div>
    );
  }
}
SessionConfigTab.contextType = GlobalContext;

export default SessionConfigTab;

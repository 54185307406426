import React from 'react';
import ReactGA from 'react-ga';
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import * as toastRequest from '../../utils/toastRequests';
import { adalApiFetch } from '../../../../adalConfig';
import { toast } from 'react-toastify';
import Presenter from './Presenter';
import PresenterAddModify from './PresenterAddModify';
import { SessionTabs } from '../../../../enums/sessiontabs.enum';
import SessionConfigTab from '../SessionConfigTab/SessionConfigTab';
import { AdminTabs } from '../../../../enums/admintabs.enum';
import style from './AdminPresenterTab.module.css';
import sharedStyle from '../AdminAllTabs.module.css';

const elementsPerPage = 20;

export default class AdminPresenterTab extends React.Component {
  constructor() {
    super();
    this.abortController = new AbortController();

    this.state = {
      activeTab: '0',
      allPresenters: [],
      searchedPresenters: [],
      domains: [],
      searchText: '',
      renderIndex: 0,
      spinnerToggle: true
    };
    this.toggle = this.toggle.bind(this);
    this.loadContent = this.loadContent.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.formRef = React.createRef();
    this.loadContent(true);
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  changeHandler(e) {
    this.setState({ searchText: e.target.value });
    let searchedPresenters = this.state.allPresenters.filter(
      (el) => el.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    );
    this.setState({ searchedPresenters: searchedPresenters, renderIndex: 0 });
  }

  nextPage = () => {
    if (
      this.state.renderIndex + elementsPerPage <
      this.state.searchedPresenters.length
    )
      this.setState({ renderIndex: this.state.renderIndex + elementsPerPage });
  };

  previousPage = () => {
    if (this.state.renderIndex - elementsPerPage >= 0)
      this.setState({
        renderIndex: Math.max(0, this.state.renderIndex - elementsPerPage)
      });
  };

  loadContent(doToast = false) {
    this.setState({
      allPresenters: [],
      searchedPresenters: [],
      searchText: '',
      spinnerToggle: true
    });

    let toastId = null;
    if (doToast) {
      toastId = toastRequest.notify('Loading presenter data');
    }
    adalApiFetch(fetch, process.env.REACT_APP_API_BASE_DOMAIN + '/api/Presenters', {
      method: 'get',
      signal: this.abortController.signal
    })
      .then((response) => {
        if (!response.ok && doToast) {
          toast.update(toastId, {
            render:
              'An error occurred when attempting to load Presenters. Please try again later.',
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else if (!response.ok) {
          toast('An error occurred when attempting to load the list of Presenters', {
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          return response.json();
        }
      })
      .then((myJson) => {
        if (myJson.success === false) {
          toast.update(toastId, {
            render: 'Error loading presenters',
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        } else {
          this.setState({
            allPresenters: myJson.data,
            searchedPresenters: myJson.data,
            searchText: '',
            spinnerToggle: false
          });
          toast.update(toastId, {
            render: 'Presenters loaded successfully',
            type: toast.TYPE.SUCCESS,
            autoClose: 2000
          });
        }
      })
      .catch((e) => {
        toast.dismiss(toastId);
        ReactGA.exception({
          description: e.toString()
        });
      });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <div className={sharedStyle.adminRoot}>
        <SessionConfigTab
          activeSessiontab={SessionTabs.Presenters}
          switchSessiontab={this.props.history.push}
          activetab={AdminTabs.SessionConfigurations}
          switchtab={this.props.history.push}
        />
        <Container>
          <Row>
            <Col>
              <Nav tabs className={sharedStyle.tabMargin}>
                <NavItem>
                  <NavLink
                    id="view-presenters"
                    className={classnames({ active: this.state.activeTab === '0' })}
                    onClick={() => {
                      this.toggle('0');
                    }}
                  >
                    VIEW
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    id="add-presenter"
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    ADD
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="0">
                  {/*TAB 1 SEARCH BAR*/}
                  <InputGroup>
                    <Label for="presenterSearchBar" className="show-for-sr">
                      {' '}
                      course search bar
                    </Label>
                    <Input
                      placeholder="search"
                      id={'presenterSearchBar'}
                      onChange={this.changeHandler}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>Search</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className={sharedStyle.subText}>
                    {this.state.searchedPresenters.length} results found in about{' '}
                    {(Math.random() / 10).toFixed(3)} seconds
                  </div>
                  {/*PAGINATION*/}
                  <div>
                    <InputGroup>
                      {this.state.searchedPresenters.length > elementsPerPage && (
                        <Button
                          id="prev-presenter-page"
                          disabled={this.state.renderIndex <= 0}
                          size={'sm'}
                          color="secondary"
                          outline={true}
                          className={style.pagination}
                          onClick={this.previousPage}
                        >
                          Back
                        </Button>
                      )}
                      {/*PAGE NUMBER*/}
                      {this.state.searchedPresenters.length > elementsPerPage && (
                        <p className={style.pageNumber}>
                          {this.state.renderIndex / elementsPerPage + 1}
                        </p>
                      )}

                      {this.state.searchedPresenters.length > elementsPerPage && (
                        <Button
                          disabled={
                            !(
                              this.state.renderIndex + elementsPerPage <
                              this.state.searchedPresenters.length
                            )
                          }
                          id="next-presenter-page"
                          size={'sm'}
                          color="secondary"
                          outline
                          className={style.pagination}
                          onClick={this.nextPage}
                        >
                          Next
                        </Button>
                      )}
                    </InputGroup>
                  </div>
                  {this.state.spinnerToggle && <Spinner color="primary" />}
                  {this.state.searchedPresenters
                    .slice(
                      this.state.renderIndex,
                      this.state.renderIndex + elementsPerPage
                    )
                    .map((presenter) => (
                      <Presenter
                        manualReload={this.loadContent}
                        payload={presenter}
                        key={presenter.presenterId}
                      />
                    ))}

                  {/*{this.state.searchedPresenters.map((presenter) => <Presenter*/}
                  {/*  manualReload={this.loadContent} payload={presenter} key={presenter.presenterId}/>)}*/}
                </TabPane>
                <TabPane tabId="1">
                  <PresenterAddModify
                    ref={this.formRef}
                    manualReload={this.loadContent}
                  />
                  <Button
                    id="submit-presenter-btn"
                    color="primary"
                    size="lg"
                    className={sharedStyle.submitBtn}
                    onClick={() => this.formRef.current.add()}
                  >
                    Submit
                  </Button>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

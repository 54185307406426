import classNames from 'classnames';
import React, { useState } from 'react';
import { AdminTabs } from '../../../../enums/admintabs.enum';
import sharedStyle from '../AdminAllTabs.module.css';
import AdminHomeTabs from '../AdminHomeTabs';
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import AddTab from './AddTab';
import ViewTab from './ViewTab';

const AdminLDCalendar = (props) => {
  const [activeTab, setActiveTab] = useState('0');

  // toggle() changes between View and Add tabs
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className={sharedStyle.adminRoot}>
      <AdminHomeTabs
        activetab={AdminTabs.LDCalendar}
        switchtab={props.history.push}
      />
      <Container>
        <Row>
          <Col>
            <Nav tabs className={sharedStyle.tabMargin}>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === '0' })}
                  id="viewTab"
                  onClick={() => {
                    toggle('0');
                  }}
                >
                  View
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === '1' })}
                  id="addTab"
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Add
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        {/* VIEW */}
        {activeTab === '0' && <ViewTab />}
        {/* ADD */}
        {activeTab === '1' && <AddTab />}
      </Container>
    </div>
  );
};

export default AdminLDCalendar;

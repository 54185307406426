import React, { Component } from 'react';
import style from './DetailedBadge.module.css';
import { UncontrolledTooltip } from 'reactstrap';
import { gaExcept } from '../../utils/reactGA';

function uid(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default class DetailedBadge extends Component {
  id = uid(5);

  render() {
    let imgUrl = null;

    // In case the asset isn't there, just don't render img.
    try {
      imgUrl = require('../../assets/badges/' + this.props.badge.image);
    } catch (e) {
      imgUrl = require('../../assets/badges/DefaultBadge.png');
      gaExcept(e);
    }

    return (
      <>
        <div
          id={`badge${this.id}`}
          className={[
            this.props.badge.earnDate !== null
              ? style.earnedBadge
              : style.unearnedBadge,
            style.badge
          ].join(' ')}
        >
          {imgUrl && (
            <img
              className={style.dashboardBadge}
              id={
                this.props.badge.earnDate !== null
                  ? this.props.badge.name + 'earnedBadge'
                  : this.props.badge.name + 'unearnedBadge'
              }
              src={imgUrl}
              alt={this.props.badge.name + ' Badge'}
            />
          )}
          <div className={style.badgeNameHolder}>
            {this.props.badge.name.toUpperCase()}
          </div>
          <div className={style.badgeContentHolder}>{this.props.badge.desc}</div>
        </div>
        <UncontrolledTooltip
          modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
          placement="top"
          target={'badge' + this.id}
        >
          {this.props.badge.desc + ' '}
          {this.props.badge.earnDate}
        </UncontrolledTooltip>
      </>
    );
  }
}

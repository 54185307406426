import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ShareButton from '../../../components/share/ShareButton';
import MandatoryCircle from '../../../components/mandatoryCircle/mandatoryCircle';
import style from './SessionList.module.css';
import RegisterButton from '../../../components/registerButton/RegisterButton';
import { NonLtcIcon } from '../../../components/nonLtcIcon/nonLtcIcon';
import { SLType, readableDuration } from './SessionUtils';
import ManagerOnlyTag from '../../../components/ManagerOnlyTag/ManagerOnlyTag';
import scrollIntoView from 'scroll-into-view-if-needed';
import { registrationRecord } from '../../../utils/courses';
import GlobalContext from '../../../context/GlobalContext';
import RecommendButton from '../../../components/recommendButton/RecommendButton';
import DigiButton from '../../../components/digiButton/DigiButton';
import DigiMenu from '../../../components/digiMenu/DigiMenu';
import DigiHeadingBox from '../../../components/DigiHeadingBox.jsx/DigiHeadingBox';
import CustomScroll from '../../../components/customScroll/CustomScroll';
import { NonLtcRegisterButton } from '../../../components/registerButton/NonLtcRegisterButton';
import WithdrawButton from '../../../components/withdrawButton/WithdrawButton';

export default class SessionList extends Component {
  static contextType = GlobalContext;

  componentDidUpdate(prevProps) {
    // Check the first element of the list and go to that date in the calendar
    if (
      prevProps.sessions.length > 0 &&
      this.props.sessions.length > 0 &&
      prevProps.sessions[0].start !== this.props.sessions[0].start
    ) {
      this.props.itemClickHandler(this.props.sessions[0].start);
    }
    if (this.props.clickedCourseId !== 0) {
      let target = document.getElementById(this.props.clickedCourseId);
      if (target)
        scrollIntoView(target, {
          scrollMode: 'if-needed',
          behavior: 'smooth',
          block: 'start',
          inline: 'start'
        });
    }
  }

  isPastSession = () => {
    return this.props.type === SLType.PAST;
  };

  itemClickHandler(target) {
    this.props.itemClickHandler(target);
  }

  isUpcoming = () => this.props.type === SLType.UPCOMING;

  displayRecommend = (course) => {
    return (
      this.isUpcoming() &&
      this.context.user &&
      this.context.user.isManager &&
      !course.managerOnly &&
      !course.mandatory
    );
  };

  render() {
      return (
      <DigiHeadingBox
        wrapperClassName={style.sessionListWrapper}
        title={`Sessions (${this.props.sessions.length})`}
      >
        <CustomScroll className={style.sessionListHolder}>
          <div className={style.sessionList}>
            {this.props.loading ? (
              <div className={style.spinnerHolder}>
                <Spinner color="primary" className={style.spinner} />
              </div>
            ) : (
              this.props.sessions.map((course, index) => {
                return (
                  <div
                    id={course.courseDetailsId}
                    key={index}
                    className={style.session}
                  >
                    <div className={style.contentHolder}>
                      <div className={style.titleHolder}>
                        <div>{course.managerOnly && <ManagerOnlyTag />}</div>
                        <a
                          className={style.title}
                          href={'/sessions/' + course.courseDetailsId}
                          onClick={(event) => {
                            this.props.parentCallback(course.courseDetailsId, event);
                          }}
                        >
                          <h3>{course.title}</h3>
                        </a>
                        <div
                          className={style.timeContainer}
                          onClick={() => this.itemClickHandler(course.courseStart)}
                        >
                          {this.props.type !== SLType.ON_DEMAND && 'Date: '}
                          {this.props.type !== SLType.ON_DEMAND && course.start.toLocaleString([], {
                            day: 'numeric',
                            year: 'numeric',
                            month: 'long'
                          })}
                          <br />
                          {this.props.type === SLType.ON_DEMAND ? (
                            <>Duration: {readableDuration(course.courseDuration)}</>
                          ) : (
                            <>
                              {!this.isPastSession() && (
                                <div>
                                  Time:{' '}
                                  {course.start.toLocaleTimeString([], {
                                    hour: 'numeric',
                                    minute: '2-digit'
                                  })}{' '}
                                  -{' '}
                                  {course.end.toLocaleTimeString([], {
                                    hour: 'numeric',
                                    minute: '2-digit'
                                  })}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className={style.iconContainer}>
                        {course.mandatory && <MandatoryCircle />}
                        {course.externalUrl && <NonLtcIcon />}
                      </div>
                    </div>
                    <div className={style.buttonHolder}>
                      {this.displayRecommend(course) && (
                        <div className={style.optionButtonContainer}>
                          <DigiMenu design={[faEllipsisV, faEllipsisV]}>
                            <ShareButton
                              session={course}
                              type={this.props.type}
                              addBadgeToList={this.props.addBadgeToList}
                            />
                          </DigiMenu>
                        </div>
                      )}
                      {this.isUpcoming() && (
                        <div
                          className={[
                            style.optionButtonContainer,
                            this.displayRecommend(course)
                              ? ''
                              : style.paddedButtonContainer
                          ].join(' ')}
                        >
                          {course.externalUrl ? (
                            <NonLtcRegisterButton
                              area="session-list"
                              courseDetails={course}
                              registrationId={
                                registrationRecord(course, this.context.user.userId)
                                  ? registrationRecord(
                                      course,
                                      this.context.user.userId
                                    ).registrationId
                                  : null
                              }
                              addBadgeToList={this.props.addBadgeToList}
                            />
                          ) : (
                            <RegisterButton
                              area="session-list"
                              courseDetails={course}
                              registered={
                                !!registrationRecord(
                                  course,
                                  this.context.user.userId
                                )
                              }
                              registrationId={
                                registrationRecord(course, this.context.user.userId)
                                  ? registrationRecord(
                                      course,
                                      this.context.user.userId
                                    ).registrationId
                                  : null
                              }
                              addBadgeToList={this.props.addBadgeToList}
                            />
                          )}
                        </div>
                      )}
                        {this.props.type === SLType.ON_DEMAND && ( //course.mandatory && (
                            <div className={style.optionButtonContainer}>
                                <WithdrawButton courseDetails={course} />
                            </div>
                            )}
                      <div className={style.optionButtonContainer}>
                        <DigiButton
                          id={`more-button-session-list-${course.courseDetailsId}`}
                          selected={
                            this.props.clickedCourseId === course.courseDetailsId
                          }
                          onClick={() => {
                            this.props.parentCallback(course.courseDetailsId);
                          }}
                        >
                          More
                        </DigiButton>
                      </div>

                      {!this.displayRecommend(course) && (
                        <div className={style.optionButtonContainer}>
                          <ShareButton
                            session={course}
                            type={this.props.type}
                            addBadgeToList={this.props.addBadgeToList}
                          />
                        </div>
                      )}

                      {this.displayRecommend(course) && (
                        <div className={style.optionButtonContainer}>
                          <RecommendButton
                            area="session-list"
                            courseId={course.courseDetailsId}
                            onClick={() => {
                              this.props.openRecommendSession(
                                course.courseDetailsId
                              );
                            }}
                            selected={
                              this.props.recommendSession &&
                              this.props.recommendSession.courseDetailsId ===
                                course.courseDetailsId
                            }
                          ></RecommendButton>
                        </div>
                      )}
                    </div>

                    <hr />
                  </div>
                );
              })
            )}
          </div>
              </CustomScroll>
      </DigiHeadingBox>
    );
  }
}

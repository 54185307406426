import React from 'react';
import styles from './Course.module.css';

import RegisterButton from '../../components/registerButton/RegisterButton';
import SurveyButton from '../../components/surveyButton/SurveyButton';
import ShareButton from '../../components/share/ShareButton.jsx';
import SessionDetails from '../../components/sessionDetails/SessionDetails';
import GlobalContext from '../../context/GlobalContext';
import { canTakeSurvey } from '../../CONSTANTS';
import { SLType } from '../sessions/components/SessionUtils';
import PropTypes from 'prop-types';
import { registrationRecord } from '../../utils/courses';
import { NonLtcRegisterButton } from '../../components/registerButton/NonLtcRegisterButton';
import WithdrawButton from '../../components/withdrawButton/WithdrawButton';

export default class Course extends React.Component {
  static propTypes = {
    session: PropTypes.object,
    type: PropTypes.any,
    closeable: PropTypes.bool,
    closeButtonClicked: PropTypes.func,
    addBadgeToList: PropTypes.func
  };

  static defaultProps = {
    closeable: true
  };

  abortController = new AbortController();
  state = {
    courseId: null
  };

  isPastSession = () => {
    return this.props.type === SLType.PAST;
  };

  componentDidUpdate(prevProps) {
    // Did the session change?
    if (this.props.session.courseId !== prevProps.session.courseId) {
      this.setState({
        courseId: this.props.session.courseId
      });
      this.context.toggleAnimateSessionMaterials(false);
    }
  }

  componentWillUnmount() {
    this.context.toggleAnimateSessionMaterials(false);
    this.abortController.abort();
  }

  getCourseFromContext = () => {
    return this.context.sessionList.filter((obj) => {
      return obj.courseDetailsId === this.props.session.courseDetailsId;
    })[0];
  };

  render() {
    const course = this.getCourseFromContext();
    const _registrationRecord = registrationRecord(course, this.context.user.userId);
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <>
            <SessionDetails
              closeable={this.props.closeable}
              sessionWithDetails={this.props.session}
              isPastSession={this.isPastSession()}
              type={this.props.type}
              closeButtonClicked={this.props.closeButtonClicked}
              registered={!!_registrationRecord}
              animateMaterials={context.animateSessionMaterials}
              onMaterialsAnimated={() => {
                context.toggleAnimateSessionMaterials(false);
              }}
            >
              {this.props.session && (
                <div>
                  <div className={styles.courseRegisterButton}>
                    <>
                      <ShareButton
                        session={this.props.session}
                        type={this.props.type}
                        addBadgeToList={this.props.addBadgeToList}
                      />
                      {this.props.type === SLType.ON_DEMAND ? ( //&& this.props.session.mandatory ? (
                        <WithdrawButton courseDetails={this.props.session} />
                      ) : (
                        this.props.type !== SLType.PAST &&
                        (this.props.session.externalUrl ? (
                          <NonLtcRegisterButton
                            area="course"
                            courseDetails={this.props.session}
                            registrationId={
                              _registrationRecord
                                ? _registrationRecord.registrationId
                                : null
                            }
                            addBadgeToList={this.props.addBadgeToList}
                          />
                                                ) :
                                                    !(this.props.type === SLType.ON_DEMAND && !this.props.session.mandatory) &&
                                                    (
                          <RegisterButton
                            area="course"
                            addBadgeToList={this.props.addBadgeToList}
                            onRegistered={() => {
                              if (this.props.type === SLType.ON_DEMAND) {
                                context.toggleAnimateSessionMaterials(true);
                              }
                            }}
                            registered={!!_registrationRecord}
                            registrationId={
                              _registrationRecord
                                ? _registrationRecord.registrationId
                                : null
                            }
                            courseDetails={this.props.session}
                          />
                        ))
                      )}
                      {this.props.type !== SLType.UPCOMING &&
                        canTakeSurvey(this.props.session, context.user.userId) && (
                          <SurveyButton session={this.props.session} />
                        )}
                    </>
                  </div>
                </div>
              )}
            </SessionDetails>
          </>
        )}
      </GlobalContext.Consumer>
    );
  }
}
Course.contextType = GlobalContext;

import React from 'react';
import style from './SessionsList.module.css';
import DigiHeadingBox from '../../../components/DigiHeadingBox.jsx/DigiHeadingBox';
import MandatoryCircle from '../../../components/mandatoryCircle/mandatoryCircle';
import CustomScroll from '../../../components/customScroll/CustomScroll';
import { stringToDate } from '../../../CONSTANTS.js';
import { Spinner } from 'reactstrap';
import AccessibleButton from '../../../components/accessibleButton/AccessibleButton';

export default function ManagerSessionsList(props) {
  /**
   * Message to display when there is no data to show
   */
  const noDataMessage = (
    <div className={style.msgHolder}>
      <p>There is no Mandatory Session in the selected timeframe.</p>
      <p>
        Please select a different timeframe to review the attendance details of
        Mandatory Sessions where it’s available.
      </p>
    </div>
  );

  const formatDate = (date) => {
    let formattedDate = stringToDate(date);
    return String(
      formattedDate.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
    );
  };

  const formatCourseTime = (runtime) => {
    let start = stringToDate(runtime.courseStart);
    let end = stringToDate(runtime.courseEnd);
    let formattedStart = String(
      start
        .toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit'
        })
        .replace('AM', '')
        .replace('PM', '')
    );
    let formattedEnd = String(
      end.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit'
      })
    );
    return `${formattedStart} - ${formattedEnd}`;
  };

  return (
    <DigiHeadingBox
      wrapperClassName={style.sessionListWrapper}
      contentHolderClassName={style.contentHolder}
      title={`Sessions (${props.sessions.length})`}
    >
      <CustomScroll className={style.sessionListHolder}>
        <div className={style.sessionList}>
          {!props.loading && props.sessions.length > 0 ? (
            /* Sessions */
            props.sessions.map((course, index) => (
              <div key={index}>
                <div className={style.session}>
                  <div className={style.contentHolder}>
                    <div className={style.titleHolder}>
                      <div
                        className={
                          props.selectedSession === course
                            ? style.titleSelected
                            : style.title
                        }
                      >
                        <AccessibleButton
                          id={`sessions-list-${course.courseDetails.courseDetailsId}`}
                          onClick={() => props.selectSession(course)}
                          className={style.clickableTitle}
                        >
                          <h3>
                            {course.courseDetails.courseName}
                            {course.futureCourse ||
                            !course.allSessionsInTimeFrame ? (
                              <span className={style['special-star']}> *</span>
                            ) : (
                              ''
                            )}
                          </h3>
                        </AccessibleButton>
                      </div>
                      <div className={style.detailsHolder}>
                        <span>
                          <div className={style.domainContainer}>
                            {course.courseDetails.course.domain.name}
                          </div>
                          {false && course.runtimes.map((runtime, i) => (
                            <>
                              <div>Date: {formatDate(runtime.courseStart)}</div>
                              <div>Time: {formatCourseTime(runtime)}</div>
                              {i < course.runtimes.length - 1 ? <br /> : <></>}
                            </>
                          ))}
                        </span>
                        <span className={style.mandatoryContainer}>
                          <MandatoryCircle />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : !props.loading && (props.noData === 0 || props.noData === 'N/A') ? (
            /* No Data */
            noDataMessage
          ) : (
            /* Loading */
            <div className={style.spinnerHolder}>
              <Spinner color="primary" />
            </div>
          )}
        </div>
      </CustomScroll>
    </DigiHeadingBox>
  );
}

import React from 'react';
import StaffMandatoryCourses from './StaffMandatoryCourses';
import StaffRegisteredSessions from './StaffRegisteredSessions';
import StaffRecommendedSessions from './StaffRecommendedSessions';

export default class ExpandedStaffData extends React.Component { //(usersList)
    constructor(props) {
        super(props);
    }

    render() {
        //debugger;
        return (
            <div id="detailsDiv" style={{
                border: '1px solid #ccc', borderRadius: '5px',
                padding: '1px', marginLeft: '46px', marginTop: '0px',
                marginRight: '5px', marginBottom: '10px',
            }}>
                <StaffMandatoryCourses
                    mandatoryCourses={this.props.mandatoryCourses.mandatoryCourses}
                    userId={this.props.data.userId}
                    isManager={this.props.data.isManager}
                />
                <StaffRegisteredSessions
                    registeredCourses={this.props.mandatoryCourses.registeredCourses}
                    userId={this.props.data.userId}
                />
                <StaffRecommendedSessions
                    recommendedCourses={this.props.mandatoryCourses.recommendedCourses}
                    userId={this.props.data.userId}
                />
                </div>
        );
    }
}
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import errorIcon from '../../images/error.svg';
import './ErrorModal.css';

export default class ErrorModal extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.open} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Error</ModalHeader>
        <ModalBody>
          <img src={errorIcon} className={'error-image'} alt={'error symbol'} />
          <p>{this.props.children}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.props.toggle}>
            Okay
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

import React, { Component } from 'react';
import styles from './TourVideo.module.css';

export default class TourVideo extends Component {
  render() {
    return (
      <div className={[styles.helpPage, 'app-container'].join(' ')}>
        <h1 className={'show-for-sr'}>Help</h1>
        <div>
          <video
            poster="placeholder.png"
            className={styles.videoPlayer}
            controls
            autoPlay
          >
            <source src={require('../../assets/help/video.mp4')} type="video/mp4" />
            <track
              kind="subtitles"
              src={require('../../assets/help/en.vtt')}
              srcLang="en"
              label="English"
            />
          </video>
        </div>
      </div>
    );
  }
}

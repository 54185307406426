import React, { Component } from 'react';
import DataTable from '../../../components/dataTableBase/DataTableBase';
import { getFormattedDate } from '../../../utils/date';
import EmptyDataTableBase from '../../../components/dataTableBase/EmptyDataTableBase';


const columns = [
    {
        name: 'Recommended Sessions',
        selector: row => row.courseDetails.courseName,
        sortable: true,
        wrap: true,
        grow: 2,
        compact: false,
    },
    {
        name: 'Date of Session',
        selector: row => getFormattedDate(row.courseDetails.courseStart),
        sortable: true,
        compact: false,
    },
    {
        name: 'Registered',
        selector: row => row.staffRegistered.some(x => x.userId === row.userId) ? 'Yes': 'No',
        sortable: true,
        compact: false,
    }
];

export default class StaffRecommendedSessions extends Component {
    render() {
        const data = this.props.recommendedCourses.map(item => ({ ...item, userId: this.props.userId }));
        const reg = data.filter(item => item.staffRecommended.some(staff => staff.userId === this.props.userId));
    //    console.log("With filter StaffRecommendedSessions: ", reg);

        return <DataTable //title="Mandatory Courses"
            columns={columns}
            data={reg}
            defaultPageSize={5}
            noDataComponent={ EmptyDataTableBase(columns)}
            style={{
                height: "100px"
            }}
        />;
    }
}
import React from 'react';
import style from './PromoteHistory.module.css';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

export default class PromoteHistory extends React.Component {
  state = {
    expanded: false
  };

  togglePromotionHistory = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded
    }));
  };

  promotionMenu = () => {
    return (
      <>
        <DropdownToggle data-id={`promote-date-0`} outline caret size="sm">
          {this.props.promotionHistory[0]}
        </DropdownToggle>
        <DropdownMenu>
          {this.props.promotionHistory.map((date, index) => {
            if (index !== 0) {
              return (
                <span key={`promote-date-${this.props.courseDetailsId}-${index}`}>
                  <DropdownItem
                    className={style.dropdownItemContainer}
                    disabled
                    data-id={`promote-date-${index}`}
                  >
                    {date}
                  </DropdownItem>
                  {index !== this.props.promotionCount - 1 ? (
                    <DropdownItem divider />
                  ) : (
                    ''
                  )}
                </span>
              );
            }
            return '';
          })}
        </DropdownMenu>
      </>
    );
  };

  buttonDropdown = () => {
    // If session promoted just once, dropdown button is disabled
    if (this.props.promotionCount === 1) {
      return (
        <ButtonDropdown
          disabled
          className={style.dropdownBtn}
          isOpen={this.state.expanded}
          toggle={this.togglePromotionHistory}
        >
          {this.promotionMenu()}
        </ButtonDropdown>
      );
    }
    return (
      <ButtonDropdown
        className={style.dropdownBtn}
        isOpen={this.state.expanded}
        toggle={this.togglePromotionHistory}
      >
        {this.promotionMenu()}
      </ButtonDropdown>
    );
  };

  render() {
    return (
      <span className={style.promoteLabel}>
        {'All times promoted: '}
        {this.buttonDropdown()}
      </span>
    );
  }
}

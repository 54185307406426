import React from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';
import { adalApiFetch } from '../../adalConfig';
import { toast } from 'react-toastify';
import styles from './ManagerDashboard.module.css';
import { Row, Col, CustomInput } from 'reactstrap';
import TimeFrame from '../../components/timeFrame/TimeFrame';
import StatCard from './components/StatCard';
import DashboardDetails from './DashboardDetails';
import GlobalContext from '../../context/GlobalContext';
import { stringToDate } from '../../CONSTANTS';
import OfficeFilter, { officeFilterStateStore } from './components/OfficeFilter';
//import StaffReportNew from './components/StaffReportNew';
import StaffReport from './components/StaffReport';
import { joinClasses } from '../../utils/styles';

const pillarLabels = [
    'LTC Toolkit',
    'LTC Tech',
    'Emerging Tech',
    'Business Acumen',
    'Non-Tech'
];

const pillarAttendedBGColor = '#90CCF4';
const pillarOfferedBGColor = '#FFFFFF';
const pillarBorderColor = '#00205B';
const pillarBorderWidth = 7;
const maxTooltipLength = 10;
export default class ManagerDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.abortController = new AbortController();
        this.state = {
            startDate: this.defaultDateDisplay('startDate'),
            endDate: this.defaultDateDisplay('endDate'),
            enableRefresh: false,
            loading: false,
            staff: [],
            upcomingSelectedIndex: 0,
            recommendedSelectedIndex: 0,
            upcomingRegisteredSessions: [],
            attendedSessions: [],
            recommendedSessionsCount: 0,
            recommendedSessions: [],
            mandatoryAttendance: {
                courses: []
            },
            attendedOnDemand: [],
            sessionRegistrationsRatio: 0,
            sessionRegistrations: [],
            allPillarStats: [],
            // Pillars Chart Data
            pillarsData: {
                labels: pillarLabels,
                datasets: [
                    {
                        backgroundColor: pillarAttendedBGColor,
                        borderColor: pillarBorderColor,
                        borderWidth: pillarBorderWidth,
                        data: []
                    }
                ]
            },
            officeFilterSelection: [],
            dateFilterHide: false,
            staffOrManager:false
        };
    }

    hideDateFilter = (newValue) => {
        this.setState({ dateFilterHide: newValue });
    }

    componentDidMount() {
        officeFilterStateStore.listenToChanges(this.listenToFilterChanges.bind(this));
        this.loadContent();
    }

    componentWillUnmount() {
        officeFilterStateStore.stopListening(this.listenToFilterChanges);
        this.abortController.abort();
    }

    listenToFilterChanges(officeFilterSelection) {
        this.setState((prevState) => ({
            ...prevState,
            officeFilterSelection,
            enableRefresh: true
        }));
        this.loadContent();
    }

    /**
     * Load stats
     */
    loadContent = (selectedCourseDetailsId = null) => {
        this.setState({ loading: true });

        adalApiFetch(
            fetch,
            process.env.REACT_APP_API_BASE_DOMAIN +
            `/api/ManagerDashboard/dashboard?
      &startDate=${this.state.startDate}
      &endDate=${this.state.endDate}
      &staffOrManager=${this.state.staffOrManager}
      ${this.state.officeFilterSelection?.length > 0
                ? '&managerUserId=' +
                this.state.officeFilterSelection.slice(-1)[0].leader.userId
                : ''
            }`,
            { method: 'get', signal: this.abortController.signal }
        )
            .then((response) => {
                return response.json();
            })
            .then((myJson) => {
                if (!myJson.success) {
                    this.setState({ loading: false });
                    toast('An error occurred when loading Manager Dashboard Stats', {
                        type: toast.TYPE.ERROR,
                        autoClose: true
                    });
                } else {
                    let upcomingSelectedIndex = 0;
                    let recommendedSelectedIndex = 0;

                    if (selectedCourseDetailsId) {
                        upcomingSelectedIndex = this.getSessionIndex(
                            myJson.data.upcomingRegistered,
                            selectedCourseDetailsId
                        );
                        recommendedSelectedIndex = this.getSessionIndex(
                            myJson.data.recommendedSessions,
                            selectedCourseDetailsId
                        );
                    }

                    this.setState({
                        staff: myJson.data.staff,

                        // set statistics
                        upcomingRegisteredSessions: myJson.data.upcomingRegistered,
                        attendedSessions: myJson.data.pastAttended,
                        mandatoryAttendance: myJson.data.mandatoryAttendance,
                        recommendedSessions: myJson.data.recommendedSessions,
                        recommendedSessionsCount: myJson.data.recommendedSessions.filter(
                            (x) => x.staffRecommendedCount > 0
                        ).length,
                        sessionRegistrations: myJson.data.sessionRegistrations,
                        sessionRegistrationsRatio: myJson.data.sessionRegistrationsRatio,
                        attendedOnDemand: myJson.data.onDemandAttended,
                        allPillarStats: myJson.data.allPillarStats,

                        // Set default selected session on 'Upcoming Sessions (Registered)' & 'Recommended Sessions' page
                        upcomingSelectedIndex: upcomingSelectedIndex,
                        recommendedSelectedIndex: recommendedSelectedIndex,
                        
                        loading: false
                    });
               //    debugger;
                }
            })
            .catch((e) => {
                ReactGA.exception({
                    description: e.toString()
                });
            });
    };

    /**
     * Returns the index of 'courseDetailsId' in 'sessionList'
     */
    getSessionIndex = (sessionList, courseDetailsId) => {
        for (let i = 0; i < sessionList.length; i++) {
            if (sessionList[i].courseDetails.courseDetailsId === courseDetailsId) return i;
        }
        return 0;
    };

    /**
     * Get the default start date (3 months ago) & end date (1 month ahead) for time frame
     * @param {*} name  "start" -> start date & "end" -> end date
     */
    defaultDateDisplay = (name) => {
        var defaultDate = new Date();
        if (name === 'startDate') {
            defaultDate.setMonth(defaultDate.getMonth() - 3);
        } else if (name === 'endDate') {
            defaultDate.setMonth(defaultDate.getMonth() + 1);
        }
        return defaultDate.toISOString().substr(0, 10);
    };

    updateTimeframe = (startDate, endDate, staffOrManager) => {
        this.setState(
            {
                startDate: startDate,
                endDate: endDate,
                staffOrManager:  staffOrManager,
            },
            () => this.loadContent()
        );
    };

    /**
     * Returns true if time frame is set to a past date range
     */
    isPastDateRange = () => {
        const now = new Date().toISOString().substr(0, 10);
        if (Date.parse(this.state.endDate) < Date.parse(now)) {
            return true;
        }
        return false;
    };

    isFutureDateRange = () => {
        const now = new Date().toISOString().substr(0, 10);
        if (Date.parse(this.state.startDate) > Date.parse(now)) {
            return true;
        }
        return false;
    };

    /**
     * Get data to display on pillar bars
     */
    getPillarsData = () => {
        let pillarStatAttended = [];
        let pillarStatOffered = [];

        if (Array.isArray(this.state.allPillarStats)) {
            this.state.allPillarStats.forEach((pillar) => {
                // Check if number of sessions under a pillar is 0. If so, data value will be empty
                const sessionsCount = pillar.sessionsCount === 0 ? '' : pillar.sessionsCount;
                pillarStatAttended.push(sessionsCount);
                const sessionsOfferedCount =
                    pillar.sessionsOfferedCount === 0 ? '' : pillar.sessionsOfferedCount;
                pillarStatOffered.push(sessionsOfferedCount);
            });
        }

        const pillarsData = {
            labels: pillarLabels,
            datasets: [
                {
                    label: 'Attended',
                    backgroundColor: pillarAttendedBGColor,
                    borderColor: pillarBorderColor,
                    borderWidth: { right: pillarBorderWidth },
                    data: pillarStatAttended
                },
                {
                    label: 'Offered',
                    backgroundColor: pillarOfferedBGColor,
                    data: pillarStatOffered
                }
            ]
        };

        return pillarsData;
    };

    hoverPillars = () => {
        let pillarHoverAttendedDisplay = {};
        let pillarHoverOfferedDisplay = {};
        for (let offered = 0; offered < 2; offered++) {
            let pillarHoverDisplay = {};
            pillarHoverDisplay[pillarLabels[0]] = '';
            pillarHoverDisplay[pillarLabels[1]] = '';
            pillarHoverDisplay[pillarLabels[2]] = '';
            pillarHoverDisplay[pillarLabels[3]] = '';
            pillarHoverDisplay[pillarLabels[4]] = '';

            this.state.allPillarStats.forEach((pillar) => {
                let hoverDisplay = [];
                let sessions = pillar.sessions;
                if (offered) {
                    sessions = pillar.sessionsOffered;
                }
                if (sessions) {
                    sessions.forEach((session) => {
                        if (hoverDisplay.length < maxTooltipLength) {
                            const sessionInfo = offered
                                ? `${session.courseName}`
                                : `${session.courseDetails.courseName} (${session.attendedStaffCount})`;
                            hoverDisplay.push(sessionInfo);
                        } else if (
                            sessions.length > maxTooltipLength &&
                            hoverDisplay.length === maxTooltipLength
                        ) {
                            hoverDisplay.push(
                                sessions.length - maxTooltipLength > 1
                                    ? `(+${sessions.length - maxTooltipLength} Other Sessions)`
                                    : `(+${sessions.length - maxTooltipLength} Other Session)`
                            );
                        }
                    });
                }

                // Get the label corresponding to the domain name
                for (let i = 0; i < pillarLabels.length; i++) {
                    if (pillar.domainName.includes(pillarLabels[i])) {
                        pillarHoverDisplay[pillarLabels[i]] = hoverDisplay;
                    }
                }
            });
            if (offered) {
                pillarHoverOfferedDisplay = pillarHoverDisplay;
            } else {
                pillarHoverAttendedDisplay = pillarHoverDisplay;
            }
        }

        return {
            attended: pillarHoverAttendedDisplay,
            offered: pillarHoverOfferedDisplay
        };
    };

    didStaffAttend = (staffUserId, session, attendanceType) => {
        for (let i = 0; i < session[attendanceType].length; i++) {
            if (session[attendanceType][i].userId === staffUserId) {
                // staffUserId attended session
                return true;
            }
        }

        // staffUserId did not attend session
        return false;
    };

    getNotRegisteredOrAttendedStaffList = (session, attendanceType) => {
        if (!session) return [];
        let notAttendedStaff = [];
        this.state.staff.forEach((s) => {
            if (!this.didStaffAttend(s.userId, session, attendanceType)) {
                if (!(!s.isManager && session.courseDetails.course.managerOnly)) // Do not show staff in list for manager only courses
                    notAttendedStaff.push(s);
            }
        });
        return notAttendedStaff;
    };

    /**
     * Returns true if courseDetails is an upcoming session
     */
    isUpcoming = (courseDetails) => {
        return stringToDate(courseDetails.courseStart) > Date.now();
    };
    toggleStaffOrManager = () => {
        this.setState(
            { staffOrManager: !this.state.staffOrManager }, // setting state of staffOrManager to the opposite current state (true <-> false) as we click it
            () => {
                // Make API call to load stats after start/end dates changed
                this.updateTimeframe(this.state.startDate, this.state.endDate, this.state.staffOrManager);
            }
        );
    };
    render() {
        return ( 
            <> 
                <div className={styles.statsHolder}>
                    <Row>   
                        <Col>
                            <OfficeFilter
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                            />
                        </Col>
                        <Col></Col>
                        <Col xs={1} className={joinClasses(
                            styles.flex,
                            this.state.staffOrManager && styles.faded
                        )}>
                            <div className="flex-resize"></div>
                            <div className={styles.texty}>
                                <span>STAFF</span>
                            </div>

                            <div className={styles.radio}>
                                <CustomInput
                                    type="switch"
                                    id="staff-manager"
                                    value={this.state.staffOrManager}
                                    label={
                                        <span className={styles.dis} aria-label="STAFF OR MANAGERS">
                                            {''}
                                        </span>
                                    }
                                    checked={this.state.staffOrManager}
                                    onChange={this.toggleStaffOrManager}
                                />
                            </div>

                        </Col>
                        <Col xs={1} className={joinClasses(
                            styles.flex,
                            !this.state.staffOrManager && styles.faded
                        )}>
                            <div className="flex-resize"></div>
                            <div className={styles.texty}>
                                <span>MANAGERS</span>
                            </div>
                        </Col>
                        <Col>{!this.state.dateFilterHide && (
                            <TimeFrame
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                updateTimeframe={this.updateTimeframe}
                                loading={this.state.loading}
                                theme="manager"
                            />)}
                        </Col>
                    </Row>

                    {/* Upcoming Sessions (Registered) Details */}
                    <Route path={`${this.props.match.url}/upcoming`}>
                        <DashboardDetails
                            pageId={'registeredUpcoming-details'}
                            sessions={this.state.upcomingRegisteredSessions}
                            recommendedSessions={this.state.recommendedSessions}
                            noData={
                                this.isPastDateRange()
                                    ? 'N/A'
                                    : this.state.upcomingRegisteredSessions.length
                            }
                            getNotRegisteredOrAttendedStaffList={
                                this.getNotRegisteredOrAttendedStaffList
                            }
                            loading={this.state.loading}
                            loadContent={this.loadContent}
                            defaultSelectedIndex={this.state.upcomingSelectedIndex}
                            hideDateFilter={this.hideDateFilter}
                        />
                    </Route>
                    {/* Attended Sessions Details */}
                    <Route path={`${this.props.match.url}/attended`}>
                        <DashboardDetails
                            pageId={'sessionAttendance-details'}
                            sessions={this.state.attendedSessions}
                            noData={
                                this.isFutureDateRange() ? 'N/A' : this.state.attendedSessions.length
                            }
                            getNotRegisteredOrAttendedStaffList={
                                this.getNotRegisteredOrAttendedStaffList
                            }
                            loading={this.state.loading}
                            loadContent={this.loadContent}
                            defaultSelectedIndex={0}
                            hideDateFilter={this.hideDateFilter}
                        />
                    </Route>
                    {/* Recommended Sessions Details */}
                    <Route path={`${this.props.match.url}/recommended`}>
                        <DashboardDetails
                            pageId={'recommendedSessions-details'}
                            sessions={this.state.recommendedSessions}
                            noData={this.state.recommendedSessionsCount}
                            staff={this.state.staff}
                            loading={this.state.loading}
                            loadContent={this.loadContent}
                            defaultSelectedIndex={this.state.recommendedSelectedIndex}
                            hideDateFilter={this.hideDateFilter}
                        />
                    </Route>
                    {/* Mandatory Attendance Details */}
                    <Route path={`${this.props.match.url}/mandatory`}>
                        <DashboardDetails
                            pageId={'mandatoryAttendance-details'}
                            sessions={this.state.mandatoryAttendance.courses}
                            noData={
                                this.isFutureDateRange()
                                    ? 'N/A'
                                    : this.state.mandatoryAttendance.courses.length
                            }
                            getNotRegisteredOrAttendedStaffList={
                                this.getNotRegisteredOrAttendedStaffList
                            }
                            loading={this.state.loading}
                            loadContent={this.loadContent}
                            defaultSelectedIndex={0}
                            hideDateFilter={this.hideDateFilter}
                        />
                    </Route>

                    <Route exact path={`${this.props.match.url}`}>
                        <>
                            <Row className={styles.cardRows}>
                                <Col xs="3">
                                    {/* Registered and Attended */}
                                    <Row className={styles.bottomRows}>
                                        {/* Mandatory Attendance */}
                                        <StatCard
                                            id={'mandatoryAttendance'}
                                            statLabel="Mandatory Attendance"
                                            stats={[
                                                {
                                                    label: 'Attended',
                                                    value:
                                                        this.state.mandatoryAttendance.cumulativeAttendance != null
                                                            ? this.state.mandatoryAttendance.cumulativeAttendance +
                                                            '%'
                                                            : 'N/A',
                                                    details: this.state.mandatoryAttendance.courses,
                                                    transform_filter: (mandatoryAttendanceCourse) => {
                                                        let attendanceRatio =
                                                            mandatoryAttendanceCourse.attendanceRatio != null
                                                                ? mandatoryAttendanceCourse.attendanceRatio + '%'
                                                                : 'N/A';
                                                        let special =
                                                            mandatoryAttendanceCourse.futureCourse ||
                                                            mandatoryAttendanceCourse.allSessionsInTimeFrame !==
                                                            true;
                                                        return (
                                                            <span>
                                                                {mandatoryAttendanceCourse.courseDetails != null
                                                                    ? mandatoryAttendanceCourse.courseDetails.courseName
                                                                    : ''}{' '}
                                                                {special ? (
                                                                    <span className={styles['special-star']}>*</span>
                                                                ) : (
                                                                    ''
                                                                )}{' '}
                                                                ({attendanceRatio})
                                                            </span>
                                                        );
                                                    }
                                                },
                                                {
                                                    label: 'Offered',
                                                    value: this.state.mandatoryAttendance.courses.length,
                                                    details: this.state.mandatoryAttendance.courses,
                                                    transform_filter: (mandatoryAttendanceCourse) =>
                                                        `${mandatoryAttendanceCourse.courseDetails != null
                                                            ? mandatoryAttendanceCourse.courseDetails.courseName
                                                            : ''
                                                        } (${mandatoryAttendanceCourse.attendanceRatio != null
                                                            ? mandatoryAttendanceCourse.attendanceRatio + '%'
                                                            : 'N/A'
                                                        })`
                                                }
                                            ]}
                                            detailsRoute="mandatory"
                                            loading={this.state.loading}
                                        />
                                        {/* Upcoming Sessions (Registered) */}
                                        <StatCard
                                            id="registeredUpcoming"
                                            statLabel="Upcoming Sessions"
                                            stats={[
                                                {
                                                    label: 'Registrants',
                                                    value: this.isPastDateRange()
                                                        ? 'N/A'
                                                        : this.state.upcomingRegisteredSessions.filter(
                                                            (session) => session.registeredStaffCount > 0
                                                        ).length,
                                                    details: this.isPastDateRange()
                                                        ? 'N/A'
                                                        : this.state.upcomingRegisteredSessions.filter(
                                                            (session) => session.registeredStaffCount > 0
                                                        ),
                                                    transform_filter: (session) =>
                                                        `${session.courseDetails.courseName} (${session.registeredStaffCount})`
                                                },
                                                {
                                                    label: 'Offered',
                                                    value: this.state.upcomingRegisteredSessions.length,
                                                    details: this.state.upcomingRegisteredSessions,
                                                    transform_filter: (session) =>
                                                        `${session.courseDetails.courseName}`
                                                }
                                            ]}
                                            detailsRoute="upcoming"
                                            onClickDetails={() =>
                                                this.props.changePage('Upcoming Sessions (Registered) Details')
                                            }
                                            loading={this.state.loading}
                                        />
                                        {/* Recommended Sessions */}
                                        <StatCard
                                            id="recommendedSessions"
                                            statLabel="Recommended Sessions"
                                            stats={[
                                                {
                                                    label: 'Recommended',
                                                    value: this.state.recommendedSessionsCount,
                                                    details: this.state.recommendedSessions.filter(
                                                        (x) => x.staffRecommendedCount > 0
                                                    ),
                                                    transform_filter: (session) =>
                                                        `${session.courseDetails.courseName} (${session.staffRecommendedCount})`
                                                },
                                                {
                                                    label: 'Offered',
                                                    value: this.state.recommendedSessions.length,
                                                    details: this.state.recommendedSessions,
                                                    transform_filter: (session) =>
                                                        `${session.courseDetails.courseName}`
                                                }
                                            ]}
                                            detailsRoute="recommended"
                                            loading={this.state.loading}
                                        />
                                    </Row>
                                </Col>

                                {/* Pillars Chart */}
                                <Col xs="9" className={styles.chartContainer}>
                                    <StaffReport 
                                        staff={this.state.staff}
                                        loading={this.state.loading}
                                        mandatoryCourses={this.state.mandatoryAttendance.courses}
                                        registeredCourses={this.state.sessionRegistrations}
                                        recommendedCourses={this.state.recommendedSessions}
                                    ></StaffReport>
                                    
                                    {/*StaffReport(this.state.staff) */}
                                </Col>
                            </Row>
                        </>
                    </Route>
                </div>
            </>
        );
    }
}

ManagerDashboard.contextType = GlobalContext;
